import { formatISO } from "date-fns";
import { noop } from "lodash";
import React, { startTransition, Suspense, useCallback, useMemo } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { v4 as uuidv4 } from "uuid";

import { useQuery } from "../../hooks/useQuery";
import { useSafeMutation } from "../../hooks/useSafeMutation";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { generateGrantLabel } from "../../services/easopGrant";
import { useAlerter } from "../Alerter";
import { CountryNotUnlockedAlert } from "../Alerts/CountryNotUnlockedAlert";
import { EquityTypeInCountryNotUnlockedAlert } from "../Alerts/EquityTypeInCountryNotUnlockedAlert";
import { GranteeIsMissingRequiredInformationAlert } from "../Alerts/GranteeIsMissingRequiredInformationAlert";
import { GrantOnManagementCompaniesNotAllowedAlert } from "../Alerts/GrantOnManagementCompaniesNotAllowedAlert";
import { InstrumentNotAvailableForGranteeAlert } from "../Alerts/InstrumentNotAvailableForGranteeAlert";
import { LabelAlreadyExistsAlert } from "../Alerts/LabelAlreadyExistsAlert";
import { PTEPNotProvidedAlert } from "../Alerts/PTEPNotProvidedAlert";
import { PTEPProvidedForFixedPTEPInstrumentAlert } from "../Alerts/PTEPProvidedForFixedPTEPInstrumentAlert";
import { WorkRelationshipNotCoveredAlert } from "../Alerts/WorkRelationshipNotCoveredAlert";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { Button } from "../ui/Button";
import { SlideOver } from "../ui/SlideOver";
import {
  GrantFormSlide_CreateGrant_Mutation,
  GrantFormSlide_CreateGrant_Mutation$data,
} from "./__generated__/GrantFormSlide_CreateGrant_Mutation.graphql";
import { GrantFormSlide_DefaultGrantee$key } from "./__generated__/GrantFormSlide_DefaultGrantee.graphql";
import { GrantFormSlide_DeleteGrant_Mutation } from "./__generated__/GrantFormSlide_DeleteGrant_Mutation.graphql";
import { GrantFormSlide_EasopGrant$key } from "./__generated__/GrantFormSlide_EasopGrant.graphql";
import { GrantFormSlide_Grantees$key } from "./__generated__/GrantFormSlide_Grantees.graphql";
import { GrantFormSlide_Organization$key } from "./__generated__/GrantFormSlide_Organization.graphql";
import { GrantFormSlide_Query } from "./__generated__/GrantFormSlide_Query.graphql";
import {
  EasopGrantAttributes,
  GrantFormSlide_UpdateGrant_Mutation,
} from "./__generated__/GrantFormSlide_UpdateGrant_Mutation.graphql";
import { GrantFormSlide_Viewer$key } from "./__generated__/GrantFormSlide_Viewer.graphql";
import { GrantForm } from "./GrantForm";
import { useGrantForm, UseGrantFormInputs } from "./useGrantForm";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantFormSlide_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" })
  @refetchable(queryName: "GrantFormSlide_Organization_RefetchQuery") {
    id
    allowAcceleration
    allowEarlyExercise
    poolAvailableShares
    poolDraftedSharesBreakdown {
      total
    }
    latestFairMarketValue {
      value
    }
    grantees {
      edges {
        node {
          ...GrantFormSlide_Grantees
        }
      }
    }
    ...GrantForm_Organization @arguments(organizationId: $organizationId)
    ...useGrantForm_Organization @arguments(organizationId: $organizationId)
  }
`;

const DEFAULT_GRANTEE_FRAGMENT = graphql`
  fragment GrantFormSlide_DefaultGrantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    # eslint-disable-next-line relay/unused-fields
    workRelationship
    # eslint-disable-next-line relay/unused-fields
    equityGridLevel {
      __typename
    }
    instruments(sortBy: TaxFavored, sortDirection: DESC) {
      id
      valuation(organizationId: $organizationId) {
        valueInDollars
      }
    }
    defaultVestingSchedule {
      id
    }
  }
`;

const GRANTEES_FRAGMENT = graphql`
  fragment GrantFormSlide_Grantees on Grantee @relay(plural: true) {
    id
    grantableStatus
    instruments(sortBy: TaxFavored, sortDirection: DESC) {
      id
      ...InstrumentNotAvailableForGranteeAlert_Instrument
    }
    ...InstrumentNotAvailableForGranteeAlert_Grantee
  }
`;

const GRANT_FRAGMENT = graphql`
  fragment GrantFormSlide_EasopGrant on EasopGrant {
    id
    earlyExercise
    accelerationClause
    exercisePrice
    exercisePriceBelowFMVSetOn
    label
    grantee {
      id
    }
    instrument {
      id
    }
    quantityGranted
    postTerminationExercisePeriod {
      id
    }
    vestingSchedule {
      id
    }
    vestingStartDate
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GrantFormSlide_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...GrantForm_Viewer @arguments(organizationId: $organizationId)
  }
`;

const CREATE_GRANT_MUTATION = graphql`
  mutation GrantFormSlide_CreateGrant_Mutation(
    $attributes: EasopGrantAttributes!
  ) {
    createEasopGrant(attributes: $attributes) {
      __typename
      ... on EditEasopGrantResultFailure {
        error
      }
    }
  }
`;

const EDIT_GRANT_MUTATION = graphql`
  mutation GrantFormSlide_UpdateGrant_Mutation(
    $attributes: EasopGrantAttributes!
  ) {
    updateEasopGrant(attributes: $attributes) {
      __typename
      ... on EditEasopGrantResultFailure {
        error
      }
    }
  }
`;

const DELETE_GRANT_MUTATION = graphql`
  mutation GrantFormSlide_DeleteGrant_Mutation($id: UUID!) {
    deleteEasopGrant(id: $id)
  }
`;

type MutationErrorResponse = {
  __typename: "EditEasopGrantResultFailure";
} & GrantFormSlide_CreateGrant_Mutation$data["createEasopGrant"];

const GrantFormSlideContent_: React.FC<{
  defaultGranteeFragment?: GrantFormSlide_DefaultGrantee$key | null;
  duplication?: boolean;
  grantFragment?: GrantFormSlide_EasopGrant$key | null;
  onClose: () => void;
  onGrantCreated?: () => void;
  onGrantDeleted?: () => void;
  onGrantUpdated?: () => void;
  open: boolean;
  organizationFragment: GrantFormSlide_Organization$key;
  viewerFragment: GrantFormSlide_Viewer$key;
}> = ({
  defaultGranteeFragment = null,
  duplication = false,
  grantFragment = null,
  onClose,
  onGrantCreated = noop,
  onGrantDeleted = noop,
  onGrantUpdated = noop,
  organizationFragment,
  viewerFragment,
}) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const grantees = useFragment<GrantFormSlide_Grantees$key>(
    GRANTEES_FRAGMENT,
    organization.grantees.edges.map((edge) => edge.node),
  );
  const easopGrant = useFragment(GRANT_FRAGMENT, grantFragment);

  const defaultGrantee =
    useFragment(DEFAULT_GRANTEE_FRAGMENT, defaultGranteeFragment) ?? null;

  type UseGrantFormParameters = Parameters<typeof useGrantForm>;

  const defaultValues: UseGrantFormParameters["0"]["defaultValues"] = easopGrant
    ? {
        accelerationClause: organization.allowAcceleration
          ? easopGrant.accelerationClause
          : null,
        earlyExercise: organization.allowEarlyExercise
          ? easopGrant.earlyExercise
          : false,
        exercisePrice: easopGrant.exercisePrice,
        exercisePriceBelowFMVSetOn: easopGrant.exercisePriceBelowFMVSetOn,
        granteeId: easopGrant.grantee.id,
        instrumentId: easopGrant.instrument.id,
        label: duplication ? generateGrantLabel() : easopGrant.label,
        postTerminationExercisePeriodId:
          easopGrant.postTerminationExercisePeriod.id,
        quantityGranted: easopGrant.quantityGranted,
        vestingScheduleId: easopGrant.vestingSchedule.id,
        vestingStartDate: easopGrant.vestingStartDate,
      }
    : {
        accelerationClause: null,
        earlyExercise: false,
        exercisePrice:
          defaultGrantee?.instruments[0]?.valuation.valueInDollars ??
          organization.latestFairMarketValue?.value ??
          undefined,
        granteeId: defaultGrantee?.id,
        instrumentId: defaultGrantee?.instruments[0]?.id ?? undefined,
        label: generateGrantLabel(),
        vestingScheduleId:
          defaultGrantee?.defaultVestingSchedule?.id ?? undefined,
        vestingStartDate: formatISO(new Date(), { representation: "date" }),
      };

  const maximumGrantableShares = useMemo(() => {
    const organizationMaximumGrantableShares =
      organization.poolAvailableShares -
      organization.poolDraftedSharesBreakdown.total;

    if (!easopGrant) {
      return organizationMaximumGrantableShares;
    }

    // If we're editing a grant, we need to add the quantity of the grant back to the pool
    return organizationMaximumGrantableShares + easopGrant.quantityGranted;
  }, [
    easopGrant,
    organization.poolAvailableShares,
    organization.poolDraftedSharesBreakdown.total,
  ]);

  const form = useGrantForm({
    currentGrantId: easopGrant?.id,
    defaultValues,
    maximumGrantedQuantity: maximumGrantableShares,
    organizationFragment: organization,
  });

  const granteeId = form.watch("granteeId");
  const grantee = useMemo(
    () => grantees.find((grantee) => grantee.id === granteeId),
    [granteeId, grantees],
  );
  const instrumentId = form.watch("instrumentId");
  const instrument = useMemo(
    () =>
      grantee?.instruments.find((instrument) => instrument.id === instrumentId),
    [grantee, instrumentId],
  );

  const [editGrant] =
    useSafeMutation<GrantFormSlide_UpdateGrant_Mutation>(EDIT_GRANT_MUTATION);
  const [createGrant] = useSafeMutation<GrantFormSlide_CreateGrant_Mutation>(
    CREATE_GRANT_MUTATION,
  );
  const alerter = useAlerter();
  const handleError = (error: MutationErrorResponse["error"]) => {
    switch (error) {
      case "COUNTRY_NOT_UNLOCKED": {
        alerter.push(<CountryNotUnlockedAlert />);
        break;
      }
      case "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED": {
        alerter.push(<EquityTypeInCountryNotUnlockedAlert />);
        break;
      }
      case "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED": {
        alerter.push(<GrantOnManagementCompaniesNotAllowedAlert />);
        break;
      }
      case "GRANTEE_IS_MISSING_REQUIRED_INFORMATION": {
        alerter.push(<GranteeIsMissingRequiredInformationAlert />);
        break;
      }
      case "INSTRUMENT_NOT_AVAILABLE_FOR_GRANTEE": {
        alerter.push(
          <InstrumentNotAvailableForGranteeAlert
            granteeFragment={grantee ?? null}
            instrumentFragment={instrument ?? null}
          />,
        );
        break;
      }
      case "LABEL_ALREADY_EXISTS": {
        alerter.push(<LabelAlreadyExistsAlert />);
        break;
      }
      case "PTEP_NOT_PROVIDED": {
        alerter.push(<PTEPNotProvidedAlert />);
        break;
      }
      case "PTEP_PROVIDED_FOR_FIXED_PTEP_INSTRUMENT": {
        alerter.push(<PTEPProvidedForFixedPTEPInstrumentAlert />);
        break;
      }
      case "WORK_RELATIONSHIP_NOT_COVERED": {
        alerter.push(<WorkRelationshipNotCoveredAlert />);
        break;
      }
    }
  };

  const handleFormSubmit = form.handleSubmit(async (_data) => {
    const data = _data as UseGrantFormInputs;
    const attributes: EasopGrantAttributes = {
      accelerationClause: data.accelerationClause,
      earlyExercise: data.earlyExercise,
      exercisePrice: data.exercisePrice,
      exercisePriceBelowFMVSetOn: data.exercisePriceBelowFMVSetOn,
      granteeId: data.granteeId,
      id: !duplication && easopGrant ? easopGrant.id : uuidv4(),
      instrumentId: data.instrumentId,
      label: data.label,
      organizationId: organization.id,
      postTerminationExercisePeriodId: data.postTerminationExercisePeriodId,
      quantityGranted: data.quantityGranted,
      vestingScheduleId: data.vestingScheduleId,
      vestingStartDate: data.vestingStartDate,
    };
    if (!duplication && easopGrant) {
      const { updateEasopGrant: result } = await editGrant({
        variables: {
          attributes,
        },
      });

      if (result.__typename === "EditEasopGrantResultFailure") {
        return handleError(result.error);
      }

      onGrantUpdated();
    } else {
      const { createEasopGrant: result } = await createGrant({
        variables: {
          attributes,
        },
      });

      if (result.__typename === "EditEasopGrantResultFailure") {
        return handleError(result.error);
      }

      onGrantCreated();
    }

    onClose();
  });

  const [deleteGrant, deleteGrantMutationIsInFlight] =
    useSafeMutation<GrantFormSlide_DeleteGrant_Mutation>(DELETE_GRANT_MUTATION);

  const handleDeleteButtonClick = async () => {
    if (!easopGrant) {
      return;
    }

    await deleteGrant({
      variables: { id: easopGrant.id },
    });

    onGrantDeleted();

    onClose();
  };

  const onGranteesUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({});
    });
  }, [refetchOrganization]);

  return (
    <GrantForm
      className="flex flex-auto flex-col"
      control={form.control}
      creatingGrant={!easopGrant}
      formActions={
        !duplication && easopGrant ? (
          <>
            <Button
              disabled={deleteGrantMutationIsInFlight}
              onClick={handleDeleteButtonClick}
              size="small"
              tabIndex={-1}
              type="button"
              variant="Danger Outline"
            >
              Delete
            </Button>
            <Button
              disabled={grantee?.grantableStatus !== "GRANTABLE"}
              size="small"
              type="submit"
            >
              Update
            </Button>
          </>
        ) : (
          <Button
            disabled={grantee?.grantableStatus !== "GRANTABLE"}
            loading={form.formState.isSubmitting}
            size="small"
            type="submit"
          >
            Create
          </Button>
        )
      }
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      maximumGrantableShares={maximumGrantableShares}
      onCancel={onClose}
      onGranteesUpdated={onGranteesUpdated}
      onSubmit={handleFormSubmit}
      organizationFragment={organization}
      resetField={form.resetField}
      setValue={form.setValue}
      viewerFragment={viewer}
    />
  );
};

const QUERY = graphql`
  query GrantFormSlide_Query(
    $organizationId: OrganizationId!
    $easopGrantId: UUID
  ) {
    organization(id: $organizationId) {
      ...GrantFormSlide_Organization @arguments(organizationId: $organizationId)
    }
    easopGrant(id: $easopGrantId) {
      ...GrantFormSlide_EasopGrant
    }
    me {
      ...GrantFormSlide_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const GrantFormSlideContent: React.FC<
  {
    easopGrantId: null | string;
    organizationId: string;
  } & Omit<
    React.ComponentProps<typeof GrantFormSlideContent_>,
    "grantFragment" | "organizationFragment" | "refreshQuery" | "viewerFragment"
  >
> = ({ easopGrantId, organizationId, ...props }) => {
  const {
    query: { easopGrant, me, organization },
  } = useQuery<GrantFormSlide_Query>(QUERY, {
    easopGrantId,
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <GrantFormSlideContent_
      grantFragment={easopGrant}
      organizationFragment={organization}
      viewerFragment={me}
      {...props}
    />
  );
};

export const GrantFormSlide: React.FC<
  React.ComponentProps<typeof GrantFormSlideContent>
> = (props) => {
  const { duplication, easopGrantId, onClose, open } = props;

  const { subtitle, title } = useMemo(() => {
    if (duplication)
      return {
        subtitle: "Start creating a new draft grant by filling this form",
        title: "Duplicate grant",
      };
    if (easopGrantId)
      return {
        subtitle: "You can update the selected grant by filling this form",
        title: "Update grant",
      };
    return {
      subtitle: "Start creating a new draft grant by filling this form",
      title: "Draft a new grant",
    };
  }, [duplication, easopGrantId]);

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} subtitle={subtitle}>
          {title}
        </SlideOver.Header>
      }
      onClose={onClose}
      show={open}
    >
      <Suspense fallback={<LoadingPlaceholder />}>
        <GrantFormSlideContent {...props} />
      </Suspense>
    </SlideOver>
  );
};
