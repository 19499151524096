import { compact } from "lodash";
import { useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
  VestingScheduleFormType,
} from "../pages/Admin/Grants/Configure/VestingSchedules/FORM_SCHEMA";
import { useFormattedVestingSchedule_Organization$key } from "./__generated__/useFormattedVestingSchedule_Organization.graphql";

type LinearVestingOccurrence =
  LinearVestingScheduleFormValues["vestingOccurrence"];

type VestingSchedule = {
  cliffDurationInMonths: number;
  durationInMonths: number;
  hasCliff: boolean;
  periods: BackloadedVestingScheduleFormValues["periods"] | null;
  type: VestingScheduleFormType;
  vestedAtCliffPercentage: number;
  vestingOccurrence: LinearVestingOccurrence;
};

export type ComputeVestingScheduleNameInput = VestingSchedule;

export const VESTING_OCCURRENCE_HELPERS_MAP: Record<
  LinearVestingOccurrence,
  {
    fromMonths: (value: number) => number;
    inputStep?: number;
    label: string;
    pluralUnit?: string;
    singularUnit?: string;
    toMonths: (value: number) => number;
    vestingLabel?: string;
  }
> = {
  Every2Months: {
    fromMonths: (value) => value,
    inputStep: 2,
    label: "Every 2 months",
    pluralUnit: "months",
    singularUnit: "month",
    toMonths: (value) => value,
    vestingLabel: "vesting every two month",
  },
  Every3Months: {
    fromMonths: (value) => value / 3,
    inputStep: 1,
    label: "Quarterly",
    pluralUnit: "quarters",
    singularUnit: "quarter",
    toMonths: (value) => value * 3,
    vestingLabel: "quarterly vesting",
  },
  Every6Months: {
    fromMonths: (value) => value / 6,
    inputStep: 1,
    label: "Every semester",
    pluralUnit: "semesters",
    singularUnit: "semester",
    toMonths: (value) => value * 6,
    vestingLabel: "vesting every semester",
  },
  Every12Months: {
    fromMonths: (value) => value / 12,
    inputStep: 1,
    label: "Yearly",
    pluralUnit: "years",
    singularUnit: "year",
    toMonths: (value) => value * 12,
    vestingLabel: "yearly vesting",
  },
  EveryMonth: {
    fromMonths: (value) => value,
    inputStep: 1,
    label: "Monthly",
    pluralUnit: "months",
    singularUnit: "month",
    toMonths: (value) => value,
    vestingLabel: "monthly vesting",
  },
  Once: {
    fromMonths: () => 0,
    label: "Once",
    toMonths: () => 0,
  },
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment useFormattedVestingSchedule_Organization on Organization {
    vestingSchedules {
      id
      name
    }
  }
`;

export const VESTING_OCCURRENCE_HELPERS = Object.entries(
  VESTING_OCCURRENCE_HELPERS_MAP,
).map(([id, helpers]) => ({ id: id as LinearVestingOccurrence, ...helpers }));

const computeVestingScheduleCliffName = ({
  intl,
  vestingSchedule: {
    cliffDurationInMonths,
    hasCliff,
    type,
    vestedAtCliffPercentage,
  },
}: {
  intl: IntlShape;
  vestingSchedule: VestingSchedule;
}) => {
  if (!hasCliff) {
    return `no cliff`;
  }

  switch (type) {
    case "BACKLOADED":
      return intl.formatMessage(
        {
          defaultMessage:
            "cliff at {cliffDurationInMonths} {cliffDurationInMonths, plural, one {month} other {months}}",
        },
        { cliffDurationInMonths },
      );
    case "LINEAR":
      return intl.formatMessage(
        {
          defaultMessage:
            "cliff at {cliffDurationInMonths} {cliffDurationInMonths, plural, one {month} other {months}} ({vestedAtCliffPercentage}%)",
        },
        { cliffDurationInMonths, vestedAtCliffPercentage },
      );
  }
};

const computeVestingScheduleVestingDuration = ({
  intl,
  vestingSchedule: { durationInMonths, vestingOccurrence },
}: {
  intl: IntlShape;
  vestingSchedule: VestingSchedule;
}) => {
  if (vestingOccurrence === "Once") {
    return "All at once";
  }

  return intl.formatMessage(
    {
      defaultMessage:
        "{durationInMonths} {durationInMonths, plural, one {month} other {months}}",
    },
    { durationInMonths },
  );
};

const getVestingScheduleVestingOccurrence = ({
  vestingSchedule: { vestingOccurrence },
}: {
  vestingSchedule: VestingSchedule;
}) => VESTING_OCCURRENCE_HELPERS_MAP[vestingOccurrence].vestingLabel;

const getVestingScheduleName = ({
  intl,
  vestingSchedule,
}: {
  intl: IntlShape;
  vestingSchedule: VestingSchedule;
}) => {
  const cliff = computeVestingScheduleCliffName({
    intl,
    vestingSchedule,
  });

  const vestingDuration = computeVestingScheduleVestingDuration({
    intl,
    vestingSchedule,
  });

  const vestingOccurrenceLabel = getVestingScheduleVestingOccurrence({
    vestingSchedule,
  });

  const periodsLabel = vestingSchedule.periods
    ? vestingSchedule.periods
        .map(({ percentageVested }) => percentageVested ?? 0)
        .join("-")
    : null;

  const vestingScheduleNameWithoutPeriods = compact([
    vestingSchedule.type === "BACKLOADED"
      ? `Backloaded: ${vestingDuration}`
      : vestingDuration,
    cliff,
    vestingOccurrenceLabel,
  ]).join(", ");

  const vestingScheduleName =
    vestingSchedule.type === "BACKLOADED" && periodsLabel
      ? `${vestingScheduleNameWithoutPeriods} (${periodsLabel})`
      : vestingScheduleNameWithoutPeriods;

  return vestingScheduleName;
};

export const useComputeVestingScheduleName = ({
  currentVestingScheduleId,
  organizationFragment,
}: {
  currentVestingScheduleId: null | string;
  organizationFragment: useFormattedVestingSchedule_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const intl = useIntl();

  return useCallback(
    (vestingSchedule: VestingSchedule) => {
      const vestingScheduleName = getVestingScheduleName({
        intl,
        vestingSchedule,
      });

      const vestingSchedulesNames = organization.vestingSchedules
        .filter(
          (vestingSchedule) =>
            !currentVestingScheduleId ||
            vestingSchedule.id !== currentVestingScheduleId,
        )
        .map((vestingSchedule) => vestingSchedule.name);

      let uniqueVestingScheduleName = vestingScheduleName;

      let i = 1;
      while (vestingSchedulesNames.includes(uniqueVestingScheduleName)) {
        uniqueVestingScheduleName = `${vestingScheduleName} (${i})`;
        i = i + 1;
      }

      return uniqueVestingScheduleName;
    },
    [intl, organization.vestingSchedules, currentVestingScheduleId],
  );
};
