/**
 * @generated SignedSource<<6a24269f2202694b22eb567bee0bc205>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsUploadOrganizationView_Query$variables = {
  organizationId: string;
  search?: string | null;
};
export type DocumentsUploadOrganizationView_Query$data = {
  readonly organization: {
    readonly foundMiscellaneousLegalDocuments: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument">;
    }>;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"UploadDocumentSlideOver_Organization">;
  };
};
export type DocumentsUploadOrganizationView_Query = {
  response: DocumentsUploadOrganizationView_Query$data;
  variables: DocumentsUploadOrganizationView_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsUploadOrganizationView_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": "foundMiscellaneousLegalDocuments",
              "args": (v3/*: any*/),
              "concreteType": "OrganizationMiscellaneousLegalDocument",
              "kind": "LinkedField",
              "name": "miscellaneousLegalDocuments",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UploadDocumentSlideOver_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsUploadOrganizationView_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "foundMiscellaneousLegalDocuments",
            "args": (v3/*: any*/),
            "concreteType": "OrganizationMiscellaneousLegalDocument",
            "kind": "LinkedField",
            "name": "miscellaneousLegalDocuments",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayedName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloadUrl",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": "unlockedEquityTypesRequiringActiveSubPlanDocument",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "requiresActiveSubPlanDocument": true
                }
              }
            ],
            "concreteType": "OrganizationUnlockedEquityType",
            "kind": "LinkedField",
            "name": "unlockedEquityTypes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "equityTypeId",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "taxResidenceCountry",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": "unlockedEquityTypes(filters:{\"requiresActiveSubPlanDocument\":true})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e06ae96aa5b8e1b6e2a3debdb39b4546",
    "id": null,
    "metadata": {},
    "name": "DocumentsUploadOrganizationView_Query",
    "operationKind": "query",
    "text": "query DocumentsUploadOrganizationView_Query(\n  $organizationId: OrganizationId!\n  $search: String\n) {\n  organization(id: $organizationId) {\n    name\n    foundMiscellaneousLegalDocuments: miscellaneousLegalDocuments(search: $search) {\n      ...DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument\n    }\n    ...UploadDocumentSlideOver_Organization\n    id\n  }\n}\n\nfragment DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument on OrganizationMiscellaneousLegalDocument {\n  displayedName\n  document {\n    updatedAt\n    downloadUrl\n    id\n  }\n  ...MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument\n}\n\nfragment MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument on OrganizationMiscellaneousLegalDocument {\n  displayedName\n  document {\n    id\n  }\n  organization {\n    id\n  }\n}\n\nfragment UploadDocumentSlideOver_Organization on Organization {\n  id\n  unlockedEquityTypesRequiringActiveSubPlanDocument: unlockedEquityTypes(filters: {requiresActiveSubPlanDocument: true}) {\n    equityTypeId\n    name\n    taxResidenceCountry {\n      emoji\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c48be9ad9698a6628cc78545f89a0b1";

export default node;
