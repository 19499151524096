// Tailwind UI source: https://tailwindui.com/components/application-ui/forms/toggles#component-bcaf782196186836b6ea686e7096e734
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { forwardRef } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { ThemeColor } from "../colors";

type Color = Extract<ThemeColor, "primary" | "white">;
type Size = "medium" | "small";

const SIZES = {
  medium: {
    enabledSwitchSpanClassName: /* tailwind */ "translate-x-6",
    switchClassName: /* tailwind */ "h-7 w-13",
    switchSpanClassName: /* tailwind */ "h-6 w-6",
  },
  small: {
    enabledSwitchSpanClassName: /* tailwind */ "translate-x-5",
    switchClassName: /* tailwind */ "h-6 w-11",
    switchSpanClassName: /* tailwind */ "h-5 w-5",
  },
} as const;

interface Props {
  className?: string;
  color?: Color;
  dataCy?: string;
  disabled?: boolean;
  enabled?: boolean;
  loading?: boolean;
  name?: string;
  onChange: (checked: boolean) => void;
  size?: Size;
  srLabel?: string;
}

const bgClassName: { [color in Color]: string } = {
  primary: "bg-primary",
  white: "bg-white",
};

const focusClassName: { [color in Color]: string } = {
  primary: "focus:ring-primary",
  white: "focus:ring-white",
};

const _Toggle = forwardRef<HTMLButtonElement, Props>(function Toggle(
  {
    className,
    color = "primary",
    dataCy,
    disabled,
    enabled = false,
    loading,
    name,
    onChange,
    size = "medium",
    srLabel,
  },
  ref,
) {
  return (
    <Switch
      checked={enabled}
      className={classNames(
        enabled ? bgClassName[color] : "bg-gray-03",
        focusClassName[color],
        "relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-default disabled:opacity-30",
        SIZES[size].switchClassName,
        {
          "animate-pulse": loading,
        },
        className,
      )}
      data-cy={dataCy}
      disabled={disabled}
      name={name}
      onChange={onChange}
      ref={ref}
    >
      {srLabel && <span className="sr-only">{srLabel}</span>}
      <span
        className={classNames(
          enabled ? SIZES[size].enabledSwitchSpanClassName : "translate-x-0",
          "pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
          SIZES[size].switchSpanClassName,
        )}
      />
    </Switch>
  );
});

function ToggleForm<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & Omit<
  React.ComponentProps<typeof _Toggle>,
  "disabled" | "enabled" | "name" | "onChange"
>) {
  const controller = useController({
    control,
    name,
  });

  return (
    <_Toggle
      disabled={controller.field.disabled}
      enabled={controller.field.value}
      name={controller.field.name}
      onChange={controller.field.onChange}
      ref={controller.field.ref}
      {...props}
    />
  );
}

export const Toggle = Object.assign(_Toggle, {
  Form: ToggleForm,
  Group: Switch.Group,
  Label: Switch.Label,
});
