import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCtmsGrantIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { ModifyCTMSGrant_Query } from "./__generated__/ModifyCTMSGrant_Query.graphql";
import { ModifyCTMSGrantPageContent } from "./ModifyCTMSGrantPageContent";

const QUERY = graphql`
  query ModifyCTMSGrant_Query(
    $organizationId: OrganizationId!
    $ctmsGrantId: CtmsGrantId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...ModifyCTMSGrantPageContent_Organization
    }
    ctmsGrant(id: $ctmsGrantId) {
      ...ModifyCTMSGrantPageContent_CTMSGrant
    }
  }
`;

const ModifyCTMSGrant: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantId = useCtmsGrantIdParam();

  const {
    query: { ctmsGrant, organization },
  } = useQuery<ModifyCTMSGrant_Query>(QUERY, {
    ctmsGrantId,
    organizationId,
  });

  const navigate = useNavigate();

  const onSaveButtonClick = useCallback(() => {
    navigate(
      generatePath(
        APPLICATION_ROUTES["organizationEquityCtmsGrantModifyReview"],
        {
          ctmsGrantId,
          organizationId,
        },
      ),
    );
  }, [navigate, organizationId, ctmsGrantId]);

  if (!organization || !ctmsGrant) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName="Admin - Grants - Modify grant"
      organizationId={organization.id}
      title={`Admin | ${organization.name} modify grant`}
    >
      <ModifyCTMSGrantPageContent
        ctmsGrantFragment={ctmsGrant}
        onSaveButtonClick={onSaveButtonClick}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default ModifyCTMSGrant;
