import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { CompactFormattedCurrency } from "../../../components/Formatted/CompactFormattedCurrency";
import { FormattedFMV } from "../../../components/FormattedFMV";
import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { Toggle } from "../../../components/ui/Form/Toggle";
import { LabelValue } from "../../../components/ui/LabelValue";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { zodExtra } from "../../../helpers/zod-extra";
import { useBoolean } from "../../../hooks/useBoolean";
import { CompanySettings_EquityOffer$key } from "./__generated__/CompanySettings_EquityOffer.graphql";
import { EquityOfferCompany } from "./Company";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";

const customStrikePriceFormSchema = z.object({
  customStrikePrice: zodExtra
    .preprocessNaNToUndefined(z.number().positive())
    .nullable(),
});

const CustomStrikePriceForm: React.FC<{
  customStrikePrice: null | number;
  fairMarketValue: null | number;
  onCancel: () => void;
  onSubmit: (customStrikePrice: null | number) => void;
  strikePriceIsBeingUpdated: boolean;
}> = ({
  customStrikePrice,
  fairMarketValue,
  onCancel,
  onSubmit: _onSubmit,
  strikePriceIsBeingUpdated,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      customStrikePrice: customStrikePrice ?? fairMarketValue ?? undefined,
    },
    resolver: zodResolver(customStrikePriceFormSchema),
  });

  const onSubmit = handleSubmit((formInputs_) => {
    const formInputs = customStrikePriceFormSchema.parse(formInputs_);
    return _onSubmit(formInputs.customStrikePrice);
  });

  return (
    <form
      className="flex items-center"
      id="equity-offer-custom-strike-price-form"
      onSubmit={onSubmit}
    >
      <Controller
        control={control}
        name="customStrikePrice"
        render={({ field, fieldState }) => (
          <FormRow className="flex-grow" error={fieldState.error?.message}>
            <Input
              before="$"
              data-cy="customStrikePrice"
              id="exercise-price"
              min={0}
              onChange={(event) =>
                field.onChange(
                  event.target.value ? Number(event.target.value) : null,
                )
              }
              step={Input.FMV_DECIMAL_STEP}
              type="number"
              value={field.value ?? ""}
            />
          </FormRow>
        )}
      />
      <Button
        className="ml-4"
        loading={strikePriceIsBeingUpdated}
        size="small"
        type="submit"
        variant="Primary Full"
      >
        Save
      </Button>
      <Button
        className="ml-2"
        onClick={onCancel}
        size="small"
        variant="Secondary Full"
      >
        Cancel
      </Button>
    </form>
  );
};

const schema = z.object({
  displayCompanyInformation: z.boolean(),
});
type EquityOfferCompanyFormInputs = z.infer<typeof schema>;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment CompanySettings_EquityOffer on EquityOffer {
    id
    organization {
      id
      name
      latestValuation
      latestPricePerShare {
        value
      }
      latestFairMarketValue {
        value
      }
    }
    displayCompanyInformation
    exercisePrice
    instrument {
      isVirtual
    }
    ...Company_EquityOffer
    ...EquityOfferPreviewLayout_EquityOffer
  }
`;

const EquityOfferCompanySettingsPage_: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<CompanySettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const organization = equityOffer.organization;

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      displayCompanyInformation: equityOffer.displayCompanyInformation,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(
    async (formInputs: EquityOfferCompanyFormInputs) => {
      await equityOfferContext.updatePartialEquityOffer({
        displayCompanyInformation: formInputs.displayCompanyInformation,
      });
    },
  );

  const {
    setFalse: hideEditStrikePrice,
    setTrue: showEditStrikePrice,
    value: editStrikePrice,
  } = useBoolean(false);

  watch(() => {
    void onSubmit();
  });

  useEffect(() => {
    equityOfferContext.onStepEntered("COMPANY");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      equityOfferContext.trackStepCompleted("COMPANY");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const strikePrice =
    equityOffer.exercisePrice ??
    organization.latestFairMarketValue?.value ??
    null;

  return (
    <EquityOfferPreviewLayout
      continueButtonDataCy="aboutTheCompanyNextStepButton"
      description="Provide information that will be used to calculate the equity compensation. You can hide this information."
      equityOfferFragment={equityOffer}
      onBackClick={equityOfferContext.goToPreviousStep}
      onContinueClick={equityOfferContext.goToNextStep}
      Preview={
        <EquityOfferCompany
          equityOfferFragment={equityOffer}
          forceMobile
          hideNoticeMessages
          isVirtual={equityOffer.instrument?.isVirtual}
          preview
        />
      }
      previewClassnames={classNames({
        ["pointer-events-none opacity-50"]:
          !equityOffer.displayCompanyInformation,
      })}
      title="About the company"
    >
      <div className="flex flex-col gap-4">
        <form id="equity-offer-company-form">
          <NoticeMessage size="Large" variant="Idea">
            <div className="flex flex-col gap-2">
              <span>
                Prices will be used to calculate the estimated value of the
                candidate&apos;s equity but you can toggle the visibility of
                those prices to the candidate.
              </span>
              <div className="flex items-center gap-2 text-black-07">
                <span>Visible to candidate</span>
                <Controller
                  control={control}
                  name="displayCompanyInformation"
                  render={({ field }) => (
                    <Toggle
                      dataCy="display-company-information-toggle"
                      enabled={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>
          </NoticeMessage>
        </form>
        {organization.latestValuation !== null && (
          <LabelValue className="gap-1" label="Current Company Valuation">
            <CompactFormattedCurrency value={organization.latestValuation} />
          </LabelValue>
        )}
        {organization.latestPricePerShare && (
          <LabelValue className="gap-1" label="Current Price Per Share">
            <FormattedFMV value={organization.latestPricePerShare.value} />
          </LabelValue>
        )}
        {editStrikePrice ? (
          <LabelValue className="w-full gap-1" label="Current Strike Price">
            <CustomStrikePriceForm
              customStrikePrice={equityOffer.exercisePrice}
              fairMarketValue={
                organization.latestFairMarketValue?.value ?? null
              }
              onCancel={hideEditStrikePrice}
              onSubmit={async (customStrikePrice) => {
                await equityOfferContext.notDebouncedUpdatePartialEquityOffer({
                  exercisePrice: customStrikePrice,
                });
                hideEditStrikePrice();
              }}
              strikePriceIsBeingUpdated={
                equityOfferContext.equityOfferIsBeingUpdated
              }
            />
          </LabelValue>
        ) : (
          <div className="flex items-center justify-between">
            <LabelValue className="gap-1" label="Current Strike Price">
              {strikePrice !== null ? (
                <FormattedFMV value={strikePrice} />
              ) : (
                "-"
              )}
            </LabelValue>
            <Button
              onClick={showEditStrikePrice}
              size="small"
              variant="Secondary Full"
            >
              Modify strike price
            </Button>
          </div>
        )}
      </div>
    </EquityOfferPreviewLayout>
  );
};

const EquityOfferCompanySettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<CompanySettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Company"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer company configuration`}
    >
      <EquityOfferCompanySettingsPage_ />
    </Page>
  );
};

export default EquityOfferCompanySettingsPage;
