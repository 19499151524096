/**
 * @generated SignedSource<<cba877acae0cd000610dd7807958a83a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Overview_Admin_Organization$data = {
  readonly hasBeenOnboarded: boolean;
  readonly id: string;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly meAsAnAdmin: {
    readonly geographiesPageHasBeenVisited: boolean;
    readonly granteePortalSettingsPageHasBeenVisited: boolean;
    readonly insightsPageHasBeenVisited: boolean;
  } | null;
  readonly name: string;
  readonly planIsFreemium: boolean;
  readonly remoteEquityPlanThresholdsExceeded: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"AdminEntitiesOverview_Organization" | "AdminTasksCard_Organization" | "AdminUpcomingCard_Organization" | "EquityOverviewCard_Organization" | "EsopPoolChartCard_Organization" | "FairMarketValueHistoryCard_Organization" | "FairMarketValueHistoryChart_Organization" | "GrantedSharesBreakdownCard_Organization" | "OnboardingSlider_Organization" | "OrganizationInfoCard_Organization" | "OverviewHero_Organization" | "PricePerShareHistoryCard_Organization" | "PricePerShareHistoryChart_Organization">;
  readonly " $fragmentType": "Overview_Admin_Organization";
};
export type Overview_Admin_Organization$key = {
  readonly " $data"?: Overview_Admin_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Overview_Admin_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Overview_Admin_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planIsFreemium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenOnboarded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanThresholdsExceeded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Admin",
      "kind": "LinkedField",
      "name": "meAsAnAdmin",
      "plural": false,
      "selections": [
        {
          "alias": "geographiesPageHasBeenVisited",
          "args": [
            {
              "kind": "Literal",
              "name": "page",
              "value": "GEOGRAPHIES"
            }
          ],
          "kind": "ScalarField",
          "name": "pageHasBeenVisited",
          "storageKey": "pageHasBeenVisited(page:\"GEOGRAPHIES\")"
        },
        {
          "alias": "granteePortalSettingsPageHasBeenVisited",
          "args": [
            {
              "kind": "Literal",
              "name": "page",
              "value": "GRANTEE_PORTAL_SETTINGS"
            }
          ],
          "kind": "ScalarField",
          "name": "pageHasBeenVisited",
          "storageKey": "pageHasBeenVisited(page:\"GRANTEE_PORTAL_SETTINGS\")"
        },
        {
          "alias": "insightsPageHasBeenVisited",
          "args": [
            {
              "kind": "Literal",
              "name": "page",
              "value": "INSIGHTS"
            }
          ],
          "kind": "ScalarField",
          "name": "pageHasBeenVisited",
          "storageKey": "pageHasBeenVisited(page:\"INSIGHTS\")"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationInfoCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminEntitiesOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EsopPoolChartCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PricePerShareHistoryCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PricePerShareHistoryChart_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminTasksCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminUpcomingCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOverviewCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FairMarketValueHistoryCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FairMarketValueHistoryChart_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewHero_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantedSharesBreakdownCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingSlider_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "9d1b3b24a7b182a9ba399f1ad1aa1a86";

export default node;
