import { addDays, differenceInDays, isPast } from "date-fns";
import { compact, isEmpty } from "lodash";
import React, { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useApplicationSupportEmailAddress } from "../hooks/useApplicationTheme";
import {
  AdminUpcomingCard_Organization$data,
  AdminUpcomingCard_Organization$key,
} from "./__generated__/AdminUpcomingCard_Organization.graphql";
import { GrantsInTheirLastExerciseDaysWarningMessage } from "./GrantsInTheirLastExerciseDaysWarningMessage";
import { Card } from "./ui/Card";
import { NoticeMessage } from "./ui/NoticeMessage";
import { ValuationWarningMessage } from "./ValuationWarningMessage";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminUpcomingCard_Organization on Organization {
    name
    organizationGeographies {
      country {
        code
      }
      taxFavoredSubplanExpirationDate
    }
    hasCTMSGrantsInTheirLastExerciseDays
    valuationWarnings {
      reason
      valuationType
      ...ValuationWarningMessage_ValuationWarning
    }
    ...ValuationWarningMessage_Organization
    ...GrantsInTheirLastExerciseDaysWarningMessage_Organization
  }
`;

const BSPCEExpirationWarningMessage: React.FC<{
  expirationDate: Date;
  organization: AdminUpcomingCard_Organization$data;
}> = ({ expirationDate, organization }) => {
  const mailtoSubjectBSPCE = encodeURI(
    `[${organization.name}] What should I do in order to refresh my BSPCE board authorisation?`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const daysBeforeExpiration = useMemo(
    () => differenceInDays(expirationDate, new Date()),
    [expirationDate],
  );

  if (daysBeforeExpiration > 0) {
    return (
      <NoticeMessage hasColor={false} size="Large" variant="Danger">
        The board authorisation to issue BSPCE expires in{" "}
        <FormattedNumber style="unit" unit="day" value={daysBeforeExpiration} />
        . Once it has expired, you won’t be able to make new BSPCE grants. If
        you plan to keep on granting BSPCE in the future, you’ll need to refresh
        the board authorisation.{" "}
        <a
          className="font-medium text-primary"
          href={`mailto:${supportEmailAddress}?subject=${mailtoSubjectBSPCE}`}
        >
          Contact us
        </a>{" "}
        to learn more.
      </NoticeMessage>
    );
  }

  return (
    <NoticeMessage
      size="Large"
      title="The board authorisation to issue BSPCE has expired"
      variant="Danger"
    >
      You are no longer able to make new BSPCE grants. If you plan to keep on
      granting BSPCE, you need to refresh the board authorisation.{" "}
      <a
        className="font-medium text-primary"
        href={`mailto:${supportEmailAddress}?subject=${mailtoSubjectBSPCE}`}
      >
        Contact us
      </a>{" "}
      to learn more.
    </NoticeMessage>
  );
};

export const AdminUpcomingCard: React.FC<{
  className?: string;
  organizationFragment: AdminUpcomingCard_Organization$key;
}> = ({ className, organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const BSPCESubplanExpirationDate = useMemo(() => {
    const frenchOrganizationGeography =
      organization.organizationGeographies.find(
        ({ country: { code: countryCode } }) => countryCode === "FR",
      );

    return frenchOrganizationGeography?.taxFavoredSubplanExpirationDate
      ? new Date(frenchOrganizationGeography.taxFavoredSubplanExpirationDate)
      : null;
  }, [organization]);

  const isBSPCESubplanExpiringSoon = useMemo(
    () =>
      BSPCESubplanExpirationDate
        ? isPast(addDays(BSPCESubplanExpirationDate, -60))
        : false,
    [BSPCESubplanExpirationDate],
  );

  const warningMessages = useMemo(() => {
    const messages = [
      ...organization.valuationWarnings.map((warning) => (
        <ValuationWarningMessage
          key={`${warning.reason}-${warning.valuationType}`}
          organizationFragment={organization}
          size="long"
          valuationWarningFragment={warning}
        />
      )),
      isBSPCESubplanExpiringSoon && BSPCESubplanExpirationDate && (
        <BSPCEExpirationWarningMessage
          expirationDate={BSPCESubplanExpirationDate}
          key="BSPCEExpirationWarningMessage"
          organization={organization}
        />
      ),
      organization.hasCTMSGrantsInTheirLastExerciseDays && (
        <GrantsInTheirLastExerciseDaysWarningMessage
          key="GrantsInTheirLastExerciseDaysWarningMessage"
          organizationFragment={organization}
        />
      ),
    ];

    return compact(messages);
  }, [isBSPCESubplanExpiringSoon, BSPCESubplanExpirationDate, organization]);

  return (
    <Card className={className} title="Upcoming" {...props}>
      {isEmpty(warningMessages) ? (
        <NoticeMessage hasColor={false} size="Large" variant="Info">
          You have nothing requiring your attention.
        </NoticeMessage>
      ) : (
        <div className="space-y-2">{...warningMessages}</div>
      )}
    </Card>
  );
};
