import { isEqual } from "lodash";
import React, { useContext, useMemo, useReducer } from "react";
import { graphql, useFragment } from "react-relay";

import {
  Context_CTMSGrantAmendmentRequest$data,
  Context_CTMSGrantAmendmentRequest$key,
} from "./__generated__/Context_CTMSGrantAmendmentRequest.graphql";
import {
  NewAccelerationAllowedOption,
  NewAccelerationAllowedSliderFormFieldValues,
} from "./NewAccelerationAllowedSlider";
import {
  NewEarlyExerciseAllowedOption,
  NewEarlyExerciseAllowedSliderFormFieldValues,
} from "./NewEarlyExerciseAllowedSlider";

type ModifyCTMSGrantContextValues = {
  newAccelerationAllowedValue: NewAccelerationAllowedSliderFormFieldValues | null;
  newEarlyExerciseAllowedValue: NewEarlyExerciseAllowedSliderFormFieldValues | null;
  newOrganizationPostTerminationExercisePeriodId: null | string;
  newVestingScheduleId: null | string;
  newVestingStartDate: null | string;
  totalAccelerationDate: null | string;
  waiveCliff: boolean | null;
};

type ReducerAction =
  | {
      newAccelerationAllowedValue: NewAccelerationAllowedSliderFormFieldValues;
      type: "SET_NEW_ACCELERATION_ALLOWED_VALUE";
    }
  | {
      newEarlyExerciseAllowedValue: NewEarlyExerciseAllowedSliderFormFieldValues;
      type: "SET_NEW_EARLY_EXERCISE_ALLOWED_VALUE";
    }
  | {
      newOrganizationPostTerminationExercisePeriodId: string;
      type: "SET_NEW_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD";
    }
  | {
      newVestingScheduleId: string;
      type: "SET_NEW_VESTING_SCHEDULE";
    }
  | {
      newVestingStartDate: string;
      type: "SET_NEW_VESTING_START_DATE";
    }
  | {
      totalAccelerationDate: string;
      type: "SET_TOTAL_ACCELERATION_DATE";
    }
  | {
      type: "SET_WAIVE_CLIFF";
      waiveCliff: boolean;
    };

interface ModifyCTMSGrantContext {
  dispatchAction: React.Dispatch<ReducerAction>;
  existingCTMSGrantAmendmentRequest: Context_CTMSGrantAmendmentRequest$data | null;
  isEmpty: boolean;
  state: ModifyCTMSGrantContextValues;
}

const reduce: React.Reducer<ModifyCTMSGrantContextValues, ReducerAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case "SET_NEW_ACCELERATION_ALLOWED_VALUE": {
      return {
        ...state,
        newAccelerationAllowedValue: action.newAccelerationAllowedValue,
      };
    }
    case "SET_NEW_EARLY_EXERCISE_ALLOWED_VALUE": {
      return {
        ...state,
        newEarlyExerciseAllowedValue: action.newEarlyExerciseAllowedValue,
      };
    }
    case "SET_NEW_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD": {
      return {
        ...state,
        newOrganizationPostTerminationExercisePeriodId:
          action.newOrganizationPostTerminationExercisePeriodId,
      };
    }
    case "SET_NEW_VESTING_SCHEDULE": {
      return {
        ...state,
        newVestingScheduleId: action.newVestingScheduleId,
      };
    }
    case "SET_NEW_VESTING_START_DATE": {
      return {
        ...state,
        newVestingStartDate: action.newVestingStartDate,
      };
    }
    case "SET_TOTAL_ACCELERATION_DATE": {
      return {
        ...state,
        totalAccelerationDate: action.totalAccelerationDate,
      };
    }
    case "SET_WAIVE_CLIFF": {
      return {
        ...state,
        waiveCliff: action.waiveCliff,
      };
    }
  }
};
const CTMS_GRANT_AMENDMENT_REQUEST_FRAGMENT = graphql`
  fragment Context_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {
    postTerminationExercisePeriodIsModified
    postTerminationExercisePeriod {
      id
    }
    accelerationClauseIsModified
    accelerationClause
    earlyExerciseIsModified
    earlyExercise
    # eslint-disable-next-line relay/unused-fields
    status
    vestingSchedule {
      id
    }
    vestingStartDate
    totalAccelerationDate
    waiveCliff
  }
`;

const useNewOrganizationPostTerminationExercisePeriodIdFromCTMSGrantAmendmentRequest =
  ({
    ctmsGrantAmendmentRequest,
  }: {
    ctmsGrantAmendmentRequest: Context_CTMSGrantAmendmentRequest$data | null;
  }): ModifyCTMSGrantContextValues["newOrganizationPostTerminationExercisePeriodId"] => {
    if (!ctmsGrantAmendmentRequest) return null;

    if (!ctmsGrantAmendmentRequest.postTerminationExercisePeriodIsModified)
      return null;

    if (!ctmsGrantAmendmentRequest.postTerminationExercisePeriod) return null;

    return ctmsGrantAmendmentRequest.postTerminationExercisePeriod.id;
  };

const useNewAccelerationAllowedValueFromCTMSGrantAmendmentRequest = ({
  ctmsGrantAmendmentRequest,
}: {
  ctmsGrantAmendmentRequest: Context_CTMSGrantAmendmentRequest$data | null;
}): ModifyCTMSGrantContextValues["newAccelerationAllowedValue"] => {
  if (!ctmsGrantAmendmentRequest) return null;

  if (!ctmsGrantAmendmentRequest.accelerationClauseIsModified) return null;

  if (!ctmsGrantAmendmentRequest.accelerationClause)
    return { acceleration: NewAccelerationAllowedOption.NOT_ALLOWED };

  return {
    acceleration: NewAccelerationAllowedOption.ALLOWED,
    accelerationClause: ctmsGrantAmendmentRequest.accelerationClause,
  };
};

const useNewEarlyExerciseAllowedValueFromCTMSGrantAmendmentRequest = ({
  ctmsGrantAmendmentRequest,
}: {
  ctmsGrantAmendmentRequest: Context_CTMSGrantAmendmentRequest$data | null;
}): ModifyCTMSGrantContextValues["newEarlyExerciseAllowedValue"] => {
  if (!ctmsGrantAmendmentRequest) return null;

  if (!ctmsGrantAmendmentRequest.earlyExerciseIsModified) return null;

  return {
    earlyExercise: ctmsGrantAmendmentRequest.earlyExercise
      ? NewEarlyExerciseAllowedOption.ALLOWED
      : NewEarlyExerciseAllowedOption.NOT_ALLOWED,
  };
};

const useInitialStateFromCTMSGrantAmendmentRequest = ({
  ctmsGrantAmendmentRequest,
}: {
  ctmsGrantAmendmentRequest: Context_CTMSGrantAmendmentRequest$data | null;
}): ModifyCTMSGrantContextValues => {
  const newOrganizationPostTerminationExercisePeriodId =
    useNewOrganizationPostTerminationExercisePeriodIdFromCTMSGrantAmendmentRequest(
      {
        ctmsGrantAmendmentRequest,
      },
    );
  const newAccelerationAllowedValue =
    useNewAccelerationAllowedValueFromCTMSGrantAmendmentRequest({
      ctmsGrantAmendmentRequest,
    });
  const newEarlyExerciseAllowedValue =
    useNewEarlyExerciseAllowedValueFromCTMSGrantAmendmentRequest({
      ctmsGrantAmendmentRequest,
    });

  return useMemo(
    () => ({
      newAccelerationAllowedValue,
      newEarlyExerciseAllowedValue,
      newOrganizationPostTerminationExercisePeriodId,
      newVestingScheduleId:
        ctmsGrantAmendmentRequest?.vestingSchedule?.id ?? null,
      newVestingStartDate: ctmsGrantAmendmentRequest?.vestingStartDate ?? null,
      totalAccelerationDate:
        ctmsGrantAmendmentRequest?.totalAccelerationDate ?? null,
      waiveCliff: ctmsGrantAmendmentRequest?.waiveCliff ?? null,
    }),
    [
      newOrganizationPostTerminationExercisePeriodId,
      newAccelerationAllowedValue,
      newEarlyExerciseAllowedValue,
      ctmsGrantAmendmentRequest,
    ],
  );
};

export const ModifyCTMSGrantContextProvider: React.FC<{
  children: React.ReactNode;
  ctmsGrantAmendmentRequestFragment: Context_CTMSGrantAmendmentRequest$key | null;
}> = ({ children, ctmsGrantAmendmentRequestFragment }) => {
  const ctmsGrantAmendmentRequest =
    useFragment(
      CTMS_GRANT_AMENDMENT_REQUEST_FRAGMENT,
      ctmsGrantAmendmentRequestFragment,
    ) ?? null;

  const initialState = useInitialStateFromCTMSGrantAmendmentRequest({
    ctmsGrantAmendmentRequest,
  });

  const [state, dispatchAction] = useReducer(reduce, initialState);

  const isEmpty = useMemo(
    () => isEqual(state, initialState),
    [state, initialState],
  );

  return (
    <Context.Provider
      value={{
        dispatchAction,
        existingCTMSGrantAmendmentRequest: ctmsGrantAmendmentRequest,
        isEmpty,
        state,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const Context = React.createContext<ModifyCTMSGrantContext | null>(null);

export const useModifyCTMSGrantContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get modify CTMSGrant context");
  }

  return context;
};
