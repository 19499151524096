import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { AlertElementProps } from "../Alerter";
import { Alert } from "../ui/Alert";
import { InstrumentNotAvailableForGranteeAlert_Grantee$key } from "./__generated__/InstrumentNotAvailableForGranteeAlert_Grantee.graphql";
import { InstrumentNotAvailableForGranteeAlert_Instrument$key } from "./__generated__/InstrumentNotAvailableForGranteeAlert_Instrument.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment InstrumentNotAvailableForGranteeAlert_Grantee on Grantee {
    taxResidenceCountry {
      name
    }
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment InstrumentNotAvailableForGranteeAlert_Instrument on Instrument {
    name
  }
`;

export const InstrumentNotAvailableForGranteeAlert: React.FC<
  {
    granteeFragment: InstrumentNotAvailableForGranteeAlert_Grantee$key | null;
    instrumentFragment: InstrumentNotAvailableForGranteeAlert_Instrument$key | null;
  } & Omit<AlertElementProps, "children" | "title">
> = ({ granteeFragment, instrumentFragment, ...props }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);

  if (!grantee) {
    return (
      <Alert title="" {...props}>
        No grantee selected
      </Alert>
    );
  }

  if (!instrument) {
    return (
      <Alert title="" {...props}>
        No instrument selected
      </Alert>
    );
  }

  if (!grantee.taxResidenceCountry) {
    return (
      <Alert title="" {...props}>
        Grantee is missing tax residence country information
      </Alert>
    );
  }

  return (
    <Alert title="" {...props}>
      The equity incentive you chose ({instrument.name}) is not available for a
      grantee with their tax residence in {grantee.taxResidenceCountry.name}
    </Alert>
  );
};
