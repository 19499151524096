/**
 * @generated SignedSource<<2c25f6487437b7fee7c26e571292e03b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalWatchOutsPage_Organization$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationModal_Organization">;
  readonly " $fragmentType": "LegalWatchOutsPage_Organization";
}>;
export type LegalWatchOutsPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: LegalWatchOutsPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LegalWatchOutsPage_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "fbd927349d41429e2e2d0191b9db2cde";

export default node;
