/**
 * @generated SignedSource<<18bc0d36ec363f915318693d70e32db9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnusedGeographies_Query$variables = Record<PropertyKey, never>;
export type UnusedGeographies_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UnusedGeographies_Organization">;
  }>;
};
export type UnusedGeographies_Query = {
  response: UnusedGeographies_Query$data;
  variables: UnusedGeographies_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnusedGeographies_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnusedGeographies_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnusedGeographies_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "topCompany",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationGeography",
            "kind": "LinkedField",
            "name": "organizationGeographies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasDraftsPending",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unlockedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Geography",
                "kind": "LinkedField",
                "name": "geography",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "country",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emoji",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationSpecificPrice",
                "kind": "LinkedField",
                "name": "price",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceAfterDiscount",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billableGranteesCount",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4349e70ff63c092a9aa671232c66b2d3",
    "id": null,
    "metadata": {},
    "name": "UnusedGeographies_Query",
    "operationKind": "query",
    "text": "query UnusedGeographies_Query {\n  organizations {\n    ...UnusedGeographies_Organization\n    id\n  }\n}\n\nfragment UnusedGeographies_Organization on Organization {\n  id\n  name\n  topCompany {\n    country {\n      code\n    }\n    id\n  }\n  organizationGeographies {\n    hasDraftsPending\n    unlockedAt\n    geography {\n      country {\n        emoji\n        name\n        code\n      }\n    }\n    price {\n      priceAfterDiscount\n      id\n    }\n    billableGranteesCount\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4098320ef01eb7131a405176fec32fe5";

export default node;
