import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import _, { isNil, round } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  GranteePortalProjectionScenarioKey,
  ProjectionTable_GranteePortalProjectionScenarios$key,
} from "./__generated__/ProjectionTable_GranteePortalProjectionScenarios.graphql";
import { CompactFormattedCurrency } from "./Formatted/CompactFormattedCurrency";
import { FormattedFMV } from "./FormattedFMV";
import { ProjectionGraphClassNames } from "./ProjectionGraph";
import { Table } from "./ui/Table";
import { Typography } from "./ui/Typography";

const GRANTEE_PORTAL_PROJECTION_SCENARIOS_FRAGMENT = graphql`
  fragment ProjectionTable_GranteePortalProjectionScenarios on GranteePortalProjectionScenario
  @relay(plural: true) {
    key
    name
    multiple
    displayed
    additionalInformation
  }
`;

const Row: React.FC<{
  additionalInformation: null | string;
  multiple: null | number;
  name: string;
  pricePerShare: null | number;
  scenarioKey: "VALUE_AT_GRANT" | GranteePortalProjectionScenarioKey;
  value: number;
}> = ({
  additionalInformation,
  multiple,
  name,
  pricePerShare,
  scenarioKey,
  value,
}) => {
  return (
    <Table.Row>
      <Table.Cell className="whitespace-nowrap">
        <div className="flex items-center gap-2">
          <div
            className={classNames(
              "h-2.5 w-2.5 rounded-sm",
              ProjectionGraphClassNames[scenarioKey].full,
            )}
          ></div>
          <span>{name}</span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            {multiple ? `x${multiple}` : "-"}
          </Typography>
          {!isNil(pricePerShare) && (
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              Price per share <FormattedFMV value={pricePerShare} />
            </Typography>
          )}
        </div>
      </Table.Cell>
      <Table.Cell className="text-black-05" variant="Regular/Caption">
        {additionalInformation || "-"}
      </Table.Cell>
      <Table.Cell>
        <div className="flex justify-end">
          <Typography
            className={classNames(
              "ml-auto flex items-center gap-1 rounded-2xl py-1",
              ProjectionGraphClassNames[scenarioKey].light,
              scenarioKey === "VALUE_AT_GRANT" ? "px-4" : "pl-2 pr-4",
            )}
            variant="Medium/Extra Small"
          >
            {scenarioKey !== "VALUE_AT_GRANT" && (
              <ArrowSmallRightIcon
                className={classNames("h-4 w-4", {
                  "-rotate-45": scenarioKey !== "CURRENT_VALUE",
                })}
              />
            )}
            <CompactFormattedCurrency value={value} />
          </Typography>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const ProjectionTable: React.FC<{
  className?: string;
  latestPricePerShareValue: number;
  projectionScenariosFragment: ProjectionTable_GranteePortalProjectionScenarios$key;
  shares: number;
  valueAtGrant: null | number;
}> = ({
  className,
  latestPricePerShareValue,
  projectionScenariosFragment,
  shares,
  valueAtGrant,
}) => {
  const granteePortalProjectionScenarios = useFragment(
    GRANTEE_PORTAL_PROJECTION_SCENARIOS_FRAGMENT,
    projectionScenariosFragment,
  );

  const sharesToValue = useCallback(
    (shares: number) => round(shares * latestPricePerShareValue, 4),
    [latestPricePerShareValue],
  );

  const projectionScenarios = useMemo(() => {
    return _([
      "CURRENT_VALUE",
      "FIRST_ROUND",
      "SECOND_ROUND",
      "THIRD_ROUND",
    ] as const)
      .map((key) =>
        granteePortalProjectionScenarios.find(
          (projectionScenario) => projectionScenario.key === key,
        ),
      )
      .compact()
      .filter((scenario) => scenario.displayed)
      .value();
  }, [granteePortalProjectionScenarios]);

  return (
    <Table className={className}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Scenario</Table.HeaderCell>
          <Table.HeaderCell>Multiplier & Price per share</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
          <Table.HeaderCell className="whitespace-nowrap text-right">
            Potential value $
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!isNil(valueAtGrant) && (
          <Row
            additionalInformation={null}
            multiple={null}
            name="Value at grant"
            pricePerShare={null}
            scenarioKey="VALUE_AT_GRANT"
            value={valueAtGrant}
          />
        )}
        {projectionScenarios.map((scenario) => (
          <Row
            additionalInformation={scenario.additionalInformation}
            key={scenario.key}
            multiple={
              scenario.key !== "CURRENT_VALUE" ? scenario.multiple : null
            }
            name={scenario.name}
            pricePerShare={sharesToValue(1 * scenario.multiple)}
            scenarioKey={scenario.key}
            value={sharesToValue(shares * scenario.multiple)}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
