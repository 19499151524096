import classNames from "classnames";
import { motion } from "framer-motion";
import React from "react";

import { Typography } from "./Typography";

const _Switch = <T,>({
  getOptionLabel,
  getOptionValue,
  loading,
  name,
  onChange,
  options,
  selectedOption,
}: {
  getOptionLabel?: (option: T, selected: boolean) => React.ReactNode;
  getOptionValue: (option: T) => string;
  loading?: boolean;
  name: string;
  onChange: (option: T) => void;
  options: readonly T[];
  selectedOption: T;
}) => {
  const selectedOptionValue = getOptionValue(selectedOption);

  return (
    <div
      className={classNames(
        "flex w-fit flex-wrap items-center justify-center rounded-full bg-gray-02 p-0.5",
        {
          "animate-pulse": loading,
        },
      )}
    >
      {options.map((option) => {
        const optionValue = getOptionValue(option);
        const selectedOptionLabel =
          getOptionLabel?.(option, true) ?? optionValue;
        const notSelectedOptionLabel =
          getOptionLabel?.(option, false) ?? optionValue;
        const selected = selectedOptionValue === optionValue;

        return (
          <button
            className="relative flex flex-1 items-center justify-center px-4 py-1.5 transition-all"
            data-cy={`switch-${optionValue.toLowerCase().replace(/_/g, "-")}`}
            key={optionValue}
            onClick={() => onChange(option)}
            type="button"
          >
            {/* use absolution positionning with opacity so that button always take the same size whether or not it is selected */}
            {selected && (
              <motion.div
                className={classNames(
                  "absolute inset-0 rounded-full bg-white shadow-100",
                )}
                layoutId={`${name}-switch`}
              />
            )}
            <Typography
              as="div"
              className={classNames("whitespace-nowrap transition-all", {
                "opacity-0": selected,
                "opacity-100": !selected,
              })}
              variant="Regular/Extra Small"
            >
              {notSelectedOptionLabel}
            </Typography>
            <Typography
              as="div"
              className={classNames(
                "absolute whitespace-nowrap transition-all",
                {
                  "opacity-0": !selected,
                  "opacity-100": selected,
                },
              )}
              variant="Medium/Extra Small"
            >
              {selectedOptionLabel}
            </Typography>
          </button>
        );
      })}
    </div>
  );
};

const Option: React.FC<{
  icon?: React.ReactElement<React.ComponentProps<"svg">>;
  label: string;
  selected: boolean;
}> = ({ icon, label, selected }) => {
  return (
    <div className="flex items-center gap-2">
      {icon &&
        React.cloneElement(icon, {
          className: classNames(
            "h-5 w-5",
            { "text-primary": selected },
            icon.props.className,
          ),
          ...icon.props,
        })}
      <div>{label}</div>
    </div>
  );
};

export const Switch = Object.assign(_Switch, {
  Option,
});
