import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useTransition } from "react";
import { FormattedNumber } from "react-intl";
import { useRefetchableFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { StopClickPropagationWrapper } from "../../../../../components/StopClickPropagationWrapper";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { ConfirmationModalRemote } from "../../../../../components/ui/ConfirmationModal";
import { MenuButton } from "../../../../../components/ui/MenuButton";
import { SearchBar } from "../../../../../components/ui/SearchBar";
import { Table } from "../../../../../components/ui/Table";
import { useDebounced } from "../../../../../hooks/useDebounced";
import { VESTING_OCCURRENCE_HELPERS_MAP } from "../../../../../hooks/useFormattedVestingSchedule";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { ConfigureGrantsLayout } from "../ConfigureGrantsLayout";
import {
  VestingSchedules_ConfigureEquity_Query,
  VestingSchedules_ConfigureEquity_Query$data,
} from "./__generated__/VestingSchedules_ConfigureEquity_Query.graphql";
import { VestingSchedules_DeleteVestingScheduleMutation } from "./__generated__/VestingSchedules_DeleteVestingScheduleMutation.graphql";
import { VestingSchedulesTable_VestingScheduleFragment$key } from "./__generated__/VestingSchedulesTable_VestingScheduleFragment.graphql";
import { EditSlideOverRemote } from "./EditSlideOver";

const DELETE_VESTING_SCHEDULE_MUTATION = graphql`
  mutation VestingSchedules_DeleteVestingScheduleMutation(
    $vestingScheduleId: VestingScheduleId!
  ) {
    deleteVestingSchedule(id: $vestingScheduleId) {
      __typename
    }
  }
`;

const VESTING_SCHEDULE_FRAGMENT = graphql`
  fragment VestingSchedulesTable_VestingScheduleFragment on VestingSchedule
  @refetchable(
    queryName: "VestingSchedulesTable_VestingScheduleFragment_RefetchQuery"
  ) {
    __typename
    id
    name
    cliffDurationInMonths
    durationInMonths
    ... on BackloadedVestingSchedule {
      backloadedVestingOccurrence: vestingOccurrence
    }
    ... on LinearVestingSchedule {
      linearVestingOccurrence: vestingOccurrence
    }
    easopGrantCount
    isUsed
  }
`;

function VestingScheduleTableRow({
  onDeleted,
  organization,
  vestingScheduleFragment,
}: {
  onDeleted: () => void;
  organization: VestingSchedules_ConfigureEquity_Query$data["organization"];
  vestingScheduleFragment: VestingSchedulesTable_VestingScheduleFragment$key;
}) {
  const [refetchTransitionInProgress, startRefetchTransition] = useTransition();
  const [vestingSchedule, refetchVestingSchedule] = useRefetchableFragment(
    VESTING_SCHEDULE_FRAGMENT,
    vestingScheduleFragment,
  );

  const handleClose = useCallback(() => {
    startRefetchTransition(() => {
      refetchVestingSchedule({}, { fetchPolicy: "network-only" });
    });
  }, [refetchVestingSchedule]);

  const editSlideOverController = EditSlideOverRemote.useController();

  const [
    triggerDeleteVestingScheduleMutation,
    deleteVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<VestingSchedules_DeleteVestingScheduleMutation>(
    DELETE_VESTING_SCHEDULE_MUTATION,
  );

  const ConfirmationModalController = ConfirmationModalRemote.useController();

  function handleVestingScheduleDelete() {
    ConfirmationModalController.open({
      data: {
        confirmationLabel: "Delete",
        title: "Delete vesting schedule",
      },
      onClose: async ({ confirmed } = { confirmed: false }) => {
        if (confirmed) {
          await triggerDeleteVestingScheduleMutation({
            variables: {
              vestingScheduleId: vestingSchedule.id,
            },
          });

          onDeleted();
        }
      },
    });
  }

  return (
    <Table.LinkRow
      key={vestingSchedule.id}
      skeleton={refetchTransitionInProgress}
      to={generatePath(
        APPLICATION_ROUTES[
          "organizationEquityConfigureVestingSchedulesDetailsView"
        ],
        {
          organizationId: organization.id,
          vestingScheduleId: vestingSchedule.id,
        },
      )}
    >
      <Table.Cell variant="Medium/Extra Small">
        {vestingSchedule.name}
      </Table.Cell>
      <Table.Cell variant="Regular/Extra Small">
        {vestingSchedule.durationInMonths > 0 ? (
          <FormattedNumber
            style="unit"
            unit="month"
            unitDisplay="long"
            value={vestingSchedule.durationInMonths}
          />
        ) : (
          "-"
        )}
      </Table.Cell>
      <Table.Cell variant="Regular/Extra Small">
        {vestingSchedule.cliffDurationInMonths ? (
          <FormattedNumber
            style="unit"
            unit="month"
            unitDisplay="long"
            value={vestingSchedule.cliffDurationInMonths}
          />
        ) : (
          "None"
        )}
      </Table.Cell>
      <Table.Cell variant="Regular/Extra Small">
        {
          VESTING_OCCURRENCE_HELPERS_MAP[
            vestingSchedule.linearVestingOccurrence ??
              vestingSchedule.backloadedVestingOccurrence!
          ].label
        }
      </Table.Cell>
      <Table.Cell variant="Regular/Extra Small">
        <FormattedNumber value={vestingSchedule.easopGrantCount} />
      </Table.Cell>
      <StopClickPropagationWrapper>
        <Table.Cell className="w-0">
          <MenuButton
            button={
              <Button
                leftIcon={<EllipsisVerticalIcon />}
                loading={
                  editSlideOverController.transitionInProgress ||
                  deleteVestingScheduleMutationIsInFlight
                }
                size="extra small"
                variant="Secondary Full"
              />
            }
            placement="bottom end"
          >
            <MenuButton.Item
              disabled={vestingSchedule.isUsed}
              leftIcon={<PencilSquareIcon />}
              loading={editSlideOverController.transitionInProgress}
              onClick={() => {
                editSlideOverController.open({
                  data: {
                    organizationFragment: organization,
                    vestingScheduleId: vestingSchedule.id,
                  },
                  onClose: handleClose,
                });
              }}
            >
              Edit
            </MenuButton.Item>
            <MenuButton.Item
              disabled={vestingSchedule.isUsed}
              leftIcon={<TrashIcon />}
              loading={deleteVestingScheduleMutationIsInFlight}
              onClick={handleVestingScheduleDelete}
            >
              Delete
            </MenuButton.Item>
          </MenuButton>
        </Table.Cell>
      </StopClickPropagationWrapper>
    </Table.LinkRow>
  );
}

function VestingSchedulesTable({
  loading,
  onVestingScheduleDeleted,
  organization,
}: {
  loading?: boolean;
  onVestingScheduleDeleted: () => void;
  organization: VestingSchedules_ConfigureEquity_Query$data["organization"];
}) {
  return (
    <Table.Containerized loading={loading}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Duration</Table.HeaderCell>
          <Table.HeaderCell>Cliff (months)</Table.HeaderCell>
          <Table.HeaderCell>Vesting occurs</Table.HeaderCell>
          <Table.HeaderCell>Number of grants</Table.HeaderCell>
          <Table.HeaderCell className="w-0" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {organization.foundVestingSchedules.map((vestingSchedule) => {
          return (
            <VestingScheduleTableRow
              key={vestingSchedule.id}
              onDeleted={onVestingScheduleDeleted}
              organization={organization}
              vestingScheduleFragment={vestingSchedule}
            />
          );
        })}
      </Table.Body>
    </Table.Containerized>
  );
}

const QUERY = graphql`
  query VestingSchedules_ConfigureEquity_Query(
    $organizationId: OrganizationId!
    $search: String
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      foundVestingSchedules: vestingSchedules(search: $search) {
        __typename
        id
        ...VestingSchedulesTable_VestingScheduleFragment
      }
      ...EditSlideOver_VestingSchedule_Organization
    }
  }
`;

export default function VestingSchedules() {
  const organizationId = useOrganizationIdParam();
  const {
    debouncedState: debouncedSearch,
    isDebouncing: searchIsDebouncing,
    liveState: liveSearch,
    setState: setSearch,
  } = useDebounced({
    initialState: "",
  });

  const { query, refreshQuery } =
    useQuery<VestingSchedules_ConfigureEquity_Query>(QUERY, {
      organizationId,
      search: debouncedSearch,
    });

  const [refetchTransitionInProgress, startRefetchTransition] = useTransition();

  function handleVestingScheduleDeleted() {
    startRefetchTransition(() => {
      refreshQuery();
    });
  }

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  function handleSearchChange(value: string) {
    startSearchTransition(() => {
      setSearch(value);
    });
  }

  return (
    <Page
      analyticsCategory="Admin Configure Vesting Schedules"
      analyticsName="Admin - Equity - Configure - Vesting Schedules"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity vesting schedules settings`}
    >
      <EditSlideOverRemote.Provider>
        <ConfirmationModalRemote.Provider>
          <ConfigureGrantsLayout
            rightAction={
              <LinkButton size="small" to="add-new">
                Add new
              </LinkButton>
            }
            subtitle={
              <SearchBar
                className="w-full max-w-[300px]"
                loading={searchTransitionIsInProgress || searchIsDebouncing}
                onChange={handleSearchChange}
                placeholder="Search"
                value={liveSearch}
              />
            }
            title="Vesting Schedules"
          >
            <div className="space-y-10">
              <VestingSchedulesTable
                loading={
                  refetchTransitionInProgress || searchTransitionIsInProgress
                }
                onVestingScheduleDeleted={handleVestingScheduleDeleted}
                organization={query.organization}
              />
            </div>
          </ConfigureGrantsLayout>
        </ConfirmationModalRemote.Provider>
      </EditSlideOverRemote.Provider>
    </Page>
  );
}
