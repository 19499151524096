import {
  EllipsisHorizontalIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { isNil } from "lodash";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../hooks/useApplicationTheme";
import {
  OrganizationCTMSGrantsFiltersOrderBy,
  OrganizationCTMSGrantsFiltersOrderByField,
} from "../pages/Admin/Grants/__generated__/Grants_OrganizationCtmsGrantsPagination_Query.graphql";
import { APPLICATION_ROUTES, useGrantsTableSearchParams } from "../paths";
import {
  CtmsGrantsTableRow_CTMSGrant$data,
  CtmsGrantsTableRow_CTMSGrant$key,
} from "./__generated__/CtmsGrantsTableRow_CTMSGrant.graphql";
import {
  CtmsGrantsTableRow_Organization$data,
  CtmsGrantsTableRow_Organization$key,
} from "./__generated__/CtmsGrantsTableRow_Organization.graphql";
import { useAlerter } from "./Alerter";
import { CtmsGrantStatusTag } from "./CtmsGrantStatusTag";
import { FormattedFMV } from "./FormattedFMV";
import { GranteeNameWithCountryFlag } from "./GranteeNameWithCountryFlag";
import { MissingCTMSInformationTag } from "./MissingInformationTag";
import { Percentage } from "./Percentage";
import { ShortDate } from "./ShortDate";
import { StopClickPropagationWrapper } from "./StopClickPropagationWrapper";
import { Alert } from "./ui/Alert";
import { Button } from "./ui/Button";
import { MenuButton } from "./ui/MenuButton";
import { SingleValueProgress } from "./ui/SingleValueProgress";
import { OrderBy, Table } from "./ui/Table";
import { TableCellExercisedProgress } from "./ui/Table/TableCellExercisedProgress";
import { TableCellSharesOverview } from "./ui/Table/TableCellSharesOverview";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantsTableRow_Organization on Organization {
    id
    name
    ...TableCellSharesOverview_Organization
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantsTableRow_CTMSGrant on CTMSGrant {
    id
    label
    grantee {
      id
      name
      ...GranteeNameWithCountryFlag_Grantee
    }
    equityTypeAwardName
    quantityIssued
    cumulativeVested
    quantityExercised
    exercisableShares
    terminationDate
    remainingDaysToExercise
    exercisePrice
    stakeholderSignatureDate
    settled
    isMissingInformationInOrderToBeModified
    ...CtmsGrantStatusTag_CtmsGrant
  }
`;

const Header: React.FC<{
  onOrderByChange: (
    orderBy: null | OrganizationCTMSGrantsFiltersOrderBy,
  ) => void;
  orderBy: null | OrganizationCTMSGrantsFiltersOrderBy;
  variant: "Active" | "Terminated";
}> = ({ onOrderByChange: _onOrderByChange, orderBy, variant }) => {
  const { setParams } = useGrantsTableSearchParams();
  const onOrderByChange = (
    orderBy: null | OrderBy<OrganizationCTMSGrantsFiltersOrderByField>,
  ) => {
    setParams(orderBy);
    _onOrderByChange(orderBy);
  };
  return (
    <Table.Row>
      <Table.SortableHeaderCellForPaginatedTable
        field="label"
        onOrderByChange={onOrderByChange}
        orderBy={orderBy}
      >
        Label
      </Table.SortableHeaderCellForPaginatedTable>

      {variant === "Terminated" && (
        <Table.HeaderCell>Termination date</Table.HeaderCell>
      )}

      <Table.SortableHeaderCellForPaginatedTable
        field="grantee"
        onOrderByChange={onOrderByChange}
        orderBy={orderBy}
      >
        Grantee
      </Table.SortableHeaderCellForPaginatedTable>

      {variant === "Active" && (
        <>
          <Table.SortableHeaderCellForPaginatedTable
            field="cumulativeVested"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Vested
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.SortableHeaderCellForPaginatedTable
            alignRight
            className="text-right"
            field="quantityIssued"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Ownership granted
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.SortableHeaderCellForPaginatedTable
            field="equityType"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Equity type
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.SortableHeaderCellForPaginatedTable
            field="exercisePrice"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Strike price
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.HeaderCell className="text-right">Signature</Table.HeaderCell>
        </>
      )}

      {variant === "Terminated" && (
        <>
          <Table.SortableHeaderCellForPaginatedTable
            alignRight
            className="text-right"
            field="quantityExercised"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Exercised
          </Table.SortableHeaderCellForPaginatedTable>

          <Table.SortableHeaderCellForPaginatedTable
            field="remainingDaysToExercise"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Days remaining to exercise
          </Table.SortableHeaderCellForPaginatedTable>

          <Table.SortableHeaderCellForPaginatedTable
            field="settled"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Exercise status
          </Table.SortableHeaderCellForPaginatedTable>
        </>
      )}
    </Table.Row>
  );
};

const CtmsGrantsTableRow_: React.FC<{
  ctmsGrantFragment: CtmsGrantsTableRow_CTMSGrant$key;
  organizationFragment: CtmsGrantsTableRow_Organization$key;
  variant: "Active" | "Terminated";
}> = ({ ctmsGrantFragment, organizationFragment, variant }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <Table.LinkRow
      to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
        ctmsGrantId: ctmsGrant.id,
        organizationId: organization.id,
      })}
    >
      <LabelCell ctmsGrant={ctmsGrant} />

      {variant === "Terminated" && (
        <TerminationDateCell
          ctmsGrant={ctmsGrant}
          organization={organization}
        />
      )}

      <GranteeCell ctmsGrant={ctmsGrant} />

      {variant === "Active" && (
        <>
          <OwnershipVestedCell ctmsGrant={ctmsGrant} />

          <OwnershipCell ctmsGrant={ctmsGrant} organization={organization} />

          <EquityTypeCell ctmsGrant={ctmsGrant} />

          <StrikePriceCell ctmsGrant={ctmsGrant} />

          <SignatureAndActionCell
            ctmsGrant={ctmsGrant}
            organization={organization}
          />
        </>
      )}

      {variant === "Terminated" && (
        <>
          <ExercisedCell ctmsGrant={ctmsGrant} />

          <DaysRemainingToExerciseCell
            ctmsGrant={ctmsGrant}
            organization={organization}
          />

          <StatusCell ctmsGrant={ctmsGrant} />
        </>
      )}
    </Table.LinkRow>
  );
};

const DaysRemainingToExerciseCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell>
      {ctmsGrant.settled ? (
        "N/A"
      ) : !ctmsGrant.cumulativeVested ? (
        <>
          <Typography className="text-black-05" variant="Regular/Caption">
            No shares vested
          </Typography>
        </>
      ) : ctmsGrant.remainingDaysToExercise !== null ? (
        <FormattedNumber
          style="unit"
          unit="day"
          value={ctmsGrant.remainingDaysToExercise}
        />
      ) : (
        <MissingCTMSInformationTag organizationFragment={organization} />
      )}
    </Table.Cell>
  );
};

const TerminationDateCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell>
      {ctmsGrant.terminationDate ? (
        <Typography variant="Regular/Caption">
          <ShortDate value={ctmsGrant.terminationDate} />
        </Typography>
      ) : (
        <MissingCTMSInformationTag organizationFragment={organization} />
      )}
    </Table.Cell>
  );
};

const SignatureAndActionCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const alerter = useAlerter();
  return (
    <Table.Cell>
      <div className="flex items-center justify-end gap-2">
        {ctmsGrant.stakeholderSignatureDate ? (
          <Tag color="green" size="Small">
            Signed
          </Tag>
        ) : (
          <Tag color="orange" size="Small">
            Pending
          </Tag>
        )}

        <StopClickPropagationWrapper>
          <MenuButton
            button={
              <Button
                leftIcon={<EllipsisHorizontalIcon />}
                size="extra small"
                variant="Secondary Full"
              />
            }
          >
            {ctmsGrant.isMissingInformationInOrderToBeModified ? (
              <MenuButton.Item
                leftIcon={<PencilSquareIcon />}
                onClick={() => {
                  const mailtoSubjectMissingInformationInOrderToBeModified =
                    encodeURI(
                      `[${organization.name}] Why can't I modify grant ${ctmsGrant.label}?`,
                    );
                  alerter.push(
                    <Alert
                      title={
                        <>
                          You requested to amend one (or several) grant(s) that
                          have not been generated on {applicationName}
                        </>
                      }
                    >
                      We are therefore missing initial information about the
                      grant(s) required for the grant amendment(s). Please{" "}
                      <a
                        className="font-medium text-primary"
                        href={`mailto:${supportEmailAddress}?subject=${mailtoSubjectMissingInformationInOrderToBeModified}`}
                      >
                        contact us
                      </a>{" "}
                      to provide us the relevant information.
                    </Alert>,
                  );
                }}
              >
                Modify
              </MenuButton.Item>
            ) : (
              <MenuButton.LinkItem
                leftIcon={<PencilSquareIcon />}
                to={generatePath(
                  APPLICATION_ROUTES.organizationEquityCtmsGrantModify,
                  {
                    ctmsGrantId: ctmsGrant.id,
                    organizationId: organization.id,
                  },
                )}
              >
                Modify
              </MenuButton.LinkItem>
            )}
          </MenuButton>
        </StopClickPropagationWrapper>
      </div>
    </Table.Cell>
  );
};

const LabelCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell className="w-0">
      <div className="flex min-h-[78px] flex-col items-start justify-center">
        <Tag color="gray" size="Small">
          {ctmsGrant.label}
        </Tag>
      </div>
    </Table.Cell>
  );
};

const GranteeCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell>
      <Typography
        as="div"
        className="break-words"
        variant="Regular/Extra Small"
      >
        <GranteeNameWithCountryFlag granteeFragment={ctmsGrant.grantee} />
      </Typography>
    </Table.Cell>
  );
};

const EquityTypeCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell>
      <Typography variant="Regular/Extra Small">
        {ctmsGrant.equityTypeAwardName ?? "-"}
      </Typography>
    </Table.Cell>
  );
};

const StatusCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell className="w-0 text-right">
      <CtmsGrantStatusTag ctmsGrantFragment={ctmsGrant} />
    </Table.Cell>
  );
};

const StrikePriceCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell>
      {!isNil(ctmsGrant.exercisePrice) ? (
        <FormattedFMV value={ctmsGrant.exercisePrice} />
      ) : (
        "-"
      )}
    </Table.Cell>
  );
};

const ExercisedCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell className="text-right">
      <div className="space-y-1">
        <TableCellExercisedProgress
          className="items-end"
          exercisableShares={ctmsGrant.exercisableShares}
          exercisedShares={ctmsGrant.quantityExercised}
        />
      </div>
    </Table.Cell>
  );
};

const OwnershipVestedCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  if (ctmsGrant.cumulativeVested === 0) {
    return (
      <Table.Cell>
        <Typography variant="Regular/Caption">Cliff not passed</Typography>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <div className="flex items-center gap-4">
        <SingleValueProgress
          className="relative h-2 w-[150px]"
          current={ctmsGrant.cumulativeVested}
          max={ctmsGrant.quantityIssued}
        />
        <Typography as="div" variant="Regular/Extra Small">
          <Percentage
            maximumFractionDigits={0}
            value={ctmsGrant.cumulativeVested / ctmsGrant.quantityIssued}
          />
        </Typography>
      </div>
    </Table.Cell>
  );
};

const OwnershipCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell className="text-right">
      <TableCellSharesOverview
        className="justify-end"
        organizationFragment={organization}
        shares={ctmsGrant.quantityIssued}
      />
    </Table.Cell>
  );
};

export const CtmsGrantsTableRow = Object.assign(CtmsGrantsTableRow_, {
  Header,
});
