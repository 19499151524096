import { isNil, noop } from "lodash";
import { FormattedRelativeTime } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { daysSinceDate } from "../helpers/date-utility";
import { useApplicationName } from "../hooks/useApplicationTheme";
import { WarningNoticeMessageWithSlideOverExplanation } from "../pages/Admin/Equity/PrepareYourGrants/WarningNoticeMessageWithSlideOverExplanation";
import {
  ValuationWarningMessage_Organization$data,
  ValuationWarningMessage_Organization$key,
} from "./__generated__/ValuationWarningMessage_Organization.graphql";
import {
  ValuationType,
  ValuationWarningMessage_ValuationWarning$key,
  ValuationWarningReason,
} from "./__generated__/ValuationWarningMessage_ValuationWarning.graphql";
import { FairMarketValueBoardApprovalSliderRemote } from "./FairMarketValueBoardApprovalSlider";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ValuationWarningMessage_Organization on Organization {
    id
    latestFairMarketValue {
      manualInvalidationDate
      remainingDaysBeforeExpiration
    }
    lastestUKValuation {
      remainingDaysBeforeExpiration
    }
  }
`;

const VALUATION_WARNING_FRAGMENT = graphql`
  fragment ValuationWarningMessage_ValuationWarning on OrganizationValuationWarning {
    reason
    valuationType
  }
`;

type ValuationWarningComponent = React.FC<{
  onValuationSentToBoard?: () => void;
  organization: ValuationWarningMessage_Organization$data;
}>;

const LongNoValuation409AWarning: ValuationWarningComponent = () => (
  <NoticeMessage
    hasColor={false}
    size="Large"
    title="No 409A uploaded"
    variant="Danger"
  >
    <>
      Your 409A certificate has not been uploaded. You are not allowed to make
      new grants.
    </>
  </NoticeMessage>
);

const LongNoValuationUKValuationWarning: ValuationWarningComponent = () => (
  <NoticeMessage
    hasColor={false}
    size="Large"
    title="Missing EMI valuation"
    variant="Danger"
  >
    <>
      Your EMI valuation is missing. You are not allowed to make new grants
      based on your EMI valuation, in the UK and any other countries where you
      used the EMI valuation for your grants.
    </>
  </NoticeMessage>
);

const LongValuationExpired409AWarning: ValuationWarningComponent = ({
  organization,
}) => (
  <NoticeMessage
    hasColor={false}
    size="Large"
    title="Your 409A has expired"
    variant="Danger"
  >
    <>
      Your 409A certificate has expired{" "}
      <Typography className="text-red" variant="Medium/Extra Small">
        <FormattedRelativeTime
          unit="day"
          value={
            organization.latestFairMarketValue?.remainingDaysBeforeExpiration
          }
        />
      </Typography>
      . You are not allowed to make new grants.
    </>
  </NoticeMessage>
);

const LongValuationExpiredUKValuationWarning: ValuationWarningComponent = ({
  organization,
}) => (
  <NoticeMessage
    hasColor={false}
    size="Large"
    title="Your EMI valuation has expired"
    variant="Danger"
  >
    <>
      Your EMI valuation has expired{" "}
      <Typography className="text-red" variant="Medium/Extra Small">
        <FormattedRelativeTime
          unit="day"
          value={organization.lastestUKValuation?.remainingDaysBeforeExpiration}
        />
      </Typography>
      . You are not allowed to make new grants based on your EMI valuation, in
      the UK and any other countries where you used the EMI valuation for your
      grants.
    </>
  </NoticeMessage>
);

const LongValuationExpiringSoon409AWarning: ValuationWarningComponent = ({
  organization,
}) => (
  <NoticeMessage
    hasColor={false}
    size="Large"
    title="Your 409A expires soon"
    variant="Danger"
  >
    <>
      Your 409A certificate will expire{" "}
      <Typography className="text-red" variant="Medium/Extra Small">
        <FormattedRelativeTime
          unit="day"
          value={
            organization.latestFairMarketValue?.remainingDaysBeforeExpiration
          }
        />
      </Typography>
      . You will not be allowed to make new grants once it has expired.
    </>
  </NoticeMessage>
);

const LongValuationExpiringSoonUKValuationWarning: ValuationWarningComponent =
  ({ organization }) => (
    <NoticeMessage
      hasColor={false}
      size="Large"
      title="Your EMI valuation expires soon"
      variant="Danger"
    >
      <>
        Your EMI valuation will expire{" "}
        <Typography className="text-red" variant="Medium/Extra Small">
          <FormattedRelativeTime
            unit="day"
            value={
              organization.lastestUKValuation?.remainingDaysBeforeExpiration
            }
          />
        </Typography>
        . Once it has expired, you won&apos;t be allowed to make new grants
        based on your EMI valuation, in the UK and any other countries where you
        used the EMI valuation for your grants.
        <br />
        Please, make sure that you don&apos;t have pending grants when the EMI
        valuation expires or you grants will be voided.
      </>
    </NoticeMessage>
  );

const LongValuationManuallyInvalidated409AWarning: ValuationWarningComponent =
  ({ organization }) => {
    const daysSinceInvalidation = organization.latestFairMarketValue
      ?.manualInvalidationDate
      ? daysSinceDate(organization.latestFairMarketValue.manualInvalidationDate)
      : null;

    return (
      <NoticeMessage
        hasColor={false}
        size="Large"
        title="Your 409A was invalidated"
        variant="Danger"
      >
        <>
          Your 409A certificate was invalidated
          {!isNil(daysSinceInvalidation) && (
            <>
              {" "}
              <Typography className="text-red" variant="Medium/Extra Small">
                <FormattedRelativeTime
                  unit="day"
                  value={-daysSinceInvalidation}
                />
              </Typography>
            </>
          )}
          . You are not allowed to make new grants.
        </>
      </NoticeMessage>
    );
  };

const LongValuationPendingBoardApproval409AWarning: ValuationWarningComponent =
  () => (
    <NoticeMessage
      hasColor={false}
      size="Large"
      title="Your 409A is pending board approval"
      variant="Warning"
    >
      You are not allowed to send drafts to your board members for approval.
      Your 409A valuation needs to be approved by the board first.
    </NoticeMessage>
  );

const ShortNoValuation409AWarning: ValuationWarningComponent = () => {
  const applicationName = useApplicationName();

  return (
    <NoticeMessage
      size="Large"
      title="Your 409A has not been uploaded"
      variant="Danger"
    >
      Drafts cannot be sent to {applicationName} for review nor be sent to your
      board members for approval until we&apos;ve uploaded your 409A valuation.
    </NoticeMessage>
  );
};

const ShortNoValuationUKValuationWarning: ValuationWarningComponent = () => {
  const applicationName = useApplicationName();

  return (
    <NoticeMessage
      size="Large"
      title="Your EMI valuation has not been uploaded"
      variant="Danger"
    >
      Drafts cannot be sent to {applicationName} for review nor be sent to your
      board members for approval until we&apos;ve uploaded your EMI valuation.
    </NoticeMessage>
  );
};

const ShortRequiresNewValuation409AWarning: ValuationWarningComponent = () => {
  const applicationName = useApplicationName();

  return (
    <NoticeMessage size="Large" title="Your 409A has expired" variant="Danger">
      Drafts cannot be sent to {applicationName} for review nor be sent to your
      board members for approval until you&apos;ve adopted a new 409A valuation.
    </NoticeMessage>
  );
};

const ShortRequiresNewValuationUKValuationWarning: ValuationWarningComponent =
  () => {
    const applicationName = useApplicationName();

    return (
      <NoticeMessage
        size="Large"
        title="Your EMI valuation has expired"
        variant="Danger"
      >
        Drafts cannot be sent to {applicationName} for review nor be sent to
        your board members for approval until you&apos;ve received a new EMI
        valuation.
      </NoticeMessage>
    );
  };

const ShortRequiresBoardApproval409AWarningContent: React.FC<{
  onValuationSentToBoard?: () => void;
  organization: ValuationWarningMessage_Organization$data;
}> = ({ onValuationSentToBoard, organization }) => {
  const fairMarketValueBoardApprovalSliderController =
    FairMarketValueBoardApprovalSliderRemote.useController();

  return (
    <>
      You can still draft grants but you need to{" "}
      <span
        className="cursor-pointer font-medium text-primary"
        onClick={() => {
          fairMarketValueBoardApprovalSliderController.open({
            data: {
              onValuationSentToBoard: () => onValuationSentToBoard?.(),
              organizationId: organization.id,
            },
          });
        }}
      >
        get your 409A approved
      </span>{" "}
      before sending grants to your board.
    </>
  );
};

const ShortRequiresBoardApproval409AWarning: ValuationWarningComponent = ({
  onValuationSentToBoard,
  organization,
}) => {
  return (
    <FairMarketValueBoardApprovalSliderRemote.Provider>
      <NoticeMessage
        size="Large"
        title="You have a new 409A to approve"
        variant="Warning"
      >
        <ShortRequiresBoardApproval409AWarningContent
          onValuationSentToBoard={onValuationSentToBoard}
          organization={organization}
        />
      </NoticeMessage>
    </FairMarketValueBoardApprovalSliderRemote.Provider>
  );
};

const ShortValuationPendingBoardApproval409AWarning: ValuationWarningComponent =
  () => {
    return (
      <NoticeMessage
        size="Large"
        title="Your 409A is pending board approval"
        variant="Danger"
      >
        Drafts cannot be sent to your board members for approval until your 409A
        valuation has been approved.
      </NoticeMessage>
    );
  };

const ShortExpiresSoon409AWarning: ValuationWarningComponent = ({
  organization,
}) => {
  return (
    <NoticeMessage size="Large" variant="Danger">
      <>
        Your 409A certificate will expire{" "}
        <Typography className="text-red" variant="Medium/Extra Small">
          <FormattedRelativeTime
            unit="day"
            value={
              organization.latestFairMarketValue?.remainingDaysBeforeExpiration
            }
          />
        </Typography>
        .
      </>
    </NoticeMessage>
  );
};

const ShortExpiresSoonUKValuationWarning: ValuationWarningComponent = ({
  organization,
}) => {
  return (
    <NoticeMessage size="Large" variant="Danger">
      <>
        Your EMI valuation will expire{" "}
        <Typography className="text-red" variant="Medium/Extra Small">
          <FormattedRelativeTime
            unit="day"
            value={
              organization.lastestUKValuation?.remainingDaysBeforeExpiration
            }
          />
        </Typography>
        .
      </>
    </NoticeMessage>
  );
};

const ShortWithExplanationNoValuation409AWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation="Drafts cannot be sent to your board members for approval until you’ve uploaded your 409A valuation."
      helpEmailSubject="My 409A has not been uploaded"
    >
      Your 409A valuation has not been uploaded.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationNoValuationUKValuationWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation="Drafts cannot be sent to your board members for approval until you’ve uploaded your EMI valuation."
      helpEmailSubject="My EMI has not been uploaded"
    >
      Your EMI valuation has not been uploaded.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationValuationExpired409AWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={`Drafts cannot be sent to your board members for approval until you’ve adopted a new 409A valuation.`}
      helpEmailSubject="My 409A has expired"
    >
      Your 409A valuation has expired.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationValuationExpiredUKValuationWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={`Drafts cannot be sent to your board members for approval until you’ve received a new EMI valuation.`}
      helpEmailSubject="My EMI has expired"
    >
      Your EMI valuation has expired.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationValuationExpiringSoon409AWarning: ValuationWarningComponent =
  ({ organization }) => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={
        <span className="first-letter:uppercase">
          <FormattedRelativeTime
            unit="day"
            value={
              organization.latestFairMarketValue?.remainingDaysBeforeExpiration
            }
          />
          , you won’t be allowed to make new grants. Grants pending approval
          will be voided.
        </span>
      }
      helpEmailSubject="My 409A will expire soon"
    >
      Your 409A certificate will expire{" "}
      <FormattedRelativeTime
        unit="day"
        value={
          organization.latestFairMarketValue?.remainingDaysBeforeExpiration
        }
      />
      .
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationValuationExpiringSoonUKValuationWarning: ValuationWarningComponent =
  ({ organization }) => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={
        <span className="first-letter:uppercase">
          <FormattedRelativeTime
            unit="day"
            value={
              organization.lastestUKValuation?.remainingDaysBeforeExpiration
            }
          />
          , you won’t be allowed to make new grants. Grants pending approval
          will be voided.
        </span>
      }
      helpEmailSubject="My EMI will expire soon"
    >
      Your EMI valuation will expire{" "}
      <FormattedRelativeTime
        unit="day"
        value={organization.lastestUKValuation?.remainingDaysBeforeExpiration}
      />
      .
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationValuationManuallyInvalidated409AWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={`Drafts cannot be sent to your board members for approval until you’ve adopted a new 409A valuation.`}
      helpEmailSubject="My 409A was invalidated"
    >
      Your 409A valuation was invalidated.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ShortWithExplanationValuationNotBoardApproved409AWarning: ValuationWarningComponent =
  ({ onValuationSentToBoard = noop, organization }) => {
    return (
      <FairMarketValueBoardApprovalSliderRemote.Provider>
        <NoticeMessage
          size="Large"
          title="Validate your new 409A"
          variant="Warning"
        >
          <FairMarketValueBoardApprovalSliderRemote.Provider>
            <FairMarketValueBoardApprovalSliderRemote.Controller
              render={({ open }) => (
                <>
                  You can still draft grants but you need to{" "}
                  <button
                    className="text-primary"
                    onClick={() => {
                      open({
                        data: {
                          onValuationSentToBoard,
                          organizationId: organization.id,
                        },
                      });
                    }}
                  >
                    validate your new 409A
                  </button>{" "}
                  before sending grants to your board.
                </>
              )}
            />
          </FairMarketValueBoardApprovalSliderRemote.Provider>
        </NoticeMessage>
      </FairMarketValueBoardApprovalSliderRemote.Provider>
    );
  };

const ShortWithExplanationValuationPendingBoardApproval409AWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={`Drafts cannot be sent to your board members for approval until your 409A valuation has been approved.`}
      helpEmailSubject="My 409A is pending board approval"
    >
      Your 409A has not been approved by the board yet.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

type WarningSize = "long" | "short" | "shortWithExplanation";

const VALUATION_WARNING_COMPONENTS_MAP: Record<
  WarningSize,
  Record<
    ValuationWarningReason,
    Record<ValuationType, null | ValuationWarningComponent>
  >
> = {
  long: {
    NO_VALUATION: {
      FMV_409A: LongNoValuation409AWarning,
      UK_VALUATION: LongNoValuationUKValuationWarning,
    },
    VALUATION_EXPIRED: {
      FMV_409A: LongValuationExpired409AWarning,
      UK_VALUATION: LongValuationExpiredUKValuationWarning,
    },
    VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS: {
      FMV_409A: LongValuationExpiringSoon409AWarning,
      UK_VALUATION: LongValuationExpiringSoonUKValuationWarning,
    },
    VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS: {
      FMV_409A: LongValuationExpiringSoon409AWarning,
      UK_VALUATION: LongValuationExpiringSoonUKValuationWarning,
    },
    VALUATION_MANUALLY_INVALIDATED: {
      FMV_409A: LongValuationManuallyInvalidated409AWarning,
      UK_VALUATION: null,
    },
    VALUATION_NOT_BOARD_APPROVED: {
      FMV_409A: null,
      UK_VALUATION: null,
    },
    VALUATION_PENDING_BOARD_APPROVAL: {
      FMV_409A: LongValuationPendingBoardApproval409AWarning,
      UK_VALUATION: null,
    },
  },
  short: {
    NO_VALUATION: {
      FMV_409A: ShortNoValuation409AWarning,
      UK_VALUATION: ShortNoValuationUKValuationWarning,
    },
    VALUATION_EXPIRED: {
      FMV_409A: ShortRequiresNewValuation409AWarning,
      UK_VALUATION: ShortRequiresNewValuationUKValuationWarning,
    },
    VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS: {
      FMV_409A: ShortExpiresSoon409AWarning,
      UK_VALUATION: ShortExpiresSoonUKValuationWarning,
    },
    VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS: {
      FMV_409A: ShortExpiresSoon409AWarning,
      UK_VALUATION: ShortExpiresSoonUKValuationWarning,
    },
    VALUATION_MANUALLY_INVALIDATED: {
      FMV_409A: ShortRequiresNewValuation409AWarning,
      UK_VALUATION: null,
    },
    VALUATION_NOT_BOARD_APPROVED: {
      FMV_409A: ShortRequiresBoardApproval409AWarning,
      UK_VALUATION: null,
    },
    VALUATION_PENDING_BOARD_APPROVAL: {
      FMV_409A: ShortValuationPendingBoardApproval409AWarning,
      UK_VALUATION: null,
    },
  },
  shortWithExplanation: {
    NO_VALUATION: {
      FMV_409A: ShortWithExplanationNoValuation409AWarning,
      UK_VALUATION: ShortWithExplanationNoValuationUKValuationWarning,
    },
    VALUATION_EXPIRED: {
      FMV_409A: ShortWithExplanationValuationExpired409AWarning,
      UK_VALUATION: ShortWithExplanationValuationExpiredUKValuationWarning,
    },
    VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS: {
      FMV_409A: ShortWithExplanationValuationExpiringSoon409AWarning,
      UK_VALUATION: ShortWithExplanationValuationExpiringSoonUKValuationWarning,
    },
    VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS: {
      FMV_409A: ShortWithExplanationValuationExpiringSoon409AWarning,
      UK_VALUATION: ShortWithExplanationValuationExpiringSoonUKValuationWarning,
    },
    VALUATION_MANUALLY_INVALIDATED: {
      FMV_409A: ShortWithExplanationValuationManuallyInvalidated409AWarning,
      UK_VALUATION: null,
    },
    VALUATION_NOT_BOARD_APPROVED: {
      FMV_409A: ShortWithExplanationValuationNotBoardApproved409AWarning,
      UK_VALUATION: null,
    },
    VALUATION_PENDING_BOARD_APPROVAL: {
      FMV_409A: ShortWithExplanationValuationPendingBoardApproval409AWarning,
      UK_VALUATION: null,
    },
  },
};

export const ValuationWarningMessage: React.FC<{
  onValuationSentToBoard?: () => void;
  organizationFragment: ValuationWarningMessage_Organization$key;
  size: WarningSize;
  valuationWarningFragment: ValuationWarningMessage_ValuationWarning$key;
}> = ({
  onValuationSentToBoard,
  organizationFragment,
  size,
  valuationWarningFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const valuationWarning = useFragment(
    VALUATION_WARNING_FRAGMENT,
    valuationWarningFragment,
  );

  const Component =
    VALUATION_WARNING_COMPONENTS_MAP[size][valuationWarning.reason][
      valuationWarning.valuationType
    ];

  if (!Component) return null;

  return (
    <Component
      onValuationSentToBoard={onValuationSentToBoard}
      organization={organization}
    />
  );
};
