import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useTransition } from "react";
import { FormattedNumber } from "react-intl";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { ConfirmationModalRemote } from "../../../../../components/ui/ConfirmationModal";
import { MenuButton } from "../../../../../components/ui/MenuButton";
import { SearchBar } from "../../../../../components/ui/SearchBar";
import { Table } from "../../../../../components/ui/Table";
import { Typography } from "../../../../../components/ui/Typography";
import { useDebounced } from "../../../../../hooks/useDebounced";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../../../paths";
import { ConfigureGrantsLayout } from "../ConfigureGrantsLayout";
import {
  PostTermination_Query,
  PostTermination_Query$data,
} from "./__generated__/PostTermination_Query.graphql";
import { PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment$key } from "./__generated__/PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment.graphql";
import { EditSlideOverRemote } from "./EditSlideOver";

const DELETE_POST_TERMINATION_EXERCISE_PERIOD_MUTATION = graphql`
  mutation PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation(
    $postTerminationExercisePeriodId: OrganizationPostTerminationExercisePeriodId!
  ) {
    deleteOrganizationPostTerminationExercisePeriod(
      id: $postTerminationExercisePeriodId
    )
  }
`;

const POST_TERMINATION_EXERCISE_PERIOD_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment on OrganizationPostTerminationExercisePeriod
  @refetchable(
    queryName: "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery"
  ) {
    __typename
    ... on OrganizationFixedPostTerminationExercisePeriod {
      id
      easopGrantUsageCount
      displayName
      isEditable
      isDeletable
    }
    ... on OrganizationVariablePostTerminationExercisePeriod {
      id
      easopGrantUsageCount
      displayName
      isEditable
      isDeletable
    }
  }
`;

function PostTerminationExercisePeriodsTableRow({
  onDeleted,
  organizationId,
  postTerminationExercisePeriodFragment,
}: {
  onDeleted: () => void;
  organizationId: string;
  postTerminationExercisePeriodFragment: PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment$key;
}) {
  const [refetchTransitionInProgress, startRefetchTransition] = useTransition();
  const [postTerminationExercisePeriod, refetch] = useRefetchableFragment(
    POST_TERMINATION_EXERCISE_PERIOD_FRAGMENT,
    postTerminationExercisePeriodFragment,
  );

  const handleClose = useCallback(() => {
    startRefetchTransition(() => {
      refetch({}, { fetchPolicy: "network-only" });
    });
  }, [refetch]);

  const editSlideOverController = EditSlideOverRemote.useController();

  const [
    triggerDeletePostTerminationExercisePeriodMutation,
    deletePostTerminationExercisePeriodMutationIsInFlight,
  ] = useSafeMutation(DELETE_POST_TERMINATION_EXERCISE_PERIOD_MUTATION);

  const ConfirmationModalController = ConfirmationModalRemote.useController();

  function handlePostTerminationExercisePeriodDelete() {
    ConfirmationModalController.open({
      data: {
        confirmationLabel: "Delete",
        title: "Delete Post-Termination Exercise Period",
      },
      onClose: async ({ confirmed } = { confirmed: false }) => {
        if (confirmed) {
          await triggerDeletePostTerminationExercisePeriodMutation({
            variables: {
              postTerminationExercisePeriodId: postTerminationExercisePeriod.id,
            },
          });

          onDeleted();
        }
      },
    });
  }

  return (
    <Table.Row
      key={postTerminationExercisePeriod.id}
      skeleton={refetchTransitionInProgress}
    >
      <Table.Cell>
        <Typography className="whitespace-nowrap" variant="Medium/Extra Small">
          {postTerminationExercisePeriod.displayName}
        </Typography>
      </Table.Cell>
      <Table.Cell>
        {postTerminationExercisePeriod.__typename ===
        "OrganizationFixedPostTerminationExercisePeriod"
          ? "No"
          : "Yes"}
      </Table.Cell>
      <Table.Cell className="text-right">
        <FormattedNumber
          value={postTerminationExercisePeriod.easopGrantUsageCount ?? 0}
        />
      </Table.Cell>
      <Table.Cell className="w-0">
        <MenuButton
          button={
            <Button
              leftIcon={<EllipsisVerticalIcon />}
              loading={
                editSlideOverController.transitionInProgress ||
                deletePostTerminationExercisePeriodMutationIsInFlight
              }
              size="extra small"
              variant="Secondary Full"
            />
          }
          placement="bottom end"
        >
          <MenuButton.Item
            disabled={!postTerminationExercisePeriod.isEditable}
            leftIcon={<PencilSquareIcon />}
            loading={editSlideOverController.transitionInProgress}
            onClick={() =>
              editSlideOverController.open({
                data: {
                  organizationId,
                  postTerminationExercisePeriodId:
                    postTerminationExercisePeriod.id,
                },
                onClose: handleClose,
              })
            }
          >
            Edit
          </MenuButton.Item>
          <MenuButton.Item
            disabled={!postTerminationExercisePeriod.isDeletable}
            leftIcon={<TrashIcon />}
            loading={deletePostTerminationExercisePeriodMutationIsInFlight}
            onClick={handlePostTerminationExercisePeriodDelete}
          >
            Delete
          </MenuButton.Item>
        </MenuButton>
      </Table.Cell>
    </Table.Row>
  );
}

function PostTerminationExercisePeriodsTable({
  loading,
  onPostTerminationExercisePeriodDeleted,
  organization,
}: {
  loading?: boolean;
  onPostTerminationExercisePeriodDeleted: () => void;
  organization: PostTermination_Query$data["organization"];
}) {
  return (
    <Table.Containerized loading={loading}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Variable</Table.HeaderCell>
          <Table.HeaderCell alignRight>Number of grants</Table.HeaderCell>
          <Table.HeaderCell className="w-0" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {organization.postTerminationExercisePeriods.map(
          (postTerminationExercisePeriod) => {
            if (
              postTerminationExercisePeriod.__typename !==
                "OrganizationFixedPostTerminationExercisePeriod" &&
              postTerminationExercisePeriod.__typename !==
                "OrganizationVariablePostTerminationExercisePeriod"
            ) {
              throw new Error("Invalid typename");
            }

            return (
              <PostTerminationExercisePeriodsTableRow
                key={postTerminationExercisePeriod.id}
                onDeleted={onPostTerminationExercisePeriodDeleted}
                organizationId={organization.id}
                postTerminationExercisePeriodFragment={
                  postTerminationExercisePeriod
                }
              />
            );
          },
        )}
      </Table.Body>
    </Table.Containerized>
  );
}

function SpecialPostTerminationExercisePeriodsTable({
  organization,
}: {
  organization: PostTermination_Query$data["organization"];
}) {
  return (
    <Table.Containerized>
      <Table.Body>
        {organization.disabilityPostTerminationExercisePeriod && (
          <Table.Row>
            <Table.Cell>
              <Typography
                className="whitespace-nowrap"
                variant="Medium/Extra Small"
              >
                Post-termination exercise period in case of disability
              </Typography>
            </Table.Cell>
            <Table.Cell>
              <FormattedNumber
                style="unit"
                unit={organization.disabilityPostTerminationExercisePeriod.unit}
                unitDisplay="long"
                value={
                  organization.disabilityPostTerminationExercisePeriod.duration
                }
              />
            </Table.Cell>
          </Table.Row>
        )}
        {organization.deceasePostTerminationExercisePeriod && (
          <Table.Row>
            <Table.Cell>
              <Typography
                className="whitespace-nowrap"
                variant="Medium/Extra Small"
              >
                Post-termination in case of death
              </Typography>
            </Table.Cell>
            <Table.Cell>
              <FormattedNumber
                style="unit"
                unit={organization.deceasePostTerminationExercisePeriod.unit}
                unitDisplay="long"
                value={
                  organization.deceasePostTerminationExercisePeriod.duration
                }
              />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table.Containerized>
  );
}

const QUERY = graphql`
  query PostTermination_Query(
    $organizationId: OrganizationId!
    $search: String
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      postTerminationExercisePeriods(search: $search) {
        __typename
        ... on OrganizationFixedPostTerminationExercisePeriod {
          id
        }
        ... on OrganizationVariablePostTerminationExercisePeriod {
          id
        }
        ...PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment
      }
      disabilityPostTerminationExercisePeriod {
        duration
        unit
      }
      deceasePostTerminationExercisePeriod {
        duration
        unit
      }
    }
  }
`;

export default function PostTermination() {
  const organizationId = useOrganizationIdParam();
  const {
    debouncedState: debouncedSearch,
    isDebouncing: searchIsDebouncing,
    liveState: liveSearch,
    setState: setSearch,
  } = useDebounced({
    initialState: "",
  });

  const { query, refreshQuery } = useQuery<PostTermination_Query>(QUERY, {
    organizationId,
    search: debouncedSearch,
  });

  const [refetchTransitionInProgress, startRefetchTransition] = useTransition();

  function handlePostTerminationExercisePeriodDeleted() {
    startRefetchTransition(() => {
      refreshQuery();
    });
  }

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  function handleSearchChange(value: string) {
    startSearchTransition(() => {
      setSearch(value);
    });
  }

  return (
    <Page
      analyticsCategory="Admin Configure Post Termination"
      analyticsName="Admin - Equity - Configure - Post Termination"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity post termination settings`}
    >
      <EditSlideOverRemote.Provider>
        <ConfirmationModalRemote.Provider>
          <ConfigureGrantsLayout
            rightAction={
              <LinkButton size="small" to="add-new">
                Add new
              </LinkButton>
            }
            subtitle={
              <SearchBar
                className="w-full max-w-[300px]"
                loading={searchTransitionIsInProgress || searchIsDebouncing}
                onChange={handleSearchChange}
                placeholder="Search"
                value={liveSearch}
              />
            }
            title="Post-termination Exercise Periods"
          >
            <div className="space-y-10">
              <PostTerminationExercisePeriodsTable
                loading={
                  refetchTransitionInProgress || searchTransitionIsInProgress
                }
                onPostTerminationExercisePeriodDeleted={
                  handlePostTerminationExercisePeriodDeleted
                }
                organization={query.organization}
              />
              <SpecialPostTerminationExercisePeriodsTable
                organization={query.organization}
              />
            </div>
          </ConfigureGrantsLayout>
        </ConfirmationModalRemote.Provider>
      </EditSlideOverRemote.Provider>
    </Page>
  );
}
