import { ArrowRightIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { flatMap, uniqBy } from "lodash";
import { startTransition, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CtmsGrantStatusTag } from "../../../../components/CtmsGrantStatusTag";
import { ShortDate } from "../../../../components/ShortDate";
import { useToaster } from "../../../../components/Toaster";
import { Checkbox } from "../../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Table } from "../../../../components/ui/Table";
import { Toast } from "../../../../components/ui/Toast";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  RepricingForm_Grants_Query,
  RepricingForm_Grants_Query$data,
} from "./__generated__/RepricingForm_Grants_Query.graphql";
import { RepricingForm_Organization$key } from "./__generated__/RepricingForm_Organization.graphql";
import { RepricingForm_RepriceGrantMutation } from "./__generated__/RepricingForm_RepriceGrantMutation.graphql";

const GRANTS_QUERY = graphql`
  query RepricingForm_Grants_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      ctmsGrants(filters: { statusIn: [Active, Terminated, Canceled] }) {
        edges {
          node {
            id
            organizationId
            label
            grantStatus
            vestingStartDate
            quantityIssued
            exercisePrice
            grantee {
              id
              name
              taxResidenceCountry {
                name
                emoji
              }
            }
            repricedFromCTMSGrant {
              id
            }
            repricedToCTMSGrant {
              id
            }
            ...CtmsGrantStatusTag_CtmsGrant
          }
        }
      }
    }
  }
`;

const MUTATION = graphql`
  mutation RepricingForm_RepriceGrantMutation(
    $ctmsGrantsRepricingPairs: [MarkCTMSGrantsAsRepricedItemInput!]!
  ) {
    markCTMSGrantsAsRepriced(
      ctmsGrantsRepricingPairs: $ctmsGrantsRepricingPairs
    )
  }
`;

type CTMSGrant =
  RepricingForm_Grants_Query$data["organization"]["ctmsGrants"]["edges"][number]["node"];
export interface RepricingSuggestion {
  from: CTMSGrant;
  to: CTMSGrant;
}

const columnHelper = createColumnHelper<RepricingSuggestion>();

const GrantLabel: React.FC<{ grant: CTMSGrant }> = ({ grant }) => {
  return (
    <Link
      className="flex items-center gap-2"
      target="_blank"
      to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
        ctmsGrantId: grant.id,
        organizationId: grant.organizationId,
      })}
    >
      <Typography variant="Medium/Extra Small">{grant.label}</Typography>
      <CtmsGrantStatusTag ctmsGrantFragment={grant} />
    </Link>
  );
};

const canBeRepriced = ({ from, to }: { from: CTMSGrant; to: CTMSGrant }) =>
  to.id !== from.id &&
  !to.repricedFromCTMSGrant &&
  to.grantStatus !== "Canceled" &&
  from.grantee.id === to.grantee.id &&
  from.quantityIssued === to.quantityIssued &&
  from.vestingStartDate === to.vestingStartDate;

const RepricingSuggestions: React.FC<{
  organizationId: string;
  selectedSuggestions: Set<RepricingSuggestion>;
  setSelectedSuggestions: (suggestions: Set<RepricingSuggestion>) => void;
}> = ({ organizationId, selectedSuggestions, setSelectedSuggestions }) => {
  const {
    query: { organization },
    refreshQuery,
  } = useQuery<RepricingForm_Grants_Query>(GRANTS_QUERY, {
    organizationId,
  });

  const form = useForm();

  const [markCTMSGrantsAsRepriced] =
    useSafeMutation<RepricingForm_RepriceGrantMutation>(MUTATION);

  const toaster = useToaster();

  const onSubmit = form.handleSubmit(async () => {
    const selectedSuggestionsList = Array.from(selectedSuggestions);
    if (selectedSuggestionsList.length === 0) return;

    const grantsUsedInRepricing = flatMap(
      selectedSuggestionsList,
      ({ from, to }) => [from, to],
    );

    if (
      uniqBy(grantsUsedInRepricing, "id").length !==
      selectedSuggestionsList.length * 2
    ) {
      toaster.push(
        <Toast title="Error" variant="error">
          Same grant appears in different repricings
        </Toast>,
      );
      return;
    }

    await markCTMSGrantsAsRepriced({
      variables: {
        ctmsGrantsRepricingPairs: selectedSuggestionsList.map(
          ({ from, to }) => ({
            from: from.id,
            to: to.id,
          }),
        ),
      },
    });

    toaster.push(
      <Toast title="Alright!">Grants have been marked as repriced.</Toast>,
    );

    startTransition(() => {
      refreshQuery();
    });
  });

  const columns = useMemo(
    () => [
      columnHelper.display({
        cell: (context) => (
          <Checkbox
            checked={context.row.getIsSelected()}
            onChange={context.row.getToggleSelectedHandler()}
          />
        ),
        header: (context) => (
          <Checkbox
            checked={context.table.getIsAllRowsSelected()}
            onChange={context.table.getToggleAllPageRowsSelectedHandler()}
          />
        ),
        id: "select",
        size: 20,
      }),
      columnHelper.accessor((row) => row.from.label, {
        cell: (context) => {
          const repricedFromGrant = context.row.original.from;
          return <GrantLabel grant={repricedFromGrant} />;
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Repriced from",
        id: "repriced-from",
      }),
      columnHelper.accessor((row) => row.to.label, {
        cell: (context) => {
          const repricedToGrant = context.row.original.to;
          return <GrantLabel grant={repricedToGrant} />;
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Repriced to",
        id: "repriced-to",
      }),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const repricedFromGrant = context.row.original.from;
          const repricedToGrant = context.row.original.to;
          return (
            <div className="flex items-center gap-2 text-primary">
              <Typography
                className="text-red line-through"
                variant="Regular/Extra Small"
              >
                {repricedFromGrant.exercisePrice !== null ? (
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={repricedFromGrant.exercisePrice}
                  />
                ) : (
                  "-"
                )}
              </Typography>
              <ArrowRightIcon className="w-4" />
              <Typography variant="Medium/Extra Small">
                {repricedToGrant.exercisePrice !== null ? (
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={repricedToGrant.exercisePrice}
                  />
                ) : (
                  "-"
                )}
              </Typography>
            </div>
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Exercise price",
        id: "exercise-price",
      }),
      columnHelper.accessor((row) => row.from.grantee.name, {
        cell: (context) => {
          const repricedFromGrant = context.row.original.from;
          const grantee = repricedFromGrant.grantee;
          return (
            <Link
              target="_blank"
              to={generatePath(APPLICATION_ROUTES.organizationGrantee, {
                granteeId: grantee.id,
                organizationId: repricedFromGrant.organizationId,
              })}
            >
              {grantee.taxResidenceCountry?.emoji} {grantee.name}
            </Link>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Grantee",
        id: "grantee",
      }),
      columnHelper.accessor((row) => row.from.vestingStartDate, {
        cell: (context) => {
          const vestingStartDate = context.getValue();
          return vestingStartDate ? (
            <ShortDate value={vestingStartDate} />
          ) : (
            "-"
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Vesting start date",
        id: "vesting-start-date",
      }),
      columnHelper.accessor((row) => row.from.quantityIssued, {
        cell: (context) => {
          const quantityIssued = context.getValue();
          return <FormattedNumber value={quantityIssued} />;
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Shares granted",
        id: "shares-granted",
      }),
    ],
    [],
  );

  const ctmsGrants = useMemo(
    (): CTMSGrant[] => organization.ctmsGrants.edges.map((edge) => edge.node),
    [organization.ctmsGrants],
  );

  const repricingSuggestions = useMemo(
    (): RepricingSuggestion[] =>
      ctmsGrants.reduce<RepricingSuggestion[]>((acc, fromCTMSGrant) => {
        // If grant is not Canceled, we don't want to suggest it
        if (fromCTMSGrant.grantStatus !== "Canceled") return acc;
        // If the grant has already been repriced, we don't want to suggest it
        if (fromCTMSGrant.repricedToCTMSGrant) return acc;

        const suggestions = ctmsGrants.filter((ctmsGrant) =>
          canBeRepriced({ from: fromCTMSGrant, to: ctmsGrant }),
        );

        for (const toCTMSGrant of suggestions) {
          acc.push({ from: fromCTMSGrant, to: toCTMSGrant });
        }

        return acc;
      }, []),
    [ctmsGrants],
  );

  const rowSelection = useMemo(
    () =>
      repricingSuggestions.reduce(
        (rowSelection: Record<string, boolean>, suggestion, index) => ({
          ...rowSelection,
          [index]: selectedSuggestions.has(suggestion),
        }),
        {},
      ),
    [repricingSuggestions, selectedSuggestions],
  );

  const table = useReactTable({
    columns,
    data: repricingSuggestions,
    enableGlobalFilter: true,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    onRowSelectionChange: (getSelection) => {
      if (typeof getSelection === "function") {
        const newRowSelection = getSelection(rowSelection);

        setSelectedSuggestions(
          repricingSuggestions.reduce((set, grant, index) => {
            if (newRowSelection[index]) {
              set.add(grant);
            }

            return set;
          }, new Set<RepricingSuggestion>()),
        );
      }
    },
    state: {
      rowSelection,
    },
  });

  if (repricingSuggestions.length === 0)
    return (
      <NoticeMessage size="Small">
        No grants can be repriced for {organization.name}
      </NoticeMessage>
    );

  return (
    <form id="repricing-form" onSubmit={onSubmit}>
      <Table.Smart
        searchBarPlaceholder="Search grant..."
        showSearchBar
        table={table}
      />
    </form>
  );
};

const ORGANIZATIONS_FRAGMENT = graphql`
  fragment RepricingForm_Organization on Organization @relay(plural: true) {
    name
    id
  }
`;

export const RepricingForm: React.FC<{
  organizationsFragment: RepricingForm_Organization$key;
  selectedSuggestions: Set<RepricingSuggestion>;
  setSelectedSuggestions: (suggestions: Set<RepricingSuggestion>) => void;
}> = ({
  organizationsFragment,
  selectedSuggestions,
  setSelectedSuggestions,
}) => {
  const organizations = useFragment(
    ORGANIZATIONS_FRAGMENT,
    organizationsFragment,
  );

  const [organizationId, setOrganizationId] = useState<null | string>(null);

  const onOrganizationChange = (organizationId: null | string) => {
    startTransition(() => {
      setOrganizationId(organizationId);
    });
  };

  const organization = useMemo(
    () =>
      organizations.find((organization) => organization.id === organizationId),
    [organizationId, organizations],
  );

  return (
    <div className="space-y-6">
      <FormRow label="Select an organization">
        <SelectAutocomplete
          getOptionLabel={(organization) => organization.name}
          getOptionValue={(organization) => organization.id}
          onChange={(organization) => {
            onOrganizationChange(organization?.id ?? null);
          }}
          options={organizations}
          value={organization}
        />
      </FormRow>
      {organization && (
        <RepricingSuggestions
          organizationId={organization.id}
          selectedSuggestions={selectedSuggestions}
          setSelectedSuggestions={setSelectedSuggestions}
        />
      )}
    </div>
  );
};
