/**
 * @generated SignedSource<<d5dafec8c8d990e8b4324af9ce5e956a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ChangeGrantInstrumentForm_Grants_Query$variables = {
  organizationId: string;
};
export type ChangeGrantInstrumentForm_Grants_Query$data = {
  readonly organization: {
    readonly ctmsGrants: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly easopGrant: {
            readonly __typename: "EasopGrant";
          } | null;
          readonly equityTypeAwardName: string | null;
          readonly grantee: {
            readonly id: string;
            readonly name: string;
            readonly taxResidenceCountry: {
              readonly emoji: string;
            } | null;
          };
          readonly id: string;
          readonly label: string;
          readonly matchingInstrument: {
            readonly name: string;
          } | null;
        };
      }>;
    };
  };
};
export type ChangeGrantInstrumentForm_Grants_Query = {
  response: ChangeGrantInstrumentForm_Grants_Query$data;
  variables: ChangeGrantInstrumentForm_Grants_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "statusIn": [
        "Active",
        "Terminated"
      ]
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equityTypeAwardName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Grantee",
  "kind": "LinkedField",
  "name": "grantee",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeGrantInstrumentForm_Grants_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v2/*: any*/),
              "concreteType": "OrganizationCTMSGrantsConnection",
              "kind": "LinkedField",
              "name": "ctmsGrants",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationCTMSGrantsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CTMSGrant",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Instrument",
                          "kind": "LinkedField",
                          "name": "matchingInstrument",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EasopGrant",
                          "kind": "LinkedField",
                          "name": "easopGrant",
                          "plural": false,
                          "selections": [
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\",\"Terminated\"]})"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeGrantInstrumentForm_Grants_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Instrument",
                        "kind": "LinkedField",
                        "name": "matchingInstrument",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EasopGrant",
                        "kind": "LinkedField",
                        "name": "easopGrant",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\",\"Terminated\"]})"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac56f0181fc314ba50b6a1e10428089f",
    "id": null,
    "metadata": {},
    "name": "ChangeGrantInstrumentForm_Grants_Query",
    "operationKind": "query",
    "text": "query ChangeGrantInstrumentForm_Grants_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    ctmsGrants(filters: {statusIn: [Active, Terminated]}) {\n      edges {\n        node {\n          id\n          label\n          equityTypeAwardName\n          matchingInstrument {\n            name\n            id\n          }\n          grantee {\n            id\n            name\n            taxResidenceCountry {\n              emoji\n            }\n          }\n          easopGrant {\n            __typename\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4e6d5f2aa383c4b968045cd129b5ff1";

export default node;
