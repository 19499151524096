import React, { useCallback, useEffect, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  useTrackDraftGrantMethodSelected,
  useTrackEvent,
} from "../../hooks/useAnalytics";
import { useApplicationName } from "../../hooks/useApplicationTheme";
import { useBoolean } from "../../hooks/useBoolean";
import { useCreateEquityOffer } from "../../pages/Admin/Tools/EquityOffer/EquityOffersSettings";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../paths";
import { GrantFormSlide } from "../GrantForm/GrantFormSlide";
import { Modal } from "../ui/Modal";
import { Picture } from "../ui/Picture";
import { RoundedBox } from "../ui/RoundedBox";
import { Tag } from "../ui/Tag";
import { Typography } from "../ui/Typography";
import { NewEquitySelectionModal_DefaultGrantee$key } from "./__generated__/NewEquitySelectionModal_DefaultGrantee.graphql";
import newEquityOfferScreen from "./new-equity-offer-screen.png";
import newGrantScreen from "./new-grant-screen.png";
import quickGrantScreen from "./quick-grant-screen.png";

const ActionCard: React.FC<{
  description: string;
  image: string;
  tag?: React.ReactNode;
  title: string;
}> = ({ description, image, tag, title }) => (
  <RoundedBox
    className="h-full space-y-4 p-6 transition-all hover:border-primary hover:shadow-200"
    withBorder
    withShadow
  >
    <Picture className="object-contain" image={image} />
    <div className="flex items-center justify-between">
      <Typography as="div" variant="Medium/Default">
        {title}
      </Typography>
      {tag}
    </div>
    <Typography as="div" className="text-black-05" variant="Regular/Small">
      {description}
    </Typography>
  </RoundedBox>
);

const NewEquitySelectionModal_: React.FC<
  {
    defaultGranteeId?: string;
    onStandardGrantFlowButtonClick: () => void;
    showEquityOfferCard: boolean;
  } & Omit<React.ComponentProps<typeof Modal>, "children" | "width">
> = ({
  defaultGranteeId,
  onClose,
  onStandardGrantFlowButtonClick,
  showEquityOfferCard,
  ...props
}) => {
  const organizationId = useOrganizationIdParam();

  const trackDraftGrantMethodSelected = useTrackDraftGrantMethodSelected();

  const { createEquityOffer, createEquityOfferMutationIsInFlight } =
    useCreateEquityOffer({ organizationId });

  const applicationName = useApplicationName();

  return (
    <Modal
      onClose={onClose}
      suspense
      width={showEquityOfferCard ? 1072 : 736}
      {...props}
    >
      <Modal.Content
        onClose={onClose}
        title={showEquityOfferCard ? "Create new" : "Draft a new grant"}
      >
        <div
          className="flex flex-col gap-4 sm:flex-row"
          data-cy="NewEquitySelectionModal"
        >
          <div className="flex-1">
            <Link
              className="h-full"
              onClick={() => trackDraftGrantMethodSelected("beginner")}
              to={{
                pathname: generatePath(
                  APPLICATION_ROUTES["organizationAssistedGrantGranteeGrantee"],
                  {
                    organizationId,
                  },
                ),
                search: defaultGranteeId
                  ? `?defaultGranteeId=${defaultGranteeId}`
                  : undefined,
              }}
            >
              <ActionCard
                description={`Draft a new grant for your team members, and send the draft to ${applicationName} for review.`}
                image={newGrantScreen}
                tag={<Tag color="primary">BEGINNER</Tag>}
                title="New grant 🎁"
              />
            </Link>
          </div>
          <div className="flex-1">
            <button
              className="h-full text-left"
              onClick={() => {
                trackDraftGrantMethodSelected("expert");
                onStandardGrantFlowButtonClick();
              }}
              type="button"
            >
              <ActionCard
                description="For the equity super heroes out there, draft grants faster as you know what you’re doing."
                image={quickGrantScreen}
                tag={<Tag color="purple">EXPERT</Tag>}
                title="New grant 🎁"
              />
            </button>
          </div>
          {showEquityOfferCard && (
            <div className="flex-1">
              <button
                className="h-full text-left"
                disabled={createEquityOfferMutationIsInFlight}
                onClick={createEquityOffer}
                type="button"
              >
                <ActionCard
                  description="Make nice offers to candidates and be fully transparent on equity compensation from day one."
                  image={newEquityOfferScreen}
                  title="Equity offer 📝"
                />
              </button>
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

const DEFAULT_GRANTEE_FRAGMENT = graphql`
  fragment NewEquitySelectionModal_DefaultGrantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    ...GrantFormSlide_DefaultGrantee @arguments(organizationId: $organizationId)
  }
`;

export type NewEquitySelectionModalState = {
  defaultGranteeFragment: NewEquitySelectionModal_DefaultGrantee$key | null;
  shown: boolean;
};

export const useNewEquitySelectionModalState = () => {
  const [newEquitySelectionModalState, setState] =
    useState<NewEquitySelectionModalState>({
      defaultGranteeFragment: null,
      shown: false,
    });

  const showNewEquitySelectionModal = ({
    granteeFragment,
  }: {
    granteeFragment: NewEquitySelectionModal_DefaultGrantee$key | null;
  }) => {
    setState({
      defaultGranteeFragment: granteeFragment,
      shown: true,
    });
  };

  const hideNewEquitySelectionModal = () => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  };

  return {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  };
};

export const NewEquitySelectionModal: React.FC<{
  onClose: () => void;
  onGrantCreated?: () => void;
  organizationId: string;
  showEquityOfferCard?: boolean;
  state: NewEquitySelectionModalState;
}> = ({
  onClose,
  onGrantCreated,
  organizationId,
  showEquityOfferCard = false,
  state,
}) => {
  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (state.shown) {
      trackEvent("Grant Draft Launch");
    }
  }, [state.shown, trackEvent]);

  const {
    setFalse: closeGrantFormSlide,
    setTrue: openGrantFormSlide,
    value: grantFormSlideIsOpened,
  } = useBoolean(false);

  const handleStandardGrantFlowButtonClick = useCallback(() => {
    onClose?.();
    openGrantFormSlide();
  }, [onClose, openGrantFormSlide]);

  const defaultGrantee = useFragment(
    DEFAULT_GRANTEE_FRAGMENT,
    state.defaultGranteeFragment,
  );

  return (
    <>
      <NewEquitySelectionModal_
        defaultGranteeId={defaultGrantee?.id}
        onClose={onClose}
        onStandardGrantFlowButtonClick={handleStandardGrantFlowButtonClick}
        show={state.shown}
        showEquityOfferCard={showEquityOfferCard}
      />

      <GrantFormSlide
        defaultGranteeFragment={defaultGrantee}
        easopGrantId={null}
        onClose={closeGrantFormSlide}
        onGrantCreated={onGrantCreated}
        open={grantFormSlideIsOpened}
        organizationId={organizationId}
      />
    </>
  );
};
