import { FormattedNumber } from "react-intl";

export const CompactFormattedCurrency: React.FC<{
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  value: number;
}> = ({
  currency = "USD",
  maximumFractionDigits = 1,
  minimumFractionDigits = 1,
  value,
}) => (
  <FormattedNumber
    compactDisplay="short"
    currency={currency}
    maximumFractionDigits={maximumFractionDigits}
    minimumFractionDigits={minimumFractionDigits}
    notation="compact"
    style="currency"
    value={value}
  />
);
