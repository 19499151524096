import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Tag2 } from "iconsax-react";
import { useCallback, useMemo, useTransition } from "react";
import { FormattedNumber } from "react-intl";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { Percentage } from "../../../../../components/Percentage";
import { Button } from "../../../../../components/ui/Button";
import { FormRow } from "../../../../../components/ui/Form/FormRow";
import { FormRowContainer } from "../../../../../components/ui/Form/FormRowContainer";
import { Input } from "../../../../../components/ui/Form/Inputs/Input";
import { RadioGroup } from "../../../../../components/ui/Form/RadioGroup";
import { Table } from "../../../../../components/ui/Table";
import { Typography } from "../../../../../components/ui/Typography";
import { useDebounced } from "../../../../../hooks/useDebounced";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../../../paths";
import { ConfigureGrantsLayout } from "../ConfigureGrantsLayout";
import {
  EquityGrid_ConfigureGrants_Query,
  EquityGrid_ConfigureGrants_Query$data,
} from "./__generated__/EquityGrid_ConfigureGrants_Query.graphql";
import { EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation } from "./__generated__/EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation.graphql";
import { CreateEquityGridLevelSlideOverController } from "./CreateEquityGridLevelSlideOver";
import { EditEquityGridLevelSlideOverController } from "./EditEquityGridLevelSlideOver";

const QUERY = graphql`
  query EquityGrid_ConfigureGrants_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      equityGrid {
        id
        activated
        name
        levels {
          id
          name
          role
          yearlySalary
          equityInShares
          equityInUsd
          ownership
        }
      }
      ...CreateEquityGridLevelSlideOver_Organization
      ...EditEquityGridLevelSlideOver_Organization
    }
  }
`;

const TOGGLE_EQUITY_GRID_ACTIVATED_MUTATION = graphql`
  mutation EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation(
    $equityGridId: UUID!
  ) {
    toggleEquityGridActivated(id: $equityGridId) {
      activated
    }
  }
`;

const UPDATE_EQUITY_GRID_NAME_MUTATION = graphql`
  mutation EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation(
    $equityGridId: UUID!
    $name: String!
  ) {
    updateEquityGrid(attributes: { name: $name }, id: $equityGridId) {
      name
    }
  }
`;

type EquityGridLevel =
  EquityGrid_ConfigureGrants_Query$data["organization"]["equityGrid"]["levels"][number];

const columnHelper = createColumnHelper<EquityGridLevel>();

export default function EarlyExercise() {
  const organizationId = useOrganizationIdParam();

  const { query, refreshQuery } = useQuery<EquityGrid_ConfigureGrants_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  const [
    triggerUpdateEquityGridNameMutation,
    updateEquityGridNameMutationIsInFlight,
  ] = useSafeMutation(UPDATE_EQUITY_GRID_NAME_MUTATION);

  const {
    isDebouncing: equityGridInputValueIsDebouncing,
    liveState: liveEquityGridInputValue,
    setState: setEquityGridInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: query.organization.equityGrid.name,
    onDebounce: useCallback(
      (value: string) => {
        if (value && value !== query.organization.equityGrid.name) {
          void triggerUpdateEquityGridNameMutation({
            variables: {
              equityGridId: query.organization.equityGrid.id,
              name: value,
            },
          });
        }
      },
      [
        query.organization.equityGrid.id,
        query.organization.equityGrid.name,
        triggerUpdateEquityGridNameMutation,
      ],
    ),
  });

  const [
    triggerToggleEquityGridActivatedMutation,
    toggleEquityGridActivatedMutationIsInFlight,
  ] =
    useSafeMutation<EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation>(
      TOGGLE_EQUITY_GRID_ACTIVATED_MUTATION,
    );

  const handleToggle = () => {
    void triggerToggleEquityGridActivatedMutation({
      variables: {
        equityGridId: query.organization.equityGrid.id,
      },
    });
  };

  const [dataLoadingTransitionIsInProgress, startDataLoadingTransition] =
    useTransition();

  function handleCreateEquityGridLevelClose() {
    startDataLoadingTransition(() => {
      refreshQuery();
    });
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        cell: (context) => {
          return (
            <Typography
              className="flex items-center gap-2"
              variant="Semibold/Extra Small"
            >
              <Tag2 className="w-6 text-purple-05" variant="Bulk" />
              <div>{context.getValue()}</div>
            </Typography>
          );
        },
        enableSorting: true,
        header: () => "Level",
        id: "level",
      }),
      columnHelper.accessor((row) => row.role, {
        cell: (context) => {
          return (
            <span className="text-black-05">{context.getValue() ?? "-"}</span>
          );
        },
        enableSorting: true,
        header: () => "Track",
        id: "track",
      }),
      columnHelper.accessor((row) => row.equityInShares, {
        cell: (context) => {
          const level = context.row.original;
          return (
            <Typography className="space-y-1" variant="Semibold/Extra Small">
              <div>
                <FormattedNumber
                  currency="USD"
                  style="currency"
                  value={level.equityInUsd}
                />
              </div>
              {level.equityInShares !== null && (
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  <FormattedNumber value={level.equityInShares} /> shares
                </Typography>
              )}
              {level.ownership !== null && (
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  <Percentage value={level.ownership} />
                </Typography>
              )}
            </Typography>
          );
        },
        enableSorting: true,
        header: () => "Level ownership",
        id: "ownership",
        meta: { alignRight: true },
      }),
      columnHelper.accessor((row) => row.yearlySalary, {
        cell: (context) => {
          const salary = context.getValue();
          return (
            <span className="text-black-05">
              {typeof salary === "number" ? (
                <FormattedNumber
                  currency="USD"
                  style="currency"
                  value={salary}
                />
              ) : (
                <>-</>
              )}
            </span>
          );
        },
        enableSorting: true,
        header: () => "Yearly salary",
        id: "salary",
        meta: { alignRight: true },
      }),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const level = context.row.original;
          return (
            <EditEquityGridLevelSlideOverController.Controller
              render={({ open }) => (
                <Button
                  onClick={() => {
                    open({
                      data: {
                        equityGridLevelId: level.id,
                        organizationFragment: query.organization,
                      },
                    });
                  }}
                  size="extra small"
                  variant="Secondary Outline"
                >
                  Edit
                </Button>
              )}
            />
          );
        },
        enableSorting: false,
        header: () => "",
        id: "action",
        meta: { alignRight: true },
      }),
    ],
    [query.organization],
  );

  const data = useMemo(
    () => [...query.organization.equityGrid.levels],
    [query.organization.equityGrid.levels],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ desc: false, id: "level" }],
    },
  });

  return (
    <Page
      analyticsCategory="Admin Configure Equity Grid"
      analyticsName="Admin - Equity - Configure - Equity Grid"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity equity grid settings`}
    >
      <CreateEquityGridLevelSlideOverController.Provider
        onClose={handleCreateEquityGridLevelClose}
      >
        <EditEquityGridLevelSlideOverController.Provider
          onClose={handleCreateEquityGridLevelClose}
        >
          <ConfigureGrantsLayout
            subtitle="The grid allows you to add levels to your grantees and show equity levels data to your board members."
            title="Equity grid"
          >
            <div className="space-y-10">
              <div className="space-y-6">
                <FormRowContainer title="Details">
                  <FormRow label="Define if the grid is active or not">
                    <RadioGroup
                      disabled={toggleEquityGridActivatedMutationIsInFlight}
                      onChange={handleToggle}
                      value={query.organization.equityGrid.activated}
                    >
                      <div className="grid grid-cols-2 gap-4">
                        <RadioGroup.Card
                          description="Activating the grid allows you to follow the ownership of your team over time, in line with your company’s philosophy and levels"
                          loading={toggleEquityGridActivatedMutationIsInFlight}
                          value={true}
                        >
                          Activate grid
                        </RadioGroup.Card>
                        <RadioGroup.Card
                          description="Deactivating the grid will prevent you to follow equity ownership based on your company levels."
                          loading={toggleEquityGridActivatedMutationIsInFlight}
                          value={false}
                        >
                          Deactivate grid
                        </RadioGroup.Card>
                      </div>
                    </RadioGroup>
                  </FormRow>
                </FormRowContainer>
                {query.organization?.equityGrid.activated && (
                  <FormRow
                    error={
                      liveEquityGridInputValue
                        ? undefined
                        : "Grid name is required"
                    }
                    label="Grid name"
                  >
                    <Input
                      invalid={!liveEquityGridInputValue}
                      loading={
                        updateEquityGridNameMutationIsInFlight ||
                        equityGridInputValueIsDebouncing
                      }
                      onChange={(event) => {
                        setEquityGridInputValue(event.currentTarget.value);
                      }}
                      value={liveEquityGridInputValue}
                    />
                  </FormRow>
                )}
              </div>
              <div className="space-y-6">
                {query.organization?.equityGrid.activated && (
                  <>
                    <Table.Smart
                      loading={dataLoadingTransitionIsInProgress}
                      table={table}
                      tableClassName="grid-cols-[1fr,1fr,1fr,1fr,90px]"
                      tableDisplay="grid"
                    />
                    <CreateEquityGridLevelSlideOverController.Controller
                      render={({ open, transitionInProgress }) => (
                        <Button
                          loading={transitionInProgress}
                          onClick={() => {
                            open({
                              data: {
                                equityGridId: query.organization.equityGrid.id,
                                organizationFragment: query.organization,
                              },
                            });
                          }}
                          size="small"
                          type="button"
                        >
                          + Add level
                        </Button>
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </ConfigureGrantsLayout>
        </EditEquityGridLevelSlideOverController.Provider>
      </CreateEquityGridLevelSlideOverController.Provider>
    </Page>
  );
}
