import { EyeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

import { useBoolean } from "../../hooks/useBoolean";
import { Input } from "./Form/Inputs/Input";

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  {
    passwordIsRevealed?: boolean;
    togglePasswordIsRevealed?: () => void;
  } & React.ComponentProps<typeof Input>
>(function PasswordInput(
  {
    passwordIsRevealed: _passwordIsRevealed,
    togglePasswordIsRevealed: _togglePasswordIsRevealed,
    ...props
  },
  ref,
) {
  const { toggle: __togglePasswordIsRevealed, value: __passwordIsRevealed } =
    useBoolean(false);

  const passwordIsRevealed = _passwordIsRevealed ?? __passwordIsRevealed;
  const togglePasswordIsRevealed =
    _togglePasswordIsRevealed ?? __togglePasswordIsRevealed;

  return (
    <Input
      ref={ref}
      {...props}
      after={
        <button onClick={togglePasswordIsRevealed} tabIndex={-1} type="button">
          <EyeIcon
            className={classNames("h-4 w-auto", {
              "text-gray-07": !passwordIsRevealed,
              "text-primary": passwordIsRevealed,
            })}
          />
        </button>
      }
      type={passwordIsRevealed ? "text" : "password"}
    />
  );
});
