import { FormattedNumber } from "react-intl";

export const FormattedCurrency: React.FC<{
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  value: number;
}> = ({ currency = "USD", value, ...props }) => (
  <FormattedNumber
    currency={currency}
    style="currency"
    value={value}
    {...props}
  />
);
