import React, { useCallback } from "react";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { CancelBoardConsentButton_cancelBoardConsent_Mutation } from "./__generated__/CancelBoardConsentButton_cancelBoardConsent_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { Toast } from "./ui/Toast";
import { WarningButton } from "./ui/WarningButton";

const CANCEL_BOARD_CONSENT_MUTATION = graphql`
  mutation CancelBoardConsentButton_cancelBoardConsent_Mutation(
    $boardConsentId: UUID!
  ) {
    cancelBoardConsent(id: $boardConsentId)
  }
`;

export const CancelBoardConsentButton: React.FC<
  {
    boardConsentId: string;
    onBoardConsentVoided?: () => void;
  } & Omit<
    React.ComponentProps<typeof Button>,
    "children" | "loading" | "onClick" | "size" | "type" | "variant"
  >
> = ({ boardConsentId, onBoardConsentVoided, ...props }) => {
  const toaster = useToaster();

  const [cancelBoardConsent, cancelBoardConsentMutationIsInFlight] =
    useSafeMutation<CancelBoardConsentButton_cancelBoardConsent_Mutation>(
      CANCEL_BOARD_CONSENT_MUTATION,
    );

  const handleCancelBoardConsentButtonClick = useCallback(async () => {
    await cancelBoardConsent({
      variables: { boardConsentId },
    });

    toaster.push(
      <Toast title="Success">
        The board consent has successfully been canceled.
      </Toast>,
    );

    onBoardConsentVoided?.();
  }, [cancelBoardConsent, boardConsentId, toaster, onBoardConsentVoided]);

  return (
    <WarningButton
      loading={cancelBoardConsentMutationIsInFlight}
      onConfirm={handleCancelBoardConsentButtonClick}
      size="small"
      type="button"
      variant="Danger Outline"
      warningContent="This will cancel the board consent and return the drafts to review."
      warningTitle="Are you sure you want to cancel this board consent?"
      {...props}
    >
      Cancel board consent
    </WarningButton>
  );
};
