/**
 * @generated SignedSource<<55aee645e1d09d94bf02e6cdc4f2fb92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningLayout_Query$variables = {
  organizationId: string;
};
export type PlanningLayout_Query$data = {
  readonly organization: {
    readonly ignoredPlanningEntrySuggestions: ReadonlyArray<{
      readonly __typename: "PlanningEntrySuggestion";
    }>;
    readonly planningEntries: ReadonlyArray<{
      readonly __typename: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"LargeOneColumnLayout_Organization">;
  };
};
export type PlanningLayout_Query = {
  response: PlanningLayout_Query$data;
  variables: PlanningLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlanningLayout_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LargeOneColumnLayout_Organization"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "planningEntries",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PlanningEntrySuggestion",
              "kind": "LinkedField",
              "name": "ignoredPlanningEntrySuggestions",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlanningLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "planningEntries",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlanningEntrySuggestion",
            "kind": "LinkedField",
            "name": "ignoredPlanningEntrySuggestions",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e49aacad56bebe3788fe0d46b912a241",
    "id": null,
    "metadata": {},
    "name": "PlanningLayout_Query",
    "operationKind": "query",
    "text": "query PlanningLayout_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    ...LargeOneColumnLayout_Organization\n    planningEntries {\n      __typename\n      id\n    }\n    ignoredPlanningEntrySuggestions {\n      __typename\n      id\n    }\n    id\n  }\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n"
  }
};
})();

(node as any).hash = "e9ec447969f91e7e1976f574277df5cb";

export default node;
