import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { ResponsiveContainer } from "recharts";
import { graphql } from "relay-runtime";

import { ValuationHistoryChart_Organization$key } from "./__generated__/ValuationHistoryChart_Organization.graphql";
import { FormattedFMV } from "./FormattedFMV";
import { ShortDate } from "./ShortDate";
import { TimelineLinearGraph } from "./TimelineLinearGraph";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

interface DotData {
  boardDetermined?: boolean | null;
  tag?: null | string;
  type: "fmv" | "pps";
  x: Date;
  y: number;
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment ValuationHistoryChart_Organization on Organization {
    name
    pricePerShareHistory {
      value
      date
      boardDetermined
      tag
    }
    fairMarketValueHistory {
      value
      date
      boardDetermined
      tag
    }
  }
`;

export const ValuationHistoryChart: React.FC<
  {
    fairMarketValue?: boolean;
    organizationFragment: ValuationHistoryChart_Organization$key;
    pricePerShare?: boolean;
  } & Omit<React.ComponentProps<typeof ResponsiveContainer>, "children">
> = ({
  fairMarketValue = false,
  organizationFragment,
  pricePerShare = false,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const pricePerShareHistory = useMemo(() => {
    return organization.pricePerShareHistory.map((pps) => ({
      boardDetermined: pps.boardDetermined,
      date: new Date(pps.date),
      tag: pps.tag,
      value: pps.value,
    }));
  }, [organization.pricePerShareHistory]);

  const fairMarketValueHistory = useMemo(() => {
    return organization.fairMarketValueHistory.map((fmv) => ({
      boardDetermined: fmv.boardDetermined,
      date: new Date(fmv.date),
      tag: fmv.tag,
      value: fmv.value,
    }));
  }, [organization.fairMarketValueHistory]);

  const sortedValuationHistory = useMemo(() => {
    const linesDots: DotData[] = [];

    if (fairMarketValue) {
      fairMarketValueHistory.forEach((fmv) => {
        linesDots.push({
          boardDetermined: fmv.boardDetermined,
          tag: fmv.tag,
          type: "fmv",
          x: fmv.date,
          y: fmv.value,
        });
      });
    }

    if (pricePerShare) {
      pricePerShareHistory.forEach((pps) => {
        linesDots.push({
          boardDetermined: pps.boardDetermined,
          tag: pps.tag,
          type: "pps",
          x: pps.date,
          y: pps.value,
        });
      });
    }

    return linesDots;
  }, [
    fairMarketValue,
    fairMarketValueHistory,
    pricePerShare,
    pricePerShareHistory,
  ]);

  return (
    <TimelineLinearGraph
      allowDecimalsOnYAxis
      dataPoints={sortedValuationHistory}
      height={230}
      renderTooltip={({ boardDetermined, tag, x: date, y }) => {
        return (
          <div className="flex flex-col gap-2 rounded bg-black-07 p-4 text-white">
            <Typography variant="Regular/Extra Small">
              {organization.name}
            </Typography>

            <Typography
              as="div"
              className="flex flex-col"
              variant="Medium/Extra Small"
            >
              <Typography variant="Regular/Caption">Value:</Typography>
              <FormattedFMV value={y} />
            </Typography>
            <Typography
              as="div"
              className="flex flex-col"
              variant="Medium/Extra Small"
            >
              <Typography variant="Regular/Caption">Updated:</Typography>
              <ShortDate value={date} />
            </Typography>
            {tag && (
              <div>
                <Tag color="primary">{tag.toUpperCase()}</Tag>
              </div>
            )}
            {boardDetermined && (
              <div>
                <Tag color="gray">BOARD DETERMINED</Tag>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
