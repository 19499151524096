import { InformationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useMemo } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { NoticeMessage } from "../NoticeMessage";
import { Tag } from "../Tag";
import { TooltipContainer } from "../TooltipContainer";
import { Typography } from "../Typography";
import { Input } from "./Inputs/Input";

const Label: React.FC<React.ComponentProps<"label">> = ({
  children,
  className,
  ...props
}) => {
  return (
    <label className={className} {...props}>
      <Typography
        as="div"
        className="text-black-07"
        variant="Medium/Extra Small"
      >
        {children}
      </Typography>
    </label>
  );
};

const FormRow_: React.FC<{
  _forwardErrorToChildren?: boolean;
  children: React.ReactElement<React.ComponentProps<typeof Input>>;
  className?: string;
  context?: React.ReactNode;
  dataCy?: string;
  error?: React.ReactNode;
  help?: React.ReactNode;
  id?: string;
  label?: React.ReactNode;
  subLabel?: React.ReactNode;
  warning?: React.ReactNode;
}> = ({
  _forwardErrorToChildren = true,
  children,
  className,
  context,
  dataCy,
  error,
  help,
  id,
  label,
  subLabel,
  warning,
}) => {
  const childrenProps = useMemo(() => {
    if (!_forwardErrorToChildren) {
      return children.props;
    }

    return {
      invalid: error ? true : undefined,
      ...children.props,
    };
  }, [_forwardErrorToChildren, children.props, error]);

  return (
    <div
      className={classNames("w-full space-y-2", className)}
      data-cy={dataCy ?? label}
    >
      {label && (
        <div className="flex flex-1 items-center justify-between gap-2">
          <div className="flex gap-2">
            <Label className="block" htmlFor={id}>
              {label}
            </Label>
            {help && (
              <TooltipContainer
                tooltipContent={<div className="text-center">{help}</div>}
              >
                <InformationCircleIcon className="w-5 text-primary" />
              </TooltipContainer>
            )}
          </div>
          {warning}
        </div>
      )}

      {subLabel && (
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {subLabel}
        </Typography>
      )}

      {React.cloneElement(children, childrenProps)}

      {error && (
        <Tag color="red" size="Small" wrapText>
          {error}
        </Tag>
      )}

      {context ? <NoticeMessage size="Small">{context}</NoticeMessage> : null}
    </div>
  );
};

const FormFormRow = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & Omit<
  React.ComponentProps<typeof FormRow_>,
  "_forwardErrorToChildren" | "error"
>) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <FormRow_
      id={field.name}
      {...props}
      _forwardErrorToChildren={false}
      error={fieldState.error?.message}
    />
  );
};

export const FormRow = Object.assign(FormRow_, { Form: FormFormRow, Label });
