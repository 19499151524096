/**
 * @generated SignedSource<<240b976073d2adeb7d340e56261e16f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsRow_Instrument$data = {
  readonly equityType: {
    readonly name: EquityTypeName;
    readonly taxResidenceCountry: {
      readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
    };
  };
  readonly needsAdditionalFormalities: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"NeedsAdditionalFormalitiesWarning_Instrument">;
  readonly " $fragmentType": "GrantDetailsRow_Instrument";
};
export type GrantDetailsRow_Instrument$key = {
  readonly " $data"?: GrantDetailsRow_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsRow_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsRow_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsAdditionalFormalities",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CountryFlag_Country"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "equityType"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NeedsAdditionalFormalitiesWarning_Instrument"
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "10da52a631c00a519357a98bbfe07691";

export default node;
