import { compact } from "lodash";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useGrantDocuments_EasopGrant$key } from "./__generated__/useGrantDocuments_EasopGrant.graphql";

const EASOP_GRANT_FRAGMENT = graphql`
  fragment useGrantDocuments_EasopGrant on EasopGrant {
    label
    grantee {
      name
    }
    grantNoticeDocument {
      downloadUrl
      updatedAt
    }
    awardAgreementDocument {
      downloadUrl
      updatedAt
    }
    signedBoardConsentDocument {
      downloadUrl
      updatedAt
    }
    countrySpecificAddendumDocument {
      downloadUrl
      updatedAt
    }
    formOfExerciseAgreementDocument {
      downloadUrl
      updatedAt
    }
    formOfEarlyExerciseStockPurchaseAgreementDocument {
      downloadUrl
      updatedAt
    }
  }
`;

export type DocumentTemplateType =
  | "AWARD_AGREEMENT"
  | "BOARD_CONSENT"
  | "COUNTRY_SPECIFIC_ADDENDUM"
  | "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT"
  | "FORM_OF_EXERCISE_AGREEMENT"
  | "GRANT_NOTICE";

export const useGrantDocuments = ({
  easopGrant,
}: {
  easopGrant: useGrantDocuments_EasopGrant$key;
}) => {
  const easopGrantFragment = useFragment(EASOP_GRANT_FRAGMENT, easopGrant);

  const buildItem = useMemo(
    () =>
      ({
        document,
        label,
        type,
      }: {
        document?: { downloadUrl: string; updatedAt?: null | string } | null;
        label: string;
        type: DocumentTemplateType;
      }) =>
        document
          ? {
              downloadUrl: document.downloadUrl,
              fileName: `${easopGrantFragment.grantee.name} - ${easopGrantFragment.label} - ${label}.pdf`,
              label,
              type,
              updatedAt: document.updatedAt,
            }
          : null,
    [easopGrantFragment.grantee.name, easopGrantFragment.label],
  );

  return useMemo(
    () =>
      compact([
        buildItem({
          document: easopGrantFragment.awardAgreementDocument,
          label: "Award agreement",
          type: "AWARD_AGREEMENT",
        }),
        buildItem({
          document: easopGrantFragment.countrySpecificAddendumDocument,
          label: "Country specific addendum",
          type: "COUNTRY_SPECIFIC_ADDENDUM",
        }),
        buildItem({
          document: easopGrantFragment.formOfExerciseAgreementDocument,
          label: "Form of exercise agreement",
          type: "FORM_OF_EXERCISE_AGREEMENT",
        }),
        buildItem({
          document:
            easopGrantFragment.formOfEarlyExerciseStockPurchaseAgreementDocument,
          label: "Form of early exercise stock purchase agreement",
          type: "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT",
        }),
        buildItem({
          document: easopGrantFragment.grantNoticeDocument,
          label: "Grant notice",
          type: "GRANT_NOTICE",
        }),
        buildItem({
          document: easopGrantFragment.signedBoardConsentDocument,
          label: "Signed board consent",
          type: "BOARD_CONSENT",
        }),
      ]),
    [buildItem, easopGrantFragment],
  );
};
