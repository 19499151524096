import { zodResolver } from "@hookform/resolvers/zod";
import { noop } from "lodash";
import React, { Suspense, useCallback } from "react";
import { useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { makeRemoteController } from "../helpers/makeRemoteController";
import { useApplicationName } from "../hooks/useApplicationTheme";
import { useDownloadFairMarketValueBoardConsentPreview } from "../hooks/useDownloadBoardConsentPreview";
import { useQuery } from "../hooks/useQuery";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation } from "./__generated__/FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation.graphql";
import { FairMarketValueBoardApprovalSlider_Organization$key } from "./__generated__/FairMarketValueBoardApprovalSlider_Organization.graphql";
import { FairMarketValueBoardApprovalSlider_Query } from "./__generated__/FairMarketValueBoardApprovalSlider_Query.graphql";
import { FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation } from "./__generated__/FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation.graphql";
import { ConfirmationNoticeMessage } from "./ConfirmationNoticeMessage";
import { BoardMembersSection } from "./ConfirmSubmitBoardConsentSlide/BoardMembersSection";
import { BoardNoteSection } from "./ConfirmSubmitBoardConsentSlide/BoardNoteSection";
import { Section } from "./ConfirmSubmitBoardConsentSlide/Section";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { CartaLogo } from "./ui/CartaLogo";
import { Divider } from "./ui/Divider";
import {
  FileInput,
  useFileInputFormUploadController,
} from "./ui/Form/FileInput";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { Input } from "./ui/Form/Inputs/Input";
import { RadioGroup } from "./ui/Form/RadioGroup";
import { PulleyLogo } from "./ui/PulleyLogo";
import { SlideOver } from "./ui/SlideOver";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValueBoardApprovalSlider_Organization on Organization {
    id
    allowBoardConsentHandledOutsideEasop
    boardMembers {
      id
    }
    latestFairMarketValue @required(action: THROW) {
      id
      date
      valuationFirm
    }
    ...BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization
    ...BoardNoteSection_Organization
  }
`;

const SEND_FAIR_MARKET_VALUE_TO_BOARD_FOR_CONSENT_MUTATION = graphql`
  mutation FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation(
    $fairMarketValueId: UUID!
    $valuationFirm: String!
    $valuationReportDocumentId: String!
  ) {
    sendFairMarketValueToBoardForConsent(
      fairMarketValueId: $fairMarketValueId
      valuationFirm: $valuationFirm
      valuationReportDocumentId: $valuationReportDocumentId
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

const IMPORT_SIGNED_BOARD_CONSENT_APPROVING_FAIR_MARKET_VALUE_MUTATION = graphql`
  mutation FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation(
    $fairMarketValueId: UUID!
    $valuationFirm: String!
    $signedBoardConsentDocumentIds: [String!]!
    $boardConsentSignatureDate: Date!
  ) {
    importSignedBoardConsentApprovingFairMarketValue(
      fairMarketValueId: $fairMarketValueId
      valuationFirm: $valuationFirm
      signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds
      boardConsentSignatureDate: $boardConsentSignatureDate
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

const SCHEMA = z.discriminatedUnion("flow", [
  z.object({
    flow: z.literal("Outside Easop"),

    acknowledgedEasopIsNotResponsibleForBoardConsent: z.literal(true, {
      message: "You must acknowledge the statement",
    }),
    boardConsentSignatureDate: z.string().date("You must select a date"),
    valuationFirm: z.string().trim().min(1),

    signedBoardConsentDocumentIds: z
      .array(z.string())
      .min(
        1,
        "You must import the board consent approving your 409A valuation",
      ),
    valuationReportDocumentId: z.string({
      message: "You must import the 409A valuation report",
    }),
  }),
  z.object({
    flow: z.literal("In app"),

    valuationFirm: z.string().trim().min(1),
    valuationReportDocumentId: z.string({
      message: "You must import the 409A valuation report",
    }),
  }),
]);

type FormInput = { boardMembers: unknown } & z.input<typeof SCHEMA>;
type FormOutput = z.output<typeof SCHEMA>;

const useFairMarketValueApprovalForm = ({
  latestFairMarketValueValuationFirm = "",
}: {
  latestFairMarketValueValuationFirm?: string;
}) =>
  useForm<FormInput, unknown, FormOutput>({
    defaultValues: {
      flow: "In app",
      valuationFirm: latestFairMarketValueValuationFirm,
    },
    resolver: zodResolver(SCHEMA),
  });

function ValuationFirmRadioButton({
  checked,
  children: Children,
  onChange,
}: {
  checked: boolean;
  children: React.ComponentType<{ className?: string }> | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label className="flex h-12 cursor-pointer items-center rounded-remote-sm border-[0.5px] border-grey-300 px-6 text-SM/Medium transition-all has-[:checked]:cursor-auto has-[:checked]:border-transparent has-[:checked]:shadow-100 has-[:checked]:ring-2 has-[:checked]:ring-primary">
      <input
        checked={checked}
        className="hidden"
        onChange={onChange}
        type="radio"
      />
      {typeof Children === "string" ? Children : <Children className="h-4" />}
    </label>
  );
}

const CARTA_VALUATION_FIRM_NAME = "Carta Valuations LLC";
const PULLEY_VALUATION_FIRM_NAME = "Prolific Labs Inc. dba Pulley";

const FairMarketValueBoardApprovalSliderContent_: React.FC<{
  onClose: () => void;
  onValuationSentToBoard: () => void;
  organizationFragment: FairMarketValueBoardApprovalSlider_Organization$key;
}> = ({ onClose, onValuationSentToBoard, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const applicationName = useApplicationName();
  const fairMarketValue = organization.latestFairMarketValue;

  const { control, handleSubmit, setError, setValue, watch } =
    useFairMarketValueApprovalForm({
      latestFairMarketValueValuationFirm: fairMarketValue.valuationFirm,
    });

  const [
    valuationFirm,
    flow,
    acknowledgedEasopIsNotResponsibleForBoardConsent,
  ] = watch([
    "valuationFirm",
    "flow",
    "acknowledgedEasopIsNotResponsibleForBoardConsent",
  ]);

  const valuationReportFileInputUploadController =
    useFileInputFormUploadController({
      accept: "application/pdf",
      acceptedExtensions: [".pdf"],
      control,
      multiple: false,
      name: "valuationReportDocumentId",
    });

  const signedBoardConsentDocumentsFileInputUploadController =
    useFileInputFormUploadController({
      accept: "*",
      control,
      multiple: true,
      name: "signedBoardConsentDocumentIds",
    });

  const showBoardConsentHandledOutsideEasopRadioGroup =
    organization.allowBoardConsentHandledOutsideEasop;

  const [sendFairMarketValueToBoardForConsent, _mutationIsInFlight] =
    useSafeMutation<FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation>(
      SEND_FAIR_MARKET_VALUE_TO_BOARD_FOR_CONSENT_MUTATION,
    );

  const [
    importSignedBoardConsentApprovingFairMarketValue,
    __mutationIsInFlight,
  ] =
    useSafeMutation<FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation>(
      IMPORT_SIGNED_BOARD_CONSENT_APPROVING_FAIR_MARKET_VALUE_MUTATION,
    );

  const somethingIsLoading =
    _mutationIsInFlight ||
    __mutationIsInFlight ||
    valuationReportFileInputUploadController.someItemsAreNotReady;

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (formData: FormOutput) => {
    if (flow === "In app" && organization.boardMembers.length === 0) {
      setError("boardMembers", {
        message: "You haven't invited any board members",
      });

      return;
    }

    switch (formData.flow) {
      case "In app":
        await sendFairMarketValueToBoardForConsent({
          variables: {
            fairMarketValueId: fairMarketValue.id,
            valuationFirm: formData.valuationFirm,
            valuationReportDocumentId: formData.valuationReportDocumentId,
          },
        });
        toaster.push(
          <Toast title="Wonderful!">
            Your 409A was successfully sent to board for consent!
          </Toast>,
        );

        break;
      case "Outside Easop":
        await importSignedBoardConsentApprovingFairMarketValue({
          variables: {
            boardConsentSignatureDate: formData.boardConsentSignatureDate,
            fairMarketValueId: fairMarketValue.id,
            signedBoardConsentDocumentIds: [
              formData.valuationReportDocumentId,
              ...formData.signedBoardConsentDocumentIds,
            ],
            valuationFirm: formData.valuationFirm,
          },
        });

        toaster.push(
          <Toast title="Wonderful!">
            Your latest 409A is approved, you can now draft equity again!
          </Toast>,
        );

        break;
    }

    onClose();
    onValuationSentToBoard();
  });

  const {
    downloadFairMarketValueBoardConsentPreview,
    downloadIsInFlight: downloadIsInFlight,
  } = useDownloadFairMarketValueBoardConsentPreview({
    fairMarketValueId: fairMarketValue.id,
  });

  const onPreviewBoardConsentClick = useCallback(async () => {
    await downloadFairMarketValueBoardConsentPreview();
  }, [downloadFairMarketValueBoardConsentPreview]);

  const handleValuationFirmRadioButtonChange = useCallback(
    (valuationFirm: string) => () => {
      setValue("valuationFirm", valuationFirm);
    },
    [setValue],
  );

  return (
    <form className="space-y-10 p-6" onSubmit={onSubmit}>
      <Section
        subtitle="Please fill in the following inputs in order to validate your new 409A"
        title="New 409A valuation details"
      >
        <div className="space-y-4">
          <FormRow.Form
            control={control}
            label="Valuation Firm"
            name="valuationFirm"
          >
            <>
              <div className="flex items-center gap-4">
                <ValuationFirmRadioButton
                  checked={valuationFirm === CARTA_VALUATION_FIRM_NAME}
                  onChange={handleValuationFirmRadioButtonChange(
                    CARTA_VALUATION_FIRM_NAME,
                  )}
                >
                  {CartaLogo}
                </ValuationFirmRadioButton>
                <ValuationFirmRadioButton
                  checked={valuationFirm === PULLEY_VALUATION_FIRM_NAME}
                  onChange={handleValuationFirmRadioButtonChange(
                    PULLEY_VALUATION_FIRM_NAME,
                  )}
                >
                  {PulleyLogo}
                </ValuationFirmRadioButton>
                <ValuationFirmRadioButton
                  checked={
                    ![
                      CARTA_VALUATION_FIRM_NAME,
                      PULLEY_VALUATION_FIRM_NAME,
                    ].includes(valuationFirm || "")
                  }
                  onChange={handleValuationFirmRadioButtonChange("Other")}
                >
                  Other
                </ValuationFirmRadioButton>
              </div>
              <Input.Form
                control={control}
                name="valuationFirm"
                placeholder="Enter valuation firm"
                type="text"
              />
            </>
          </FormRow.Form>
          <FormRow
            label="409A Valuation Date"
            subLabel="The effective date of the new 409A valuation"
          >
            <DatePicker
              disabled
              onChange={noop}
              value={organization.latestFairMarketValue.date}
            />
          </FormRow>
          <FormRow.Form
            control={control}
            label="Upload your new 409A valuation"
            name="valuationReportDocumentId"
          >
            <FileInput
              {...valuationReportFileInputUploadController.inputProps}
            />
          </FormRow.Form>
        </div>
      </Section>

      <Divider />

      {showBoardConsentHandledOutsideEasopRadioGroup && (
        <>
          <Section
            subtitle="A new 409A valuation requires a board signature. Please select below how you want to proceed:"
            title="Approval"
          >
            <div className="space-y-6">
              <FormRow.Form
                control={control}
                label="Choose a way to approve your new 409A"
                name="flow"
              >
                <RadioGroup.Form control={control} name="flow">
                  <div className="space-y-2">
                    <RadioGroup.Card value="In app">
                      Generate and approve a board consent with Remote Equity
                    </RadioGroup.Card>
                    <RadioGroup.Card value="Outside Easop">
                      Upload your own board consent
                    </RadioGroup.Card>
                  </div>
                </RadioGroup.Form>
              </FormRow.Form>

              {flow === "Outside Easop" && (
                <>
                  <FormRow.Form
                    control={control}
                    label="Date of approval"
                    name="boardConsentSignatureDate"
                    subLabel="Input the date of approval of the 409A valuation"
                  >
                    <DatePicker.Form
                      control={control}
                      maxDate={new Date()}
                      name="boardConsentSignatureDate"
                      placeholder="Select a date"
                    />
                  </FormRow.Form>

                  <FormRow.Form
                    control={control}
                    label="Upload board consent"
                    name="signedBoardConsentDocumentIds"
                  >
                    <FileInput
                      {...signedBoardConsentDocumentsFileInputUploadController.inputProps}
                    />
                  </FormRow.Form>
                </>
              )}
            </div>
          </Section>

          <Divider />
        </>
      )}

      {flow === "In app" && (
        <>
          <FormRow.Form control={control} name="boardMembers">
            <BoardMembersSection organizationFragment={organization} />
          </FormRow.Form>
          <Divider />
          <BoardNoteSection
            organizationFragment={organization}
            type="FAIR_MARKET_VALUE"
          />
          <Divider />
        </>
      )}

      {flow === "Outside Easop" && (
        <>
          <FormRow.Form
            control={control}
            name="acknowledgedEasopIsNotResponsibleForBoardConsent"
          >
            <Section
              subtitle="Please confirm the following before proceeding"
              title="Acknowledgements"
            >
              <div className="space-y-4">
                <ConfirmationNoticeMessage
                  confirmed={acknowledgedEasopIsNotResponsibleForBoardConsent}
                  onConfirmClick={() => {
                    setValue(
                      "acknowledgedEasopIsNotResponsibleForBoardConsent",
                      true,
                    );
                  }}
                  size="Large"
                  variant="Info"
                >
                  I confirm that a valid resolution approving the 409A valuation
                  has been adopted by the board (either via an oral meeting, or
                  by way of written decision signed by all board members) on the
                  above date of approval, in accordance with the laws of
                  Delaware and the company&apos;s constitutional documents, and
                  that {applicationName} is not responsible for verifying its
                  content.
                </ConfirmationNoticeMessage>
              </div>
            </Section>
          </FormRow.Form>
          <Divider />
        </>
      )}

      <div className="space-y-4">
        <div className="flex justify-between gap-4">
          <Button
            loading={downloadIsInFlight}
            onClick={onPreviewBoardConsentClick}
            size="small"
            type="button"
            variant="Secondary Outline"
          >
            Preview board consent
          </Button>
          <div className="flex gap-4">
            <Button
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button loading={somethingIsLoading} size="small" type="submit">
              Validate your 409A
            </Button>
          </div>
        </div>
        {flow === "In app" && (
          <Typography
            as="div"
            className="text-right text-gray-09"
            variant="Regular/Caption"
          >
            Board members will be notified of the new approval via email
          </Typography>
        )}
      </div>
    </form>
  );
};

const QUERY = graphql`
  query FairMarketValueBoardApprovalSlider_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      ...FairMarketValueBoardApprovalSlider_Organization
    }
  }
`;

const FairMarketValueBoardApprovalSliderContent: React.FC<
  {
    organizationId: string;
  } & Omit<
    React.ComponentProps<typeof FairMarketValueBoardApprovalSliderContent_>,
    "organizationFragment"
  >
> = ({ organizationId, ...props }) => {
  const {
    query: { organization },
  } = useQuery<FairMarketValueBoardApprovalSlider_Query>(QUERY, {
    organizationId,
  });

  return (
    <FairMarketValueBoardApprovalSliderContent_
      organizationFragment={organization}
      {...props}
    />
  );
};

export const FairMarketValueBoardApprovalSliderRemote = makeRemoteController<{
  onValuationSentToBoard: () => void;
  organizationId: string;
}>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <SlideOver
        floating
        header={
          <SlideOver.Header onClose={close}>
            Validate new 409A valuation
          </SlideOver.Header>
        }
        onClose={close}
        show={state.show}
      >
        <Suspense fallback={<LoadingPlaceholder />}>
          <FairMarketValueBoardApprovalSliderContent
            onClose={close}
            onValuationSentToBoard={state.data.onValuationSentToBoard}
            organizationId={state.data.organizationId}
          />
        </Suspense>
      </SlideOver>
    );
  },
});
