import classNames from "classnames";
import { useMemo } from "react";
import { z } from "zod";

import { InfoCard } from "../../components/ui/InfoCard";

export const passwordRules = {
  atLeast8CharLength: (password: string) => password.length >= 8,
  atLeastOneDigit: (password: string) => /[\d]+/.test(password),
  atLeastOneLowercaseLetter: (password: string) => /[a-z]+/.test(password),
  atLeastOneUppercaseLetter: (password: string) => /[A-Z]+/.test(password),
};

export const validatePasswordStrength = (password: string) => {
  const atLeast8CharLength = passwordRules.atLeast8CharLength(password);
  const atLeastOneLowercaseLetter =
    passwordRules.atLeastOneLowercaseLetter(password);
  const atLeastOneUppercaseLetter =
    passwordRules.atLeastOneUppercaseLetter(password);
  const atLeastOneDigit = passwordRules.atLeastOneDigit(password);
  const passwordIsStrongEnough =
    atLeast8CharLength &&
    atLeastOneLowercaseLetter &&
    atLeastOneUppercaseLetter &&
    atLeastOneDigit;

  return {
    atLeast8CharLength,
    atLeastOneDigit,
    atLeastOneLowercaseLetter,
    atLeastOneUppercaseLetter,
    passwordIsStrongEnough,
  };
};

export const passwordValidationSchema = () =>
  z.string().refine(
    (password) => {
      const validatePasswordStrengthResult = validatePasswordStrength(password);

      return validatePasswordStrengthResult.passwordIsStrongEnough;
    },
    {
      message: "Too weak",
    },
  );

const checkListItemClassName = (isValid: boolean) =>
  classNames({
    "list-disc": !isValid,
    "list-white-heavy-check-mark": isValid,
  });

export const PasswordStrengthInfoCard: React.FC<
  {
    password: string;
  } & Omit<React.ComponentProps<typeof InfoCard>, "title" | "variant">
> = ({ password, ...props }) => {
  const validatePasswordStrengthResult = useMemo(
    () => validatePasswordStrength(password),
    [password],
  );

  return (
    <InfoCard title="Your password must contain :" variant="error" {...props}>
      <ul className="ml-4 list-disc">
        <li
          className={checkListItemClassName(
            validatePasswordStrengthResult.atLeast8CharLength,
          )}
        >
          At least 8 characters
        </li>
        <li
          className={checkListItemClassName(
            validatePasswordStrengthResult.atLeastOneLowercaseLetter,
          )}
        >
          Lowercase letters (a-z)
        </li>
        <li
          className={checkListItemClassName(
            validatePasswordStrengthResult.atLeastOneUppercaseLetter,
          )}
        >
          Capital letters (A-Z)
        </li>
        <li
          className={checkListItemClassName(
            validatePasswordStrengthResult.atLeastOneDigit,
          )}
        >
          Numbers (0-9)
        </li>
      </ul>
    </InfoCard>
  );
};
