export function mapRecordValues<T extends string, TValue, TReturn>(
  record: Record<T, TValue>,
  mappingFunction: (value: TValue, key: T) => TReturn,
): Record<T, TReturn> {
  const result: Record<T, TReturn> = {} as Record<T, TReturn>;
  for (const key in record) {
    result[key] = mappingFunction(record[key], key);
  }
  return result;
}

export function keysOf<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}
