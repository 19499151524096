/**
 * @generated SignedSource<<19a77da5a06f2bf965eaa7f0cdfe0470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestSimulation_Organization$data = {
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly value: number;
    readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestNotifySection_FairMarketValueAtExercise" | "ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovalSection_Organization" | "ExerciseRequestNotifySection_Organization" | "ExerciseRequestTaxTreatmentSection_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "ExerciseRequestSimulation_Organization";
};
export type ExerciseRequestSimulation_Organization$key = {
  readonly " $data"?: ExerciseRequestSimulation_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestSimulation_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestSimulation_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestTaxTreatmentSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestApprovalSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestNotifySection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "FairMarketValue",
        "kind": "LinkedField",
        "name": "latestFairMarketValue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExerciseRequestNotifySection_FairMarketValueAtExercise"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "latestFairMarketValue"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e885c8bca8668efbaee7ab96fa833f56";

export default node;
