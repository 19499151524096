import classNames from "classnames";
import { addMonths, format, isAfter, parse } from "date-fns";
import _, { last, max, reverse, sortBy, sum } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { REMOTE_COLORS } from "../constants/colors";
import { roundedDifferenceInMonths } from "../helpers/date-utility";
import { ShortDate } from "./ShortDate";
import { Switch } from "./ui/Switch";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

export interface DataPoint {
  cumulativeVested: number;
  date: Date;
}

const getCumulativeVestedAtDate = (
  vestingDataPoints: ReadonlyArray<DataPoint>,
  date: Date,
) => {
  const reversedVestingDataPoints = reverse([...vestingDataPoints]);
  return (
    reversedVestingDataPoints.find(
      (dataPoint) => !isAfter(new Date(dataPoint.date), date),
    )?.cumulativeVested || 0
  );
};

const Colors = [
  {
    backgroundColor: classNames("bg-red-05"),
    fill: REMOTE_COLORS.red[100],
    stroke: REMOTE_COLORS.red[700],
    textColor: classNames("text-red-03"),
  },
  {
    backgroundColor: classNames("bg-orange-05"),
    fill: REMOTE_COLORS.orange[100],
    stroke: REMOTE_COLORS.orange[700],
    textColor: classNames("text-orange-03"),
  },
  {
    backgroundColor: classNames("bg-fuchsia-05"),
    fill: REMOTE_COLORS.fuchsia[100],
    stroke: REMOTE_COLORS.fuchsia[700],
    textColor: classNames("text-fuchsia-03"),
  },
  {
    backgroundColor: classNames("bg-purple-05"),
    fill: REMOTE_COLORS.purple[100],
    stroke: REMOTE_COLORS.purple[700],
    textColor: classNames("text-purple-03"),
  },
] as const;

export const vestingGraphColors = (index: number) =>
  Colors[index % Colors.length];

export const VestingGraph: React.FC<
  {
    dateDisplayMode?: "absolute" | "relative";
    displayTodayLabel?: boolean;
    getGrantIndex?: (key: string) => number;
    showLegend?: boolean;
    vestingsDataPoints: Array<{
      dataPoints: ReadonlyArray<DataPoint>;
      key: string;
      label: string;
    }>;
    vestingStartDate?: string;
  } & React.ComponentProps<"div">
> = ({
  className,
  dateDisplayMode = "absolute",
  displayTodayLabel = true,
  getGrantIndex = () => 0,
  showLegend = true,
  vestingsDataPoints: unsortedVestingsDataPoints,
  vestingStartDate,
}) => {
  const now = new Date().getTime();
  const vestingStartDateTimeStamp = vestingStartDate
    ? parse(vestingStartDate, "yyyy-MM", new Date()).getTime()
    : null;

  const vestingsDataPoints = useMemo(
    () =>
      sortBy(unsortedVestingsDataPoints, ({ dataPoints }) =>
        dataPoints
          .find(({ cumulativeVested }) => cumulativeVested > 0)
          ?.date.toISOString(),
      ),
    [unsortedVestingsDataPoints],
  );

  const allDates = _(vestingsDataPoints)
    .flatMap((vestingDataPoints) => vestingDataPoints.dataPoints)
    .map((dataPoint) => dataPoint.date)
    .uniqBy((date) => date.toISOString())
    .sortBy((date) => date.toISOString())
    .value();

  const vestingsByDate: Record<string, { [key: string]: number }> = {};
  allDates.forEach((date, i) => {
    const vestingsAtDateByKey: { [key: string]: number } = {};
    vestingsDataPoints.forEach((vestingDataPoints) => {
      let cumulativeVested: number = getCumulativeVestedAtDate(
        vestingDataPoints.dataPoints,
        date,
      );
      if (cumulativeVested === 0 && i > 0) {
        const previousDate = allDates[i - 1];
        if (previousDate) {
          cumulativeVested =
            vestingsByDate[previousDate.toISOString()]?.[
              vestingDataPoints.key
            ] || 0;
        }
      }
      vestingsAtDateByKey[vestingDataPoints.key] = cumulativeVested;
    });
    vestingsByDate[date.toISOString()] = vestingsAtDateByKey;
  });

  const allDataPoints = allDates.map((date) => ({
    timestamp: date.getTime(),
    ...vestingsByDate[date.toISOString()],
    cumulative: sum(Object.values(vestingsByDate[date.toISOString()] || {})),
  }));

  const minTimestamp = allDataPoints[0]?.timestamp || 0;
  const maxTimestamp = last(allDataPoints)?.timestamp || 0;

  const threeMonthsBeforeTimestamp = addMonths(
    new Date(minTimestamp),
    -3,
  ).getTime();
  const threeMonthsAfterTimestamp = addMonths(
    new Date(maxTimestamp),
    3,
  ).getTime();

  const getMonthLabel = useCallback(
    (timestamp: number, mode: "long" | "short") => {
      const months = roundedDifferenceInMonths(
        new Date(timestamp),
        vestingStartDate ? new Date(vestingStartDate) : new Date(),
      );

      return mode === "short" ? `M${months}` : `Month ${months}`;
    },
    [vestingStartDate],
  );

  const dateFormatter = (timestamp: number) => {
    switch (dateDisplayMode) {
      case "absolute":
        return format(new Date(timestamp), "yyyy-MM");
      case "relative":
        return getMonthLabel(timestamp, "short");
    }
  };

  const intl = useIntl();

  const viewStates = ["Split view", "Cumulative"] as const;

  const [viewState, setViewState] = useState<(typeof viewStates)[number]>(
    viewStates[0],
  );

  const maxVestedValue = useMemo(
    () =>
      viewState === "Cumulative"
        ? sum(
            vestingsDataPoints.map(
              (vestingDataPoints) =>
                last(vestingDataPoints.dataPoints)?.cumulativeVested || 0,
            ),
          )
        : max(
            vestingsDataPoints.flatMap((vestingDataPoints) =>
              vestingDataPoints.dataPoints.map(
                (dataPoint) => dataPoint.cumulativeVested,
              ),
            ),
          ) || 0,
    [vestingsDataPoints, viewState],
  );

  const { XAxisAngle, XAxisTickMargin } = useMemo(() => {
    switch (dateDisplayMode) {
      case "absolute":
        return { XAxisAngle: 40, XAxisTickMargin: 25 };
      case "relative":
        return { XAxisAngle: 0, XAxisTickMargin: 8 };
    }
  }, [dateDisplayMode]);

  return (
    <div className={classNames(className)}>
      {vestingsDataPoints.length > 1 ? (
        <div className="mb-4 flex justify-start">
          <Switch
            getOptionValue={(option) => option}
            name="vesting-graph-view"
            onChange={setViewState}
            options={viewStates}
            selectedOption={viewState}
          />
        </div>
      ) : null}
      <ResponsiveContainer height={400} width={"100%"}>
        <AreaChart
          data={allDataPoints}
          margin={{
            bottom: 30, // Leaves some space for the XAxis label
            right: 20, // Leaves some space for last label of the XAxis
            top: 10, // Leaves some space for the the top of the chart
          }}
        >
          <CartesianGrid stroke="#D9D9D9" vertical={false} />

          <XAxis
            angle={XAxisAngle}
            dataKey="timestamp"
            domain={[threeMonthsBeforeTimestamp, threeMonthsAfterTimestamp]}
            interval={5}
            minTickGap={-20}
            scale="time"
            stroke="#D9D9D9"
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: 12,
            }}
            tick={{
              className: "fill-gray-08",
            }}
            tickFormatter={dateFormatter}
            tickMargin={XAxisTickMargin}
            type="number"
          />

          <YAxis
            axisLine={false}
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: 12,
            }}
            tick={{
              className: "fill-gray-08",
            }}
            tickFormatter={(value: number) => {
              if (!value) return "0";

              return intl.formatNumber(value, { notation: "compact" });
            }}
            tickLine={false}
            tickMargin={10}
          />

          <Tooltip
            content={({ active, payload }) => {
              if (!active || !payload || !payload[0]) return null;

              const data = payload[0].payload as { [key: string]: number };
              const { timestamp } = payload[0].payload as { timestamp: number };

              return (
                <div className="rounded bg-black-07 px-4 py-2">
                  <Typography
                    as="div"
                    className="capitalize text-white"
                    variant="Regular/Extra Small"
                  >
                    {dateDisplayMode === "absolute" && (
                      <ShortDate value={timestamp} />
                    )}
                    {dateDisplayMode === "relative" &&
                      getMonthLabel(timestamp, "long")}
                  </Typography>

                  {viewState === "Cumulative" ? (
                    <Typography
                      as="div"
                      className="mt-1 text-white"
                      variant="Regular/Caption"
                    >
                      <span className="text-purple-03">All grants:</span>
                      &nbsp;
                      {intl.formatNumber(data["cumulative"] || 0)}
                    </Typography>
                  ) : (
                    vestingsDataPoints.map((vestingDataPoints) => (
                      <Typography
                        as="div"
                        className="mt-1 text-white"
                        key={vestingDataPoints.key}
                        variant="Regular/Caption"
                      >
                        <span
                          className={classNames(
                            vestingGraphColors(
                              getGrantIndex(vestingDataPoints.key),
                            )?.textColor,
                          )}
                        >
                          {vestingDataPoints.label}:
                        </span>
                        &nbsp;
                        {intl.formatNumber(data[vestingDataPoints.key] || 0)}
                      </Typography>
                    ))
                  )}
                </div>
              );
            }}
            cursor={false}
          />

          {viewState === "Cumulative" ? (
            <Area
              className="fill-purple-01 stroke-purple-05"
              dataKey="cumulative"
              stackId="cumulative"
              type="stepAfter"
            />
          ) : (
            vestingsDataPoints.map((vestingDataPoints) => (
              <Area
                dataKey={vestingDataPoints.key}
                fill={
                  vestingGraphColors(getGrantIndex(vestingDataPoints.key))?.fill
                }
                key={vestingDataPoints.key}
                stackId={vestingDataPoints.key}
                stroke={
                  vestingGraphColors(getGrantIndex(vestingDataPoints.key))
                    ?.stroke
                }
                type="stepAfter"
              />
            ))
          )}

          {displayTodayLabel && (
            <ReferenceLine
              isFront={true}
              label={({ viewBox }: { viewBox: { x: number; y: number } }) => {
                return (
                  <foreignObject
                    className={`h-7 w-[52px]`}
                    x={viewBox.x - 26}
                    y={viewBox.y - 32}
                  >
                    <Tag color="gray">Today</Tag>
                  </foreignObject>
                );
              }}
              segment={[
                { x: now, y: 0 },
                { x: now, y: maxVestedValue * 0.9 },
              ]}
              stroke="#626D7D"
            />
          )}
          {vestingStartDateTimeStamp && (
            <ReferenceLine
              isFront={true}
              label={({ viewBox }: { viewBox: { x: number; y: number } }) => {
                return (
                  <foreignObject
                    className={`h-7 w-[86px]`}
                    x={viewBox.x - 43}
                    y={viewBox.y - 32}
                  >
                    <Tag color="gray">Vesting start</Tag>
                  </foreignObject>
                );
              }}
              segment={[
                { x: vestingStartDateTimeStamp, y: 0 },
                { x: vestingStartDateTimeStamp, y: maxVestedValue * 0.7 },
              ]}
              stroke="#626D7D"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>

      {showLegend ? (
        <div className="mt-6 flex flex-wrap justify-end gap-x-10 gap-y-2">
          {viewState === "Cumulative" ? (
            <div className="flex items-center gap-2">
              <div className="h-[10px] w-[10px] rounded-sm bg-purple-03"></div>
              <Typography variant="Regular/Extra Small">All grants</Typography>
            </div>
          ) : (
            vestingsDataPoints.map((vestingDataPoints) => (
              <div
                className="flex items-center gap-2"
                key={vestingDataPoints.key}
              >
                <div
                  className={classNames(
                    "h-[10px] w-[10px] rounded-sm",
                    vestingGraphColors(getGrantIndex(vestingDataPoints.key))
                      ?.backgroundColor,
                  )}
                ></div>
                <Typography variant="Regular/Extra Small">
                  {vestingDataPoints.label}
                </Typography>
              </div>
            ))
          )}
        </div>
      ) : null}
    </div>
  );
};

export const VestingGraphSkeleton = () => {
  return <div className="h-[400px] w-full animate-pulse rounded bg-gray-03" />;
};
