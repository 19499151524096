/**
 * @generated SignedSource<<8456cd82d183315256fb5399e3b30768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LinearVestingOccurrence = "Every12Months" | "Every2Months" | "Every3Months" | "Every6Months" | "EveryMonth" | "Once";
export type DetailsView_VestingSchedules_Query$variables = {
  organizationId: string;
  vestingScheduleId: string;
};
export type DetailsView_VestingSchedules_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditSlideOver_VestingSchedule_Organization" | "LargeCenteredDetailsLayout_Organization">;
  };
  readonly vestingSchedule: {
    readonly __typename: "BackloadedVestingSchedule";
    readonly cliffActivatedOnFirstPeriod: boolean;
    readonly cliffDurationInMonths: number;
    readonly durationInMonths: number;
    readonly easopGrantCount: number;
    readonly id: string;
    readonly isUsed: boolean;
    readonly name: string;
    readonly periods: ReadonlyArray<{
      readonly durationInMonths: number;
      readonly percentageVested: number;
    }>;
    readonly vestedAtCliffPercentage: number;
    readonly vestingOccurrence: LinearVestingOccurrence;
  } | {
    readonly __typename: "LinearVestingSchedule";
    readonly cliffDurationInMonths: number;
    readonly durationInMonths: number;
    readonly easopGrantCount: number;
    readonly id: string;
    readonly isUsed: boolean;
    readonly name: string;
    readonly vestedAtCliffPercentage: number;
    readonly vestingOccurrence: LinearVestingOccurrence;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type DetailsView_VestingSchedules_Query = {
  response: DetailsView_VestingSchedules_Query$data;
  variables: DetailsView_VestingSchedules_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vestingScheduleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vestingScheduleId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUsed",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationInMonths",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vestingOccurrence",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cliffDurationInMonths",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vestedAtCliffPercentage",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "easopGrantCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cliffActivatedOnFirstPeriod",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "BackloadedVestingSchedulePeriod",
  "kind": "LinkedField",
  "name": "periods",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentageVested",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsView_VestingSchedules_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LargeCenteredDetailsLayout_Organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditSlideOver_VestingSchedule_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      },
      {
        "alias": "vestingSchedule",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "LinearVestingSchedule",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "type": "BackloadedVestingSchedule",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailsView_VestingSchedules_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedules",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "vestingSchedule",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "LinearVestingSchedule",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "type": "BackloadedVestingSchedule",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02982c451c673979e26c4e68f2b95a2b",
    "id": null,
    "metadata": {},
    "name": "DetailsView_VestingSchedules_Query",
    "operationKind": "query",
    "text": "query DetailsView_VestingSchedules_Query(\n  $organizationId: OrganizationId!\n  $vestingScheduleId: ID!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...LargeCenteredDetailsLayout_Organization\n    ...EditSlideOver_VestingSchedule_Organization\n  }\n  vestingSchedule: node(id: $vestingScheduleId) {\n    __typename\n    ... on LinearVestingSchedule {\n      __typename\n      id\n      name\n      isUsed\n      durationInMonths\n      vestingOccurrence\n      cliffDurationInMonths\n      vestedAtCliffPercentage\n      easopGrantCount\n    }\n    ... on BackloadedVestingSchedule {\n      __typename\n      id\n      name\n      isUsed\n      durationInMonths\n      vestingOccurrence\n      cliffDurationInMonths\n      vestedAtCliffPercentage\n      easopGrantCount\n      cliffActivatedOnFirstPeriod\n      periods {\n        percentageVested\n        durationInMonths\n      }\n    }\n    id\n  }\n}\n\nfragment EditSlideOver_VestingSchedule_Organization on Organization {\n  ...useVestingScheduleForm_Organization\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeCenteredDetailsLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment useFormattedVestingSchedule_Organization on Organization {\n  vestingSchedules {\n    __typename\n    id\n    name\n  }\n}\n\nfragment useVestingScheduleForm_Organization on Organization {\n  ...useFormattedVestingSchedule_Organization\n}\n"
  }
};
})();

(node as any).hash = "d57ea0761dfd103897507be0eb1ae410";

export default node;
