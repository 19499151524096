/**
 * @generated SignedSource<<67499e13c0f23f32fc2a386579ff5b7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$variables = {
  fairMarketValueId: string;
};
export type useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$data = {
  readonly generateFairMarketValueBoardConsentPreviewURL: string;
};
export type useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation = {
  response: useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$data;
  variables: useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fairMarketValueId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fairMarketValueId",
        "variableName": "fairMarketValueId"
      }
    ],
    "kind": "ScalarField",
    "name": "generateFairMarketValueBoardConsentPreviewURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3bb28d910c039998532ad5de97c12926",
    "id": null,
    "metadata": {},
    "name": "useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation(\n  $fairMarketValueId: UUID!\n) {\n  generateFairMarketValueBoardConsentPreviewURL(fairMarketValueId: $fairMarketValueId)\n}\n"
  }
};
})();

(node as any).hash = "6a193dc0dfbf86c9e5ec6e2c2ecdee9a";

export default node;
