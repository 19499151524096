import { startTransition, useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { Button } from "../../../../../components/ui/Button";
import { DetailsTable } from "../../../../../components/ui/DetailsTable";
import { LargeCenteredDetailsLayout } from "../../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { VESTING_OCCURRENCE_HELPERS_MAP } from "../../../../../hooks/useFormattedVestingSchedule";
import { useQuery } from "../../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
  useVestingScheduleIdParam,
} from "../../../../../paths";
import NotFoundPage from "../../../../NotFound/NotFound";
import {
  DetailsView_VestingSchedules_Query,
  DetailsView_VestingSchedules_Query$data,
} from "./__generated__/DetailsView_VestingSchedules_Query.graphql";
import { EditSlideOverRemote } from "./EditSlideOver";
import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
} from "./FORM_SCHEMA";
import { VestingSchedulePreview } from "./VestingSchedulePreview";

const QUERY = graphql`
  query DetailsView_VestingSchedules_Query(
    $organizationId: OrganizationId!
    $vestingScheduleId: ID!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...LargeCenteredDetailsLayout_Organization
      ...EditSlideOver_VestingSchedule_Organization
    }
    vestingSchedule: node(id: $vestingScheduleId) {
      ... on LinearVestingSchedule {
        __typename
        id
        name
        isUsed
        durationInMonths
        vestingOccurrence
        cliffDurationInMonths
        vestedAtCliffPercentage
        easopGrantCount
      }
      ... on BackloadedVestingSchedule {
        __typename
        id
        name
        isUsed
        durationInMonths
        vestingOccurrence
        cliffDurationInMonths
        vestedAtCliffPercentage
        easopGrantCount
        cliffActivatedOnFirstPeriod
        periods {
          percentageVested
          durationInMonths
        }
      }
    }
  }
`;

type NonNullableVestingSchedule = NonNullable<
  DetailsView_VestingSchedules_Query$data["vestingSchedule"]
>;
type VestingSchedule = {
  __typename: Exclude<NonNullableVestingSchedule["__typename"], "%other">;
} & NonNullableVestingSchedule;

function TypeLine({ vestingSchedule }: { vestingSchedule: VestingSchedule }) {
  const intl = useIntl();

  switch (vestingSchedule.__typename) {
    case "BackloadedVestingSchedule":
      return (
        <DetailsTable.Row
          label="Type"
          value={`Backloaded (${vestingSchedule.periods
            .map((period) =>
              intl.formatNumber(period.percentageVested / 100, {
                maximumFractionDigits: 2,
                style: "percent",
              }),
            )
            .join(" - ")})`}
        />
      );
    case "LinearVestingSchedule":
      return <DetailsTable.Row label="Type" value="Linear" />;
  }
}

const DetailsView_: React.FC<{
  organization: DetailsView_VestingSchedules_Query$data["organization"];
  vestingSchedule: VestingSchedule;
}> = ({ organization, vestingSchedule }) => {
  const parentLink = generatePath(
    APPLICATION_ROUTES["organizationEquityConfigureVestingSchedules"],
    {
      organizationId: organization.id,
    },
  );

  const vestingScheduleType = useMemo(() => {
    switch (vestingSchedule.__typename) {
      case "BackloadedVestingSchedule":
        return "BACKLOADED";
      case "LinearVestingSchedule":
        return "LINEAR";
    }
  }, [vestingSchedule]);

  const vestingScheduleValues = useMemo(() => {
    switch (vestingSchedule.__typename) {
      case "BackloadedVestingSchedule": {
        const value: BackloadedVestingScheduleFormValues = {
          cliffActivatedOnFirstPeriod:
            vestingSchedule.cliffActivatedOnFirstPeriod,
          name: vestingSchedule.name,
          periods: vestingSchedule.periods.map(
            ({ durationInMonths, percentageVested }) => ({
              durationInMonths,
              percentageVested,
            }),
          ),
          vestingOccurrence:
            vestingSchedule.vestingOccurrence as BackloadedVestingScheduleFormValues["vestingOccurrence"],
        };
        return value;
      }
      case "LinearVestingSchedule": {
        const value: LinearVestingScheduleFormValues = {
          cliffDurationInMonths: vestingSchedule.cliffDurationInMonths,
          durationInMonths: vestingSchedule.durationInMonths,
          hasCliff: vestingSchedule.cliffDurationInMonths > 0,
          name: vestingSchedule.name,
          vestedAtCliffPercentage:
            vestingSchedule.__typename === "LinearVestingSchedule"
              ? vestingSchedule.vestedAtCliffPercentage
              : 0,
          vestingOccurrence: vestingSchedule.vestingOccurrence,
        };
        return value;
      }
    }
  }, [vestingSchedule]);

  return (
    <LargeCenteredDetailsLayout
      navigationTitle={vestingSchedule.name}
      organizationFragment={organization}
      parents={[
        {
          link: parentLink,
          title: "Vesting schedules",
        },
      ]}
      title="Vesting schedule"
      titleActions={
        <EditSlideOverRemote.Controller
          render={({ open, transitionInProgress }) => (
            <Button
              disabled={vestingSchedule.isUsed}
              loading={transitionInProgress}
              onClick={() => {
                open({
                  data: {
                    organizationFragment: organization,
                    vestingScheduleId: vestingSchedule.id,
                  },
                });
              }}
              size="small"
              type="button"
            >
              Edit
            </Button>
          )}
        />
      }
    >
      <DetailsTable.Large
        footer={
          <VestingSchedulePreview
            type={vestingScheduleType}
            values={vestingScheduleValues}
          />
        }
        title={vestingSchedule.name}
      >
        <DetailsTable.Row label="Name" value={vestingSchedule.name} />
        <TypeLine vestingSchedule={vestingSchedule} />
        <DetailsTable.Row
          label="Duration"
          value={
            <FormattedNumber
              style="unit"
              unit="month"
              unitDisplay="long"
              value={vestingSchedule.durationInMonths}
            />
          }
        />
        <DetailsTable.Row
          label="Vesting occurs"
          value={
            VESTING_OCCURRENCE_HELPERS_MAP[vestingSchedule.vestingOccurrence]
              .label
          }
        />
        <DetailsTable.Row
          label="Cliff"
          value={
            vestingSchedule.cliffDurationInMonths ? (
              <>
                {vestingSchedule.__typename === "LinearVestingSchedule" && (
                  <>
                    <FormattedNumber
                      maximumFractionDigits={2}
                      style="percent"
                      value={vestingSchedule.vestedAtCliffPercentage / 100}
                    />{" "}
                    after{" "}
                  </>
                )}
                <FormattedNumber
                  style="unit"
                  unit="month"
                  unitDisplay="long"
                  value={vestingSchedule.cliffDurationInMonths}
                />
              </>
            ) : (
              <>No cliff</>
            )
          }
        />
        <DetailsTable.Row
          label="Grants"
          value={<FormattedNumber value={vestingSchedule.easopGrantCount} />}
        />
      </DetailsTable.Large>
    </LargeCenteredDetailsLayout>
  );
};

export default function DetailsView() {
  const organizationId = useOrganizationIdParam();
  const vestingScheduleId = useVestingScheduleIdParam();
  const {
    query: { organization, vestingSchedule },
    refreshQuery,
  } = useQuery<DetailsView_VestingSchedules_Query>(QUERY, {
    organizationId,
    vestingScheduleId,
  });

  function handleEditSlideOverClose() {
    startTransition(() => {
      refreshQuery();
    });
  }

  if (!vestingSchedule) {
    return <NotFoundPage />;
  }

  if (vestingSchedule.__typename === "%other") {
    throw new Error("Invalid typename");
  }

  return (
    <EditSlideOverRemote.Provider onClose={handleEditSlideOverClose}>
      <Page
        analyticsCategory="Admin Configure Vesting Schedule"
        analyticsName="Admin - Equity - Configure - Vesting Schedule - Details"
        organizationId={organizationId}
        title={`Admin | ${organization.name} equity settings | vesting schedule details`}
      >
        <DetailsView_
          organization={organization}
          vestingSchedule={vestingSchedule}
        />
      </Page>
    </EditSlideOverRemote.Provider>
  );
}
