/**
 * @generated SignedSource<<14c6ebd9fc7a19bca65f595b586a7d72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Instrument_Query$variables = {
  granteeId: string;
  organizationId: string;
};
export type Instrument_Query$data = {
  readonly grantee: {
    readonly " $fragmentSpreads": FragmentRefs<"Instrument_Grantee">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"Instrument_Organization">;
  };
};
export type Instrument_Query = {
  response: Instrument_Query$data;
  variables: Instrument_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workRelationship",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Instrument_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Instrument_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Instrument_Grantee"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "grantee"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Instrument_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUSCitizen",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "sortBy",
                "value": "TaxFavored"
              },
              {
                "kind": "Literal",
                "name": "sortDirection",
                "value": "DESC"
              }
            ],
            "concreteType": "Instrument",
            "kind": "LinkedField",
            "name": "instruments",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "awardSuperType",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "taxResidenceCountry",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
                "kind": "LinkedField",
                "name": "legalWatchOuts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "messageShouldBeDisplayedOnGrantFlow",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noWatchOut",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalWatchOut",
                    "kind": "LinkedField",
                    "name": "watchOuts",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "needsAdditionalFormalities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityType",
                "kind": "LinkedField",
                "name": "equityType",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullname",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "taxResidenceCountry",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": "instruments(sortBy:\"TaxFavored\",sortDirection:\"DESC\")"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06918cb8abb05b76fcfd29ba62874723",
    "id": null,
    "metadata": {},
    "name": "Instrument_Query",
    "operationKind": "query",
    "text": "query Instrument_Query(\n  $organizationId: OrganizationId!\n  $granteeId: GranteeId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...Instrument_Organization\n  }\n  grantee(id: $granteeId) {\n    ...Instrument_Grantee\n    id\n  }\n}\n\nfragment InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts on EquityTypeWorkRelationshipCategoryLegalWatchOuts {\n  noWatchOut\n  watchOuts {\n    id\n    title\n    content\n  }\n}\n\nfragment InstrumentLegalWatchOuts_Instrument on Instrument {\n  name\n}\n\nfragment Instrument_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    emoji\n    name\n    code\n  }\n  isUSCitizen\n  workRelationship\n  instruments(sortBy: TaxFavored, sortDirection: DESC) {\n    id\n    awardSuperType\n    name\n    taxResidenceCountry {\n      emoji\n      name\n    }\n    legalWatchOuts {\n      messageShouldBeDisplayedOnGrantFlow\n      ...InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n    }\n    ...InstrumentLegalWatchOuts_Instrument\n    ...Instrument_InstrumentButton_Instrument\n  }\n}\n\nfragment Instrument_InstrumentButton_Instrument on Instrument {\n  name\n  needsAdditionalFormalities\n  equityType {\n    fullname\n    id\n  }\n  ...LinkToEquityType_Instrument\n}\n\nfragment Instrument_Organization on Organization {\n  id\n  name\n}\n\nfragment LinkToEquityType_Instrument on Instrument {\n  workRelationship\n  equityType {\n    id\n    name\n    taxResidenceCountry {\n      emoji\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d70c90ae0af652eb251158e494525272";

export default node;
