/**
 * @generated SignedSource<<6d01a15d139043cabd77afdfb6e19cc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingSlider_Organization$data = {
  readonly adminOnboardingConnectHRISStepSkipped: boolean;
  readonly adminOnboardingSetupGranteesStepCompleted: boolean;
  readonly adminOnboardingSetupGranteesStepSkipped: boolean;
  readonly easopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly granteeCleanupSuggestionsCount: number;
  readonly grantees: {
    readonly totalCount: number;
  };
  readonly id: string;
  readonly isConnectedToHRISProvider: boolean;
  readonly name: string;
  readonly planIsFreemium: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantButton_Organization">;
  readonly " $fragmentType": "OnboardingSlider_Organization";
};
export type OnboardingSlider_Organization$key = {
  readonly " $data"?: OnboardingSlider_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingSlider_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingSlider_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteeCleanupSuggestionsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToHRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminOnboardingConnectHRISStepSkipped",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminOnboardingSetupGranteesStepSkipped",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminOnboardingSetupGranteesStepCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planIsFreemium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantButton_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "81257baf02c48cb40e493bffd3259133";

export default node;
