import React from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveContainer, XAxisProps } from "recharts";

import { PricePerShareHistoryChart_Organization$key } from "./__generated__/PricePerShareHistoryChart_Organization.graphql";
import { ValuationHistoryChart } from "./ValuationHistoryChart";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PricePerShareHistoryChart_Organization on Organization {
    ...ValuationHistoryChart_Organization
  }
`;

export const PricePerShareHistoryChart: React.FC<
  {
    hideXAxis?: boolean;
    organizationFragment: PricePerShareHistoryChart_Organization$key;
    xAxisPadding?: XAxisProps["padding"];
    xDomain?: [number, number];
  } & Omit<React.ComponentProps<typeof ResponsiveContainer>, "children">
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <ValuationHistoryChart
      organizationFragment={organization}
      pricePerShare
      {...props}
    />
  );
};
