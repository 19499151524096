/**
 * @generated SignedSource<<4ac28b3fab90760b51111071f6242f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Grantee_Organization$data = {
  readonly activeGrantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"Grantee_Grantee">;
      };
    }>;
  };
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly id: string;
  readonly instruments: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Organization" | "GranteeNotGrantableAlert_Organization" | "GranteeSelect_Organization">;
  readonly " $fragmentType": "Grantee_Organization";
};
export type Grantee_Organization$key = {
  readonly " $data"?: Grantee_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grantee_Organization">;
};

import Grantee_Organization_RefetchQuery_graphql from './Grantee_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": Grantee_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "Grantee_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instruments",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "activeGrantees",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "status": "Active"
          }
        }
      ],
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Grantee_Grantee"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "grantees(filters:{\"status\":\"Active\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNotGrantableAlert_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeSelect_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "ebcc0abade01dfc225bc480d2c26fb00";

export default node;
