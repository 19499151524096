import { zodResolver } from "@hookform/resolvers/zod";
import { startTransition, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { z } from "zod";

import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { zodExtra } from "../../../../helpers/zod-extra";
import {
  AccelerationClause,
  AccelerationClauseOptions,
} from "../../../../services/AccelerationClause";
import { CreateSlideOverRemote } from "../../Grants/Configure/PostTermination/CreateSlideOver";
import { NewAccelerationAllowedSlider_CTMSGrant$key } from "./__generated__/NewAccelerationAllowedSlider_CTMSGrant.graphql";
import { NewAccelerationAllowedSlider_Unfavorable_Query } from "./__generated__/NewAccelerationAllowedSlider_Unfavorable_Query.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment NewAccelerationAllowedSlider_CTMSGrant on CTMSGrant {
    id
    accelerationClause
  }
`;

const UNFAVORABLE_QUERY = graphql`
  query NewAccelerationAllowedSlider_Unfavorable_Query(
    $ctmsGrantId: CtmsGrantId!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    areCTMSGrantAmendmentRequestParametersUnfavorable(
      ctmsGrantId: $ctmsGrantId
      attributes: $attributes
    )
  }
`;

enum Option {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export const NewAccelerationAllowedOption = Option;

const formSchema = z.discriminatedUnion("acceleration", [
  z.object({
    acceleration: z.literal(Option.NOT_ALLOWED),
    accelerationClause: zodExtra.accelerationClause().optional(),
  }),
  z.object({
    acceleration: z.literal(Option.ALLOWED),
    accelerationClause: zodExtra.accelerationClause(),
  }),
]);

type FieldValues = z.infer<typeof formSchema>;
export type NewAccelerationAllowedSliderFormFieldValues = FieldValues;

const DEFAULT_VALUES: FieldValues = {
  acceleration: Option.NOT_ALLOWED,
  accelerationClause: undefined,
};

function NewAccelerationAllowedSlider({
  ctmsGrantFragment,
  newAccelerationAllowedValue,
  onClose,
  onExited,
  onNewAccelerationAllowedValueChanges,
  show,
}: {
  ctmsGrantFragment: NewAccelerationAllowedSlider_CTMSGrant$key;
  newAccelerationAllowedValue: FieldValues | null;
  onClose: () => void;
  onExited?: () => void;
  onNewAccelerationAllowedValueChanges: (value: FieldValues) => void;
  show: boolean;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const defaultValues = useMemo(() => {
    if (newAccelerationAllowedValue) return newAccelerationAllowedValue;

    if (ctmsGrant.accelerationClause) {
      return {
        acceleration: Option.ALLOWED,
        accelerationClause: ctmsGrant.accelerationClause,
      };
    }

    return DEFAULT_VALUES;
  }, [newAccelerationAllowedValue, ctmsGrant.accelerationClause]);

  const { control, formState, handleSubmit, watch } = useForm<FieldValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onNewAccelerationAllowedValueChanges(formSchema.parse(data));
    onClose();
  });

  const [acceleration, setAcceleration] = useState<null | Option>(
    defaultValues.acceleration,
  );

  const [accelerationClause, setAccelerationClause] =
    useState<AccelerationClause | null>(
      defaultValues.accelerationClause ?? null,
    );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "acceleration": {
          startTransition(() => {
            setAcceleration(value.acceleration ?? null);
          });
          break;
        }
        case "accelerationClause": {
          startTransition(() => {
            setAccelerationClause(value.accelerationClause ?? null);
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setAcceleration, defaultValues]);

  const {
    areCTMSGrantAmendmentRequestParametersUnfavorable:
      isNewAccelerationClauseUnfavorable,
  } = useLazyLoadQuery<NewAccelerationAllowedSlider_Unfavorable_Query>(
    UNFAVORABLE_QUERY,
    {
      attributes: {
        accelerationClause:
          acceleration === Option.ALLOWED ? accelerationClause : null,
        accelerationClauseIsModified: true,
        earlyExerciseIsModified: false,
        postTerminationExercisePeriodIsModified: false,
      },
      ctmsGrantId: ctmsGrant.id,
    },
  );

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Acceleration Terms
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <CreateSlideOverRemote.Provider>
        <form className="space-y-10" onSubmit={onSubmit}>
          <div className="space-y-6 p-6">
            <NoticeMessage hasColor={false} size="Large">
              Acceleration is an important decision that indirectly impacts your
              investors. If you opt for acceleration, discuss it with your
              investors and lawyers first.
            </NoticeMessage>

            <FormRow label="Allow acceleration">
              <Controller
                control={control}
                name="acceleration"
                render={({ field }) => (
                  <Toggle
                    enabled={field.value === Option.ALLOWED}
                    onChange={(allowed) =>
                      field.onChange(
                        allowed ? Option.ALLOWED : Option.NOT_ALLOWED,
                      )
                    }
                  />
                )}
              />
            </FormRow>
            {acceleration === Option.ALLOWED && (
              <FormRow.Form
                control={control}
                label="Acceleration clause"
                name="accelerationClause"
              >
                <SelectAutocomplete.Form
                  control={control}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  name="accelerationClause"
                  options={AccelerationClauseOptions}
                  usePortal
                />
              </FormRow.Form>
            )}
            {isNewAccelerationClauseUnfavorable && (
              <NoticeMessage hasColor={false} size="Large" variant="Warning">
                Deleting the acceleration clause is usually considered as not
                grantee friendly. The grantee&apos;s acceptation will be
                required.
              </NoticeMessage>
            )}
          </div>
          <Divider />
          <div className="space-y-4 px-6 py-10">
            <div className="flex items-center justify-end gap-2">
              <Button
                disabled={formState.isSubmitting}
                onClick={onClose}
                size="small"
                type="button"
                variant="Secondary Full"
              >
                Cancel
              </Button>
              <Button
                loading={formState.isSubmitting}
                size="small"
                type="submit"
              >
                Save
              </Button>
            </div>
            <Typography
              as="div"
              className="text-right text-black-05"
              variant="Regular/Caption"
            >
              Amending a grant will always require the approval from the board.
            </Typography>
          </div>
        </form>
      </CreateSlideOverRemote.Provider>
    </SlideOver>
  );
}

export const NewAccelerationAllowedSliderRemote = makeRemoteController<{
  ctmsGrantFragment: NewAccelerationAllowedSlider_CTMSGrant$key;
  newAccelerationAllowedValue: FieldValues | null;
  onNewAccelerationAllowedValueChanges: (value: FieldValues) => void;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <NewAccelerationAllowedSlider
        ctmsGrantFragment={state.data.ctmsGrantFragment}
        newAccelerationAllowedValue={state.data.newAccelerationAllowedValue}
        onClose={close}
        onExited={reset}
        onNewAccelerationAllowedValueChanges={
          state.data.onNewAccelerationAllowedValueChanges
        }
        show={state.show}
      />
    );
  },
});
