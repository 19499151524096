// TODO: Remove once CTMS and Easop grants interface is merged
import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CountryFlag } from "../../../../../components/CountryFlag";
import { MissingInformationTag } from "../../../../../components/MissingInformationTag";
import { NeedsAdditionalFormalitiesWarning } from "../../../../../components/NeedsAdditionalFormalitiesWarning";
import { Percentage } from "../../../../../components/Percentage";
import { ShortDate } from "../../../../../components/ShortDate";
import { InformationRows } from "../../../../../components/ui/InformationRows";
import { RoundedBox } from "../../../../../components/ui/RoundedBox";
import { Typography } from "../../../../../components/ui/Typography";
import { VestingColumn } from "../../../../../components/VestingColumn";
import { useOrganizationSharesUtil } from "../../../../../hooks/useOrganizationSharesUtil";
import { APPLICATION_ROUTES } from "../../../../../paths";
import { getEquityTypeWorkRelationship } from "../../../../../services/workRelationship";
import { DraftGrantDetailsGrant_EasopGrant$key } from "./__generated__/DraftGrantDetailsGrant_EasopGrant.graphql";
import { DraftGrantDetailsGrant_Organization$key } from "./__generated__/DraftGrantDetailsGrant_Organization.graphql";
import { DraftGrantDetailsGrant_Viewer$key } from "./__generated__/DraftGrantDetailsGrant_Viewer.graphql";

const EASOP_GRANT_FRAGMENT = graphql`
  fragment DraftGrantDetailsGrant_EasopGrant on EasopGrant {
    quantityGranted
    isVirtual
    grantDate
    grantStatus
    instrument {
      workRelationship
      needsAdditionalFormalities
      equityType {
        id
        name
        taxResidenceCountry {
          ...CountryFlag_Country
        }
      }
      ...NeedsAdditionalFormalitiesWarning_Instrument
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment DraftGrantDetailsGrant_Organization on Organization {
    id
    ...useOrganizationSharesUtil_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment DraftGrantDetailsGrant_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

export const DraftGrantDetailsGrant: React.FC<{
  easopGrantFragment: DraftGrantDetailsGrant_EasopGrant$key;
  organizationFragment: DraftGrantDetailsGrant_Organization$key;
  viewerFragment: DraftGrantDetailsGrant_Viewer$key;
}> = ({ easopGrantFragment, organizationFragment, viewerFragment }) => {
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const { instrument } = easopGrant;

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const ownershipGranted = sharesToFullyDilutedRatio(
    easopGrant.quantityGranted,
  );
  const valueGranted = sharesToValue(easopGrant.quantityGranted);

  return (
    <RoundedBox
      className="flex flex-col gap-6 divide-y-[0.5px] divide-gray-04 [&>*:not(:first-child)]:pt-6"
      withBorder
      withShadow
    >
      <div className="flex flex-col gap-6 p-6">
        <Typography variant="Medium/Small">Grant</Typography>
        <div className="flex justify-between">
          {ownershipGranted !== null && (
            <VestingColumn
              fullyVestedValue={<Percentage value={ownershipGranted} />}
              label={
                easopGrant.isVirtual
                  ? "Total virtual % fully dilluted"
                  : "Total % fully diluted"
              }
            />
          )}
          <VestingColumn
            fullyVestedValue={
              <FormattedNumber value={easopGrant.quantityGranted} />
            }
            label={easopGrant.isVirtual ? "Total # SARs" : "Total # options"}
          />
          {valueGranted !== null && (
            <VestingColumn
              fullyVestedValue={
                <FormattedNumber
                  currency="USD"
                  style="currency"
                  value={valueGranted}
                />
              }
              label="Total $ value"
            />
          )}
        </div>
      </div>
      <div className="p-6">
        <InformationRows>
          <InformationRows.Row label="Equity type">
            {instrument.equityType ? (
              <div className="flex flex-col items-end gap-1">
                <Typography variant="Regular/Extra Small">
                  {instrument.equityType.name}{" "}
                  <CountryFlag
                    countryFragment={instrument.equityType.taxResidenceCountry}
                  />
                </Typography>
                {viewer.isAllowedToManageOrganization && (
                  <Link
                    to={generatePath(
                      APPLICATION_ROUTES[
                        "organizationEquityTypeWorkRelationship"
                      ],
                      {
                        equityTypeId: instrument.equityType.id,
                        equityTypeWorkRelationshipCategory:
                          getEquityTypeWorkRelationship(
                            instrument.workRelationship,
                          ).slug,
                        organizationId: organization.id,
                      },
                    )}
                  >
                    <Typography
                      className="text-primary"
                      variant="Medium/Extra Small"
                    >
                      Learn more on this equity type
                    </Typography>
                  </Link>
                )}
              </div>
            ) : (
              <MissingInformationTag />
            )}
          </InformationRows.Row>
          {easopGrant.grantStatus === "PendingCtmsImplementation" &&
          easopGrant.grantDate ? (
            <InformationRows.Row label="Board approval date">
              <ShortDate value={easopGrant.grantDate} />
            </InformationRows.Row>
          ) : null}
        </InformationRows>
        {instrument.needsAdditionalFormalities && (
          <NeedsAdditionalFormalitiesWarning instrumentFragment={instrument} />
        )}
      </div>
    </RoundedBox>
  );
};
