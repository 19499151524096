// Tailwind UI source: https://tailwindui.com/components/application-ui/forms/select-menus
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { Fragment } from "react";

import { Typography, TypographyVariant } from "../../../Typography";
import { SelectRow } from "./SelectRow";

export const SELECT_BASE_CLASS = classNames(
  "absolute z-10 mt-1 max-h-60 min-w-full overflow-auto rounded bg-white py-1 font-sans text-base ring-1 ring-black ring-opacity-5 placeholder:font-sans focus:outline-none",
);

export const getOptionClassName = (active: boolean) => {
  return classNames(
    {
      "bg-primary text-white": active,
    },
    "relative cursor-pointer select-none py-2 pl-3 pr-9",
  );
};

export interface SelectProps<T> {
  getOptionHelp?: (item: T) => React.ReactNode;
  getOptionLabel: (item: T) => React.ReactNode;
  getOptionValue: (item: T) => null | string | undefined;
  name?: string;
  onChange: (value: null | string | undefined) => void;
  options: readonly T[];
  value: null | string | undefined;

  alignOptions?: "left" | "right";
  className?: string;
  classNameBase?: string;
  disabled?: boolean;
  placeholder?: React.ReactNode;
  variant?: TypographyVariant;
}

export function Select<T>({
  alignOptions = "left",
  className,
  classNameBase = "h-[38px] rounded-md py-2 pl-3 pr-10 border-gray-07 bg-white border-[0.5px] text-left focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary disabled:border-gray-02 disabled:bg-gray-01 disabled:text-gray text-sm",
  disabled,
  getOptionHelp,
  getOptionLabel,
  getOptionValue,
  name,
  onChange,
  options,
  placeholder,
  value,
  variant = "Regular/Extra Small",
}: SelectProps<T>) {
  const selected = options.find((option) => getOptionValue(option) === value);

  return (
    <Listbox disabled={disabled} name={name} onChange={onChange} value={value}>
      {({ open }) => (
        <div className={classNames("relative")}>
          <Listbox.Button
            className={classNames(
              classNameBase,
              "relative block w-full cursor-default",
              className,
            )}
          >
            <span className="block truncate">
              {selected ? (
                getOptionLabel(selected)
              ) : (
                <Typography className="text-gray-08" variant={variant}>
                  {placeholder}
                </Typography>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-04"
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={open}
          >
            <Listbox.Options
              className={classNames(SELECT_BASE_CLASS, {
                "left-0": alignOptions === "left",
                "right-0": alignOptions === "right",
              })}
            >
              {options.map((option) => (
                <Listbox.Option
                  className={({ active }) => getOptionClassName(active)}
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                >
                  {({ active, selected }) => (
                    <SelectRow
                      active={active}
                      help={getOptionHelp && getOptionHelp(option)}
                      label={getOptionLabel(option)}
                      selected={selected}
                    />
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
