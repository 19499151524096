import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "./ui/Typography";

const ICON_COLOR_CLASSNAMES = {
  blue: {
    container: classNames("bg-primary-01"),
    icon: classNames("text-primary-05"),
  },
  green: {
    container: classNames("bg-green-01"),
    icon: classNames("text-green-05"),
  },
  purple: {
    container: classNames("bg-purple-01"),
    icon: classNames("text-purple-05"),
  },
};

export const Icon: React.FC<{
  color: keyof typeof ICON_COLOR_CLASSNAMES;
  icon: React.ComponentType<{ className?: string }>;
}> = ({ color, icon: Icon }) => {
  return (
    <div
      className={classNames(
        ICON_COLOR_CLASSNAMES[color].container,
        "flex items-center justify-center rounded-full p-2",
      )}
    >
      <Icon className={classNames(ICON_COLOR_CLASSNAMES[color].icon, "w-5")} />
    </div>
  );
};

const _ButtonBanner = React.forwardRef<
  HTMLAnchorElement,
  {
    icon?: React.ReactNode;
    subtitle?: React.ReactNode;
    tag?: React.ReactNode;
  } & React.ComponentProps<typeof Link>
>(function ButtonBanner(
  { children, className, icon, subtitle, tag, ...props },
  ref,
) {
  return (
    <Link
      className={classNames(
        className,
        "flex items-center gap-6 rounded-lg border-[0.5px] border-gray-04 p-6 shadow-100 transition-all hover:shadow-200",
      )}
      ref={ref}
      {...props}
    >
      {icon && <div>{icon}</div>}
      <div className="flex-1 space-y-2">
        <div className="flex items-center gap-2">
          <Typography as="div" variant="Medium/Small">
            {children}
          </Typography>
          {tag}
        </div>
        {subtitle && (
          <Typography
            as="div"
            className="text-gray-09"
            variant="Regular/Extra Small"
          >
            {subtitle}
          </Typography>
        )}
      </div>
      <div className="flex items-center">
        <ChevronRightIcon className="w-5 text-black-07" />
      </div>
    </Link>
  );
});

export const ButtonBanner = Object.assign(_ButtonBanner, {
  Icon,
});
