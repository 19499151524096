/**
 * @generated SignedSource<<b33b2d39675626e3aa50cb31516fb0c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DurationUnit = "day" | "month" | "year";
export type EditSlideOver_Query$variables = {
  organizationId: string;
  postTerminationExercisePeriodId: string;
};
export type EditSlideOver_Query$data = {
  readonly node: {
    readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
    readonly duration: number;
    readonly durationUnit: DurationUnit;
  } | {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly duration: number;
    readonly durationUnit: DurationUnit;
    readonly extensionDuration: number;
    readonly extensionDurationUnit: DurationUnit;
    readonly thresholdForExtensionInYears: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly organization: {
    readonly hasCooleyAsOutsideCounsel: boolean;
    readonly termOfOptionsInMonths: number;
    readonly " $fragmentSpreads": FragmentRefs<"PostTerminationForm_Organization">;
  };
};
export type EditSlideOver_Query = {
  response: EditSlideOver_Query$data;
  variables: EditSlideOver_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postTerminationExercisePeriodId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "postTerminationExercisePeriodId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationUnit",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensionDuration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensionDurationUnit",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thresholdForExtensionInYears",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCooleyAsOutsideCounsel",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "termOfOptionsInMonths",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "OrganizationFixedPostTerminationExercisePeriod",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "OrganizationVariablePostTerminationExercisePeriod",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PostTerminationForm_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "OrganizationFixedPostTerminationExercisePeriod",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "OrganizationVariablePostTerminationExercisePeriod",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33f90f3ac9b8cffefea622a03b064e34",
    "id": null,
    "metadata": {},
    "name": "EditSlideOver_Query",
    "operationKind": "query",
    "text": "query EditSlideOver_Query(\n  $organizationId: OrganizationId!\n  $postTerminationExercisePeriodId: ID!\n) {\n  node(id: $postTerminationExercisePeriodId) {\n    __typename\n    ... on OrganizationFixedPostTerminationExercisePeriod {\n      __typename\n      duration\n      durationUnit\n    }\n    ... on OrganizationVariablePostTerminationExercisePeriod {\n      __typename\n      duration\n      durationUnit\n      extensionDuration\n      extensionDurationUnit\n      thresholdForExtensionInYears\n    }\n    id\n  }\n  organization(id: $organizationId) {\n    hasCooleyAsOutsideCounsel\n    termOfOptionsInMonths\n    ...PostTerminationForm_Organization\n    id\n  }\n}\n\nfragment PostTerminationForm_Organization on Organization {\n  hasCooleyAsOutsideCounsel\n}\n"
  }
};
})();

(node as any).hash = "6a7b0853cc9dcb670d63efd9cf21b00f";

export default node;
