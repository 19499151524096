import {
  CalendarIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  GiftIcon,
  PencilIcon,
  PlayCircleIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";
import { isFuture } from "date-fns";
import React, { Suspense, useCallback } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useFragment, useRefetchableFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DeelGlyph } from "../../../../components/CompaniesGlyphs/DeelGlyph";
import { RemoteGlyph } from "../../../../components/CompaniesGlyphs/RemoteGlyph";
import {
  GranteeDeletionConfirmationModal,
  useGranteeDeletionConfirmationModalState,
} from "../../../../components/GranteeDeletionConfirmationModal";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../../components/GranteeFormSlide";
import { GranteeNameWithCountryFlag } from "../../../../components/GranteeNameWithCountryFlag";
import { GranteeStatusTag } from "../../../../components/GranteeStatusTag";
import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "../../../../components/InviteGranteesModal";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { Percentage } from "../../../../components/Percentage";
import { PortalAccessTag } from "../../../../components/PortalAccessTag";
import {
  RevokeGranteeAccessModal,
  useRevokeGranteeAccessModalState,
} from "../../../../components/RevokeGranteeAccessModal";
import { ShortDate } from "../../../../components/ShortDate";
import { StopClickPropagationWrapper } from "../../../../components/StopClickPropagationWrapper";
import { Avatar } from "../../../../components/ui/Avatar";
import { Button } from "../../../../components/ui/Button";
import { MenuButton } from "../../../../components/ui/MenuButton";
import { Progress } from "../../../../components/ui/Progress";
import { Table } from "../../../../components/ui/Table";
import { Tag } from "../../../../components/ui/Tag";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import { HRIS_PROVIDER_MAP } from "../../../../hooks/useHRISProvider";
import { useOrganizationCTMS } from "../../../../hooks/useOrganizationCTMS";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { useResendGranteeInvitationEmail } from "../../../../hooks/useResendGranteeInvitationEmail";
import { APPLICATION_ROUTES } from "../../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import {
  GranteesTableRow_Deferred_Grantee$data,
  GranteesTableRow_Deferred_Grantee$key,
} from "./__generated__/GranteesTableRow_Deferred_Grantee.graphql";
import {
  GranteesTableRow_Deferred_Organization$data,
  GranteesTableRow_Deferred_Organization$key,
} from "./__generated__/GranteesTableRow_Deferred_Organization.graphql";
import { GranteesTableRow_Grantee$key } from "./__generated__/GranteesTableRow_Grantee.graphql";
import { GranteesTableRow_Organization$key } from "./__generated__/GranteesTableRow_Organization.graphql";
import {
  GranteesTableRow_Viewer$data,
  GranteesTableRow_Viewer$key,
} from "./__generated__/GranteesTableRow_Viewer.graphql";
import { GranteesTableColumn } from "./OrganizationGranteesTable";
import { SharesIcon } from "./SharesIcon";

const VIEWER_FRAGMENT = graphql`
  fragment GranteesTableRow_Viewer on Account {
    ...InviteGranteesModal_Viewer
  }
`;

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment GranteesTableRow_Deferred_Organization on Organization {
    id
    planIsFreemium
    granteePortalInvitationIsAllowed

    ...useOrganizationCTMS_Organization
    ...GranteeFormSlide_Organization
    ...useOrganizationSharesUtil_Organization
    ...InviteGranteesModal_Organization
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

const DEFERRED_GRANTEE_FRAGMENT = graphql`
  fragment GranteesTableRow_Deferred_Grantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" })
  @refetchable(queryName: "GranteesTableRow_Deferred_GranteeRefetchQuery") {
    id
    name
    tenure {
      months
      years
    }
    email
    offGrid
    equityGridLevel {
      name
    }
    ctmsGrantsCount
    draftGrantCount
    plannedGrantCount
    totalGrantedSharesBreakdown {
      total
    }
    totalVestedSharesBreakdown {
      total
    }
    totalExercisedSharesBreakdown {
      total
    }
    hasPortalAccess
    cannotBeInvitedReason
    hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    isTerminable
    isDeletable
    jobTitle
    workRelationship
    status
    terminationInformations {
      terminationDate
    }
    hRISProviderEmployee {
      hRISProvider
    }
    ...GranteeStatusTag_Grantee
    ...GranteeFormSlide_Grantee
    ...PortalAccessTag_Grantee
    ...InviteGranteesModal_Grantees
    ...NewEquitySelectionModal_DefaultGrantee
      @arguments(organizationId: $organizationId)
    ...RevokeGranteeAccessModal_Grantee
    ...GranteeNameWithCountryFlag_Grantee
  }
`;

const NoGrantTag: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <TooltipContainer
      tooltipContent={
        <div className="space-y-2">
          <Typography as="div" variant="Regular/Extra Small">
            No grant
          </Typography>
          <Typography
            as="div"
            className="text-gray-07"
            variant="Regular/Extra Small"
          >
            This means that the grantee didn’t receive any equity through{" "}
            {applicationName}
            or any other Cap Table Management Solutions. This also means that it
            is not possible to invite the grantee to view their portal. Make an
            offer that will translate into a grant or directly draft a new grant
            to allow more options on the grantee.
          </Typography>
        </div>
      }
    >
      <Tag>No grant</Tag>
    </TooltipContainer>
  );
};

const ExercisedCellContent: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
}> = ({ grantee }) => {
  if (grantee.totalGrantedSharesBreakdown.total === 0) {
    return <NoGrantTag />;
  }

  if (grantee.totalVestedSharesBreakdown.total === 0) {
    return (
      <Typography as="div" className="text-black-05" variant="Regular/Caption">
        No shares vested
      </Typography>
    );
  }

  const exercisedOverVestedRatio =
    grantee.totalExercisedSharesBreakdown.total /
    grantee.totalVestedSharesBreakdown.total;

  return (
    <div className="space-y-1 text-right">
      <Typography as="div" variant="Regular/Extra Small">
        <Percentage
          maximumFractionDigits={0}
          value={exercisedOverVestedRatio}
        />
      </Typography>
      <Typography as="div" variant="Regular/Extra Small">
        <FormattedMessage
          defaultMessage="<strong>{exercisedShares, number}</strong> / {vestedShares, plural, one {# share} other {# shares}}"
          values={{
            exercisedShares: grantee.totalExercisedSharesBreakdown.total,
            strong: (chunks) => (
              <Typography variant="Medium/Extra Small">{chunks}</Typography>
            ),
            vestedShares: grantee.totalVestedSharesBreakdown.total,
          }}
        />
      </Typography>
      <Progress className="w-full bg-gray-03" max={1}>
        <Progress.Value
          className="bg-primary"
          value={exercisedOverVestedRatio}
        />
      </Progress>
    </div>
  );
};

const TotalOwnershipCellContent: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
  organization: GranteesTableRow_Deferred_Organization$data;
}> = ({ grantee, organization }) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const totalSharesGranted = grantee.totalGrantedSharesBreakdown.total;

  if (!totalSharesGranted) {
    return null;
  }

  const totalOwnershipGranted = sharesToFullyDilutedRatio(totalSharesGranted);
  const totalValueGranted = sharesToValue(totalSharesGranted);

  return (
    <div className="space-y-1 text-right">
      {totalValueGranted !== null && (
        <Typography as="div" variant="Medium/Extra Small">
          <FormattedNumber
            currency="usd"
            maximumFractionDigits={0}
            style="currency"
            value={totalValueGranted}
          />
        </Typography>
      )}
      <Typography as="div" className="text-black-05" variant="Regular/Caption">
        <FormattedMessage
          defaultMessage="{shares, plural, one {# share} other {# shares}}"
          values={{ shares: totalSharesGranted }}
        />
      </Typography>
      {totalOwnershipGranted !== null && (
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Caption"
        >
          <Percentage value={totalOwnershipGranted} />
        </Typography>
      )}
    </div>
  );
};

const VestedCellContent: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
}> = ({ grantee }) => {
  if (grantee.totalGrantedSharesBreakdown.total === 0) {
    return <NoGrantTag />;
  }

  const vestedOverGrantedRatio =
    grantee.totalVestedSharesBreakdown.total /
    grantee.totalGrantedSharesBreakdown.total;

  return (
    <TooltipContainer
      tooltipContent={
        <div className="space-y-2">
          <Typography as="div" variant="Medium/Extra Small">
            Vesting
          </Typography>
          <div>
            <Typography
              as="div"
              className="text-gray-07"
              variant="Regular/Caption"
            >
              Currently vested
            </Typography>
            <Typography as="div" variant="Medium/Caption">
              <Percentage
                maximumFractionDigits={0}
                value={vestedOverGrantedRatio}
              />
            </Typography>
            <Typography as="div" variant="Regular/Caption">
              <FormattedMessage
                defaultMessage="<strong>{vestedShares, number}</strong> / {grantedShares, number}"
                values={{
                  grantedShares: grantee.totalGrantedSharesBreakdown.total,
                  strong: (chunks) => (
                    <Typography variant="Medium/Caption">{chunks}</Typography>
                  ),
                  vestedShares: grantee.totalVestedSharesBreakdown.total,
                }}
              />
            </Typography>
          </div>
        </div>
      }
    >
      <div className="flex items-center gap-4">
        <Progress className="min-w-[128px] bg-gray-03" max={1}>
          <Progress.Value
            className="bg-primary"
            value={vestedOverGrantedRatio}
          />
        </Progress>
        <Typography variant="Regular/Extra Small">
          <Percentage
            maximumFractionDigits={0}
            value={vestedOverGrantedRatio}
          />
        </Typography>
      </div>
    </TooltipContainer>
  );
};

const TenureCellContent: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
  organization: GranteesTableRow_Deferred_Organization$data;
}> = ({ grantee, organization }) => {
  const intl = useIntl();
  if (!grantee.tenure) {
    return <MissingCTMSInformationTag organizationFragment={organization} />;
  }

  return (
    <span className="whitespace-nowrap">
      <FormattedMessage
        defaultMessage="{years} {months}"
        description="Tenure"
        values={{
          months: intl.formatNumber(grantee.tenure.months, {
            style: "unit",
            unit: "month",
            unitDisplay: "narrow",
          }),
          years: intl.formatNumber(grantee.tenure.years, {
            style: "unit",
            unit: "year",
            unitDisplay: "narrow",
          }),
        }}
      />
    </span>
  );
};

const ActionCellContent: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
  granteesRelayConnectionIds?: string[];
  onEditGranteeButtonClick: () => void;
  onGranteeUpdated?: () => void;
  organization: GranteesTableRow_Deferred_Organization$data;
  viewer: GranteesTableRow_Viewer$data;
}> = ({
  grantee,
  granteesRelayConnectionIds,
  onEditGranteeButtonClick,
  onGranteeUpdated,
  organization,
  viewer,
}) => {
  const {
    closeGranteeDeletionConfirmationModal,
    granteeDeletionConfirmationModalState,
    showGranteeDeletionConfirmationModal,
  } = useGranteeDeletionConfirmationModalState();

  const {
    hideInviteGranteeModal,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const {
    hideRevokeGranteeAccessModal,
    revokeGranteeAccessModalState,
    showRevokeGranteeAccessModal,
  } = useRevokeGranteeAccessModalState();

  const handleInviteGranteeButtonClick = useCallback(() => {
    showInviteGranteeModal(grantee);
  }, [grantee, showInviteGranteeModal]);

  const onDraftNewGrantButtonClick = useCallback(() => {
    hideInviteGranteeModal();
    showNewEquitySelectionModal({ granteeFragment: grantee });
  }, [grantee, showNewEquitySelectionModal, hideInviteGranteeModal]);

  const { resendInvitationEmail } = useResendGranteeInvitationEmail();

  return (
    <StopClickPropagationWrapper>
      <InviteGranteesModal
        granteesRelayConnectionIds={granteesRelayConnectionIds}
        onClose={hideInviteGranteeModal}
        onDraftNewGrantButtonClick={onDraftNewGrantButtonClick}
        onGranteesInvited={onGranteeUpdated}
        onGranteeUpdated={onGranteeUpdated}
        organizationFragment={organization}
        state={inviteGranteeModalState}
        viewerFragment={viewer}
      />
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        onGrantCreated={onGranteeUpdated}
        organizationId={organization.id}
        state={newEquitySelectionModalState}
      />

      <GranteeDeletionConfirmationModal
        granteesRelayConnectionIds={granteesRelayConnectionIds}
        onClose={closeGranteeDeletionConfirmationModal}
        onGranteeDeleted={() => {
          closeGranteeDeletionConfirmationModal();
        }}
        state={granteeDeletionConfirmationModalState}
      />

      <RevokeGranteeAccessModal
        onClose={hideRevokeGranteeAccessModal}
        state={revokeGranteeAccessModalState}
      />
      <MenuButton
        button={
          <Button
            leftIcon={<EllipsisHorizontalIcon />}
            size="small"
            variant="Secondary Full"
          />
        }
        placement="bottom end"
      >
        {organization.granteePortalInvitationIsAllowed &&
          !grantee.cannotBeInvitedReason && (
            <MenuButton.Item
              leftIcon={<EnvelopeIcon />}
              onClick={handleInviteGranteeButtonClick}
            >
              Invite grantee to portal
            </MenuButton.Item>
          )}
        {grantee.hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal && (
          <MenuButton.Item
            leftIcon={<EnvelopeIcon />}
            onClick={() => resendInvitationEmail(grantee)}
          >
            Resend invitation
          </MenuButton.Item>
        )}
        <MenuButton.Item
          leftIcon={<PencilIcon />}
          onClick={onEditGranteeButtonClick}
        >
          Edit grantee
        </MenuButton.Item>
        {!organization.planIsFreemium && (
          <MenuButton.Item
            leftIcon={<GiftIcon />}
            onClick={onDraftNewGrantButtonClick}
          >
            Draft a new grant
          </MenuButton.Item>
        )}
        {organization.granteePortalInvitationIsAllowed && (
          <MenuButton.Item
            leftIcon={<PlayCircleIcon />}
            onClick={() => {
              const url = generatePath(APPLICATION_ROUTES.grantee, {
                granteeId: grantee.id,
              });
              window.open(url, "_blank");
            }}
          >
            Preview portal
          </MenuButton.Item>
        )}
        {grantee.hasPortalAccess && (
          <MenuButton.Item
            leftIcon={<StopCircleIcon />}
            onClick={() => {
              showRevokeGranteeAccessModal(grantee);
            }}
          >
            Revoke access to portal
          </MenuButton.Item>
        )}
        {!organization.planIsFreemium && grantee.isTerminable && (
          <MenuButton.LinkItem
            leftIcon={<CalendarIcon />}
            to={generatePath(APPLICATION_ROUTES.organizationTerminateGrantee, {
              granteeId: grantee.id,
              organizationId: organization.id,
            })}
          >
            Terminate grantee
          </MenuButton.LinkItem>
        )}
        {grantee.isDeletable && (
          <MenuButton.Item
            leftIcon={<CalendarIcon />}
            onClick={() => {
              showGranteeDeletionConfirmationModal(grantee.id);
            }}
          >
            Delete grantee
          </MenuButton.Item>
        )}
      </MenuButton>
    </StopClickPropagationWrapper>
  );
};

const PositionCellContent: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
}> = ({ grantee }) => {
  return (
    <div className="space-y-1">
      {grantee.jobTitle && (
        <Typography as="div" variant="Regular/Extra Small">
          {grantee.jobTitle}
        </Typography>
      )}
      {grantee.workRelationship && (
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Caption"
        >
          {
            WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
              .singularLabel
          }
        </Typography>
      )}
    </div>
  );
};

const HRISProviderGlyph: React.FC<{
  hRISProvider: "DEEL" | "REMOTE";
}> = ({ hRISProvider }) => {
  switch (hRISProvider) {
    case "DEEL":
      return <DeelGlyph className="h-8 w-8" />;
    case "REMOTE":
      return <RemoteGlyph className="h-8 w-8" />;
  }
};

const AvatarCell: React.FC<{
  grantee: GranteesTableRow_Deferred_Grantee$data;
}> = ({ grantee }) => {
  const hRISProvider = grantee.hRISProviderEmployee?.hRISProvider;
  if (!hRISProvider) return <Avatar>{grantee.name}</Avatar>;

  return (
    <TooltipContainer
      tooltipContent={
        <>Employee is synced with {HRIS_PROVIDER_MAP[hRISProvider]}</>
      }
    >
      <div>
        <HRISProviderGlyph hRISProvider={hRISProvider} />
      </div>
    </TooltipContainer>
  );
};

const Column: React.FC<{
  column: GranteesTableColumn;
  grantee: GranteesTableRow_Deferred_Grantee$data;
  granteesRelayConnectionIds?: string[];
  openGranteeFormSlideInEditMode: (
    grantee: GranteesTableRow_Deferred_Grantee$data,
  ) => void;
  organization: GranteesTableRow_Deferred_Organization$data;
  refetchGrantee: () => void;
  viewer: GranteesTableRow_Viewer$data;
}> = ({
  column,
  grantee,
  granteesRelayConnectionIds,
  openGranteeFormSlideInEditMode,
  organization,
  refetchGrantee,
  viewer,
}) => {
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  switch (column) {
    case "Action":
      return (
        <Table.Cell>
          <ActionCellContent
            grantee={grantee}
            granteesRelayConnectionIds={granteesRelayConnectionIds}
            onEditGranteeButtonClick={() => {
              openGranteeFormSlideInEditMode(grantee);
            }}
            onGranteeUpdated={() => {
              refetchGrantee();
            }}
            organization={organization}
            viewer={viewer}
          />
        </Table.Cell>
      );
    case "Avatar":
      return (
        <Table.Cell className="justify-end">
          <AvatarCell grantee={grantee} />
        </Table.Cell>
      );
    case "Equity Grid Level": {
      const equityGridLevel = grantee.offGrid
        ? "Off Grid"
        : (grantee.equityGridLevel?.name ?? null);
      return (
        <Table.Cell>
          {equityGridLevel ? (
            <Tag className="whitespace-nowrap" color="glass-green">
              {equityGridLevel}
            </Tag>
          ) : (
            <Tag className="whitespace-nowrap" color="gray">
              No level
            </Tag>
          )}
        </Table.Cell>
      );
    }
    case "Exercised":
      return (
        <Table.Cell className="justify-end">
          <ExercisedCellContent grantee={grantee} />
        </Table.Cell>
      );
    case "Grants":
      return (
        <Table.Cell>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
              <SharesIcon className="w-4 text-primary" />
              <Typography as="div" variant="Regular/Extra Small">
                {grantee.ctmsGrantsCount}
              </Typography>
            </div>
            <div className="flex items-center gap-1">
              {grantee.draftGrantCount > 0 && (
                <Tag color="purple" size="Small">
                  +{grantee.draftGrantCount} drafted
                </Tag>
              )}
              {grantee.plannedGrantCount > 0 && (
                <Tag color="dashed" size="Small">
                  +{grantee.plannedGrantCount} planned
                </Tag>
              )}
            </div>
          </div>
        </Table.Cell>
      );
    case "Name":
      return (
        <Table.Cell truncate>
          <div className="flex min-h-[78px] flex-col items-start justify-center space-y-1">
            <Typography as="div" variant="Regular/Extra Small">
              <GranteeNameWithCountryFlag granteeFragment={grantee} />
            </Typography>
            <Typography
              as="div"
              className="w-full truncate text-black-05"
              variant="Regular/Caption"
            >
              {grantee.email}
            </Typography>
            {grantee.status === "Active" && grantee.terminationInformations ? (
              isFuture(
                new Date(grantee.terminationInformations.terminationDate),
              ) ? (
                <Tag color="orange">
                  Will be terminated on{" "}
                  <ShortDate
                    value={grantee.terminationInformations.terminationDate}
                  />
                </Tag>
              ) : (
                <Tag color="orange">
                  {organizationCTMS ? (
                    <>
                      Termination pending implementation in{" "}
                      {organizationCTMS.name}
                    </>
                  ) : (
                    <>Termination pending implementation</>
                  )}
                </Tag>
              )
            ) : null}
          </div>
        </Table.Cell>
      );
    case "Ownership":
      return (
        <Table.Cell className="justify-end">
          <TotalOwnershipCellContent
            grantee={grantee}
            organization={organization}
          />
        </Table.Cell>
      );
    case "Portal Access":
      return (
        <Table.Cell className="justify-end">
          <PortalAccessTag.WithToolTip granteeFragment={grantee} />
        </Table.Cell>
      );
    case "Position":
      return (
        <Table.Cell>
          <PositionCellContent grantee={grantee} />
        </Table.Cell>
      );
    case "Status":
      return (
        <Table.Cell>
          <GranteeStatusTag granteeFragment={grantee} />
        </Table.Cell>
      );
    case "Tenure":
      return (
        <Table.Cell>
          <TenureCellContent grantee={grantee} organization={organization} />
        </Table.Cell>
      );
    case "Vested":
      return (
        <Table.Cell>
          <VestedCellContent grantee={grantee} />
        </Table.Cell>
      );
  }
};

export const Deferred: React.FC<{
  columns: GranteesTableColumn[];
  granteeFragment: GranteesTableRow_Deferred_Grantee$key;
  granteesRelayConnectionIds?: string[];
  organizationFragment: GranteesTableRow_Deferred_Organization$key;
  viewerFragment: GranteesTableRow_Viewer$key;
}> = ({
  columns,
  granteeFragment,
  granteesRelayConnectionIds,
  organizationFragment,
  viewerFragment,
}) => {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const [grantee, refetchGrantee] = useRefetchableFragment(
    DEFERRED_GRANTEE_FRAGMENT,
    granteeFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  return (
    <>
      <GranteeFormSlide
        granteesRelayConnectionIds={granteesRelayConnectionIds}
        onCancel={closeGranteeFormSlide}
        onGranteeDeleted={closeGranteeFormSlide}
        onGranteeUpdated={() => {
          closeGranteeFormSlide();
          refetchGrantee({});
        }}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />

      <Table.LinkRow
        to={generatePath(APPLICATION_ROUTES.organizationGrantee, {
          granteeId: grantee.id,
          organizationId: organization.id,
        })}
      >
        {columns.map((column) => (
          <Column
            column={column}
            grantee={grantee}
            granteesRelayConnectionIds={granteesRelayConnectionIds}
            key={column}
            openGranteeFormSlideInEditMode={openGranteeFormSlideInEditMode}
            organization={organization}
            refetchGrantee={() => refetchGrantee({})}
            viewer={viewer}
          />
        ))}
      </Table.LinkRow>
    </>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteesTableRow_Organization on Organization {
    ...GranteesTableRow_Deferred_Organization @defer
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteesTableRow_Grantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...GranteesTableRow_Deferred_Grantee
      @defer
      @arguments(organizationId: $organizationId)
  }
`;

export const GranteesTableRow: React.FC<
  {
    granteeFragment: GranteesTableRow_Grantee$key;
    organizationFragment: GranteesTableRow_Organization$key;
  } & Omit<
    React.ComponentProps<typeof Deferred>,
    "granteeFragment" | "organizationFragment"
  >
> = ({ granteeFragment, organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <Suspense fallback={<Table.Row skeleton />}>
      <Deferred
        granteeFragment={grantee}
        organizationFragment={organization}
        {...props}
      />
    </Suspense>
  );
};
