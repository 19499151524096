import classNames from "classnames";
import { FormattedNumber } from "react-intl";

import { Percentage } from "../../Percentage";
import { Typography } from "../Typography";

export const TableCellExercisedProgress: React.FC<{
  className?: string;
  exercisableShares: number;
  exercisedShares: number;
}> = ({ className, exercisableShares, exercisedShares }) => (
  <div className={classNames("flex flex-col gap-1", className)}>
    <Typography as="div" variant="Regular/Extra Small">
      <Percentage
        maximumFractionDigits={0}
        value={
          exercisableShares === 0 ? 0 : exercisedShares / exercisableShares
        }
      />
    </Typography>

    <Typography className="text-black-05" variant="Regular/Caption">
      <FormattedNumber value={exercisedShares} /> /{" "}
      <FormattedNumber value={exercisableShares} /> vested shares
    </Typography>
  </div>
);
