import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedFMV } from "../../../components/FormattedFMV";
import { ShortDate } from "../../../components/ShortDate";
import { LoadingSpinner } from "../../../components/ui/LoadingSpinner";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP } from "../CTMSGrantAmendmentRequests/shared";
import { FairMarketValueApprovalTable_FairMarketValue$key } from "./__generated__/FairMarketValueApprovalTable_FairMarketValue.graphql";

const FAIR_MARKET_VALUE_FRAGMENT = graphql`
  fragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue {
    date
    value
    valuationFirm
  }
`;

export const FairMarketValueApprovalTable: React.FC<{
  boardConsentGenerationIsInProgress?: boolean;
  fairMarketValueFragment: FairMarketValueApprovalTable_FairMarketValue$key;
}> = ({ boardConsentGenerationIsInProgress, fairMarketValueFragment }) => {
  const fairMarketValue = useFragment(
    FAIR_MARKET_VALUE_FRAGMENT,
    fairMarketValueFragment,
  );

  return (
    <Table className="min-w-[720px] grid-cols-[1fr,1fr,1fr,1fr]" display="grid">
      <Table.Header borderless>
        <Table.Row>
          <Table.HeaderCell>Valuation date</Table.HeaderCell>
          <Table.HeaderCell>Valuation firm</Table.HeaderCell>
          <Table.HeaderCell>Fair Market Value</Table.HeaderCell>
          <Table.HeaderCell alignRight>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <ShortDate value={fairMarketValue.date} />
          </Table.Cell>
          <Table.Cell>{fairMarketValue.valuationFirm}</Table.Cell>
          <Table.Cell>
            <FormattedFMV value={fairMarketValue.value} />
          </Table.Cell>
          <Table.Cell className="justify-end">
            {boardConsentGenerationIsInProgress ? (
              <Tag color="dashed">
                <LoadingSpinner className="h-3 w-3 shrink-0" /> Generating board
                consent
              </Tag>
            ) : (
              <Tag color="dashed">
                {
                  GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP[
                    "PENDING_APPROVAL"
                  ]
                }
              </Tag>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
