import React, { useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../../paths";
import { ConfirmationNoticeMessage } from "../ConfirmationNoticeMessage";
import { AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment$key } from "./__generated__/AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment.graphql";
import { AcknowledgementSection_ConfirmSubmitBoardConsentSlide_Organization$key } from "./__generated__/AcknowledgementSection_ConfirmSubmitBoardConsentSlide_Organization.graphql";
import { Section } from "./Section";

const INSTRUMENT_REQUIRING_ELIGIBILITY_ACKNOWLEDGMENT_FRAGMENT = graphql`
  fragment AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment on Instrument
  @relay(plural: true) {
    id
    name
    equityType {
      id
      name
    }
    taxResidenceCountry {
      emoji
      code
    }
  }
`;

export type Acknowledgement = {
  confirmed: boolean;
  content: React.ReactNode;
  dontAskMeAgain?: boolean;
  key: string;
  onConfirmClick: () => void;
  onDontAskMeAgainChange?: (dontAskMeAgain: boolean) => void;
  reset?: () => void;
  shouldBeDisplayed: boolean;
  type: "info" | "warning";
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment AcknowledgementSection_ConfirmSubmitBoardConsentSlide_Organization on Organization {
    id
    meAsAnAdmin {
      dontShow409ARenewalObligationBeforeSendingGrants
      dontShowBoardMembersListConfirmationBeforeSendingGrants
    }
  }
`;

export const use409ARenewalObligationAcknowledgement = ({
  organizationFragment,
}: {
  organizationFragment: AcknowledgementSection_ConfirmSubmitBoardConsentSlide_Organization$key;
}): Acknowledgement | null => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const admin = organization.meAsAnAdmin;
  const [
    acknowledged409ARenewalObligation,
    setAcknowledged409ARenewalObligation,
  ] = useState(false);
  const [
    dontShow409ARenewalObligationBeforeSendingGrants,
    setDoNotShow409ARenewalObligationBeforeSendingGrants,
  ] = useState(false);

  if (!admin) return null;

  return {
    confirmed: acknowledged409ARenewalObligation,
    content: (
      <>
        I am aware that certain major events may have an impact on my company
        409a valuation, and I am confident that no such event has taken place
        since the date of the latest 409a valuation.
      </>
    ),
    dontAskMeAgain: dontShow409ARenewalObligationBeforeSendingGrants,
    key: "409ARenewalObligation",
    onConfirmClick: () => setAcknowledged409ARenewalObligation(true),
    onDontAskMeAgainChange:
      setDoNotShow409ARenewalObligationBeforeSendingGrants,
    shouldBeDisplayed: !admin.dontShow409ARenewalObligationBeforeSendingGrants,
    type: "warning",
  };
};

export const useBoardMembersListConfirmation = ({
  organizationFragment,
}: {
  organizationFragment: AcknowledgementSection_ConfirmSubmitBoardConsentSlide_Organization$key;
}): Acknowledgement | null => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const admin = organization.meAsAnAdmin;
  const [confirmedBoardMembersList, setConfirmedBoardMembersList] =
    useState(false);
  const [
    dontShowBoardMembersListConfirmationBeforeSendingGrants,
    setDoNotShowBoardMembersListConfirmationBeforeSendingGrants,
  ] = useState(false);

  if (!admin) return null;

  return {
    confirmed: confirmedBoardMembersList,
    content: (
      <>
        I confirm that the board is composed of all the above persons (and that
        no other board member has been overlooked)
      </>
    ),
    dontAskMeAgain: dontShowBoardMembersListConfirmationBeforeSendingGrants,
    key: "boardMembersListConfirmation",
    onConfirmClick: () => setConfirmedBoardMembersList(true),
    onDontAskMeAgainChange:
      setDoNotShowBoardMembersListConfirmationBeforeSendingGrants,
    shouldBeDisplayed:
      !admin.dontShowBoardMembersListConfirmationBeforeSendingGrants,
    type: "warning",
  };
};

export const useOutsideEasopBoardConsentAcknowledgement = ({
  shouldBeDisplayed,
  type,
}: {
  shouldBeDisplayed: boolean;
  type:
    | "GRANT"
    | "GRANT_AMENDMENT_REQUEST"
    | "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST";
}): Acknowledgement | null => {
  const [
    boardConsentHandledOutsideEasopAcknowledged,
    setBoardConsentHandledOutsideEasopAcknowledged,
  ] = useState(false);
  const applicationName = useApplicationName();

  return {
    confirmed: boardConsentHandledOutsideEasopAcknowledged,
    content: (
      <div>
        I hereby
        <ol className="list-decimal pl-5">
          <li>
            confirm that a valid resolution has been adopted by the board
            (either via oral meeting, or written board consent signed by all
            board members) on the date of approval mentioned above, in
            accordance with the laws of Delaware, and the company&apos;s
            constitutional documents;
          </li>
          {type === "GRANT" ? (
            <>
              <li>
                confirm that the board resolution validly approves the grant of
                the equity awards under the same parameters and conditions as
                the drafts related to these equity awards prepared on{" "}
                {applicationName}, and in accordance with Section 409A of the
                Internal Revenue Code;
              </li>
              <li>
                acknowledge that {applicationName} is not responsible for
                cross-checking such board consent with the related grants and
                fully relies on your confirmation.
              </li>
            </>
          ) : (
            <>
              <li>
                confirm that the board resolution validly approves the amendment
                of the equity awards under the same parameters and conditions as
                the drafts related to these equity awards amendments prepared on
                {applicationName}, and in accordance with Section 409A of the
                Internal Revenue Code;
              </li>
              <li>
                acknowledge that {applicationName} is not responsible for
                cross-checking such board consent with the related amendments
                and fully relies on your confirmation.
              </li>
            </>
          )}
        </ol>
      </div>
    ),
    key: "outsideEasopBoardConsentAcknowledgement",
    onConfirmClick: () => setBoardConsentHandledOutsideEasopAcknowledged(true),
    shouldBeDisplayed,
    type: "info",
  };
};

export const useEligibilityAcknowledgments = ({
  instrumentsFragment,
  organizationId,
}: {
  instrumentsFragment: AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment$key;
  organizationId: string;
}) => {
  const instruments = useFragment(
    INSTRUMENT_REQUIRING_ELIGIBILITY_ACKNOWLEDGMENT_FRAGMENT,
    instrumentsFragment,
  );
  const [acknowledgedEligibility, setAcknowledgedEligibility] = useState<
    Record<string, boolean>
  >({});

  const isEligibilityAcknowledged = useCallback(
    (instrumentId: string) => acknowledgedEligibility[instrumentId] ?? false,
    [acknowledgedEligibility],
  );

  const everythingIsAcknowledged = useMemo(() => {
    return instruments.every(
      (instrument) => acknowledgedEligibility[instrument.id],
    );
  }, [acknowledgedEligibility, instruments]);

  const handleAcknowledgmentChange = (instrumentId: string) => {
    setAcknowledgedEligibility((prev) => ({
      ...prev,
      [instrumentId]: !prev[instrumentId],
    }));
  };

  return {
    everythingIsAcknowledged,
    items: useMemo(
      (): Acknowledgement[] =>
        instruments.map((instrument) => ({
          confirmed: isEligibilityAcknowledged(instrument.id),
          content: (
            <>
              I am aware that granting{" "}
              {instrument.equityType
                ? `${instrument.equityType.name} ${instrument.taxResidenceCountry.emoji}`
                : `${instrument.name} ${instrument.taxResidenceCountry.emoji}`}{" "}
              is subject to some conditions, which are listed in my{" "}
              <Link
                to={generatePath(APPLICATION_ROUTES["organizationCountry"], {
                  countryCode: instrument.taxResidenceCountry.code,
                  organizationId,
                })}
              >
                Geography portal
              </Link>
              . I confirm that I&apos;ve read and understood these conditions.
            </>
          ),
          key: `${instrument.id} eligibilityAcknowledgment`,
          onConfirmClick: () => handleAcknowledgmentChange(instrument.id),
          shouldBeDisplayed: true,
          type: "info",
        })),
      [instruments, isEligibilityAcknowledged, organizationId],
    ),
  };
};

export function AcknowledgementSection({
  acknowledgments,
}: {
  acknowledgments: Acknowledgement[];
}) {
  return (
    <Section
      subtitle="Please confirm the following before proceeding"
      title="Acknowledgements"
    >
      <div className="space-y-4">
        {acknowledgments.map(
          ({
            confirmed,
            content,
            dontAskMeAgain,
            key,
            onConfirmClick,
            onDontAskMeAgainChange,
            type,
          }) => (
            <ConfirmationNoticeMessage
              confirmed={confirmed}
              dontAskMeAgain={dontAskMeAgain}
              hasColor={false}
              key={key}
              onConfirmClick={onConfirmClick}
              onDontAskMeAgainChange={onDontAskMeAgainChange}
              size="Large"
              variant={type === "warning" ? "Warning" : "Info"}
            >
              {content}
            </ConfirmationNoticeMessage>
          ),
        )}
      </div>
    </Section>
  );
}
