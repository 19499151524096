import { Suspense, useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "../../../components/InviteGranteesModal";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { SuspensedInviteGranteesModal_Deferred_Organization$key } from "./__generated__/SuspensedInviteGranteesModal_Deferred_Organization.graphql";
import { SuspensedInviteGranteesModal_Organization$key } from "./__generated__/SuspensedInviteGranteesModal_Organization.graphql";
import { SuspensedInviteGranteesModal_Viewer$key } from "./__generated__/SuspensedInviteGranteesModal_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment SuspensedInviteGranteesModal_Viewer on Account {
    ...InviteGranteesModal_Viewer
  }
`;

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment SuspensedInviteGranteesModal_Deferred_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    granteePortalInvitationIsAllowed
    grantees {
      edges {
        node {
          id
          ...NewEquitySelectionModal_DefaultGrantee
            @arguments(organizationId: $organizationId)
        }
      }
    }
    granteesThatShouldBeInvited: grantees(
      filters: { portalInvitationSuggested: true, status: Active }
    ) {
      totalCount
      edges {
        node {
          ...InviteGranteesModal_Grantees
        }
      }
    }
    ...InviteGranteesModal_Organization
  }
`;

const Deferred: React.FC<{
  onDataUpdated?: () => void;
  organizationFragment: SuspensedInviteGranteesModal_Deferred_Organization$key;
  renderController: (props: {
    granteePortalInvitationIsAllowedForOrganization: boolean;
    organizationHasActiveWithoutPortalAccessGrantees: boolean;
    showInviteGranteeModal: () => void;
  }) => React.ReactNode;
  viewerFragment: SuspensedInviteGranteesModal_Viewer$key;
}> = ({
  onDataUpdated,
  organizationFragment,
  renderController,
  viewerFragment,
}) => {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const {
    hideInviteGranteeModal,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const handleInviteGranteesButtonClick = useCallback(() => {
    showInviteGranteeModal(
      organization.granteesThatShouldBeInvited.edges.map((edge) => edge.node),
    );
  }, [organization.granteesThatShouldBeInvited.edges, showInviteGranteeModal]);

  const onDraftNewGrantButtonClick = useCallback(
    (granteeId: string) => {
      const grantee = organization.grantees.edges
        .map((edge) => edge.node)
        .find((node) => node.id === granteeId);
      if (!grantee) throw new Error("Grantee not found");
      hideInviteGranteeModal();
      showNewEquitySelectionModal({ granteeFragment: grantee });
    },
    [
      organization.grantees.edges,
      hideInviteGranteeModal,
      showNewEquitySelectionModal,
    ],
  );

  const organizationHasActiveWithoutPortalAccessGrantees =
    organization.granteesThatShouldBeInvited.totalCount > 0;

  return (
    <>
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        onGrantCreated={onDataUpdated}
        organizationId={organization.id}
        state={newEquitySelectionModalState}
      />
      <InviteGranteesModal
        onClose={hideInviteGranteeModal}
        onDraftNewGrantButtonClick={onDraftNewGrantButtonClick}
        onGranteeCreated={onDataUpdated}
        onGranteeDeleted={onDataUpdated}
        onGranteesInvited={onDataUpdated}
        onGranteeUpdated={onDataUpdated}
        organizationFragment={organization}
        state={inviteGranteeModalState}
        viewerFragment={viewer}
      />
      {renderController({
        granteePortalInvitationIsAllowedForOrganization:
          organization.granteePortalInvitationIsAllowed,
        organizationHasActiveWithoutPortalAccessGrantees,
        showInviteGranteeModal: handleInviteGranteesButtonClick,
      })}
    </>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment SuspensedInviteGranteesModal_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...SuspensedInviteGranteesModal_Deferred_Organization
      @arguments(organizationId: $organizationId)
      @defer
  }
`;

export const SuspensedInviteGranteesModal: React.FC<
  {
    organizationFragment: SuspensedInviteGranteesModal_Organization$key;
  } & Omit<React.ComponentProps<typeof Deferred>, "organizationFragment">
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Suspense>
      <Deferred organizationFragment={organization} {...props} />
    </Suspense>
  );
};
