import { FolderArrowDownIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import saveAs from "file-saver";
import { compact, isNil, sumBy } from "lodash";
import { useMemo } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useTrackDocumentsDownloaded } from "../hooks/useAnalytics";
import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { useTailwindActiveBreakpoints } from "../hooks/useTailwindActiveBreakpoints";
import {
  BoardMemberApprovalsTables_Organization$data,
  BoardMemberApprovalsTables_Organization$key,
} from "./__generated__/BoardMemberApprovalsTables_Organization.graphql";
import {
  BoardMemberApprovalsTables_SignatureRequests$data,
  BoardMemberApprovalsTables_SignatureRequests$key,
} from "./__generated__/BoardMemberApprovalsTables_SignatureRequests.graphql";
import { FormattedFMV } from "./FormattedFMV";
import { Percentage } from "./Percentage";
import { ShortDate } from "./ShortDate";
import { Button } from "./ui/Button";
import { RoundedBox } from "./ui/RoundedBox";
import { Table } from "./ui/Table";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const SIGNATURE_REQUESTS_FRAGMENT = graphql`
  fragment BoardMemberApprovalsTables_SignatureRequests on SignatureRequest
  @relay(plural: true) {
    id
    completedAt
    boardConsent {
      __typename
      ... on GrantBoardConsent {
        grantCount
        totalGrantedShares
      }
      ... on GranteeTerminationGrantAmendmentBoardConsent {
        granteeTerminationCTMSGrantAmendmentRequestCount
      }
      ... on GrantAmendmentBoardConsent {
        ctmsGrantAmendmentRequestsCount
      }
      ... on FairMarketValueBoardConsent {
        fairMarketValue {
          valuationFirm
          value
        }
      }
      completedAt
      missingSignaturesCount
      signatureRequestsCount
      signedDocument {
        downloadUrl
      }
    }
    completedAt
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardMemberApprovalsTables_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
    id
    name
  }
`;

type SignatureRequest =
  BoardMemberApprovalsTables_SignatureRequests$data[number];

const SignatureRequestLabelContent: React.FC<{
  signatureRequest: SignatureRequest;
}> = ({ signatureRequest }) => {
  const { boardConsent } = signatureRequest;
  switch (boardConsent.__typename) {
    case "FairMarketValueBoardConsent":
      return <FormattedMessage defaultMessage="Approval of 409A Valuation" />;
    case "GrantAmendmentBoardConsent":
      return (
        <FormattedMessage
          defaultMessage={`Approval of {grantAmendmentRequestCount, plural, 
              one {# grant amendment request}
              other {# grant amendment requests}
            }`}
          values={{
            grantAmendmentRequestCount:
              boardConsent.ctmsGrantAmendmentRequestsCount,
          }}
        />
      );
    case "GrantBoardConsent":
      return (
        <FormattedMessage
          defaultMessage={`Approval of {grantCount, plural, 
            one {# grant}
            other {# grants}
          }`}
          values={{
            grantCount: boardConsent.grantCount,
          }}
        />
      );
    case "GranteeTerminationGrantAmendmentBoardConsent":
      return (
        <FormattedMessage
          defaultMessage={`Approval of {grantAmendmentRequestCount, plural, 
              one {# grant amendment request}
              other {# grant amendment requests}
            }`}
          values={{
            grantAmendmentRequestCount:
              boardConsent.granteeTerminationCTMSGrantAmendmentRequestCount,
          }}
        />
      );
  }
};

const SignatureRequestLabel: React.FC<{
  onLabelClick: () => void;
  organization: BoardMemberApprovalsTables_Organization$data;
  signatureRequest: SignatureRequest;
}> = ({ onLabelClick, organization, signatureRequest }) => {
  return (
    <>
      <Typography
        as="div"
        className="cursor-pointer whitespace-nowrap text-primary-06"
        onClick={onLabelClick}
        variant="Medium/Extra Small"
      >
        <SignatureRequestLabelContent signatureRequest={signatureRequest} />
      </Typography>

      <Typography variant="Regular/Extra Small">{organization.name}</Typography>
    </>
  );
};

const SignatureRequestStatusTag: React.FC<{
  signatureRequest: SignatureRequest;
}> = ({ signatureRequest }) => {
  const boardConsent = signatureRequest.boardConsent;

  const isFullySigned = boardConsent.missingSignaturesCount === 0;
  return isFullySigned ? (
    <Tag className="block" color="green">
      Fully signed
    </Tag>
  ) : (
    <Tag className="block whitespace-nowrap" color="fuchsia">
      {boardConsent.missingSignaturesCount === 1 &&
      !signatureRequest.completedAt
        ? "Only your signature is missing"
        : `Missing ${boardConsent.missingSignaturesCount}/${boardConsent.signatureRequestsCount} signatures`}
    </Tag>
  );
};

const SignatureRequestCompletedTag: React.FC<{
  className?: string;
  completedAt: Date;
}> = ({ className, completedAt }) => {
  return (
    <Tag
      className={classNames(
        "block justify-center whitespace-nowrap",
        className,
      )}
      color="gray"
    >
      You signed on <ShortDate value={completedAt} />
    </Tag>
  );
};

const DownloadBoardConsentDocumentsButton: React.FC<{
  boardConsent: SignatureRequest["boardConsent"];
  children: string;
  fullWidth?: boolean;
}> = ({ boardConsent, children, fullWidth }) => {
  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();
  const intl = useIntl();
  return (
    <Button
      className="ml-auto whitespace-nowrap"
      fullWidth={fullWidth}
      leftIcon={<FolderArrowDownIcon />}
      onClick={() => {
        if (
          !boardConsent.completedAt ||
          !boardConsent.signedDocument?.downloadUrl
        )
          return;

        trackDocumentsDownloaded({
          documentsTypes: ["BOARD_CONSENT"],
          downloadType: "UNIQUE",
        });

        const filename = `signed board consent - ${intl.formatDate(
          boardConsent.completedAt,
        )}.pdf`;

        saveAs(boardConsent.signedDocument?.downloadUrl, filename);
      }}
      size="small"
      variant="Primary Outline"
    >
      {children}
    </Button>
  );
};

const columnHelper =
  createColumnHelper<
    BoardMemberApprovalsTables_SignatureRequests$data[number]
  >();

const ApprovalsTable_: React.FC<{
  onSignatureRequestClick: (signatureRequestId: string) => void;
  organization: BoardMemberApprovalsTables_Organization$data;
  signatureRequests: BoardMemberApprovalsTables_SignatureRequests$data;
  tableHeader: React.ReactNode;
  type: "AMENDMENT" | "FAIR_MARKET_VALUE" | "GRANT";
}> = ({
  onSignatureRequestClick,
  organization,
  signatureRequests,
  tableHeader,
  type,
}) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const columns = useMemo(
    () =>
      compact([
        columnHelper.accessor((row) => row.boardConsent.grantCount, {
          cell: (context) => {
            const signatureRequest = context.row.original;
            return (
              <SignatureRequestLabel
                onLabelClick={() =>
                  onSignatureRequestClick(signatureRequest.id)
                }
                organization={organization}
                signatureRequest={signatureRequest}
              />
            );
          },
          header: () => "Approval",
          id: "approval",
        }),
        type === "GRANT" &&
          columnHelper.accessor((row) => row.boardConsent.totalGrantedShares, {
            cell: (context) => {
              const totalGrantedShares = context.getValue() ?? null;
              const totalGrantedOwnership =
                totalGrantedShares !== null
                  ? sharesToFullyDilutedRatio(totalGrantedShares)
                  : null;

              if (totalGrantedOwnership === null) return <>-</>;

              return (
                <>
                  <Percentage value={totalGrantedOwnership} />
                  &nbsp;fully diluted
                </>
              );
            },
            header: () => "% Fully diluted",
            id: "fully_diluted",
          }),
        type === "GRANT" &&
          columnHelper.accessor((row) => row.boardConsent.totalGrantedShares, {
            cell: (context) => {
              const totalGrantedShares = context.getValue() ?? null;
              const totalGrantedUSDValue =
                totalGrantedShares !== null
                  ? sharesToValue(totalGrantedShares)
                  : null;

              if (totalGrantedUSDValue === null) return <>-</>;

              return (
                <FormattedNumber
                  currency="USD"
                  maximumFractionDigits={0}
                  style="currency"
                  value={totalGrantedUSDValue}
                />
              );
            },
            header: () => "$ Value",
            id: "total_value",
          }),
        type === "GRANT" &&
          columnHelper.accessor((row) => row.boardConsent.totalGrantedShares, {
            cell: (context) => {
              const totalGrantedShares = context.getValue();

              if (typeof totalGrantedShares !== "number") return <>-</>;

              return (
                <FormattedMessage
                  defaultMessage={`{shares, plural, 
                one {# share}
                other {# shares}
              }`}
                  values={{
                    shares: totalGrantedShares,
                  }}
                />
              );
            },
            header: () => "# Total shares",
            id: "total_shares",
          }),
        type === "FAIR_MARKET_VALUE" &&
          columnHelper.accessor(
            (row) => row.boardConsent.fairMarketValue?.valuationFirm,
            {
              cell: (context) => context.getValue(),
              header: () => "Valuation Firm",
              id: "fair_market_value_valuation_firm",
            },
          ),
        type === "FAIR_MARKET_VALUE" &&
          columnHelper.accessor(
            (row) => row.boardConsent.fairMarketValue?.value,
            {
              cell: (context) => {
                const value = context.getValue();
                if (isNil(value)) return <>-</>;
                return (
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={value}
                  />
                );
              },
              header: () => "Fair Market Value (1 share)",
              id: "fair_market_value_value",
            },
          ),
        columnHelper.accessor((row) => row.boardConsent.totalGrantedShares, {
          cell: (context) => {
            const signatureRequest = context.row.original;

            return (
              <div className="relative flex items-center justify-between gap-4">
                <div className="flex flex-col items-start gap-2">
                  <SignatureRequestStatusTag
                    signatureRequest={signatureRequest}
                  />

                  {signatureRequest.completedAt ? (
                    <SignatureRequestCompletedTag
                      completedAt={new Date(signatureRequest.completedAt)}
                    />
                  ) : null}
                </div>

                {!signatureRequest.completedAt ? (
                  <Button
                    onClick={() => onSignatureRequestClick(signatureRequest.id)}
                    size="small"
                  >
                    Review
                  </Button>
                ) : null}

                {signatureRequest.boardConsent.completedAt ? (
                  <DownloadBoardConsentDocumentsButton
                    boardConsent={signatureRequest.boardConsent}
                  >
                    Document
                  </DownloadBoardConsentDocumentsButton>
                ) : null}
              </div>
            );
          },
          header: () => "Status",
          id: "status",
        }),
      ]),
    [
      sharesToValue,
      sharesToFullyDilutedRatio,
      onSignatureRequestClick,
      organization,
      type,
    ],
  );

  const data = useMemo(() => [...signatureRequests], [signatureRequests]);

  const table = useReactTable({
    columns,
    data,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <RoundedBox withBorder>
      <Typography
        as="div"
        className={classNames(
          "flex w-full items-center gap-6 rounded-t-lg bg-gray-02 px-4 py-2 text-gray-09",
        )}
        variant="Medium/Caption"
      >
        {tableHeader}
      </Typography>
      <Table.Smart borderlessContainer table={table} />
    </RoundedBox>
  );
};

const MobileApprovalsListValuesStack: React.FC<{
  children: React.ReactNode;
  label: string;
}> = ({ children, label }) => (
  <div className="flex flex-1 flex-col">
    <Typography
      className="uppercase text-black-05"
      variant="Regular/XS-Caption"
    >
      {label}
    </Typography>
    <Typography>{children}</Typography>
  </div>
);

const MobileApprovalsListBreakDown: React.FC<{
  organization: BoardMemberApprovalsTables_Organization$data;
  signatureRequest: SignatureRequest;
  type: "AMENDMENT" | "FAIR_MARKET_VALUE" | "GRANT";
}> = ({ organization, signatureRequest, type }) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });
  switch (type) {
    case "AMENDMENT":
      return null;
    case "FAIR_MARKET_VALUE":
      if (!signatureRequest.boardConsent.fairMarketValue) return null;
      return (
        <div className="flex w-full">
          <MobileApprovalsListValuesStack label="Valuation Firm">
            {signatureRequest.boardConsent.fairMarketValue.valuationFirm}
          </MobileApprovalsListValuesStack>
          <MobileApprovalsListValuesStack label="Fair Market Value (1 share)">
            <FormattedFMV
              value={signatureRequest.boardConsent.fairMarketValue.value}
            />
          </MobileApprovalsListValuesStack>
        </div>
      );
    case "GRANT": {
      const totalGrantedShares =
        signatureRequest.boardConsent.totalGrantedShares ?? null;
      const totalGrantedOwnership =
        totalGrantedShares !== null
          ? sharesToFullyDilutedRatio(totalGrantedShares)
          : null;
      const totalGrantedUSDValue =
        totalGrantedShares !== null ? sharesToValue(totalGrantedShares) : null;

      if (typeof totalGrantedShares !== "number") return null;
      return (
        <div className="flex w-full">
          {totalGrantedOwnership !== null && (
            <MobileApprovalsListValuesStack label="% fully diluted">
              <Percentage value={totalGrantedOwnership} />
            </MobileApprovalsListValuesStack>
          )}
          {totalGrantedUSDValue !== null && (
            <MobileApprovalsListValuesStack label="$ value">
              <FormattedNumber
                compactDisplay="short"
                currency="USD"
                maximumFractionDigits={0}
                notation="compact"
                style="currency"
                value={totalGrantedUSDValue}
              />
            </MobileApprovalsListValuesStack>
          )}
          <MobileApprovalsListValuesStack label="shares">
            <FormattedNumber
              compactDisplay="short"
              maximumFractionDigits={0}
              notation="compact"
              value={totalGrantedShares}
            />
          </MobileApprovalsListValuesStack>
        </div>
      );
    }
  }
};

const _MobileApprovalsList: React.FC<{
  onSignatureRequestClick: (signatureRequestId: string) => void;
  organization: BoardMemberApprovalsTables_Organization$data;
  signatureRequests: BoardMemberApprovalsTables_SignatureRequests$data;
  tableHeader: React.ReactNode;
  type: "AMENDMENT" | "FAIR_MARKET_VALUE" | "GRANT";
}> = ({
  onSignatureRequestClick,
  organization,
  signatureRequests,
  tableHeader,
  type,
}) => {
  return (
    <RoundedBox className="flex flex-col overflow-hidden" withBorder withShadow>
      <Typography
        className="bg-gray-02 px-4 py-2 text-gray-09"
        variant="Regular/Caption"
      >
        {tableHeader}
      </Typography>
      <div className="divide-y-[0.5px] divide-gray-04">
        {signatureRequests.map((signatureRequest) => {
          return (
            <div
              className="flex flex-col items-start gap-4 px-4 py-6"
              key={signatureRequest.id}
            >
              <div className="flex flex-col gap-1">
                <SignatureRequestLabel
                  onLabelClick={() =>
                    onSignatureRequestClick(signatureRequest.id)
                  }
                  organization={organization}
                  signatureRequest={signatureRequest}
                />
              </div>
              <SignatureRequestStatusTag signatureRequest={signatureRequest} />
              <MobileApprovalsListBreakDown
                organization={organization}
                signatureRequest={signatureRequest}
                type={type}
              />
              {signatureRequest.completedAt ? (
                <SignatureRequestCompletedTag
                  className="w-full"
                  completedAt={new Date(signatureRequest.completedAt)}
                />
              ) : (
                <Button
                  fullWidth
                  onClick={() => onSignatureRequestClick(signatureRequest.id)}
                  size="small"
                >
                  Review
                </Button>
              )}
              {signatureRequest.boardConsent.completedAt ? (
                <DownloadBoardConsentDocumentsButton
                  boardConsent={signatureRequest.boardConsent}
                  fullWidth
                >
                  Download documents
                </DownloadBoardConsentDocumentsButton>
              ) : null}
            </div>
          );
        })}
      </div>
    </RoundedBox>
  );
};

const BoardMemberApprovalsTables_: React.FC<{
  onSignatureRequestClick: (signatureRequestId: string) => void;
  organization: BoardMemberApprovalsTables_Organization$data;
  signatureRequests: BoardMemberApprovalsTables_SignatureRequests$data;
  tableHeader: React.ReactNode;
  type: "AMENDMENT" | "FAIR_MARKET_VALUE" | "GRANT";
}> = ({
  onSignatureRequestClick,
  organization,
  signatureRequests,
  tableHeader,
  type,
}) => {
  const { isXs } = useTailwindActiveBreakpoints();
  if (!signatureRequests.length) return null;
  return isXs ? (
    <_MobileApprovalsList
      onSignatureRequestClick={onSignatureRequestClick}
      organization={organization}
      signatureRequests={signatureRequests}
      tableHeader={tableHeader}
      type={type}
    />
  ) : (
    <ApprovalsTable_
      onSignatureRequestClick={onSignatureRequestClick}
      organization={organization}
      signatureRequests={signatureRequests}
      tableHeader={tableHeader}
      type={type}
    />
  );
};

export const BoardMemberApprovalsTables: React.FC<{
  onSignatureRequestClick: (signatureRequestId: string) => void;
  organizationFragment: BoardMemberApprovalsTables_Organization$key;
  signatureRequestsFragment: BoardMemberApprovalsTables_SignatureRequests$key;
}> = ({
  onSignatureRequestClick,
  organizationFragment,
  signatureRequestsFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const signatureRequests = useFragment(
    SIGNATURE_REQUESTS_FRAGMENT,
    signatureRequestsFragment,
  );
  const signatureRequestsGroupedByBoardConsentType = useMemo(
    () =>
      signatureRequests.reduce<{
        AMENDMENT: SignatureRequest[];
        FAIR_MARKET_VALUE: SignatureRequest[];
        GRANT: SignatureRequest[];
      }>(
        (acc, cur) => {
          switch (cur.boardConsent.__typename) {
            case "FairMarketValueBoardConsent":
              acc.FAIR_MARKET_VALUE.push(cur);
              break;
            case "GrantAmendmentBoardConsent":
            case "GranteeTerminationGrantAmendmentBoardConsent":
              acc.AMENDMENT.push(cur);
              break;
            case "GrantBoardConsent":
              acc.GRANT.push(cur);
              break;
          }
          return acc;
        },
        {
          AMENDMENT: [],
          FAIR_MARKET_VALUE: [],
          GRANT: [],
        },
      ),
    [signatureRequests],
  );
  const grantsApprovalTableHeader = useMemo(() => {
    return (
      <FormattedMessage
        defaultMessage={`Total of {approvalCount, plural,
                          one {# approval}
                          other {# approvals}
                        } - Total of {grantCount, plural,
                          one {# grant}
                          other {# grants}
                        }`}
        values={{
          approvalCount:
            signatureRequestsGroupedByBoardConsentType["GRANT"].length,
          grantCount: sumBy(
            signatureRequestsGroupedByBoardConsentType["GRANT"],
            (signatureRequest) => signatureRequest.boardConsent.grantCount ?? 0,
          ),
        }}
      />
    );
  }, [signatureRequestsGroupedByBoardConsentType]);

  const amendmentsApprovalTableHeader = useMemo(() => {
    return (
      <FormattedMessage
        defaultMessage={`Total of {approvalCount, plural,
                          one {# approval}
                          other {# approvals}
                        } - Total of {amendmentCount, plural,
                          one {# grant amendment}
                          other {# grant amendments}
                        }`}
        values={{
          amendmentCount: sumBy(
            signatureRequestsGroupedByBoardConsentType["AMENDMENT"],
            (signatureRequest) =>
              signatureRequest.boardConsent.ctmsGrantAmendmentRequestsCount ??
              signatureRequest.boardConsent
                .granteeTerminationCTMSGrantAmendmentRequestCount ??
              0,
          ),
          approvalCount:
            signatureRequestsGroupedByBoardConsentType["AMENDMENT"].length,
        }}
      />
    );
  }, [signatureRequestsGroupedByBoardConsentType]);
  return (
    <>
      <BoardMemberApprovalsTables_
        onSignatureRequestClick={onSignatureRequestClick}
        organization={organization}
        signatureRequests={
          signatureRequestsGroupedByBoardConsentType["FAIR_MARKET_VALUE"]
        }
        tableHeader="409A valuation pending approval"
        type="FAIR_MARKET_VALUE"
      />
      <BoardMemberApprovalsTables_
        onSignatureRequestClick={onSignatureRequestClick}
        organization={organization}
        signatureRequests={signatureRequestsGroupedByBoardConsentType["GRANT"]}
        tableHeader={grantsApprovalTableHeader}
        type="GRANT"
      />
      <BoardMemberApprovalsTables_
        onSignatureRequestClick={onSignatureRequestClick}
        organization={organization}
        signatureRequests={
          signatureRequestsGroupedByBoardConsentType["AMENDMENT"]
        }
        tableHeader={amendmentsApprovalTableHeader}
        type="AMENDMENT"
      />
    </>
  );
};
