import { sumBy } from "lodash";
import { z } from "zod";

import { zodExtra } from "../../../../../helpers/zod-extra";

export const LINEAR_VESTING_SCHEDULE_FORM_SCHEMA = z
  .object({
    cliffDurationInMonths: z.coerce.number().int().optional(),
    durationInMonths: z.coerce
      .number()
      .int()
      .positive()
      .max(120, "Vesting duration cannot exceed 10 years"),
    hasCliff: z.boolean(),
    name: z.string().trim().min(1),
    vestedAtCliffPercentage: z.coerce
      .number()
      .nonnegative()
      .max(100)
      .optional(),
    vestingOccurrence: zodExtra.linearVestingOccurrenceSchema(),
  })
  .refine(
    (vestingSchedule) => {
      return (
        !vestingSchedule.hasCliff ||
        typeof vestingSchedule.cliffDurationInMonths === "number"
      );
    },
    { message: "required", path: ["cliffDurationInMonths"] },
  )
  .refine(
    (vestingSchedule) => {
      return (
        !vestingSchedule.hasCliff ||
        typeof vestingSchedule.cliffDurationInMonths !== "number" ||
        vestingSchedule.cliffDurationInMonths > 0
      );
    },
    {
      message: "Cliff duration should be greater than 0",
      path: ["cliffDurationInMonths"],
    },
  )
  .refine(
    (vestingSchedule) => {
      return (
        !vestingSchedule.hasCliff ||
        typeof vestingSchedule.vestedAtCliffPercentage === "number"
      );
    },
    { message: "required", path: ["vestedAtCliffPercentage"] },
  )
  .refine(
    (vestingSchedule) => {
      return (
        vestingSchedule.vestingOccurrence !== "Once" ||
        typeof vestingSchedule.durationInMonths === "number"
      );
    },
    { message: "required", path: ["durationInMonths"] },
  )
  .transform((data) => {
    if (data.vestingOccurrence === "Once") {
      return {
        ...data,
        durationInMonths: 0,
      };
    }

    return data;
  });

const PERIOD_SCHEMA = z.object({
  durationInMonths: z.coerce
    .number({ invalid_type_error: "Required" })
    .int()
    .nonnegative()
    .max(120, "Vesting duration cannot exceed 10 years"),
  percentageVested: z.coerce
    .number({ invalid_type_error: "Required" })
    .nonnegative()
    .max(100),
});

export const BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA = z.object({
  cliffActivatedOnFirstPeriod: z.boolean(),
  name: z.string().trim().min(1),
  periods: z
    .array(PERIOD_SCHEMA)
    .min(1, "At least one period is required")
    .max(10, "Maximum of 10 periods allowed")
    .refine((periods) => {
      return sumBy(periods, (period) => period.percentageVested) === 100;
    }, "Sum of periods vested percentage is not 100%")
    .refine((periods) => {
      return sumBy(periods, (period) => period.durationInMonths) < 120;
    }, "Vesting duration cannot exceed 10 years"),
  vestingOccurrence: zodExtra.backloadedVestingOccurrenceSchema(),
});

export type LinearVestingScheduleFormValues = z.infer<
  typeof LINEAR_VESTING_SCHEDULE_FORM_SCHEMA
>;
export type BackloadedVestingScheduleFormValues = z.infer<
  typeof BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA
>;

export type VestingScheduleFormType = "BACKLOADED" | "LINEAR";
