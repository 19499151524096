import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import {
  generatePath,
  Navigate,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { graphql } from "relay-runtime";

import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { FlowLayout } from "../../components/ui/Layout/FlowLayout";
import {
  AnalyticsContextProvider,
  useTrackEvent,
} from "../../hooks/useAnalytics";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../hooks/useQuery";
import {
  AssistedGrantContextProvider,
  useGetAssistedGrantStepState,
  useVisitedStep,
} from "../../pages/Admin/AssistedGrant/Context";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { AssistedGrantLayout_Query } from "./__generated__/AssistedGrantLayout_Query.graphql";
import Aside from "./AssistedGrantAside";

const AssistedGrantLayout_: React.FC = () => {
  const trackEvent = useTrackEvent();
  const visitedStep = useVisitedStep();
  const getAssistedGrantStepState = useGetAssistedGrantStepState();
  const navigate = useNavigate();
  const organizationId = useOrganizationIdParam();

  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };

    addEventListener("beforeunload", beforeUnload);

    return () => {
      removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  const visitedStepState = useMemo(
    () => getAssistedGrantStepState(visitedStep),
    [getAssistedGrantStepState, visitedStep],
  );
  const handleExitButtonClick = useCallback(() => {
    trackEvent("Grant Draft Discard", { grantMethod: "beginner" });
    navigate(
      generatePath(APPLICATION_ROUTES["organizationEquityGrants"], {
        organizationId,
      }),
    );
  }, [navigate, organizationId, trackEvent]);

  if (visitedStepState === "upcoming") {
    return (
      <Navigate
        replace
        to={generatePath(
          APPLICATION_ROUTES["organizationAssistedGrantGrantee"],
          {
            organizationId,
          },
        )}
      />
    );
  }

  return (
    <FlowLayout
      className="p-24"
      onCloseClick={handleExitButtonClick}
      Sidenav={<Aside />}
    >
      <Suspense fallback={<LoadingPlaceholder />}>
        <Outlet />
      </Suspense>
    </FlowLayout>
  );
};

const QUERY = graphql`
  query AssistedGrantLayout_Query(
    $organizationId: OrganizationId!
    $planningEntryId: PlanningEntryId!
    $skipPlanningEntry: Boolean!
    $equityOfferId: String!
    $skipEquityOffer: Boolean!
  ) {
    organization(id: $organizationId) {
      name
    }
    planningEntry(planningEntryId: $planningEntryId)
      @skip(if: $skipPlanningEntry) {
      ...Context_AssistedGrant_PlanningEntry
    }
    equityOffer(equityOfferId: $equityOfferId) @skip(if: $skipEquityOffer) {
      ...Context_AssistedGrant_EquityOffer
    }
  }
`;

const AssistedGrantLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const [searchParams] = useSearchParams();
  const planningEntryId = searchParams.get("planningEntryId");
  const equityOfferId = searchParams.get("equityOfferId");
  const { query } = useQuery<AssistedGrantLayout_Query>(QUERY, {
    equityOfferId: equityOfferId ?? "",
    organizationId,
    planningEntryId:
      planningEntryId ??
      // Buffer.from("PlanningEntry:00000000-0000-0000-0000-000000000000", "utf-8").toString("base64url")
      "UGxhbm5pbmdFbnRyeTowMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDA",
    skipEquityOffer: !equityOfferId,
    skipPlanningEntry: !planningEntryId,
  });
  const { account } = useAuthentication();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <FeatureFlagProvider
      account={account}
      organizationName={query.organization.name}
    >
      <AnalyticsContextProvider organizationId={organizationId}>
        <AssistedGrantContextProvider
          equityOfferSourceFragment={query.equityOffer ?? null}
          planningEntrySourceFragment={query.planningEntry ?? null}
        >
          <AssistedGrantLayout_ />
        </AssistedGrantContextProvider>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default AssistedGrantLayout;
