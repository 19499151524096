import { CheckIcon } from "@heroicons/react/24/outline";

import { CheckboxFormRow } from "../pages/Admin/EquityOffer/CheckboxFormRow";
import { Button } from "./ui/Button";
import { Checkbox } from "./ui/Form/Checkbox";
import { NoticeMessage } from "./ui/NoticeMessage";

export const ConfirmationNoticeMessage: React.FC<{
  children: React.ReactNode;
  confirmed: boolean;
  dontAskMeAgain?: boolean;
  hasColor?: React.ComponentProps<typeof NoticeMessage>["hasColor"];
  onConfirmClick: () => void;
  onDontAskMeAgainChange?: (value: boolean) => void;
  size: React.ComponentProps<typeof NoticeMessage>["size"];
  variant: React.ComponentProps<typeof NoticeMessage>["variant"];
}> = ({
  children,
  confirmed,
  dontAskMeAgain,
  hasColor,
  onConfirmClick,
  onDontAskMeAgainChange,
  size,
  variant,
}) => {
  return (
    <NoticeMessage hasColor={hasColor} size={size} variant={variant}>
      <div className="space-y-4">
        <div>{children}</div>
        <div className="flex items-center gap-6">
          <Button
            disabled={confirmed}
            onClick={onConfirmClick}
            rightIcon={confirmed ? <CheckIcon /> : undefined}
            size="small"
            variant="Primary Full"
          >
            {confirmed ? "Confirmed" : "Confirm"}
          </Button>
          {onDontAskMeAgainChange && (
            <CheckboxFormRow
              checkbox={
                <Checkbox
                  checked={dontAskMeAgain}
                  onChange={(e) => {
                    const checked = e.currentTarget.checked;
                    onDontAskMeAgainChange(checked);
                  }}
                />
              }
              label="Don’t ask me again"
            />
          )}
        </div>
      </div>
    </NoticeMessage>
  );
};
