/**
 * @generated SignedSource<<bec8b220f6bed00af7ecfc6d1f493626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatchEasopGrantsAndCreateBoardConsentResultFailureError = "DIFFERENT_ACCELERATION_CLAUSE";
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type BoardApproval_SendDraftsToBoard_Mutation$variables = {
  acknowledged409ARenewalObligation: boolean;
  boardConsentHandledOutsideEasop: boolean;
  boardConsentSignatureDate?: string | null;
  confirmedBoardMembersList: boolean;
  dontShow409ARenewalObligationBeforeSendingGrants: boolean;
  dontShowBoardMembersListConfirmationBeforeSendingGrants: boolean;
  easopGrantsIds: ReadonlyArray<string>;
  grantsRequireEligibilityAcknowledgmentChecked: boolean;
  organizationId: string;
  signedBoardConsentDocumentIds?: ReadonlyArray<string> | null;
};
export type BoardApproval_SendDraftsToBoard_Mutation$data = {
  readonly batchEasopGrantsAndCreateBoardConsent: {
    readonly __typename: "BatchEasopGrantsAndCreateBoardConsentResultFailure";
    readonly error: BatchEasopGrantsAndCreateBoardConsentResultFailureError;
  } | {
    readonly __typename: "BatchEasopGrantsAndCreateBoardConsentResultSuccess";
    readonly easopGrants: ReadonlyArray<{
      readonly grantStatus: EasopGrantStatus;
    }>;
    readonly organization: {
      readonly meAsAnAdmin: {
        readonly dontShow409ARenewalObligationBeforeSendingGrants: boolean;
        readonly dontShowBoardMembersListConfirmationBeforeSendingGrants: boolean;
      } | null;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type BoardApproval_SendDraftsToBoard_Mutation = {
  response: BoardApproval_SendDraftsToBoard_Mutation$data;
  variables: BoardApproval_SendDraftsToBoard_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acknowledged409ARenewalObligation"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentHandledOutsideEasop"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentSignatureDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "confirmedBoardMembersList"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dontShow409ARenewalObligationBeforeSendingGrants"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dontShowBoardMembersListConfirmationBeforeSendingGrants"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "easopGrantsIds"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grantsRequireEligibilityAcknowledgmentChecked"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signedBoardConsentDocumentIds"
},
v10 = [
  {
    "kind": "Variable",
    "name": "acknowledged409ARenewalObligation",
    "variableName": "acknowledged409ARenewalObligation"
  },
  {
    "kind": "Variable",
    "name": "boardConsentHandledOutsideEasop",
    "variableName": "boardConsentHandledOutsideEasop"
  },
  {
    "kind": "Variable",
    "name": "boardConsentSignatureDate",
    "variableName": "boardConsentSignatureDate"
  },
  {
    "kind": "Variable",
    "name": "confirmedBoardMembersList",
    "variableName": "confirmedBoardMembersList"
  },
  {
    "kind": "Variable",
    "name": "dontShow409ARenewalObligationBeforeSendingGrants",
    "variableName": "dontShow409ARenewalObligationBeforeSendingGrants"
  },
  {
    "kind": "Variable",
    "name": "dontShowBoardMembersListConfirmationBeforeSendingGrants",
    "variableName": "dontShowBoardMembersListConfirmationBeforeSendingGrants"
  },
  {
    "kind": "Variable",
    "name": "easopGrantsIds",
    "variableName": "easopGrantsIds"
  },
  {
    "kind": "Variable",
    "name": "grantsRequireEligibilityAcknowledgmentChecked",
    "variableName": "grantsRequireEligibilityAcknowledgmentChecked"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "signedBoardConsentDocumentIds",
    "variableName": "signedBoardConsentDocumentIds"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "BatchEasopGrantsAndCreateBoardConsentResultFailure",
  "abstractKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grantStatus",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dontShow409ARenewalObligationBeforeSendingGrants",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dontShowBoardMembersListConfirmationBeforeSendingGrants",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardApproval_SendDraftsToBoard_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "batchEasopGrantsAndCreateBoardConsent",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrants",
                "plural": true,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Admin",
                    "kind": "LinkedField",
                    "name": "meAsAnAdmin",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "BatchEasopGrantsAndCreateBoardConsentResultSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v8/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardApproval_SendDraftsToBoard_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "batchEasopGrantsAndCreateBoardConsent",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrants",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Admin",
                    "kind": "LinkedField",
                    "name": "meAsAnAdmin",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BatchEasopGrantsAndCreateBoardConsentResultSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79107f50dec95794b6713f337644ddf6",
    "id": null,
    "metadata": {},
    "name": "BoardApproval_SendDraftsToBoard_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardApproval_SendDraftsToBoard_Mutation(\n  $easopGrantsIds: [UUID!]!\n  $organizationId: OrganizationId!\n  $acknowledged409ARenewalObligation: Boolean!\n  $dontShow409ARenewalObligationBeforeSendingGrants: Boolean!\n  $confirmedBoardMembersList: Boolean!\n  $dontShowBoardMembersListConfirmationBeforeSendingGrants: Boolean!\n  $grantsRequireEligibilityAcknowledgmentChecked: Boolean!\n  $boardConsentHandledOutsideEasop: Boolean!\n  $boardConsentSignatureDate: Date\n  $signedBoardConsentDocumentIds: [String!]\n) {\n  batchEasopGrantsAndCreateBoardConsent(easopGrantsIds: $easopGrantsIds, organizationId: $organizationId, acknowledged409ARenewalObligation: $acknowledged409ARenewalObligation, dontShow409ARenewalObligationBeforeSendingGrants: $dontShow409ARenewalObligationBeforeSendingGrants, confirmedBoardMembersList: $confirmedBoardMembersList, dontShowBoardMembersListConfirmationBeforeSendingGrants: $dontShowBoardMembersListConfirmationBeforeSendingGrants, grantsRequireEligibilityAcknowledgmentChecked: $grantsRequireEligibilityAcknowledgmentChecked, boardConsentHandledOutsideEasop: $boardConsentHandledOutsideEasop, boardConsentSignatureDate: $boardConsentSignatureDate, signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds) {\n    __typename\n    ... on BatchEasopGrantsAndCreateBoardConsentResultFailure {\n      error\n    }\n    ... on BatchEasopGrantsAndCreateBoardConsentResultSuccess {\n      easopGrants {\n        grantStatus\n        id\n      }\n      organization {\n        meAsAnAdmin {\n          dontShow409ARenewalObligationBeforeSendingGrants\n          dontShowBoardMembersListConfirmationBeforeSendingGrants\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a9b8933892ab7e8a1fc9b152e788a6f";

export default node;
