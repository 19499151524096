/**
 * @generated SignedSource<<7ae7ddc810481855f1b7a780b1500c38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueBoardApprovalSlider_Organization$data = {
  readonly allowBoardConsentHandledOutsideEasop: boolean;
  readonly boardMembers: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly date: string;
    readonly id: string;
    readonly valuationFirm: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization" | "BoardNoteSection_Organization">;
  readonly " $fragmentType": "FairMarketValueBoardApprovalSlider_Organization";
};
export type FairMarketValueBoardApprovalSlider_Organization$key = {
  readonly " $data"?: FairMarketValueBoardApprovalSlider_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueBoardApprovalSlider_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValueBoardApprovalSlider_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowBoardConsentHandledOutsideEasop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "FairMarketValue",
        "kind": "LinkedField",
        "name": "latestFairMarketValue",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valuationFirm",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "latestFairMarketValue"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardNoteSection_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "686249d66b87f4e6e4e077f5dbc438f5";

export default node;
