import { RefreshSquare } from "iconsax-react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import {
  PlanningEntrySuggestionsTable_Organization$data,
  PlanningEntrySuggestionsTable_Organization$key,
} from "./__generated__/PlanningEntrySuggestionsTable_Organization.graphql";
import {
  PlanningEntrySuggestionsTable_PlanningEntrySuggestion$data,
  PlanningEntrySuggestionsTable_PlanningEntrySuggestion$key,
} from "./__generated__/PlanningEntrySuggestionsTable_PlanningEntrySuggestion.graphql";
import { GranteeNameWithCountryFlag } from "./GranteeNameWithCountryFlag";
import { Percentage } from "./Percentage";
import { PlanningEntryTypeTag } from "./PlanningEntryTypeTag";
import { Button } from "./ui/Button";
import { Table } from "./ui/Table";
import { TooltipContainer } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningEntrySuggestionsTable_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
  }
`;

const PLANNING_ENTRY_SUGGESTION_FRAGMENT = graphql`
  fragment PlanningEntrySuggestionsTable_PlanningEntrySuggestion on PlanningEntrySuggestion
  @relay(plural: true) {
    id
    type
    shares
    grantee {
      ...GranteeNameWithCountryFlag_Grantee
    }
  }
`;

type PlanningEntrySuggestion =
  PlanningEntrySuggestionsTable_PlanningEntrySuggestion$data[number];

const NameCell: React.FC<{
  planningEntrySuggestion: PlanningEntrySuggestion;
}> = ({ planningEntrySuggestion }) => (
  <Table.Cell>
    <div className="flex items-center gap-1">
      <div className="h-6 w-6 rounded bg-gradient-light-aubergine p-1">
        <RefreshSquare className="h-full w-full text-purple" variant="Bulk" />
      </div>
      <Typography variant="Regular/Extra Small">
        <GranteeNameWithCountryFlag
          granteeFragment={planningEntrySuggestion.grantee}
        />
      </Typography>
    </div>
  </Table.Cell>
);

const ReservedSharesCell: React.FC<{
  organization: PlanningEntrySuggestionsTable_Organization$data;
  planningEntrySuggestion: PlanningEntrySuggestion;
}> = ({ organization, planningEntrySuggestion }) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const suggestedShares = planningEntrySuggestion.shares;

  if (suggestedShares === null)
    return <Table.Cell className="justify-end">-</Table.Cell>;

  const suggestedOwnership = sharesToFullyDilutedRatio(suggestedShares);
  const suggestedValue = sharesToValue(suggestedShares);

  return (
    <Table.Cell className="justify-end">
      <div className="space-y-1 text-right">
        {suggestedValue && (
          <Typography as="div" variant="Medium/Caption">
            <FormattedNumber
              currency="USD"
              maximumFractionDigits={0}
              style="currency"
              value={suggestedValue}
            />
          </Typography>
        )}
        <Typography as="div" variant="Regular/Caption">
          <FormattedNumber value={suggestedShares} /> shares
        </Typography>
        {suggestedOwnership !== null && (
          <Typography as="div" variant="Regular/Caption">
            <Percentage value={suggestedOwnership} />
          </Typography>
        )}
      </div>
    </Table.Cell>
  );
};

const TypeCell: React.FC<{
  planningEntrySuggestion: PlanningEntrySuggestion;
}> = ({ planningEntrySuggestion }) => (
  <Table.Cell>
    <PlanningEntryTypeTag type={planningEntrySuggestion.type} />
  </Table.Cell>
);

const REASON_LABELS: Record<PlanningEntrySuggestion["type"], string> = {
  LEVELING_GRANT:
    "Employee is under-incentivized regarding their equity grid level",
  TENURE_GRANT:
    "Employee has reached their vesting threshold and is now eligible",
};
const ReasonCell: React.FC<{
  planningEntrySuggestion: PlanningEntrySuggestion;
}> = ({ planningEntrySuggestion }) => (
  <Table.Cell truncate>
    <TooltipContainer
      className="truncate"
      tooltipContent={REASON_LABELS[planningEntrySuggestion.type]}
    >
      <span>{REASON_LABELS[planningEntrySuggestion.type]}</span>
    </TooltipContainer>
  </Table.Cell>
);

const ActionCell: React.FC<{
  onPlanningEntrySuggestionIgnoreClick?: (
    planningEntrySuggestionId: string,
  ) => void;
  onPlanningEntrySuggestionPlanClick: (
    planningEntrySuggestionId: string,
  ) => void;
  planningEntrySuggestion: PlanningEntrySuggestion;
}> = ({
  onPlanningEntrySuggestionIgnoreClick,
  onPlanningEntrySuggestionPlanClick,
  planningEntrySuggestion,
}) => (
  <Table.Cell className="justify-end">
    <div className="flex items-center justify-end gap-2">
      {onPlanningEntrySuggestionIgnoreClick && (
        <Button
          onClick={() => {
            onPlanningEntrySuggestionIgnoreClick(planningEntrySuggestion.id);
          }}
          size="extra small"
          variant="Secondary Outline"
        >
          Ignore
        </Button>
      )}
      <Button
        onClick={() => {
          onPlanningEntrySuggestionPlanClick(planningEntrySuggestion.id);
        }}
        size="extra small"
      >
        Plan
      </Button>
    </div>
  </Table.Cell>
);

const Row: React.FC<{
  onPlanningEntrySuggestionIgnoreClick?: (
    planningEntrySuggestionId: string,
  ) => void;
  onPlanningEntrySuggestionPlanClick: (
    planningEntrySuggestionId: string,
  ) => void;
  organization: PlanningEntrySuggestionsTable_Organization$data;
  planningEntrySuggestion: PlanningEntrySuggestion;
}> = ({
  onPlanningEntrySuggestionIgnoreClick,
  onPlanningEntrySuggestionPlanClick,
  organization,
  planningEntrySuggestion,
}) => (
  <Table.Row>
    <NameCell planningEntrySuggestion={planningEntrySuggestion} />
    <ReservedSharesCell
      organization={organization}
      planningEntrySuggestion={planningEntrySuggestion}
    />
    <TypeCell planningEntrySuggestion={planningEntrySuggestion} />
    <ReasonCell planningEntrySuggestion={planningEntrySuggestion} />
    <ActionCell
      onPlanningEntrySuggestionIgnoreClick={
        onPlanningEntrySuggestionIgnoreClick
      }
      onPlanningEntrySuggestionPlanClick={onPlanningEntrySuggestionPlanClick}
      planningEntrySuggestion={planningEntrySuggestion}
    />
  </Table.Row>
);

export const PlanningEntrySuggestionsTable: React.FC<{
  onPlanningEntrySuggestionIgnoreClick?: (
    planningEntrySuggestionId: string,
  ) => void;
  onPlanningEntrySuggestionPlanClick: (
    planningEntrySuggestionId: string,
  ) => void;
  organizationFragment: PlanningEntrySuggestionsTable_Organization$key;
  planningEntrySuggestionsFragment: PlanningEntrySuggestionsTable_PlanningEntrySuggestion$key;
}> = ({
  onPlanningEntrySuggestionIgnoreClick,
  onPlanningEntrySuggestionPlanClick,
  organizationFragment,
  planningEntrySuggestionsFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const planningEntrySuggestions = useFragment(
    PLANNING_ENTRY_SUGGESTION_FRAGMENT,
    planningEntrySuggestionsFragment,
  );

  return (
    <Table
      className="grid grid-cols-[1fr_1fr_1fr_1fr_150px] gap-x-2"
      dataCy="planning-entry-suggestions-table"
      display="grid"
    >
      <Table.Header borderless>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell alignRight>Reserved</Table.HeaderCell>
          <Table.HeaderCell>Grant type</Table.HeaderCell>
          <Table.HeaderCell>Reason</Table.HeaderCell>
          <Table.HeaderCell className="text-right">Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {planningEntrySuggestions.map((planningEntrySuggestion) => (
          <Row
            key={planningEntrySuggestion.id}
            onPlanningEntrySuggestionIgnoreClick={
              onPlanningEntrySuggestionIgnoreClick
            }
            onPlanningEntrySuggestionPlanClick={
              onPlanningEntrySuggestionPlanClick
            }
            organization={organization}
            planningEntrySuggestion={planningEntrySuggestion}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
