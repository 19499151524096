import { InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FieldPath } from "react-hook-form";
import { FormattedNumber } from "react-intl";

import { Button } from "../../../../../components/ui/Button";
import { Divider } from "../../../../../components/ui/Divider";
import { FormRow } from "../../../../../components/ui/Form/FormRow";
import { FormRowContainer } from "../../../../../components/ui/Form/FormRowContainer";
import { Input } from "../../../../../components/ui/Form/Inputs/Input";
import { MaskedInput } from "../../../../../components/ui/Form/Inputs/MaskedInput";
import { SelectAutocomplete } from "../../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { RadioGroup } from "../../../../../components/ui/Form/RadioGroup";
import { Toggle } from "../../../../../components/ui/Form/Toggle";
import { RoundedBox } from "../../../../../components/ui/RoundedBox";
import { Table } from "../../../../../components/ui/Table";
import { Tag } from "../../../../../components/ui/Tag";
import { TooltipContainer } from "../../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../../components/ui/Typography";
import {
  VESTING_OCCURRENCE_HELPERS,
  VESTING_OCCURRENCE_HELPERS_MAP,
} from "../../../../../hooks/useFormattedVestingSchedule";
import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
  VestingScheduleFormType,
} from "./FORM_SCHEMA";
import {
  BackloadedVestingScheduleForm,
  LinearVestingScheduleForm,
} from "./useVestingScheduleForm";

const BACKLOADED_VESTING_OCCURRENCE_HELPERS = [
  {
    id: "EveryMonth" as const,
    ...VESTING_OCCURRENCE_HELPERS_MAP["EveryMonth"],
  },
  {
    id: "Every3Months" as const,
    ...VESTING_OCCURRENCE_HELPERS_MAP["Every3Months"],
  },
];

export function VestingScheduleForm({
  backloadedVestingScheduleForm,
  id,
  linearVestingScheduleForm,
  onBackloadedVestingScheduleFormSubmit,
  onLinearVestingScheduleFormSubmit,
  onTypeChange,
  type,
}: {
  onTypeChange: ((type: VestingScheduleFormType) => void) | null;
} & React.ComponentProps<typeof VestingScheduleFormContent>) {
  return (
    <div className="space-y-10">
      {onTypeChange && (
        <FormRowContainer title="Vesting schedule type">
          <RadioGroup onChange={onTypeChange} value={type}>
            <div className="grid grid-cols-2 gap-4">
              <RadioGroup.Card
                description="Under a linear vesting schedule, the quantity of options received at each vesting event is consistent."
                tag="Most common"
                value="LINEAR"
              >
                Linear vesting
              </RadioGroup.Card>
              <RadioGroup.Card
                description="Backloaded vesting increases the quantity of options that vest at each event, with much larger grants at the end."
                value="BACKLOADED"
              >
                Backloaded vesting
              </RadioGroup.Card>
            </div>
          </RadioGroup>
        </FormRowContainer>
      )}
      <VestingScheduleFormContent
        backloadedVestingScheduleForm={backloadedVestingScheduleForm}
        id={id}
        linearVestingScheduleForm={linearVestingScheduleForm}
        onBackloadedVestingScheduleFormSubmit={
          onBackloadedVestingScheduleFormSubmit
        }
        onLinearVestingScheduleFormSubmit={onLinearVestingScheduleFormSubmit}
        type={type}
      />
    </div>
  );
}

const VestingScheduleFormContent: React.FC<{
  backloadedVestingScheduleForm: BackloadedVestingScheduleForm;
  id: string;
  linearVestingScheduleForm: LinearVestingScheduleForm;
  onBackloadedVestingScheduleFormSubmit: (
    data: BackloadedVestingScheduleFormValues,
  ) => void;
  onLinearVestingScheduleFormSubmit: (
    data: LinearVestingScheduleFormValues,
  ) => void;
  type: VestingScheduleFormType;
}> = ({
  backloadedVestingScheduleForm,
  id,
  linearVestingScheduleForm,
  onBackloadedVestingScheduleFormSubmit,
  onLinearVestingScheduleFormSubmit,
  type,
}) => {
  switch (type) {
    case "BACKLOADED":
      return (
        <BackLoadedVestingScheduleFormContent
          form={backloadedVestingScheduleForm}
          id={id}
          onSubmit={onBackloadedVestingScheduleFormSubmit}
        />
      );
    case "LINEAR":
      return (
        <LinearVestingScheduleFormContent
          form={linearVestingScheduleForm}
          id={id}
          onSubmit={onLinearVestingScheduleFormSubmit}
        />
      );
  }
};

const LinearVestingScheduleFormContent: React.FC<{
  form: LinearVestingScheduleForm;
  id: string;
  onSubmit: (data: LinearVestingScheduleFormValues) => void;
}> = ({ form, id, onSubmit }) => {
  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <form className="block space-y-10" id={id} onSubmit={handleSubmit}>
      <FormRowContainer title="Time periods">
        <div className="space-y-6">
          <FormRow.Form
            control={form.control}
            label="Vesting will occur"
            name="vestingOccurrence"
          >
            <SelectAutocomplete.Form
              control={form.control}
              getOptionLabel={(vestingOccurrence) => vestingOccurrence.label}
              getOptionValue={(vestingOccurrence) => vestingOccurrence.id}
              name="vestingOccurrence"
              options={VESTING_OCCURRENCE_HELPERS}
            />
          </FormRow.Form>
          {form.vestingOccurrence !== "Once" && (
            <FormRow.Form
              control={form.control}
              label="Vesting duration (in months)"
              name="durationInMonths"
            >
              <MaskedInput.FormNumber
                control={form.control}
                name="durationInMonths"
              />
            </FormRow.Form>
          )}
        </div>
      </FormRowContainer>
      {form.vestingOccurrence !== "Once" && (
        <FormRowContainer title="Cliff period">
          <div className="space-y-6">
            <FormRow.Form
              control={form.control}
              label="Requires a cliff period"
              name="hasCliff"
            >
              <Toggle.Form control={form.control} name="hasCliff" />
            </FormRow.Form>
            {form.hasCliff && (
              <>
                <FormRow.Form
                  control={form.control}
                  label="Cliff duration (in months)"
                  name="cliffDurationInMonths"
                >
                  <MaskedInput.FormNumber
                    control={form.control}
                    name="cliffDurationInMonths"
                  />
                </FormRow.Form>
                <FormRow.Form
                  control={form.control}
                  label="Percentage vested at cliff"
                  name="vestedAtCliffPercentage"
                >
                  <MaskedInput.FormNumber
                    allowDecimal
                    before="%"
                    control={form.control}
                    name="vestedAtCliffPercentage"
                  />
                </FormRow.Form>
              </>
            )}
          </div>
        </FormRowContainer>
      )}
      <FormRowContainer title="Name">
        <FormRow.Form
          control={form.control}
          label="Vesting schedule name (auto generated based on parameters)"
          name="name"
        >
          <Input.Form control={form.control} name="name" />
        </FormRow.Form>
      </FormRowContainer>
    </form>
  );
};

const BackLoadedVestingScheduleFormContent: React.FC<{
  form: BackloadedVestingScheduleForm;
  id: string;
  onSubmit: (data: BackloadedVestingScheduleFormValues) => void;
}> = ({ form, id, onSubmit }) => {
  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <form className="block space-y-10" id={id} onSubmit={handleSubmit}>
      <FormRowContainer title="Time periods">
        <div className="space-y-10">
          <FormRow.Form
            control={form.control}
            label="Vesting will occur"
            name="vestingOccurrence"
          >
            <SelectAutocomplete.Form
              control={form.control}
              getOptionLabel={(vestingOccurrence) => vestingOccurrence.label}
              getOptionValue={(vestingOccurrence) => vestingOccurrence.id}
              name="vestingOccurrence"
              options={BACKLOADED_VESTING_OCCURRENCE_HELPERS}
            />
          </FormRow.Form>

          <FormRow.Form
            control={form.control}
            label="Vesting Schedule Periods"
            name="vestingOccurrence"
          >
            <RoundedBox className="space-y-4 p-6" withBorder>
              <FormRow.Form
                control={form.control}
                name={
                  "periods.root" as FieldPath<BackloadedVestingScheduleFormValues>
                }
              >
                <RoundedBox className="overflow-hidden" withBorder>
                  <Table
                    className="grid grid-cols-[72px_1fr_1fr_108px_84px]"
                    display="grid"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Duration in months</Table.HeaderCell>
                        <Table.HeaderCell>Percentage vested</Table.HeaderCell>
                        <Table.HeaderCell>Cliff</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {form.periodsController.fields.map((field, index) => (
                        <Table.Row key={field.id}>
                          <Table.Cell>
                            <Tag color="gray">{index + 1}</Tag>
                          </Table.Cell>
                          <Table.Cell stretchContent>
                            <FormRow.Form
                              control={form.control}
                              name={`periods.${index}.durationInMonths`}
                            >
                              <MaskedInput.FormNumber
                                control={form.control}
                                name={`periods.${index}.durationInMonths`}
                              />
                            </FormRow.Form>
                          </Table.Cell>
                          <Table.Cell stretchContent>
                            <FormRow.Form
                              control={form.control}
                              name={`periods.${index}.percentageVested`}
                            >
                              <MaskedInput.FormNumber
                                allowDecimal
                                before="%"
                                control={form.control}
                                name={`periods.${index}.percentageVested`}
                              />
                            </FormRow.Form>
                          </Table.Cell>
                          <Table.Cell>
                            {index === 0 && (
                              <div className="flex items-center gap-1">
                                <Toggle.Form
                                  control={form.control}
                                  name="cliffActivatedOnFirstPeriod"
                                  size="small"
                                />
                                <TooltipContainer
                                  tooltipContent={
                                    <div className="text-center">
                                      Use the first period as the cliff period.
                                      It means the percentage of shares become
                                      vested all at once when the first period
                                      duration is over.
                                    </div>
                                  }
                                >
                                  <InformationCircleIcon className="w-5 text-primary" />
                                </TooltipContainer>
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              disabled={index === 0}
                              onClick={() => form.deletePeriod(index)}
                              size="extra small"
                              type="button"
                              variant="Secondary Outline"
                            >
                              Delete
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <Divider />
                  <div className="flex items-center gap-2 bg-gray-01 px-4 py-2 text-black-05">
                    <Typography
                      className="flex-1"
                      variant="Regular/Extra Small"
                    >
                      Total duration:{" "}
                      <span className="font-medium text-black-07">
                        <FormattedNumber
                          style="unit"
                          unit="month"
                          unitDisplay="long"
                          value={form.totalDurationInMonths}
                        />
                      </span>
                    </Typography>
                    {form.totalPercentageVested !== 100 && (
                      <Typography
                        className="text-red"
                        variant="Regular/Caption"
                      >
                        Total must be 100%
                      </Typography>
                    )}
                    <Typography variant="Regular/Extra Small">
                      Total vested:
                    </Typography>
                    <Typography
                      className={classNames("transition-all", {
                        "text-green-05": form.totalPercentageVested === 100,
                        "text-red-05": form.totalPercentageVested !== 100,
                      })}
                      variant="Medium/Extra Small"
                    >
                      <FormattedNumber
                        maximumFractionDigits={2}
                        style="percent"
                        value={form.totalPercentageVested / 100}
                      />
                    </Typography>
                  </div>
                </RoundedBox>
              </FormRow.Form>

              <Button
                leftIcon={<PlusIcon />}
                onClick={() => form.addPeriod()}
                size="small"
                type="button"
                variant="Secondary Outline"
              >
                Add period
              </Button>
            </RoundedBox>
          </FormRow.Form>
        </div>
      </FormRowContainer>

      <FormRowContainer title="Name">
        <FormRow.Form
          control={form.control}
          label="Vesting schedule name (auto generated based on parameters)"
          name="name"
        >
          <Input.Form control={form.control} name="name" />
        </FormRow.Form>
      </FormRowContainer>
    </form>
  );
};
