import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import _, { chain } from "lodash";
import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Percentage } from "../../../../components/Percentage";
import { VerticalDivider } from "../../../../components/ui/Divider";
import { Progress } from "../../../../components/ui/Progress";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tag } from "../../../../components/ui/Tag";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { filterByPropertiesNotNull } from "../../../../helpers/ts-utlity";
import { ExercisesActivityExercisableSharesBoxes_Organization$key } from "./__generated__/ExercisesActivityExercisableSharesBoxes_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesActivityExercisableSharesBoxes_Organization on Organization {
    ctmsGrants(filters: { statusIn: [Active, Terminated] }) {
      edges {
        node {
          grantStatus
          equityTypeAwardName
          earlyExercise
          quantityExercised
          exercisableNonExercisedShares
          cumulativeVested
          quantityIssued
        }
      }
    }
  }
`;

const PROGRESS_STYLE = {
  green: "bg-green-05",
  primary: "bg-primary-05",
  red: "bg-red-05",
} as const;

const Box: React.FC<{
  color: "green" | "primary" | "red";
  tag: string;
  title: string;
  tooltipContent: React.ReactNode;
  total: number;
  totalLabel: React.ReactNode;
  value: number;
  valueLabel: React.ReactNode;
}> = ({
  color,
  tag,
  title,
  tooltipContent,
  total,
  totalLabel,
  value,
  valueLabel,
}) => {
  return (
    <div className="flex-1 space-y-2">
      <div className="flex items-center gap-2">
        <Typography
          as="div"
          className="text-gray-09"
          variant="Medium/Extra Small"
        >
          {title}
        </Typography>
        <TooltipContainer tooltipContent={tooltipContent}>
          <QuestionMarkCircleIcon className="h-4 w-4 shrink-0 cursor-pointer text-primary" />
        </TooltipContainer>
      </div>
      <Tag color={color} size="Small">
        {tag}
      </Tag>
      <Typography as="div" variant="Medium/Default">
        <Percentage value={total ? value / total : 0} />
      </Typography>
      <div className="space-y-1">
        <Typography
          as="div"
          className="flex gap-2 text-gray-09"
          variant="Regular/Caption"
        >
          <span className="flex-1">{valueLabel}</span>
          <span className="flex-1 text-right">{totalLabel}</span>
        </Typography>
        <Progress className="bg-gray-02" max={total}>
          <Progress.Value
            className={classNames(PROGRESS_STYLE[color])}
            value={value}
          />
        </Progress>
      </div>
    </div>
  );
};

export const ExercisesActivityExercisableSharesBoxes: React.FC<{
  organizationFragment: ExercisesActivityExercisableSharesBoxes_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrants = useMemo(
    () => organization.ctmsGrants.edges.map(({ node }) => node),
    [organization.ctmsGrants.edges],
  );

  const [activeNSOGrants, terminatedNSOGrants] = useMemo(
    () =>
      chain(filterByPropertiesNotNull(ctmsGrants, "equityTypeAwardName"))
        .map((grant) => {
          switch (grant.equityTypeAwardName) {
            case "BSPCE":
            case "EMI":
            case "ISO":
            case "NSO":
              return grant;
            default:
              return null;
          }
        })
        .compact()
        .partition((grant) => {
          switch (grant.grantStatus) {
            case "Active":
              return true;
            case "Terminated":
              return false;
            case "Canceled":
              throw new Error("Canceled grants should be filtered by the API");
          }
        })
        .value(),
    [ctmsGrants],
  );

  const totalExercisedSharesForActiveGrants = useMemo(
    () => _(activeNSOGrants).sumBy((grant) => grant.quantityExercised),
    [activeNSOGrants],
  );
  const totalExercisableAndExercisedSharesForActiveGrants = useMemo(
    () =>
      _(activeNSOGrants).sumBy(
        (grant) =>
          grant.quantityExercised + grant.exercisableNonExercisedShares,
      ),
    [activeNSOGrants],
  );

  const totalVestedAndExercisedSharesForTerminatedGrants = useMemo(
    () =>
      _(terminatedNSOGrants).sumBy((grant) =>
        Math.min(grant.quantityExercised, grant.cumulativeVested),
      ),
    [terminatedNSOGrants],
  );
  const totalVestedSharesForTerminatedGrants = useMemo(
    () => _(terminatedNSOGrants).sumBy((grant) => grant.cumulativeVested),
    [terminatedNSOGrants],
  );

  const earlyExercisableActiveGrants = useMemo(
    () =>
      _(activeNSOGrants)
        .filter((grant) => grant.earlyExercise)
        .value(),
    [activeNSOGrants],
  );
  const totalExercisedSharesNotVestedForEarlyExercisableActiveGrants = useMemo(
    () =>
      _(earlyExercisableActiveGrants).sumBy((grant) =>
        Math.max(0, grant.quantityExercised - grant.cumulativeVested),
      ),
    [earlyExercisableActiveGrants],
  );
  const totalSharesNotVestedForEarlyExercisableActiveGrants = useMemo(
    () =>
      _(earlyExercisableActiveGrants).sumBy(
        (grant) => grant.quantityIssued - grant.cumulativeVested,
      ),
    [earlyExercisableActiveGrants],
  );

  return (
    <RoundedBox className="flex gap-6 p-6" withBorder withShadow>
      <Box
        color="green"
        tag="Active grantees"
        title="Ratio exercised/exercisable"
        tooltipContent="This is the total number of options exercised by active grantees divided by the total number of options exercisable by active grantees (i.e. vested options and all early exercisable options)."
        total={totalExercisableAndExercisedSharesForActiveGrants}
        totalLabel={
          <>
            <FormattedNumber
              value={totalExercisableAndExercisedSharesForActiveGrants}
            />{" "}
            options exercisable
          </>
        }
        value={totalExercisedSharesForActiveGrants}
        valueLabel={
          <>
            <FormattedNumber value={totalExercisedSharesForActiveGrants} />{" "}
            options exercised
          </>
        }
      />
      <VerticalDivider />
      <Box
        color="red"
        tag="Terminated grantees"
        title="Ratio exercised/terminated"
        tooltipContent="This is the total number of options exercised by terminated grantees divided by the total number of options exercisable by terminated grantees in the history of the company."
        total={totalVestedSharesForTerminatedGrants}
        totalLabel={
          <>
            <FormattedNumber value={totalVestedSharesForTerminatedGrants} />{" "}
            options exercisable
          </>
        }
        value={totalVestedAndExercisedSharesForTerminatedGrants}
        valueLabel={
          <>
            <FormattedNumber
              value={totalVestedAndExercisedSharesForTerminatedGrants}
            />{" "}
            options exercised
          </>
        }
      />
      <VerticalDivider />
      <Box
        color="primary"
        tag="Early exercisable active grants"
        title="Early exercised options"
        tooltipContent="This is the total number of options early exercised by active grantees divided by the total number of options early exercisable by active grantees (all vested options excluded from the count)."
        total={totalSharesNotVestedForEarlyExercisableActiveGrants}
        totalLabel={
          <>
            <FormattedNumber
              value={totalSharesNotVestedForEarlyExercisableActiveGrants}
            />{" "}
            options early exercisable
          </>
        }
        value={totalExercisedSharesNotVestedForEarlyExercisableActiveGrants}
        valueLabel={
          <>
            <FormattedNumber
              value={
                totalExercisedSharesNotVestedForEarlyExercisableActiveGrants
              }
            />{" "}
            options early exercised
          </>
        }
      />
    </RoundedBox>
  );
};
