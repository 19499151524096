import { FormattedNumber } from "react-intl";

export const Percentage = ({
  maximumFractionDigits = 2,
  signDisplay,
  value,
}: {
  maximumFractionDigits?: 0 | 2 | 4;
  signDisplay?: React.ComponentProps<typeof FormattedNumber>["signDisplay"];
  value: number;
}) => {
  const smallestPercentage = Math.pow(10, -maximumFractionDigits) / 100;
  if (value < smallestPercentage && value > 0 && !signDisplay) {
    return (
      <>
        &lt;
        <FormattedNumber
          maximumFractionDigits={maximumFractionDigits}
          style="percent"
          value={smallestPercentage}
        />
      </>
    );
  }
  return (
    <FormattedNumber
      maximumFractionDigits={maximumFractionDigits}
      signDisplay={signDisplay}
      style="percent"
      value={value}
    />
  );
};
