import {
  Bars3Icon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Pill } from "@remote-com/norma";
import classNames from "classnames";
import { isNil } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useFragment } from "react-relay";
import { NavLink as RouterNavLink, To, useLocation } from "react-router-dom";
import { graphql } from "relay-runtime";

import { NonNullableProperties } from "../../../helpers/ts-utlity";
import { useBoolean } from "../../../hooks/useBoolean";
import { OrganizationRoleSwitcher, Role } from "../../OrganizationRoleSwitcher";
import { Modal } from "../Modal";
import { Tag } from "../Tag";
import { Typography } from "../Typography";
import { ApplicationSideBar_Organization$key } from "./__generated__/ApplicationSideBar_Organization.graphql";
import { ApplicationSideBar_Viewer$key } from "./__generated__/ApplicationSideBar_Viewer.graphql";
import { ApplicationSideBarContent } from "./ApplicationSideBarContent";
import { LogoLink } from "./LogoLink";

const Section: React.FC<{
  children: React.ReactNode;
  title: string;
}> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-[1px]">
      <Typography className="pl-2 text-gray-09" variant="Regular/XS-Caption">
        {title.toUpperCase()}
      </Typography>
      {children}
    </div>
  );
};

const useNavLinkClassNames = () => {
  return useCallback(
    ({
      hoverable = true,
      isActive,
      isSubLink,
    }: {
      hoverable?: boolean;
      isActive: boolean;
      isSubLink: boolean;
    }) =>
      classNames(
        "flex h-9 items-center gap-4 rounded-lg pr-1.5 transition-all active:bg-gray-03",
        {
          "bg-gray-02": isActive,
          "hover:bg-gray-02 hover:text-black-07": hoverable && !isActive,
          "pl-2": !isSubLink,
          "pl-11": isSubLink,
          "text-black-05": !isActive,
        },
      ),
    [],
  );
};

const PaidTag: React.FC = () => <Pill>Paid</Pill>;

const NavLinkContent: React.FC<{
  children: React.ReactNode;
  count: number;
  icon: null | React.ReactElement<React.ComponentProps<"svg">>;
  paid: boolean;
  squareClassName?: string;
  tag: null | React.ReactNode;
}> = ({ children, count, icon, paid, squareClassName, tag }) => {
  return (
    <>
      {icon && (
        <div>
          {React.cloneElement(icon, {
            className: classNames("h-5 w-5", icon.props.className),
            ...icon.props,
          })}
        </div>
      )}
      <div className="flex flex-grow items-center justify-between">
        <div className="flex items-center gap-2">
          {squareClassName && (
            <div
              className={classNames("h-2.5 w-2.5 rounded-sm", squareClassName)}
            />
          )}
          <Typography as="div" variant="Medium/Extra Small">
            {children}
          </Typography>
          {paid && <PaidTag />}
          {tag && <Tag color="purple">{tag}</Tag>}
        </div>
        {count > 0 && (
          <Typography
            as="div"
            className="flex h-4.5 w-4.5 min-w-fit items-center justify-center rounded-sm bg-gray-03 px-0.5 text-black-05"
            variant="Medium/Caption"
          >
            {count}
          </Typography>
        )}
      </div>
    </>
  );
};

const NavLink: React.FC<
  {
    children: React.ReactNode;
    className?: string;
    count?: number;
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
    isSubLink?: boolean;
    paid?: boolean;
    squareClassName?: string;
    tag?: React.ReactNode;
    to?: To;
  } & Omit<
    React.ComponentPropsWithoutRef<typeof RouterNavLink>,
    "children" | "className" | "to"
  >
> = ({
  children,
  className,
  count = 0,
  icon = null,
  isSubLink = false,
  paid = false,
  squareClassName,
  tag = null,
  to,
  ...props
}) => {
  const navLinkClassNames = useNavLinkClassNames();

  if (!to) {
    return (
      <div
        className={classNames(
          navLinkClassNames({ hoverable: false, isActive: false, isSubLink }),
          className,
          "cursor-not-allowed",
        )}
      >
        <NavLinkContent
          count={count}
          icon={icon}
          paid={paid}
          squareClassName={squareClassName}
          tag={tag}
        >
          {children}
        </NavLinkContent>
      </div>
    );
  }

  return (
    <RouterNavLink
      className={({ isActive }) =>
        classNames(navLinkClassNames({ isActive, isSubLink }), className)
      }
      {...props}
      to={to}
    >
      {() => (
        <NavLinkContent
          count={count}
          icon={icon}
          paid={paid}
          squareClassName={squareClassName}
          tag={tag}
        >
          {children}
        </NavLinkContent>
      )}
    </RouterNavLink>
  );
};

const SquaredIconNavLink: React.FC<
  {
    children: React.ReactNode;
    count?: null | number;
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
  } & Omit<
    React.ComponentPropsWithoutRef<typeof RouterNavLink>,
    "children" | "className"
  >
> = ({ children, count = null, icon, ...props }) => {
  return (
    <RouterNavLink
      className={({ isActive }) =>
        classNames(
          "group",
          "flex h-9 items-center gap-4 pl-2 pr-1.5 transition-all",
          "text-black-05 hover:bg-gray-02 hover:text-black-07 focus:bg-gray-03",
          { "bg-gray-02 text-black-07 focus:bg-gray-03": isActive },
          "rounded-xl",
          "w-full",
        )
      }
      {...props}
    >
      {({ isActive }) => (
        <>
          {icon && (
            <div className="h-6 w-6 rounded bg-gray-03">
              {React.cloneElement(icon, {
                className: classNames(
                  "m-auto h-full w-5",
                  {
                    "text-primary": isActive,
                  },
                  icon.props.className,
                ),
                ...icon.props,
              })}
            </div>
          )}
          <div className="flex flex-grow items-center gap-2">
            <Typography as="div" variant="Medium/Extra Small">
              {children}
            </Typography>
            {!isNil(count) && (
              <Typography
                as="div"
                className={classNames(
                  "flex h-4.5 w-4.5 min-w-fit items-center justify-center rounded-sm px-0.5",
                  "bg-gray-03",
                )}
                variant="Medium/Caption"
              >
                {count}
              </Typography>
            )}
          </div>
        </>
      )}
    </RouterNavLink>
  );
};

const NavButton: React.FC<{
  afterIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  children: React.ReactNode;
  icon?: React.ReactElement<React.ComponentProps<"svg">>;
  isSubLink?: boolean;
  loading?: boolean;
  onClick: () => void;
}> = ({ afterIcon, children, icon, isSubLink = false, loading, onClick }) => {
  const navLinkClassNames = useNavLinkClassNames();
  return (
    <button
      className={classNames(navLinkClassNames({ isActive: false, isSubLink }), {
        "animate-pulse bg-gray-02": loading,
      })}
      onClick={onClick}
      type="button"
    >
      {icon && (
        <div>
          {React.cloneElement(icon, {
            className: classNames("h-5 w-5", icon.props.className),
            ...icon.props,
          })}
        </div>
      )}

      <Typography as="div" variant="Medium/Extra Small">
        {children}
      </Typography>

      {afterIcon
        ? React.cloneElement(afterIcon, {
            className: classNames("ml-auto h-4 w-4", afterIcon.props.className),
            ...afterIcon.props,
          })
        : null}
    </button>
  );
};

const FoldableItem: React.FC<{
  children: React.ReactNode;
  className?: string;
  count?: number;
  icon?: React.ReactElement<React.ComponentProps<"svg">>;
  isOpen: boolean;
  label: React.ReactNode;
  onToggle: () => void;
  tag?: React.ReactNode;
}> = ({ children, count = 0, icon, isOpen, label, onToggle, tag }) => {
  return (
    <>
      <button
        className={classNames(
          "flex h-9 items-center gap-4 rounded-xl pl-2 pr-1.5 transition-all",
          {
            "text-black-05 hover:bg-gray-02 hover:text-black-07 active:bg-gray-03":
              !isOpen,
            "text-black-07": isOpen,
          },
        )}
        onClick={onToggle}
        type="button"
      >
        {icon && (
          <div>
            {React.cloneElement(icon, {
              className: classNames("h-5 w-5", icon.props.className),
              ...icon.props,
            })}
          </div>
        )}
        <div className="flex flex-grow items-center gap-2">
          <Typography as="div" variant="Medium/Extra Small">
            {label}
          </Typography>
          {count > 0 && (
            <Typography
              as="div"
              className="flex h-4.5 w-4.5 min-w-fit items-center justify-center rounded-sm bg-gray-03 px-0.5 text-black-05"
              variant="Medium/Caption"
            >
              {count}
            </Typography>
          )}
          {tag && <Tag color="purple">{tag}</Tag>}
        </div>

        <ChevronUpIcon
          className={classNames(
            "w-4 transition-all",
            isOpen ? "" : "-rotate-180",
          )}
        />
      </button>
      {isOpen && children}
    </>
  );
};

const Button: React.FC<{
  children: React.ReactNode;
  icon?: React.ReactElement<React.ComponentProps<"svg">>;
  loading?: boolean;
  onClick: () => void;
}> = ({ children, icon, loading, onClick }) => {
  return (
    <button
      className={classNames(
        "group",
        "flex h-9 items-center gap-4 pl-2 pr-1.5 transition-all",
        "text-black-05 hover:bg-gray-02 hover:text-black-07 focus:bg-gray-03",
        "rounded-xl",
        {
          "animate-pulse bg-gray-02": loading,
        },
        "w-full",
      )}
      onClick={onClick}
      type="button"
    >
      {icon && (
        <div className="h-6 w-6 rounded bg-gray-03">
          {React.cloneElement(icon, {
            className: classNames("m-auto h-full w-5", icon.props.className),
            ...icon.props,
          })}
        </div>
      )}

      <Typography as="div" variant="Medium/Extra Small">
        {children}
      </Typography>
    </button>
  );
};

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex h-full flex-col bg-white md:flex-row">{children}</div>
);

const VIEWER_FRAGMENT = graphql`
  fragment ApplicationSideBar_Viewer on Account {
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ApplicationSideBarContent_Viewer
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...OrganizationRoleSwitcher_Account
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApplicationSideBar_Organization on Organization {
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ApplicationSideBarContent_Organization
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...OrganizationRoleSwitcher_Organization
  }
`;

const ApplicationSideBar_: React.FC<
  React.PropsWithChildren<{
    activeRole: null | Role;
    bottomFixedContent?: React.ReactNode;
    className?: string;
    homePath?: React.ComponentProps<typeof NavLink>["to"];
    organizationFragment: ApplicationSideBar_Organization$key | null;
    showLogo?: boolean;
    viewerFragment: ApplicationSideBar_Viewer$key;
  }>
> = ({
  activeRole,
  bottomFixedContent,
  children,
  className,
  homePath,
  organizationFragment,
  viewerFragment,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const organization =
    useFragment(ORGANIZATION_FRAGMENT, organizationFragment) ?? null;

  const {
    setFalse: hideMobileNavigationModal,
    setTrue: showMobileNavigationModal,
    value: mobileNavigationModalIsShown,
  } = useBoolean(false);

  const location = useLocation();

  // Needed to close modal when clicking on a link
  useEffect(() => {
    hideMobileNavigationModal();
  }, [hideMobileNavigationModal, location]);

  return (
    <>
      <Modal.Raw
        onClose={hideMobileNavigationModal}
        panelClassName="w-screen h-screen overflow-y-auto py-6 px-4"
        show={mobileNavigationModalIsShown}
        suspense
      >
        <div className="flex items-center gap-4 !pb-0">
          <div className="flex flex-grow items-center gap-4">
            <LogoLink homePath={homePath} />
            {organization && activeRole && (
              <OrganizationRoleSwitcher
                accountFragment={viewer}
                activeRole={activeRole}
                organizationFragment={organization}
                placement="bottom"
              />
            )}
          </div>
          <button
            className="h-6 w-6 self-start"
            onClick={() => hideMobileNavigationModal()}
          >
            <XMarkIcon className="text-black-07" />
          </button>
        </div>
        <ApplicationSideBarContent
          activeRole={activeRole}
          className={classNames(className, "h-auto w-full px-0 shadow-none")}
          homePath={homePath}
          organizationFragment={organization}
          showHeader={false}
          viewerFragment={viewer}
        >
          {children}
        </ApplicationSideBarContent>
      </Modal.Raw>
      <div className="z-0 flex justify-between bg-white px-4 py-5 shadow-100 md:hidden">
        <LogoLink homePath={homePath} />
        <button onClick={showMobileNavigationModal} type="button">
          <Bars3Icon className="h-6 w-6" />
        </button>
      </div>
      <div className="flex flex-col bg-gray-01 md:h-screen">
        <ApplicationSideBarContent
          activeRole={activeRole}
          className={classNames(
            className,
            "hidden w-[240px] flex-grow px-4 md:flex",
          )}
          homePath={homePath}
          organizationFragment={organization}
          viewerFragment={viewer}
        >
          {children}
        </ApplicationSideBarContent>
        {bottomFixedContent && (
          <div className="hidden shrink-0 p-4 md:block">
            {bottomFixedContent}
          </div>
        )}
      </div>
    </>
  );
};

const SQUARE_NAV_LINK_VARIANTS = ({ isActive }: { isActive: boolean }) => ({
  default: classNames("text-gray-09 hover:bg-gray-02", {
    "bg-gray-02": isActive,
  }),
  orange: classNames("text-black-05 hover:bg-orange-02", {
    "bg-orange-02": isActive,
  }),
});

const SquareNavLink: React.FC<
  {
    children: string;
    className?: string;
    count?: number;
    squareClassName?: string;
    variant?: keyof ReturnType<typeof SQUARE_NAV_LINK_VARIANTS>;
  } & Omit<
    NonNullableProperties<React.ComponentPropsWithoutRef<typeof NavLink>, "to">,
    "children" | "className"
  >
> = ({
  children,
  className,
  count = 0,
  squareClassName,
  variant = "default",
  ...props
}) => (
  <RouterNavLink
    className={({ isActive }) =>
      classNames(
        "relative flex w-full items-center gap-4 rounded py-1.5 pl-3.5 pr-1.5 transition-all",
        SQUARE_NAV_LINK_VARIANTS({ isActive })[variant],
        className,
      )
    }
    {...props}
  >
    <div className="flex min-w-0 flex-1 items-center gap-2">
      <div className={classNames("h-2.5 w-2.5 rounded-sm", squareClassName)} />
      <Typography
        as="div"
        className="truncate"
        title={children}
        variant="Medium/Extra Small"
      >
        {children}
      </Typography>
    </div>
    {count > 0 && (
      <Typography
        as="div"
        className="flex h-4.5 w-4.5 min-w-fit items-center justify-center rounded-sm bg-gray-03 px-0.5 text-black-05"
        variant="Medium/Caption"
      >
        {count}
      </Typography>
    )}
  </RouterNavLink>
);

export const ApplicationSideBar = Object.assign(ApplicationSideBar_, {
  Button,
  FoldableItem,
  Layout,
  NavButton,
  NavLink,
  Section,
  SquaredIconNavLink,
  SquareNavLink,
});
