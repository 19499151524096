import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { LargeCenteredDetailsLayout } from "../../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { AddNew_BackloadedVestingSchedules_Mutation } from "./__generated__/AddNew_BackloadedVestingSchedules_Mutation.graphql";
import { AddNew_LinearVestingSchedules_Mutation } from "./__generated__/AddNew_LinearVestingSchedules_Mutation.graphql";
import { AddNew_VestingSchedules_Query } from "./__generated__/AddNew_VestingSchedules_Query.graphql";
import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
  VestingScheduleFormType,
} from "./FORM_SCHEMA";
import {
  useBackloadedVestingScheduleForm,
  useLinearVestingScheduleForm,
} from "./useVestingScheduleForm";
import { VestingScheduleForm } from "./VestingScheduleForm";
import { VestingSchedulePreview } from "./VestingSchedulePreview";

const QUERY = graphql`
  query AddNew_VestingSchedules_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      ...LargeCenteredDetailsLayout_Organization
      ...useVestingScheduleForm_Organization
    }
  }
`;

const CREATE_LINEAR_VESTING_SCHEDULE_MUTATION = graphql`
  mutation AddNew_LinearVestingSchedules_Mutation(
    $attributes: LinearVestingScheduleAttributes!
    $organizationId: OrganizationId!
  ) {
    createLinearVestingSchedule(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      __typename
    }
  }
`;

const CREATE_BACKLOADED_VESTING_SCHEDULE_MUTATION = graphql`
  mutation AddNew_BackloadedVestingSchedules_Mutation(
    $attributes: BackloadedVestingScheduleAttributes!
    $organizationId: OrganizationId!
  ) {
    createBackloadedVestingSchedule(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      __typename
    }
  }
`;

export default function AddNew() {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<AddNew_VestingSchedules_Query>(QUERY, {
    organizationId,
  });
  const navigate = useNavigate();

  const [
    triggerCreateLinearVestingScheduleMutation,
    linearVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<AddNew_LinearVestingSchedules_Mutation>(
    CREATE_LINEAR_VESTING_SCHEDULE_MUTATION,
  );

  const [
    triggerCreateBackloadedVestingScheduleMutation,
    backloadedVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<AddNew_BackloadedVestingSchedules_Mutation>(
    CREATE_BACKLOADED_VESTING_SCHEDULE_MUTATION,
  );

  const parentLink = generatePath(
    APPLICATION_ROUTES["organizationEquityConfigureVestingSchedules"],
    {
      organizationId,
    },
  );

  const linearVestingScheduleForm = useLinearVestingScheduleForm({
    currentVestingScheduleId: null,
    organizationFragment: query.organization,
  });

  const handleLinearVestingScheduleFormSubmit = async (
    values: LinearVestingScheduleFormValues,
  ) => {
    await triggerCreateLinearVestingScheduleMutation({
      variables: {
        attributes: {
          cliffDurationInMonths: values.hasCliff
            ? (values.cliffDurationInMonths ?? 0)
            : 0,
          durationInMonths: values.durationInMonths,
          name: values.name,
          vestedAtCliffPercentage: values.hasCliff
            ? (values.vestedAtCliffPercentage ?? 0)
            : 0,
          vestingOccurrence: values.vestingOccurrence,
        },
        organizationId,
      },
    });

    navigate(parentLink, {
      replace: true,
    });
  };

  const backloadedVestingScheduleForm = useBackloadedVestingScheduleForm({
    currentVestingScheduleId: null,
    organizationFragment: query.organization,
  });

  const handleBackloadedVestingScheduleFormSubmit = async (
    values: BackloadedVestingScheduleFormValues,
  ) => {
    await triggerCreateBackloadedVestingScheduleMutation({
      variables: {
        attributes: {
          cliffActivatedOnFirstPeriod: values.cliffActivatedOnFirstPeriod,
          name: values.name,
          periods: values.periods,
          vestingOccurrence: values.vestingOccurrence,
        },
        organizationId,
      },
    });

    navigate(parentLink, {
      replace: true,
    });
  };

  const [vestingScheduleType, setVestingScheduleType] =
    useState<VestingScheduleFormType>("LINEAR");
  const validatedLiveValues = useMemo(() => {
    switch (vestingScheduleType) {
      case "BACKLOADED":
        return backloadedVestingScheduleForm.validatedLiveValues;
      case "LINEAR":
        return linearVestingScheduleForm.validatedLiveValues;
    }
  }, [
    vestingScheduleType,
    backloadedVestingScheduleForm.validatedLiveValues,
    linearVestingScheduleForm.validatedLiveValues,
  ]);

  return (
    <Page
      analyticsCategory="Admin Configure Vesting Schedule"
      analyticsName="Admin - Equity - Configure - Vesting Schedule - Add New"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} equity settings | add new vesting schedule`}
    >
      <LargeCenteredDetailsLayout
        organizationFragment={query.organization}
        parents={[
          {
            link: parentLink,
            title: "Vesting schedules",
          },
        ]}
        subtitle="Define the vesting parameters below"
        title="Add new vesting schedule"
      >
        <div className="space-y-10">
          <VestingScheduleForm
            backloadedVestingScheduleForm={backloadedVestingScheduleForm}
            id="create-vesting-schedule-form"
            linearVestingScheduleForm={linearVestingScheduleForm}
            onBackloadedVestingScheduleFormSubmit={
              handleBackloadedVestingScheduleFormSubmit
            }
            onLinearVestingScheduleFormSubmit={
              handleLinearVestingScheduleFormSubmit
            }
            onTypeChange={setVestingScheduleType}
            type={vestingScheduleType}
          />
          <div className="flex items-center gap-2">
            <Button
              form="create-vesting-schedule-form"
              loading={
                linearVestingScheduleMutationIsInFlight ||
                backloadedVestingScheduleMutationIsInFlight
              }
              size="small"
              type="submit"
            >
              Create vesting schedule
            </Button>
            <LinkButton size="small" to={parentLink} variant="Secondary Full">
              Cancel
            </LinkButton>
          </div>
          {validatedLiveValues && (
            <VestingSchedulePreview
              type={vestingScheduleType}
              values={validatedLiveValues}
            />
          )}
        </div>
      </LargeCenteredDetailsLayout>
    </Page>
  );
}
