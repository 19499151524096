import classNames from "classnames";

import { Typography } from "./Typography";

const Chip: React.FC<Omit<React.ComponentProps<"div">, "children">> = ({
  className,
  ...props
}) => (
  <div className={classNames("h-2.5 w-2.5 rounded-sm", className)} {...props} />
);

const _Legend: React.FC<
  { chip: React.ReactElement<typeof Chip> } & React.ComponentProps<"div">
> = ({ children, chip, className, ...props }) => (
  <div className={classNames("flex items-center gap-2", className)} {...props}>
    {chip}
    <Typography className="text-black-05" variant="Regular/Extra Small">
      {children}
    </Typography>
  </div>
);

export const Legend = Object.assign(_Legend, { Chip });
