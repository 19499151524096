import classNames from "classnames";
import React from "react";
import { FormattedNumber } from "react-intl";

import { SingleValueProgress } from "./SingleValueProgress";
import { Typography } from "./Typography";

type Variant = "primary" | "red";
type Size = "medium" | "small";
const VARIANTS: Record<
  Variant,
  {
    progressVariant: React.ComponentProps<
      typeof SingleValueProgress
    >["variant"];
    text: string;
  }
> = {
  primary: {
    progressVariant: "primary",
    text: /* tailwind */ "text-primary",
  },
  red: { progressVariant: "full-red", text: /* tailwind */ "text-red" },
};

const SIZES: Record<
  Size,
  {
    className: string;
  }
> = {
  medium: { className: /* tailwind */ "w-[144px]" },
  small: { className: /* tailwind */ "w-[104px]" },
};

export const VestedProgress: React.FC<{
  className?: string;
  grantedShares: number;
  size?: Size;
  variant?: Variant;
  vestedShares: number;
  vestedSharesClassName?: string;
}> = ({
  className,
  grantedShares,
  size = "medium",
  variant = "primary",
  vestedShares,
  vestedSharesClassName,
}) => (
  <div className={classNames("flex flex-col gap-2 text-right", className)}>
    <Typography variant="Regular/Extra Small">
      <Typography
        className={classNames(VARIANTS[variant].text, vestedSharesClassName)}
        variant="Medium/Extra Small"
      >
        <FormattedNumber value={vestedShares} /> shares
      </Typography>{" "}
      out of <br /> <FormattedNumber value={grantedShares} /> shares
    </Typography>
    <SingleValueProgress
      className={classNames("relative h-2", SIZES[size].className)}
      current={vestedShares}
      max={grantedShares}
      variant={VARIANTS[variant].progressVariant}
    />
  </div>
);
