import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CompactFormattedCurrency } from "../../../../components/Formatted/CompactFormattedCurrency";
import {
  GranteesListSlideOver,
  useGranteesListSlideOverState,
} from "../../../../components/GranteesListSlideOver";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { ScatterGraph } from "../../../../components/ScatterGraph";
import { Typography } from "../../../../components/ui/Typography";
import { TeamIncentivizationGraph_Organization$key } from "./__generated__/TeamIncentivizationGraph_Organization.graphql";
import {
  TeamIncentivizationGraph_TeamIncentivizationDataPoint$data,
  TeamIncentivizationGraph_TeamIncentivizationDataPoint$key,
} from "./__generated__/TeamIncentivizationGraph_TeamIncentivizationDataPoint.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment TeamIncentivizationGraph_Organization on Organization {
    ...GranteesListSlideOver_Organization
  }
`;

const DATA_POINTS_FRAGMENT = graphql`
  fragment TeamIncentivizationGraph_TeamIncentivizationDataPoint on TeamIncentivizationDataPoint
  @relay(plural: true) {
    grantees {
      id
      name
    }
    grantsCount
    valueInUSD
  }
`;

export const TeamIncentivizationGraphModes = [
  "TO_VEST",
  "VESTED",
  "TOTAL_GRANTED",
] as const;
export type TeamIncentivizationGraphMode =
  (typeof TeamIncentivizationGraphModes)[number];

const ModeLabelMap: Record<TeamIncentivizationGraphMode, string> = {
  TO_VEST: "value to vest",
  TOTAL_GRANTED: "total value granted",
  VESTED: "value vested",
};

const Tooltip: React.FC<{
  grantees: TeamIncentivizationGraph_TeamIncentivizationDataPoint$data[number]["grantees"];
  grantsCount: number;
  mode: TeamIncentivizationGraphMode;
  valueInUSD: number;
}> = ({ grantees, grantsCount, mode, valueInUSD }) => {
  return (
    <GraphTooltip
      bottomContent={
        <FormattedMessage
          defaultMessage="Click on the data point to see the {count, plural, one {grantee} other {list of grantees}}"
          values={{ count: grantees.length }}
        />
      }
      topContent={
        valueInUSD ? (
          <FormattedMessage
            defaultMessage={`{valueInUSD} of {vestingMode}`}
            values={{
              valueInUSD: (
                <CompactFormattedCurrency
                  minimumFractionDigits={0}
                  value={valueInUSD}
                />
              ),
              vestingMode: ModeLabelMap[mode],
            }}
          />
        ) : null
      }
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className="h-2 w-2 rounded-full bg-purple-05" />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Regular/Extra Small">
            <FormattedMessage
              defaultMessage="{count, plural, =0 {# grant} one {# grant} other {# grants}} received"
              values={{ count: grantsCount }}
            />
          </Typography>
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage="{count, plural, one {# grantee} other {# grantees}}"
              values={{ count: grantees.length }}
            />
          </Typography>
          {grantees.length <= 5 && (
            <ul className="list-disc pl-5">
              {grantees.map(({ id, name }) => (
                <li key={id}>
                  <Typography variant="Regular/Extra Small">{name}</Typography>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </GraphTooltip>
  );
};

export const TeamIncentivizationGraph: React.FC<{
  dataPointsFragment: TeamIncentivizationGraph_TeamIncentivizationDataPoint$key;
  mode: TeamIncentivizationGraphMode;
  organizationFragment: TeamIncentivizationGraph_Organization$key;
}> = ({ dataPointsFragment, mode, organizationFragment }) => {
  const intl = useIntl();

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const dataPoints = useFragment(DATA_POINTS_FRAGMENT, dataPointsFragment);

  const data = useMemo(
    () =>
      dataPoints.map((dataPoint) => ({
        grantees: dataPoint.grantees,
        x: dataPoint.valueInUSD,
        y: dataPoint.grantsCount,
        z: dataPoint.grantees.length,
      })),
    [dataPoints],
  );

  const {
    close: closeGranteesListSlideOver,
    open: openGranteesListSlideOver,
    state: granteesListSlideOverState,
  } = useGranteesListSlideOverState();

  return (
    <>
      <GranteesListSlideOver
        onClose={closeGranteesListSlideOver}
        organizationFragment={organization}
        state={granteesListSlideOverState}
      />
      <ScatterGraph
        dataPoints={data}
        onCellClick={({ grantees, x: valueInUSD, z: granteesCount }) =>
          openGranteesListSlideOver({
            granteesIds: grantees.map(({ id }) => id),
            title: (
              <FormattedMessage
                defaultMessage={`There {granteesCount, plural, one {is # grantee} other {are # grantees}} having {valueInUSD} of {vestingMode}`}
                values={{
                  granteesCount,
                  valueInUSD: (
                    <CompactFormattedCurrency
                      minimumFractionDigits={0}
                      value={valueInUSD}
                    />
                  ),
                  vestingMode: ModeLabelMap[mode],
                }}
              />
            ),
          })
        }
        renderTooltip={({ grantees, x: valueInUSD, y: grantsCount }) => (
          <Tooltip
            grantees={grantees}
            grantsCount={grantsCount}
            mode={mode}
            valueInUSD={valueInUSD}
          />
        )}
        xLabel="$ Value"
        xTickFormatter={(value) =>
          intl.formatNumber(value, {
            compactDisplay: "short",
            currency: "USD",
            maximumFractionDigits: 0,
            notation: "compact",
            style: "currency",
          })
        }
        yLabel="# Grants"
        yTickFormatter={(value) => intl.formatNumber(value)}
      />
    </>
  );
};
