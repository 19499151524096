import { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { DeleteOrganizationButton_Mutation } from "./__generated__/DeleteOrganizationButton_Mutation.graphql";
import { DeleteOrganizationButton_Organization$key } from "./__generated__/DeleteOrganizationButton_Organization.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const DELETE_ORGANIZATION_MUTATION = graphql`
  mutation DeleteOrganizationButton_Mutation($organizationId: OrganizationId!) {
    deleteOrganization(id: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment DeleteOrganizationButton_Organization on Organization {
    id
    name
  }
`;

export const DeleteOrganizationButton: React.FC<{
  organizationFragment: DeleteOrganizationButton_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const navigate = useNavigate();

  const {
    setFalse: closeDeleteConfirmationModal,
    setTrue: showDeleteConfirmationModal,
    value: deleteConfirmationModalIsShown,
  } = useBoolean(false);

  const [_deleteOrganization, organizationDeletionIsInFlight] =
    useSafeMutation<DeleteOrganizationButton_Mutation>(
      DELETE_ORGANIZATION_MUTATION,
    );

  const toaster = useToaster();

  const deleteOrganization = useCallback(async () => {
    await _deleteOrganization({
      variables: { organizationId: organization.id },
    });
    toaster.push(
      <Toast title="Alright!">
        {organization.name} successfully deleted! 👋
      </Toast>,
    );
    navigate(generatePath(APPLICATION_ROUTES["roleSelection"]));
  }, [_deleteOrganization, organization, navigate, toaster]);
  return (
    <>
      <ConfirmationModal
        confirmationLabel="Delete"
        loading={organizationDeletionIsInFlight}
        onClose={closeDeleteConfirmationModal}
        onConfirmed={deleteOrganization}
        show={deleteConfirmationModalIsShown}
        title="Delete organization"
      >
        Are you sure you want to delete {organization.name}?
      </ConfirmationModal>
      <Button
        onClick={showDeleteConfirmationModal}
        size="small"
        type="button"
        variant="Danger Full"
      >
        Delete organization
      </Button>
    </>
  );
};
