import { Button } from "@remote-com/norma";
import React, { useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import { useSearchParams } from "react-router-dom";

import { AngelListLogo } from "../../../components/ui/AngelListLogo";
import { CartaLogo } from "../../../components/ui/CartaLogo";
import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import { PulleyLogo } from "../../../components/ui/PulleyLogo";
import { useBoolean } from "../../../hooks/useBoolean";
import { useGenerateOrganizationCartaAuthorizationFlowURL } from "../../../hooks/useGenerateOrganizationCartaAuthorizationFlowURL";
import { useTrackRemoteEquityOnboardingFlowEvent } from "../../../hooks/useTrackRemoteEquityOnboardingFlowEvent";
import { ConnectCTMSView_Organization$key } from "./__generated__/ConnectCTMSView_Organization.graphql";
import { ConnectCTMSView_Viewer$key } from "./__generated__/ConnectCTMSView_Viewer.graphql";
import { ContactUsModal } from "./ContactUsModal";
import { CartaErrorCard } from "./NoticeCard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConnectCTMSView_Organization on Organization {
    id
    name
    ...ContactUsModal_Organization
    ...useTrackRemoteEquityOnboardingFlowEvent_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ConnectCTMSView_Viewer on Account {
    ...ContactUsModal_Viewer
  }
`;

const CTMSCard: React.FC<{
  action: React.ReactNode;
  images: React.ReactNode | React.ReactNode[];
  subtitle: React.ReactNode;
  title: React.ReactNode;
}> = ({ action, images: _images, subtitle, title }) => {
  const images = React.Children.toArray(_images);

  return (
    <div className="flex flex-col items-center gap-8 rounded-remote-md bg-remote-white p-10">
      <div className="flex items-center gap-2">
        {images.map((image, index) => (
          <div
            className="flex h-14 items-center justify-center rounded-remote-full bg-remote-grey-50 px-6"
            key={index}
          >
            {image}
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center gap-2 text-center">
        <div className="text-remote-Base/Medium text-black-09">{title}</div>
        <div className="px-2 text-remote-SM/Normal text-remote-grey-700">
          {subtitle}
        </div>
      </div>
      <div className="flex flex-col items-center">{action}</div>
    </div>
  );
};

export const ConnectCTMSView: React.FC<{
  organizationFragment: ConnectCTMSView_Organization$key;
  viewerFragment: ConnectCTMSView_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const [searchParams] = useSearchParams();

  const errorSearchParam = searchParams.get("error");

  const trackEvent = useTrackRemoteEquityOnboardingFlowEvent({
    organizationFragment: organization,
  });

  const {
    generateOrganizationCartaAuthorizationFlowURL,
    mutationIsInFlight: logInToCartaMutationIsInFlight,
  } = useGenerateOrganizationCartaAuthorizationFlowURL({
    doNotConnectIfMultipleIssuersSelected: true,
    organizationId: organization.id,
    returnURL: window.location.href,
  });

  const handleLogInToCartaButtonClick = async () => {
    trackEvent("Carta connection required");
    const url = await generateOrganizationCartaAuthorizationFlowURL();

    window.location.href = url;
  };

  const {
    setFalse: closeContactUsModal,
    setTrue: showContactUsModal,
    value: contactUsModalIsShown,
  } = useBoolean(false);

  const onContactUsClicked = useCallback(() => {
    trackEvent("Other CTMS required");
    showContactUsModal();
  }, [showContactUsModal, trackEvent]);

  return (
    <RemoteEquityOnboardingLayout
      subtitle={
        <div className="max-w-[433px]">
          Get started with Remote Equity by connecting to your cap table
          management solution.
        </div>
      }
      title={
        <div className="max-w-[518px]">
          Connect your cap table management solution (CTMS)
        </div>
      }
    >
      <ContactUsModal
        onClose={closeContactUsModal}
        organizationFragment={organization}
        show={contactUsModalIsShown}
        viewerFragment={viewer}
      />
      <div className="grid grid-cols-[1fr] gap-6 md:grid-cols-[364px_364px]">
        {errorSearchParam && (
          <div className="col-span-full">
            <CartaErrorCard
              error={errorSearchParam}
              organizationName={organization.name}
            />
          </div>
        )}
        <CTMSCard
          action={
            <Button
              isLoading={logInToCartaMutationIsInFlight}
              onClick={handleLogInToCartaButtonClick}
              size="sm"
            >
              Log in to Carta
            </Button>
          }
          images={<CartaLogo className="h-6" />}
          subtitle="Sync your data on Carta to Remote Equity."
          title="Connect to Carta"
        />
        <CTMSCard
          action={
            <Button
              onClick={onContactUsClicked}
              size="sm"
              tone="secondary"
              variant="outline"
            >
              Contact us
            </Button>
          }
          images={[
            <PulleyLogo className="h-7" key="pulley" />,
            <AngelListLogo className="h-4" key="angel" />,
          ]}
          subtitle="One of our equity specialists can help get you set up with Remote Equity."
          title="Connect to other CTMS"
        />
      </div>
    </RemoteEquityOnboardingLayout>
  );
};
