import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FolderAdd } from "iconsax-react";
import { uniqueId } from "lodash";
import { useCallback, useId, useMemo, useRef, useState } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { FormattedList, FormattedNumber } from "react-intl";
import { useMutation } from "react-relay";
import { useDropArea } from "react-use";
import { graphql } from "relay-runtime";

import { RoundedBox } from "../RoundedBox";
import { Typography } from "../Typography";
import { FileInput_CreateDocument_Mutation } from "./__generated__/FileInput_CreateDocument_Mutation.graphql";

const CREATE_DOCUMENT_MUTATION = graphql`
  mutation FileInput_CreateDocument_Mutation(
    $contentLength: Int!
    $contentType: String!
    $originalFilename: String!
  ) {
    createDocument(
      contentLength: $contentLength
      contentType: $contentType
      originalFilename: $originalFilename
    ) {
      document {
        id
      }
      uploadUrl
      expectedContentDisposition
    }
  }
`;

export interface Item {
  documentId?: string;
  fileName: string;
  id: string;
  size: number;
  uploading: boolean;
}

export function useFileInputUploadController({
  accept,
  acceptedExtensions,
  id,
  initialState = [],
  multiple,
  name,
  onItemsChange,
}: {
  accept?: string;
  acceptedExtensions?: string[];
  id?: string;
  initialState?: Item[];
  multiple?: boolean;
  name?: string;
  onItemsChange?: (items: Item[]) => void;
} = {}) {
  const [items, _setItems] = useState(initialState);

  const setItems = useCallback(
    (updater: (previousItems: Item[]) => Item[]) => {
      _setItems((previousItems) => {
        const nextItems = updater(previousItems);

        onItemsChange?.(nextItems);

        return nextItems;
      });
    },
    [onItemsChange],
  );

  const [triggerCreateDocumentMutation] =
    useMutation<FileInput_CreateDocument_Mutation>(CREATE_DOCUMENT_MUTATION);

  const someItemsAreUploading = useMemo(() => {
    return items.some((item) => item.uploading);
  }, [items]);

  const activeRequests = useRef<Set<XMLHttpRequest>>(new Set());

  const handleFilesAdded = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        const id = uniqueId();

        setItems((previousItems) => {
          return [
            ...previousItems,
            {
              fileName: file.name,
              id,
              size: file.size,
              uploading: true,
            },
          ];
        });

        triggerCreateDocumentMutation({
          onCompleted: (response) => {
            setItems((previousItems) => {
              return previousItems.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    documentId: response.createDocument.document.id,
                  };
                }

                return item;
              });
            });

            const xhr = new XMLHttpRequest();

            xhr.upload.onload = () => {
              setItems((previousItems) => {
                return previousItems.map((item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      uploading: false,
                    };
                  }

                  return item;
                });
              });

              activeRequests.current.delete(xhr);
            };

            xhr.upload.onloadstart = () => {
              setItems((previousItems) => {
                return previousItems.map((item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      uploading: true,
                    };
                  }

                  return item;
                });
              });
            };

            xhr.open("PUT", response.createDocument.uploadUrl, true);

            xhr.setRequestHeader(
              "Content-Disposition",
              response.createDocument.expectedContentDisposition,
            );

            xhr.send(file);

            activeRequests.current.add(xhr);
          },
          variables: {
            contentLength: file.size,
            contentType: file.type,
            originalFilename: file.name,
          },
        });
      });
    },
    [setItems, triggerCreateDocumentMutation],
  );

  const handleItemRemoved = useCallback(
    (itemId: string) => {
      setItems((previousItems) => {
        return previousItems.filter((item) => item.id !== itemId);
      });
    },
    [setItems],
  );

  const someItemsAreNotReady = useMemo(() => {
    return someItemsAreUploading || items.some((item) => !item.documentId);
  }, [items, someItemsAreUploading]);

  const documentIds = useMemo(() => {
    return items.map((item) => item.documentId).filter(Boolean) as string[];
  }, [items]);

  const reset = useCallback(() => {
    activeRequests.current.forEach((xhr) => {
      xhr.abort();
    });

    setItems(() => []);
  }, [setItems]);

  return useMemo(() => {
    return {
      documentIds,
      inputProps: {
        accept,
        acceptedExtensions,
        id,
        items,
        multiple,
        name,
        onFilesAdded: handleFilesAdded,
        onItemRemoved: handleItemRemoved,
      },
      reset,
      someItemsAreNotReady,
    };
  }, [
    accept,
    acceptedExtensions,
    documentIds,
    handleFilesAdded,
    handleItemRemoved,
    id,
    items,
    multiple,
    name,
    reset,
    someItemsAreNotReady,
  ]);
}

export function useFileInputFormUploadController<
  TFieldValues extends FieldValues,
>({
  control,
  name,
  ...props
}: {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;

  accept?: string;
  acceptedExtensions?: string[];
  initialState?: Item[];
  multiple?: boolean;
}) {
  const controller = useController({
    control,
    name,
  });

  return useFileInputUploadController({
    id: controller.field.name,
    name: controller.field.name,
    onItemsChange: useCallback(
      (items: Item[]) => {
        if (props.multiple) {
          controller.field.onChange(items.map((item) => item.documentId));
        } else {
          controller.field.onChange(items[0]?.documentId);
        }
      },
      [controller.field, props.multiple],
    ),
    ...props,
  });
}

export function FileInput({
  accept,
  acceptedExtensions,
  id: _id,
  items,
  multiple,
  name,
  onFilesAdded,
  onItemRemoved,
}: {
  accept?: string;
  acceptedExtensions?: string[];
  id?: string;
  items: readonly Item[];
  multiple?: boolean;
  name?: string;
  onFilesAdded: (files: File[]) => void;
  onItemRemoved: (itemId: string) => void;
}) {
  const uniqueId = useId();
  const id = _id ?? uniqueId;
  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files ?? []);

      onFilesAdded(files);
    },
    [onFilesAdded],
  );

  const [bond] = useDropArea({
    onFiles: (files) => {
      onFilesAdded(files);
    },
  });

  return (
    <div className="space-y-6">
      <div>
        <input
          accept={accept}
          className="sr-only"
          id={id}
          multiple={multiple}
          name={name}
          onChange={handleFileInputChange}
          type="file"
        />
        <label
          className={classNames(
            "group block cursor-pointer rounded-lg border-[0.5px] border-dashed border-gray-09 px-3 py-6 text-center text-white",
          )}
          htmlFor={id}
          {...bond}
        >
          <div className="space-y-2 transition-all group-hover:scale-[1.02]">
            <FolderAdd
              className="mx-auto h-6 w-6 text-primary"
              variant="Bulk"
            />
            <Typography
              as="div"
              className="text-gray-08"
              variant="Regular/Extra Small"
            >
              <Typography
                className="text-black-05"
                variant="Medium/Extra Small"
              >
                Drag & drop
              </Typography>{" "}
              or{" "}
              <Typography className="text-primary" variant="Medium/Extra Small">
                Choose a file
              </Typography>{" "}
              to upload
            </Typography>
            {acceptedExtensions && (
              <Typography
                as="div"
                className="rounded-lg bg-gray-02 px-2 py-1 text-gray-09"
                variant="Regular/Extra Small"
              >
                <FormattedList type="disjunction" value={acceptedExtensions} />{" "}
                files
              </Typography>
            )}
          </div>
        </label>
      </div>
      {items.length > 0 && (
        <div className="space-y-2">
          {items.map((item) => (
            <RoundedBox
              background="gray"
              key={item.id}
              loading={item.uploading}
              rounded="4px"
            >
              <div className="space-y-2 px-3 py-4">
                <div className="flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <FolderAdd
                      className="h-6 w-6 text-primary"
                      variant="Bulk"
                    />
                    <div className="space-y-2">
                      <Typography
                        className="text-black-05"
                        variant="Medium/Extra Small"
                      >
                        {item.fileName}
                      </Typography>
                      <Typography
                        as="div"
                        className="text-gray-09"
                        variant="Regular/Extra Small"
                      >
                        <FormattedNumber
                          maximumFractionDigits={0}
                          style="unit"
                          unit="kilobyte"
                          unitDisplay="narrow"
                          value={item.size / 1000}
                        />
                      </Typography>
                    </div>
                  </div>
                  <div className="space-y-2 text-right">
                    <div>
                      <button
                        className="p-1"
                        onClick={() => {
                          onItemRemoved(item.id);
                        }}
                      >
                        <XMarkIcon className="w-4" />
                      </button>
                    </div>
                    <Typography
                      as="div"
                      className="text-gray-09"
                      variant="Regular/Extra Small"
                    >
                      {item.uploading ? "Uploading..." : "Uploaded"}
                    </Typography>
                  </div>
                </div>
              </div>
            </RoundedBox>
          ))}
        </div>
      )}
    </div>
  );
}
