/**
 * @generated SignedSource<<b56b49011d23196bb8b7a2f2466f0bb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_PENDING_BOARD_APPROVAL";
import { FragmentRefs } from "relay-runtime";
export type AdminUpcomingCard_Organization$data = {
  readonly hasCTMSGrantsInTheirLastExerciseDays: boolean;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly country: {
      readonly code: string;
    };
    readonly taxFavoredSubplanExpirationDate: string | null;
  }>;
  readonly valuationWarnings: ReadonlyArray<{
    readonly reason: ValuationWarningReason;
    readonly valuationType: ValuationType;
    readonly " $fragmentSpreads": FragmentRefs<"ValuationWarningMessage_ValuationWarning">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsInTheirLastExerciseDaysWarningMessage_Organization" | "ValuationWarningMessage_Organization">;
  readonly " $fragmentType": "AdminUpcomingCard_Organization";
};
export type AdminUpcomingCard_Organization$key = {
  readonly " $data"?: AdminUpcomingCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminUpcomingCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminUpcomingCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavoredSubplanExpirationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCTMSGrantsInTheirLastExerciseDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationValuationWarning",
      "kind": "LinkedField",
      "name": "valuationWarnings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "valuationType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ValuationWarningMessage_ValuationWarning"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ValuationWarningMessage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsInTheirLastExerciseDaysWarningMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8670319f6b9da2389810a50a96c1f45b";

export default node;
