import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useDurationFormatter } from "../../helpers/duration-utils";
import { useTrackEvent } from "../../hooks/useAnalytics";
import { useApplicationName } from "../../hooks/useApplicationTheme";
import { ACCELERATION_CLAUSE_LABELS } from "../../services/AccelerationClause";
import { AnchorButton, Button } from "../ui/Button";
import {
  ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$data,
  ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$key,
} from "./__generated__/ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants.graphql";
import { Section } from "./Section";

const EASOP_GRANTS_FRAGMENT = graphql`
  fragment ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants on EasopGrant
  @relay(plural: true) {
    grantee {
      name
      taxResidenceCountry {
        name
      }
      USStateOfResidence {
        name
      }
    }
    quantityGranted
    vestingSchedule {
      name
    }
    earlyExercise
    accelerationClause
    awardSuperType
    equityTypeName
    vestingStartDate
    exercisePrice
    postTerminationExercisePeriod {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        duration
        durationUnit
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        duration
        durationUnit
        extensionDuration
        extensionDurationUnit
        thresholdForExtensionInYears
      }
    }
  }
`;

export const CSV_SEPARATOR = ",";

export const GRANTS_CSV_HEADER = [
  "Grantee name",
  "Country of residence",
  "State of residence",
  "Quantity granted",
  "Vesting schedule",
  "Allow early exercise",
  "Acceleration clause",
  "Award type",
  "Vesting start date",
  "Exercise price",
  "Post termination exercise period",
  "Extended post termination exercise period",
  "Extended post termination exercise period relationship duration threshold",
].join(CSV_SEPARATOR);

export function escapeCSV(field: string): string {
  // Check if the field contains commas, double quotes, or line breaks
  if (/["\r\n,]/.test(field)) {
    // Double up any double quotes in the field
    return `"${field.replace(/"/g, '""')}"`;
  }
  return field;
}

export function useGrantsCSVDownloadLink({
  easopGrants,
}: {
  easopGrants: ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$data;
}) {
  const intl = useIntl();

  const formatDuration = useDurationFormatter();

  const rows = easopGrants.map((grant) => {
    if (grant.postTerminationExercisePeriod.__typename === "%other") {
      throw new Error("Invalid postTerminationExercisePeriod type");
    }
    const row = [
      grant.grantee.name,
      grant.grantee.taxResidenceCountry?.name ?? "Unknown",
      grant.grantee.USStateOfResidence?.name ?? "N/A",
      intl.formatNumber(grant.quantityGranted),
      grant.vestingSchedule.name,
      grant.earlyExercise ? "Yes" : "No",
      grant.accelerationClause
        ? ACCELERATION_CLAUSE_LABELS[grant.accelerationClause]
        : "None",
      grant.equityTypeName ?? grant.awardSuperType,
      intl.formatDate(new Date(grant.vestingStartDate), {
        day: "2-digit",
        month: "short",
        timeZone: "UTC",
        year: "numeric",
      }),
      intl.formatNumber(grant.exercisePrice, {
        currency: "USD",
        maximumFractionDigits: 21,
        style: "currency",
      }),
      formatDuration({
        duration: grant.postTerminationExercisePeriod.duration,
        unit: grant.postTerminationExercisePeriod.durationUnit,
      }),
      grant.postTerminationExercisePeriod.__typename ===
      "OrganizationVariablePostTerminationExercisePeriod"
        ? formatDuration({
            duration: grant.postTerminationExercisePeriod.extensionDuration,
            unit: grant.postTerminationExercisePeriod.extensionDurationUnit,
          })
        : "None",
      grant.postTerminationExercisePeriod.__typename ===
      "OrganizationVariablePostTerminationExercisePeriod"
        ? intl.formatNumber(
            grant.postTerminationExercisePeriod.thresholdForExtensionInYears,
            { style: "unit", unit: "year", unitDisplay: "long" },
          )
        : "None",
    ];

    return row.map(escapeCSV).join(CSV_SEPARATOR);
  });

  return useMemo(() => {
    const csv = [GRANTS_CSV_HEADER, ...rows].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    return URL.createObjectURL(blob);
  }, [rows]);
}

export function ExportGrantsSection({
  easopGrantsFragment,
  onPreviewBoardConsentClick,
  previewBoardConsentIsInFlight,
  type,
}: {
  easopGrantsFragment: ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$key;
  onPreviewBoardConsentClick: () => void;
  previewBoardConsentIsInFlight: boolean;
  type: "AMENDMENT_REQUEST" | "GRANT";
}) {
  const easopGrants = useFragment(EASOP_GRANTS_FRAGMENT, easopGrantsFragment);
  const applicationName = useApplicationName();
  const csvDownloadLink = useGrantsCSVDownloadLink({
    easopGrants,
  });
  const trackEvent = useTrackEvent();
  const handleDownloadButtonClick = useCallback(() => {
    trackEvent(
      type === "GRANT"
        ? "Consent Outside Easop - Grants Exported"
        : "Consent Outside Easop - Grant Amendments Exported",
    );
  }, [trackEvent, type]);

  return (
    <Section
      index={1}
      subtitle={
        <>
          You may need the list of grants to append to your own written board
          consent, or to the minutes of the board meeting during which the
          grants are approved (if you approve the grants during a real board
          meeting). You can also download a preview of the board consent that
          would be generated by {applicationName}.
        </>
      }
      title="Export your list of grants"
    >
      <div className="flex justify-between gap-4">
        <AnchorButton
          download="grants.csv"
          href={csvDownloadLink}
          onClick={handleDownloadButtonClick}
          size="small"
          variant="Secondary Full"
        >
          Export grants
        </AnchorButton>
        <Button
          loading={previewBoardConsentIsInFlight}
          onClick={onPreviewBoardConsentClick}
          size="small"
          type="button"
          variant="Secondary Outline"
        >
          Preview board consent
        </Button>
      </div>
    </Section>
  );
}
