import { useCallback, useMemo } from "react";

import { useTrackEvent } from "../../hooks/useAnalytics";
import { useApplicationName } from "../../hooks/useApplicationTheme";
import { FileInput, Item } from "../ui/Form/FileInput";
import { DatePicker } from "../ui/Form/Inputs/DatePicker";
import { NoticeMessage } from "../ui/NoticeMessage";
import { Section, SectionTitle } from "./Section";

export function SelfManagedBoardConsentDetailsSection({
  boardConsentSignatureDate,
  fileInputItems,
  onFileInputItemRemoved,
  onFilesAdded,
  setBoardConsentSignatureDate,
  type,
}: {
  boardConsentSignatureDate: null | string;
  fileInputItems: readonly Item[];
  onFileInputItemRemoved: (itemId: string) => void;
  onFilesAdded: (files: File[]) => void;
  setBoardConsentSignatureDate: (date: string) => void;
  type:
    | "GRANT"
    | "GRANT_AMENDMENT_REQUEST"
    | "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST";
}) {
  const applicationName = useApplicationName();

  const sectionSubTitle = useMemo(() => {
    switch (type) {
      case "GRANT":
        return `Once your board has approved the grants, add the board consent (or the minutes of the board meeting) relating to the selected grants and they'll be available in the grant documentation section on ${applicationName}`;
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return `Once your board has approved the grant amendments, add the board consent (or the minutes of the board meeting) relating to the selected grant amendments and they'll be available in the grant documentation section on ${applicationName}`;
    }
  }, [applicationName, type]);

  const dateApprovalSubTitle = useMemo(() => {
    switch (type) {
      case "GRANT":
        return (
          <div>
            Input the date of approval of the selected grants
            <br />
            💡 The date of approval is either:
            <br />
            <ul className="list-disc pl-5">
              <li>
                the date of the board meeting during which grants were approved
                (if the decision was made via a real oral board meeting); or
              </li>
              <li>
                the date on which the last board member signed the board consent
                (if the decision was made via a written consent)
              </li>
            </ul>
          </div>
        );
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return (
          <div>
            Input the date of approval of the selected grant amendments
            <br />
            💡 The date of approval is either:
            <br />
            <ul className="list-disc pl-5">
              <li>
                the date of the board meeting during which grant amendments were
                approved (if the decision was made via a real oral board
                meeting); or
              </li>
              <li>
                the date on which the last board member signed the board consent
                (if the decision was made via a written consent)
              </li>
            </ul>
          </div>
        );
    }
  }, [type]);
  const trackEvent = useTrackEvent();

  const handleFilesAdded = useCallback(
    (files: File[]) => {
      trackEvent(
        type === "GRANT"
          ? `Consent Outside ${applicationName} - Grants Consent imported`
          : `Consent Outside ${applicationName} - Grant Amendments Consent imported`,
        {
          number_of_files: files.length,
        },
      );
      onFilesAdded(files);
    },
    [onFilesAdded, trackEvent, type, applicationName],
  );

  return (
    <Section
      index={2}
      subtitle={sectionSubTitle}
      title="Import your board consent"
    >
      <div className="space-y-4">
        {fileInputItems.length === 0 && (
          <div>
            <NoticeMessage
              className="w-fit"
              hasColor={false}
              size="Small"
              variant="Info"
            >
              You haven&apos;t uploaded any documents yet.
            </NoticeMessage>
          </div>
        )}
        <FileInput
          accept="*"
          items={fileInputItems}
          multiple
          onFilesAdded={handleFilesAdded}
          onItemRemoved={onFileInputItemRemoved}
        />
        <SectionTitle subtitle={dateApprovalSubTitle}>
          Date of approval
        </SectionTitle>
        <DatePicker
          maxDate={new Date()}
          onChange={setBoardConsentSignatureDate}
          placeholder="Select a date"
          value={boardConsentSignatureDate}
        />
      </div>
    </Section>
  );
}
