import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { downloadFile } from "../helpers/downloadFile";
import { useOrganizationCTMS } from "../hooks/useOrganizationCTMS";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { GenerateCTMSXlsxButton_Carta_Mutation } from "./__generated__/GenerateCTMSXlsxButton_Carta_Mutation.graphql";
import { GenerateCTMSXlsxButton_Organization$key } from "./__generated__/GenerateCTMSXlsxButton_Organization.graphql";
import { GenerateCTMSXlsxButton_Pulley_Mutation } from "./__generated__/GenerateCTMSXlsxButton_Pulley_Mutation.graphql";
import { Button } from "./ui/Button";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GenerateCTMSXlsxButton_Organization on Organization {
    id
    easopGrantsPendingCTMSImplementation: easopGrants(
      filters: { statusIn: [PendingCtmsImplementation] }
    ) {
      __typename
    }
    ...useOrganizationCTMS_Organization
  }
`;
const GENERATE_CARTA_IMPORTABLE_XLSXS_MUTATION = graphql`
  mutation GenerateCTMSXlsxButton_Carta_Mutation(
    $organizationId: OrganizationId!
  ) {
    generateOrganizationPendingImplementationOptionsCartaImportableXLSXDataURL(
      organizationId: $organizationId
    )
    generateOrganizationPendingImplementationRSUsCartaImportableXLSXDataURL(
      organizationId: $organizationId
    )
    generateOrganizationPendingImplementationSARsCartaImportableXLSXDataURL(
      organizationId: $organizationId
    )
  }
`;

const GENERATE_PULLEY_IMPORTABLE_XLSXS_MUTATION = graphql`
  mutation GenerateCTMSXlsxButton_Pulley_Mutation(
    $organizationId: OrganizationId!
  ) {
    generateOrganizationPendingImplementationOptionsPulleyImportableXLSXDataURL(
      organizationId: $organizationId
    )
    generateOrganizationPendingImplementationSARsPulleyImportableXLSXDataURL(
      organizationId: $organizationId
    )
  }
`;

export const GenerateCTMSXlsxButton: React.FC<
  {
    organizationFragment: GenerateCTMSXlsxButton_Organization$key;
  } & React.ComponentProps<typeof Button>
> = ({ className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  const [
    generateCartaImportableXLSXsMutation,
    generateCartaImportableXLSXsMutationIsInFlight,
  ] = useSafeMutation<GenerateCTMSXlsxButton_Carta_Mutation>(
    GENERATE_CARTA_IMPORTABLE_XLSXS_MUTATION,
  );

  const [
    generatePulleyImportableXLSXsMutation,
    generatePulleyImportableXLSXsMutationIsInFlight,
  ] = useSafeMutation<GenerateCTMSXlsxButton_Pulley_Mutation>(
    GENERATE_PULLEY_IMPORTABLE_XLSXS_MUTATION,
  );

  const generateCartaImportableXLSXs = useCallback(async () => {
    const result = await generateCartaImportableXLSXsMutation({
      variables: { organizationId: organization.id },
    });

    const {
      generateOrganizationPendingImplementationOptionsCartaImportableXLSXDataURL:
        organizationPendingImplementationOptionsCartaImportableXLSXDataURL,
      generateOrganizationPendingImplementationRSUsCartaImportableXLSXDataURL:
        organizationPendingImplementationRSUsCartaImportableXLSXDataURL,
      generateOrganizationPendingImplementationSARsCartaImportableXLSXDataURL:
        organizationPendingImplementationSARsCartaImportableXLSXDataURL,
    } = result;

    if (organizationPendingImplementationOptionsCartaImportableXLSXDataURL) {
      downloadFile({
        filename: "carta_grants_option_import.xlsx",
        url: organizationPendingImplementationOptionsCartaImportableXLSXDataURL,
      });
    }

    if (organizationPendingImplementationRSUsCartaImportableXLSXDataURL) {
      downloadFile({
        filename: "carta_grants_rsu_import.xlsx",
        url: organizationPendingImplementationRSUsCartaImportableXLSXDataURL,
      });
    }

    if (organizationPendingImplementationSARsCartaImportableXLSXDataURL) {
      downloadFile({
        filename: "carta_grants_sar_import.xlsx",
        url: organizationPendingImplementationSARsCartaImportableXLSXDataURL,
      });
    }
  }, [generateCartaImportableXLSXsMutation, organization.id]);

  const generatePulleyImportableXLSXs = useCallback(async () => {
    const result = await generatePulleyImportableXLSXsMutation({
      variables: { organizationId: organization.id },
    });

    const {
      generateOrganizationPendingImplementationOptionsPulleyImportableXLSXDataURL:
        organizationPendingImplementationOptionsPulleyImportableXLSXDataURL,
      generateOrganizationPendingImplementationSARsPulleyImportableXLSXDataURL:
        organizationPendingImplementationSARsPulleyImportableXLSXDataURL,
    } = result;

    if (organizationPendingImplementationOptionsPulleyImportableXLSXDataURL) {
      downloadFile({
        filename: "pulley_grants_option_import.xlsx",
        url: organizationPendingImplementationOptionsPulleyImportableXLSXDataURL,
      });
    }
    if (organizationPendingImplementationSARsPulleyImportableXLSXDataURL) {
      downloadFile({
        filename: "pulley_grants_sar_import.xlsx",
        url: organizationPendingImplementationSARsPulleyImportableXLSXDataURL,
      });
    }
  }, [generatePulleyImportableXLSXsMutation, organization.id]);

  const generateImportableXLSXs = useMemo(() => {
    switch (organizationCTMS?.name) {
      case "Carta":
        return generateCartaImportableXLSXs;
      case "Pulley":
        return generatePulleyImportableXLSXs;
      default:
        return null;
    }
  }, [
    generateCartaImportableXLSXs,
    generatePulleyImportableXLSXs,
    organizationCTMS,
  ]);

  if (!generateImportableXLSXs) {
    return null;
  }

  return (
    <Button
      className={className}
      disabled={organization.easopGrantsPendingCTMSImplementation.length === 0}
      loading={
        generateCartaImportableXLSXsMutationIsInFlight ||
        generatePulleyImportableXLSXsMutationIsInFlight
      }
      onClick={generateImportableXLSXs}
      size="small"
      type="button"
    >
      Generate {organizationCTMS?.name} importable XLSXs
    </Button>
  );
};
