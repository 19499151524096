import React, { useCallback, useContext, useState } from "react";
import { useAsync } from "react-use";

import { LoadingPlaceholder } from "../components/LoadingPlaceholder";
import {
  useIdentifyAccountInAnalytics,
  useTrackEvent,
} from "../hooks/useAnalytics";
import useIdentifyAccountInSentry from "../hooks/useIdentifyAccountInSentry";
import { FeatureFlagProvider } from "../hooks/useIsFeatureEnabled";
import { AuthenticationAPIClient } from "./AuthenticationAPIClient";
import { useIdentifyAccountInPostHog } from "./PostHogProvider";
import { useRelayStoreContext } from "./RelayStoreContextProvider";

export interface Account {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

const Context = React.createContext<{
  account: Account | null;
  logout: () => void;
  setAccount: (account: Account) => void;
} | null>(null);

const AuthenticationProvider_: React.FC<{
  account: Account | null;
  children: React.ReactNode;
}> = ({ account: _account, children }) => {
  const [account, _setAccount] = useState(_account);
  const relayStoreContext = useRelayStoreContext();
  useIdentifyAccountInSentry(account);
  useIdentifyAccountInAnalytics(account);
  useIdentifyAccountInPostHog(account);

  const trackEvent = useTrackEvent();

  const logout = useCallback(() => {
    trackEvent("Log Out");
    void AuthenticationAPIClient.logout();
    _setAccount(null);
    relayStoreContext.resetStore();
  }, [relayStoreContext, _setAccount, trackEvent]);

  const setAccount = useCallback(
    (account: Account) => {
      _setAccount(account);
      relayStoreContext.resetStore();
    },
    [relayStoreContext],
  );

  return (
    <FeatureFlagProvider account={account}>
      <Context.Provider value={{ account, logout, setAccount }}>
        {children}
      </Context.Provider>
    </FeatureFlagProvider>
  );
};

export const useAuthentication = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get authentication context");
  }

  return context;
};

const AuthenticationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const state = useAsync(async () => {
    return AuthenticationAPIClient.getMe();
  }, []);

  if (state.error) {
    throw state.error;
  }

  if (state.loading) {
    return <LoadingPlaceholder />;
  }

  if (!state.value) {
    throw new Error("empty value");
  }

  return (
    <AuthenticationProvider_
      account={state.value.outcome === "SUCCESS" ? state.value.account : null}
    >
      {children}
    </AuthenticationProvider_>
  );
};

export default AuthenticationProvider;
