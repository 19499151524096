import { isFuture } from "date-fns";
import {
  ArrowRight,
  BrifecaseTimer,
  Calendar1,
  CalendarRemove,
  Chart1,
  Forward,
  Judge,
} from "iconsax-react";
import { last } from "lodash";
import React, { ComponentProps, ReactElement, ReactNode, useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import { ShortDate } from "../../../../components/ShortDate";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import {
  APPLICATION_ROUTES,
  MAP_AMENDMENT_STATUS_TO_PATH,
} from "../../../../paths";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../services/AccelerationClause";
import { ModifyCTMSGrantPageContent_CTMSGrant$key } from "./__generated__/ModifyCTMSGrantPageContent_CTMSGrant.graphql";
import { ModifyCTMSGrantPageContent_Organization$key } from "./__generated__/ModifyCTMSGrantPageContent_Organization.graphql";
import { CancelFlowButton } from "./CancelFlowButton";
import { useModifyCTMSGrantContext } from "./Context";
import {
  NewAccelerationAllowedOption,
  NewAccelerationAllowedSliderRemote,
} from "./NewAccelerationAllowedSlider";
import {
  NewEarlyExerciseAllowedOption,
  NewEarlyExerciseAllowedSliderRemote,
} from "./NewEarlyExerciseAllowedSlider";
import { NewPTEPSliderRemote } from "./NewPTEPSlider";
import { NewVestingScheduleSliderRemote } from "./NewVestingScheduleSlider";
import { NewVestingStartDateSliderRemote } from "./NewVestingStartDateSlider";
import { VestingAccelerationSliderRemote } from "./VestingAccelerationSlider";
import { WaiveCliffSliderRemote } from "./WaiveCliffSlider";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ModifyCTMSGrantPageContent_Organization on Organization {
    id
    allowAcceleration
    allowEarlyExercise
    postTerminationExercisePeriods {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        id
        displayName
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        id
        displayName
      }
    }
    vestingSchedules {
      id
      name
    }
    ...LargeCenteredDetailsLayout_Organization
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
    ...NewPTEPSlider_Organization
    ...NewEarlyExerciseAllowedSlider_Organization
    ...NewVestingScheduleSlider_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ModifyCTMSGrantPageContent_CTMSGrant on CTMSGrant {
    id
    label
    earlyExercise
    vestingStartDate
    vestingScheduleName
    cumulativeVested
    easopGrant {
      accelerationClause
    }
    postTerminationExercisePeriod {
      displayName
    }
    vestingDataPoints {
      cumulativeVested
      date
    }
    vestingSchedule {
      cliffDurationInMonths
    }
    ...VestingAccelerationSlider_CTMSGrant
    ...NewPTEPSlider_CTMSGrant
    ...NewAccelerationAllowedSlider_CTMSGrant
    ...NewEarlyExerciseAllowedSlider_CTMSGrant
    ...NewVestingStartDateSlider_CTMSGrant
    ...NewVestingScheduleSlider_CTMSGrant
  }
`;

const EditableFieldSection: React.FC<{
  disabledWarningMessage?: ReactNode;
  icon: ReactElement<ComponentProps<"svg">>;
  label: ReactNode;
  modifyButtonLabel?: ReactNode;
  newValue: null | ReactNode;
  newValueLabel?: ReactNode;
  onModifyClick: () => void;
  readOnly?: boolean;
  title: ReactNode;
  value: ReactNode;
}> = ({
  disabledWarningMessage,
  icon,
  label,
  modifyButtonLabel = "Modify",
  newValue,
  newValueLabel,
  onModifyClick,
  readOnly,
  title,
  value,
}) => {
  if (readOnly && newValue === null) return null;

  return (
    <div className="space-y-4 p-6">
      <Typography
        as="div"
        className="text-black-05"
        variant="Regular/Extra Small"
      >
        {title}
      </Typography>
      {disabledWarningMessage ? (
        <NoticeMessage hasColor={false} size="Large" variant="Warning">
          {disabledWarningMessage}
        </NoticeMessage>
      ) : (
        <div className="flex items-center gap-4 rounded-lg bg-gray-01 p-4">
          <div className="flex h-6 w-6 items-center rounded bg-purple-02 p-1">
            {React.cloneElement(icon, {
              className: "text-purple",
              ...icon.props,
            })}
          </div>
          <div className="flex flex-grow items-center gap-4">
            {newValue ? (
              <>
                <div className="space-y-1 opacity-50">
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    {label}
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-07 line-through"
                    variant="Medium/Extra Small"
                  >
                    {value}
                  </Typography>
                </div>
                <ArrowRight className="w-4 text-black-07" variant="Bulk" />
                <div className="space-y-1">
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    {newValueLabel ?? label}
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-07"
                    variant="Medium/Extra Small"
                  >
                    {newValue}
                  </Typography>
                </div>
              </>
            ) : (
              <div className="space-y-1">
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  {label}
                </Typography>
                <Typography
                  as="div"
                  className="text-black-07"
                  variant="Medium/Extra Small"
                >
                  {value}
                </Typography>
              </div>
            )}
          </div>
          {!readOnly && (
            <Button
              onClick={onModifyClick}
              size="small"
              variant="Secondary Outline"
            >
              {modifyButtonLabel}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const ModifyCTMSGrantPageContent_: React.FC<{
  ctmsGrantFragment: ModifyCTMSGrantPageContent_CTMSGrant$key;
  onDeleteButtonClick?: (() => void) | null;
  onSaveButtonClick: () => void;
  organizationFragment: ModifyCTMSGrantPageContent_Organization$key;
  readOnly?: boolean;
}> = ({
  ctmsGrantFragment,
  onDeleteButtonClick,
  onSaveButtonClick,
  organizationFragment,
  readOnly,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const context = useModifyCTMSGrantContext();

  // NEW VESTING SCHEDULE
  const newVestingScheduleSliderController =
    NewVestingScheduleSliderRemote.useController();

  const { newVestingScheduleId } = context.state;

  const newVestingSchedule = useMemo(
    () =>
      organization.vestingSchedules.find(
        ({ id }) => newVestingScheduleId === id,
      ),
    [organization.vestingSchedules, newVestingScheduleId],
  );

  // NEW VESTING START DATE
  const newVestingStartDateSliderController =
    NewVestingStartDateSliderRemote.useController();

  const { newVestingStartDate } = context.state;

  // VESTING SCHEDULE ACCELERATION
  const vestingAccelerationSliderController =
    VestingAccelerationSliderRemote.useController();

  const lastVestingEventDate = useMemo(
    () => last(ctmsGrant.vestingDataPoints)?.date ?? null,
    [ctmsGrant.vestingDataPoints],
  );

  const { totalAccelerationDate } = context.state;

  // WAIVE CLIFF
  const waiveCliffSliderController = WaiveCliffSliderRemote.useController();

  const cliffEndDate = useMemo(() => {
    if (!ctmsGrant.vestingSchedule?.cliffDurationInMonths) return null;

    return (
      ctmsGrant.vestingDataPoints.filter(
        ({ cumulativeVested }) => cumulativeVested,
      )?.[0]?.date ?? null
    );
  }, [
    ctmsGrant.vestingDataPoints,
    ctmsGrant.vestingSchedule?.cliffDurationInMonths,
  ]);

  const { waiveCliff } = context.state;

  // NEW PTEP
  const newPTEPSliderController = NewPTEPSliderRemote.useController();

  const { newOrganizationPostTerminationExercisePeriodId } = context.state;

  const organizationPostTerminationExercisePeriods = useMemo(
    () =>
      organization.postTerminationExercisePeriods.map((ptep) => {
        if (ptep.__typename === "%other") throw new Error("Invalid typename");
        return ptep;
      }),
    [organization.postTerminationExercisePeriods],
  );

  const newOrganizationPostTerminationExercisePeriod = useMemo(
    () =>
      organizationPostTerminationExercisePeriods.find(
        ({ id }) => newOrganizationPostTerminationExercisePeriodId === id,
      ),
    [
      organizationPostTerminationExercisePeriods,
      newOrganizationPostTerminationExercisePeriodId,
    ],
  );

  // NEW ACCELERATION CLAUSE
  const newAccelerationClauseSliderController =
    NewAccelerationAllowedSliderRemote.useController();

  const { newAccelerationAllowedValue } = context.state;

  // NEW EARLY EXERCISE ALLOWED
  const newEarlyExerciseAllowedSliderController =
    NewEarlyExerciseAllowedSliderRemote.useController();

  const { newEarlyExerciseAllowedValue } = context.state;

  const { parentLink, parentTitle } = useMemo(() => {
    if (context.existingCTMSGrantAmendmentRequest) {
      switch (context.existingCTMSGrantAmendmentRequest.status) {
        case "IMPLEMENTED":
          break;
        default:
          return {
            parentLink: generatePath(
              APPLICATION_ROUTES[
                MAP_AMENDMENT_STATUS_TO_PATH[
                  context.existingCTMSGrantAmendmentRequest.status
                ]
              ],
              {
                organizationId: organization.id,
              },
            ),
            parentTitle: "Amendments",
          };
      }
    }
    return {
      parentLink: generatePath(APPLICATION_ROUTES["organizationEquityGrants"], {
        organizationId: organization.id,
      }),
      parentTitle: "Grants",
    };
  }, [context.existingCTMSGrantAmendmentRequest, organization.id]);

  return (
    <LargeCenteredDetailsLayout
      organizationFragment={organization}
      parents={[
        {
          link: parentLink,
          title: parentTitle,
        },
      ]}
      subtitle={
        readOnly ? (
          <>Here is a recap of the grant amendment</>
        ) : (
          <>Amending a grant will always require the approval from the board.</>
        )
      }
      title={
        readOnly ? (
          <>Amendment on grant {ctmsGrant.label}</>
        ) : (
          <>Modify grant {ctmsGrant.label}</>
        )
      }
    >
      <div className="space-y-10">
        <RoundedBox withBorder withShadow>
          <EditableFieldSection
            icon={<Calendar1 variant="Bulk" />}
            label="Vesting is starting on:"
            newValue={
              newVestingStartDate ? (
                <ShortDate value={newVestingStartDate} />
              ) : null
            }
            onModifyClick={() => {
              newVestingStartDateSliderController.open({
                data: {
                  ctmsGrantFragment: ctmsGrant,
                  newVestingStartDate,
                  onNewVestingStartDateChange: ({ vestingStartDate }) =>
                    context.dispatchAction({
                      newVestingStartDate: vestingStartDate,
                      type: "SET_NEW_VESTING_START_DATE",
                    }),
                },
              });
            }}
            readOnly={readOnly}
            title="Vesting start date"
            value={
              ctmsGrant.vestingStartDate ? (
                <ShortDate value={ctmsGrant.vestingStartDate} />
              ) : (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )
            }
          />
          <Divider />
          <EditableFieldSection
            icon={<Chart1 variant="Bulk" />}
            label="Vesting schedule:"
            newValue={newVestingSchedule?.name ?? null}
            onModifyClick={() => {
              newVestingScheduleSliderController.open({
                data: {
                  ctmsGrantFragment: ctmsGrant,
                  newVestingScheduleId,
                  onNewVestingScheduleChanges: ({ vestingScheduleId }) =>
                    context.dispatchAction({
                      newVestingScheduleId: vestingScheduleId,
                      type: "SET_NEW_VESTING_SCHEDULE",
                    }),
                  organizationFragment: organization,
                },
              });
            }}
            readOnly={readOnly}
            title="Vesting schedule"
            value={
              ctmsGrant.vestingScheduleName ?? (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )
            }
          />
          <Divider />
          <EditableFieldSection
            icon={<CalendarRemove variant="Bulk" />}
            label="Vesting end date:"
            modifyButtonLabel="Accelerate"
            newValue={
              totalAccelerationDate ? (
                <ShortDate value={totalAccelerationDate} />
              ) : null
            }
            newValueLabel="Vesting end date:"
            onModifyClick={() => {
              vestingAccelerationSliderController.open({
                data: {
                  ctmsGrantFragment: ctmsGrant,
                  onTotalAccelerationDateChange: ({ totalAccelerationDate }) =>
                    context.dispatchAction({
                      totalAccelerationDate,
                      type: "SET_TOTAL_ACCELERATION_DATE",
                    }),
                  totalAccelerationDate,
                },
              });
            }}
            readOnly={readOnly}
            title="Vesting acceleration"
            value={
              lastVestingEventDate ? (
                <ShortDate value={lastVestingEventDate} />
              ) : (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )
            }
          />
          {waiveCliff ||
            (cliffEndDate && isFuture(cliffEndDate) && (
              <>
                <Divider />
                <EditableFieldSection
                  icon={<CalendarRemove variant="Bulk" />}
                  label="Cliff end date:"
                  modifyButtonLabel="Waive"
                  newValue={waiveCliff ? <>Cliff waived</> : null}
                  newValueLabel="Cliff end date:"
                  onModifyClick={() => {
                    waiveCliffSliderController.open({
                      data: {
                        onWaiveCliffChange: ({ waiveCliff }) =>
                          context.dispatchAction({
                            type: "SET_WAIVE_CLIFF",
                            waiveCliff,
                          }),
                        waiveCliff,
                      },
                    });
                  }}
                  readOnly={readOnly}
                  title="Cliff"
                  value={
                    cliffEndDate ? (
                      <ShortDate value={cliffEndDate} />
                    ) : (
                      <MissingCTMSInformationTag
                        organizationFragment={organization}
                      />
                    )
                  }
                />
              </>
            ))}
          <Divider />
          <EditableFieldSection
            icon={<BrifecaseTimer variant="Bulk" />}
            label="Current period:"
            newValue={
              newOrganizationPostTerminationExercisePeriod?.displayName ?? null
            }
            newValueLabel="Updated period:"
            onModifyClick={() => {
              newPTEPSliderController.open({
                data: {
                  ctmsGrantFragment: ctmsGrant,
                  newOrganizationPostTerminationExercisePeriodId,
                  onNewOrganizationPostTerminationExercisePeriodChanges: ({
                    organizationPostTerminationExercisePeriodId,
                  }) =>
                    context.dispatchAction({
                      newOrganizationPostTerminationExercisePeriodId:
                        organizationPostTerminationExercisePeriodId,
                      type: "SET_NEW_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD",
                    }),
                  organizationFragment: organization,
                },
              });
            }}
            readOnly={readOnly}
            title="Post-Termination Exercise Period"
            value={
              ctmsGrant.postTerminationExercisePeriod ? (
                ctmsGrant.postTerminationExercisePeriod.displayName
              ) : (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )
            }
          />
          <Divider />
          <EditableFieldSection
            disabledWarningMessage={
              !organization.allowAcceleration ? (
                <>
                  Acceleration is disabled at company level, please update your{" "}
                  <Link
                    className="font-medium text-primary"
                    to={generatePath(
                      APPLICATION_ROUTES[
                        "organizationEquityConfigureAcceleration"
                      ],
                      { organizationId: organization.id },
                    )}
                  >
                    equity configuration
                  </Link>{" "}
                  to allow this option.
                </>
              ) : null
            }
            icon={<Forward variant="Bulk" />}
            label="Acceleration clause:"
            newValue={
              newAccelerationAllowedValue
                ? newAccelerationAllowedValue.acceleration ===
                  NewAccelerationAllowedOption.NOT_ALLOWED
                  ? "Not allowed"
                  : ACCELERATION_CLAUSE_LABELS[
                      newAccelerationAllowedValue.accelerationClause
                    ]
                : null
            }
            onModifyClick={() => {
              newAccelerationClauseSliderController.open({
                data: {
                  ctmsGrantFragment: ctmsGrant,
                  newAccelerationAllowedValue,
                  onNewAccelerationAllowedValueChanges: (value) =>
                    context.dispatchAction({
                      newAccelerationAllowedValue: value,
                      type: "SET_NEW_ACCELERATION_ALLOWED_VALUE",
                    }),
                },
              });
            }}
            readOnly={readOnly}
            title="Acceleration Terms"
            value={
              ctmsGrant.easopGrant ? (
                ctmsGrant.easopGrant.accelerationClause ? (
                  ACCELERATION_CLAUSE_LABELS[
                    ctmsGrant.easopGrant.accelerationClause
                  ]
                ) : (
                  "Not allowed"
                )
              ) : (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )
            }
          />
          <Divider />
          <EditableFieldSection
            disabledWarningMessage={
              !organization.allowEarlyExercise ? (
                <>
                  Early Exercise is disabled at company level, please update
                  your{" "}
                  <Link
                    className="font-medium text-primary"
                    to={generatePath(
                      APPLICATION_ROUTES[
                        "organizationEquityConfigureEarlyExercise"
                      ],
                      { organizationId: organization.id },
                    )}
                  >
                    equity configuration
                  </Link>{" "}
                  to allow this option.
                </>
              ) : null
            }
            icon={<Judge variant="Bulk" />}
            label="Early exercise clause:"
            newValue={
              newEarlyExerciseAllowedValue
                ? newEarlyExerciseAllowedValue.earlyExercise ===
                  NewEarlyExerciseAllowedOption.NOT_ALLOWED
                  ? "Not allowed"
                  : "Allowed"
                : null
            }
            onModifyClick={() => {
              newEarlyExerciseAllowedSliderController.open({
                data: {
                  ctmsGrantFragment: ctmsGrant,
                  newEarlyExerciseAllowedValue,
                  onNewEarlyExerciseAllowedValueChanges: (value) =>
                    context.dispatchAction({
                      newEarlyExerciseAllowedValue: value,
                      type: "SET_NEW_EARLY_EXERCISE_ALLOWED_VALUE",
                    }),
                  organizationFragment: organization,
                },
              });
            }}
            readOnly={readOnly}
            title="Early exercise"
            value={ctmsGrant.earlyExercise ? "Allowed" : "Not allowed"}
          />
        </RoundedBox>
        {!readOnly || onDeleteButtonClick ? (
          <div className="flex gap-4">
            {!readOnly && (
              <Button
                disabled={context.isEmpty}
                onClick={onSaveButtonClick}
                size="small"
                variant="Primary Full"
              >
                Save and review
              </Button>
            )}
            {onDeleteButtonClick && (
              <Button
                onClick={onDeleteButtonClick}
                size="small"
                variant="Danger Outline"
              >
                Delete
              </Button>
            )}
            {!readOnly && <CancelFlowButton organizationId={organization.id} />}
          </div>
        ) : null}
      </div>
    </LargeCenteredDetailsLayout>
  );
};

export const ModifyCTMSGrantPageContent: React.FC<
  React.ComponentProps<typeof ModifyCTMSGrantPageContent_>
> = ({ ...props }) => {
  return (
    <NewPTEPSliderRemote.Provider>
      <NewAccelerationAllowedSliderRemote.Provider>
        <NewEarlyExerciseAllowedSliderRemote.Provider>
          <NewVestingStartDateSliderRemote.Provider>
            <NewVestingScheduleSliderRemote.Provider>
              <VestingAccelerationSliderRemote.Provider>
                <WaiveCliffSliderRemote.Provider>
                  <ModifyCTMSGrantPageContent_ {...props} />
                </WaiveCliffSliderRemote.Provider>
              </VestingAccelerationSliderRemote.Provider>
            </NewVestingScheduleSliderRemote.Provider>
          </NewVestingStartDateSliderRemote.Provider>
        </NewEarlyExerciseAllowedSliderRemote.Provider>
      </NewAccelerationAllowedSliderRemote.Provider>
    </NewPTEPSliderRemote.Provider>
  );
};
