import {
  ChevronDownIcon,
  PencilIcon,
  PlusIcon,
  StarIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Star1 } from "iconsax-react";
import { isEmpty } from "lodash";
import React, { startTransition, useCallback } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import {
  generatePath,
  Navigate,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { SafeCalculatorEmptyPlaceholder } from "../../../../components/SafeCalculatorEmptyPlaceholder";
import { EditSafeCalculatorVersionModal } from "../../../../components/SafeCalculatorVersionModal/EditSafeCalculatorVersionModal";
import { FormValues } from "../../../../components/SafeCalculatorVersionModal/FORM_SCHEMA";
import { SafeCalculatorVersionModal } from "../../../../components/SafeCalculatorVersionModal/SafeCalculatorVersionModal";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { ConfirmationModal } from "../../../../components/ui/ConfirmationModal";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { MenuButton } from "../../../../components/ui/MenuButton";
import { TabNavigation } from "../../../../components/ui/TabNavigation";
import { Typography } from "../../../../components/ui/Typography";
import { isNonEmptyArray } from "../../../../helpers/ts-utlity";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useLocationMatchesRoutes,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { OrganizationSettingsSafeCalculatorLayout_Account$key } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_Account.graphql";
import { OrganizationSettingsSafeCalculatorLayout_CreateSafeCalculatorVersion_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_CreateSafeCalculatorVersion_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorLayout_EditSafeCalculatorVersion_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_EditSafeCalculatorVersion_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorLayout_Organization$key } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_Organization.graphql";
import { OrganizationSettingsSafeCalculatorLayout_Query } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_Query.graphql";
import { OrganizationSettingsSafeCalculatorLayout_SetSafeCalculatorVersionAsDefault_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorLayout_SetSafeCalculatorVersionAsDefault_Mutation.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsSafeCalculatorLayout_Organization on Organization
  @refetchable(
    queryName: "OrganizationSettingsSafeCalculatorLayout_Organization_RefetchQuery"
  ) {
    id
    name
    safeCalculatorVersions {
      id
      name
      default
    }
    ...SafeCalculatorEmptyPlaceholder_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettingsSafeCalculatorLayout_Account on Account {
    isSuperAdmin
    ...SafeCalculatorEmptyPlaceholder_Account
  }
`;

const CREATE_SAFE_CALCULATOR_VERSION_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorLayout_CreateSafeCalculatorVersion_Mutation(
    $organizationId: OrganizationId!
    $duplicateFromSafeCalculatorVersionId: ID
    $name: String!
  ) {
    createSafeCalculatorVersion(
      organizationId: $organizationId
      duplicateFromSafeCalculatorVersionId: $duplicateFromSafeCalculatorVersionId
      name: $name
    ) {
      id
    }
  }
`;

const EDIT_SAFE_CALCULATOR_VERSION_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorLayout_EditSafeCalculatorVersion_Mutation(
    $safeCalculatorVersionId: ID!
    $name: String!
  ) {
    editSafeCalculatorVersion(
      safeCalculatorVersionId: $safeCalculatorVersionId
      name: $name
    ) {
      name
    }
  }
`;

const DELETE_SAFE_CALCULATOR_VERSION_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation(
    $safeCalculatorVersionId: ID!
  ) {
    deleteSafeCalculatorVersion(
      safeCalculatorVersionId: $safeCalculatorVersionId
    ) {
      safeCalculatorVersions {
        id
      }
    }
  }
`;

const SET_SAFE_CALCULATOR_VERSION_AS_DEFAULT_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorLayout_SetSafeCalculatorVersionAsDefault_Mutation(
    $safeCalculatorVersionId: ID!
  ) {
    setSafeCalculatorVersionAsDefault(
      safeCalculatorVersionId: $safeCalculatorVersionId
    ) {
      __typename
    }
  }
`;

const OrganizationSettingsSafeCalculatorVersionLayout_: React.FC<{
  organizationFragment: OrganizationSettingsSafeCalculatorLayout_Organization$key;
  viewerFragment: OrganizationSettingsSafeCalculatorLayout_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const navigate = useNavigate();
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const { safeCalculatorVersionId } = useParams();

  const refreshData = useCallback(() => {
    startTransition(() => {
      refetchOrganization({});
    });
  }, [refetchOrganization]);

  const onSafeCalculatorVersionCreated = () => {
    refreshData();
  };

  const [createSafeCalculatorVersion] =
    useSafeMutation<OrganizationSettingsSafeCalculatorLayout_CreateSafeCalculatorVersion_Mutation>(
      CREATE_SAFE_CALCULATOR_VERSION_MUTATION,
    );
  const [deleteSafeCalculatorVersion] =
    useSafeMutation<OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation>(
      DELETE_SAFE_CALCULATOR_VERSION_MUTATION,
    );
  const [editSafeCalculatorVersion] =
    useSafeMutation<OrganizationSettingsSafeCalculatorLayout_EditSafeCalculatorVersion_Mutation>(
      EDIT_SAFE_CALCULATOR_VERSION_MUTATION,
    );
  const [setSafeCalculatorVersionAsDefault] =
    useSafeMutation<OrganizationSettingsSafeCalculatorLayout_SetSafeCalculatorVersionAsDefault_Mutation>(
      SET_SAFE_CALCULATOR_VERSION_AS_DEFAULT_MUTATION,
    );

  const {
    setFalse: closeSafeCalculatorVersionModal,
    setTrue: openSafeCalculatorVersionModal,
    value: safeCalculatorVersionModalIsShown,
  } = useBoolean(false);
  const {
    setFalse: closeEditSafeCalculatorVersionModal,
    setTrue: openEditSafeCalculatorVersionModal,
    value: editSafeCalculatorVersionModalIsShown,
  } = useBoolean(false);
  const {
    setFalse: closeDeleteSafeCalculatorVersionModal,
    setTrue: openDeleteSafeCalculatorVersionModal,
    value: deleteSafeCalculatorVersionModalIsShown,
  } = useBoolean(false);

  const handleSafeCalculatorVersionFormSubmit = useCallback(
    async (formData: FormValues) => {
      const { createSafeCalculatorVersion: newSafeCalculatorVersion } =
        await createSafeCalculatorVersion({
          variables: {
            duplicateFromSafeCalculatorVersionId: safeCalculatorVersionId,
            name: formData.name,
            organizationId: organization.id,
          },
        });

      closeSafeCalculatorVersionModal();
      refetchOrganization(
        {},
        {
          onComplete: () => {
            navigate(
              generatePath(
                APPLICATION_ROUTES["organizationSettingsSafeCalculatorVersion"],
                {
                  organizationId: organization.id,
                  safeCalculatorVersionId: newSafeCalculatorVersion.id,
                },
              ),
            );
          },
        },
      );
    },
    [
      createSafeCalculatorVersion,
      navigate,
      organization.id,
      refetchOrganization,
      safeCalculatorVersionId,
      closeSafeCalculatorVersionModal,
    ],
  );

  const onEditSafeCalculatorVersion = async ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) => {
    await editSafeCalculatorVersion({
      variables: {
        name,
        safeCalculatorVersionId: id,
      },
    });
    closeEditSafeCalculatorVersionModal();
  };

  const onDeleteSafeCalculatorVersion = async () => {
    if (!safeCalculatorVersionId) return;
    const { deleteSafeCalculatorVersion: updatedOrganization } =
      await deleteSafeCalculatorVersion({
        variables: {
          safeCalculatorVersionId,
        },
      });

    closeDeleteSafeCalculatorVersionModal();
    if (isNonEmptyArray(updatedOrganization.safeCalculatorVersions)) {
      navigate(
        generatePath(
          APPLICATION_ROUTES["organizationSettingsSafeCalculatorVersion"],
          {
            organizationId: organization.id,
            safeCalculatorVersionId:
              updatedOrganization.safeCalculatorVersions[0].id,
          },
        ),
      );
    } else {
      navigate(
        generatePath(APPLICATION_ROUTES["organizationSettingsSafeCalculator"], {
          organizationId: organization.id,
        }),
      );
    }
  };

  const onSetSafeCalculatorVersionAsDefault = async () => {
    if (!safeCalculatorVersionId) return;
    await setSafeCalculatorVersionAsDefault({
      variables: {
        safeCalculatorVersionId,
      },
    });
    refreshData();
  };

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">SAFE converter</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      {isEmpty(organization.safeCalculatorVersions) ? (
        <SafeCalculatorEmptyPlaceholder
          onSafeCalculatorVersionCreated={onSafeCalculatorVersionCreated}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      ) : (
        <div>
          <SafeCalculatorVersionModal
            onClose={closeSafeCalculatorVersionModal}
            onSubmit={handleSafeCalculatorVersionFormSubmit}
            show={safeCalculatorVersionModalIsShown}
          />
          {safeCalculatorVersionId && (
            <EditSafeCalculatorVersionModal
              onClose={closeEditSafeCalculatorVersionModal}
              onSubmit={onEditSafeCalculatorVersion}
              safeCalculatorVersionId={safeCalculatorVersionId}
              show={editSafeCalculatorVersionModalIsShown}
            />
          )}
          <ConfirmationModal
            onClose={closeDeleteSafeCalculatorVersionModal}
            onConfirmed={() => onDeleteSafeCalculatorVersion()}
            show={deleteSafeCalculatorVersionModalIsShown}
            title="Are you sure?"
          >
            Please confirm if you wish to delete this version.
          </ConfirmationModal>
          <div className="space-y-2">
            <div className="flex gap-4">
              <div className="flex-grow space-y-2">
                <Typography as="div" variant="Medium/Large">
                  SAFE converter
                </Typography>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Small"
                >
                  Preview the impact of the conversion of your SAFE notes on
                  your option pool and fully diluted shares count.
                </Typography>
              </div>
              {viewer.isSuperAdmin && (
                <MenuButton
                  button={
                    <Button
                      rightIcon={<ChevronDownIcon />}
                      size="small"
                      type="button"
                      variant="Secondary Outline"
                    >
                      Manage
                    </Button>
                  }
                  placement="bottom start"
                >
                  <MenuButton.Item
                    leftIcon={<PlusIcon />}
                    onClick={openSafeCalculatorVersionModal}
                  >
                    Create new version
                  </MenuButton.Item>
                  {safeCalculatorVersionId && (
                    <MenuButton.Item
                      leftIcon={<PencilIcon />}
                      onClick={openEditSafeCalculatorVersionModal}
                    >
                      Edit version
                    </MenuButton.Item>
                  )}
                  {safeCalculatorVersionId && (
                    <MenuButton.Item
                      leftIcon={<StarIcon />}
                      onClick={onSetSafeCalculatorVersionAsDefault}
                    >
                      Make version default
                    </MenuButton.Item>
                  )}
                  {safeCalculatorVersionId && (
                    <MenuButton.Item
                      leftIcon={<TrashIcon />}
                      onClick={openDeleteSafeCalculatorVersionModal}
                    >
                      Delete version
                    </MenuButton.Item>
                  )}
                </MenuButton>
              )}
            </div>
            <div className="space-y-6">
              <TabNavigation>
                {organization.safeCalculatorVersions.map(
                  (safeCalculatorVersion) => (
                    <TabNavigation.Link
                      key={safeCalculatorVersion.id}
                      leftIcon={
                        safeCalculatorVersion.default ? (
                          <Star1 variant="Bulk" />
                        ) : undefined
                      }
                      to={generatePath(
                        APPLICATION_ROUTES[
                          "organizationSettingsSafeCalculatorVersion"
                        ],
                        {
                          organizationId: organization.id,
                          safeCalculatorVersionId: safeCalculatorVersion.id,
                        },
                      )}
                    >
                      {safeCalculatorVersion.name}
                    </TabNavigation.Link>
                  ),
                )}
              </TabNavigation>
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query OrganizationSettingsSafeCalculatorLayout_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      safeCalculatorVersions {
        id
      }
      ...OrganizationSettingsSafeCalculatorLayout_Organization
    }
    me {
      ...OrganizationSettingsSafeCalculatorLayout_Account
    }
  }
`;

const OrganizationSettingsSafeCalculatorLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsSafeCalculatorLayout_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  const isOnSafeCalculatorVersionPage = useLocationMatchesRoutes([
    "organizationSettingsSafeCalculatorVersion",
  ]);

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (
    isNonEmptyArray(query.organization.safeCalculatorVersions) &&
    !isOnSafeCalculatorVersionPage
  ) {
    return (
      <Navigate
        to={generatePath(
          APPLICATION_ROUTES["organizationSettingsSafeCalculatorVersion"],
          {
            organizationId,
            safeCalculatorVersionId:
              query.organization.safeCalculatorVersions[0].id,
          },
        )}
      />
    );
  }

  return (
    <Page
      analyticsCategory="SAFE converter"
      analyticsName="Admin - SAFE converter"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} SAFE converter`}
    >
      <OrganizationSettingsSafeCalculatorVersionLayout_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default OrganizationSettingsSafeCalculatorLayout;
