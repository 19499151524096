import { FormRow } from "../ui/Form/FormRow";
import { Input } from "../ui/Form/Inputs/Input";
import { Modal } from "../ui/Modal";
import { FormValues } from "./FORM_SCHEMA";
import { useSafeCalculatorVersionForm } from "./useSafeCalculatorVersionForm";

const ModalContent: React.FC<{
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
}> = ({ onClose, onSubmit: _onSubmit }) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useSafeCalculatorVersionForm();

  const onSubmit = handleSubmit((data) => {
    _onSubmit(data);
  });

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.ActionButton
          disabled={!isDirty}
          form="safe-converter-version-form"
          loading={isSubmitting}
          type="submit"
        >
          Save
        </Modal.ActionButton>
      }
      onClose={onClose}
      subTitle="This will create a separate version for the admin to look at"
      title="Create new version"
    >
      <Modal.Form id="safe-converter-version-form" onSubmit={onSubmit}>
        <FormRow.Form
          control={control}
          label="Version name"
          name="name"
          subLabel="Give meaning but keep it short"
        >
          <Input.Form
            control={control}
            name="name"
            placeholder="e.g. Simulation for new valuation"
          />
        </FormRow.Form>
      </Modal.Form>
    </Modal.Content>
  );
};

export const SafeCalculatorVersionModal: React.FC<
  {
    show: boolean;
  } & React.ComponentProps<typeof ModalContent>
> = ({ onClose, show, ...props }) => {
  return (
    <Modal onClose={onClose} show={show}>
      <ModalContent onClose={onClose} {...props} />
    </Modal>
  );
};
