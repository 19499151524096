/**
 * @generated SignedSource<<e18294897707d4fe385f3451fdd4064a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteesTableRow_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Deferred_Organization">;
  readonly " $fragmentType": "GranteesTableRow_Organization";
};
export type GranteesTableRow_Organization$key = {
  readonly " $data"?: GranteesTableRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteesTableRow_Organization",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteesTableRow_Deferred_Organization"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f0cc283de93b45d1ac834095484b566d";

export default node;
