import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { addPeriodToDate } from "../helpers/addPeriodToDate";
import { PostTerminationExercisePeriod } from "../helpers/ptep-utils";
import { zodExtra } from "../helpers/zod-extra";
import { grantAmendmentRequest_useAmendedLastDayToExercise_Organization$key } from "./__generated__/grantAmendmentRequest_useAmendedLastDayToExercise_Organization.graphql";

export const durationUnit = () => z.enum(["day", "month", "year"]);

export const grantAmendmentSchema = z.object({
  ctmsGrantId: z.string(),
  postTerminationExercisePeriodId: z.string().optional(),
  vestingEndCustomDate: zodExtra.ISODate().optional(),
  vestingEndOption: zodExtra.vestingEndOption().optional(),
  waiveCliff: z.boolean().optional(),
});

export type GrantAmendmentRequestFormInputs = z.infer<
  typeof grantAmendmentSchema
>;

export type GrantAmendmentRequest = z.output<typeof grantAmendmentSchema>;

export const GrantAmendmentRequestsFormSchema = z.object({
  grantAmendments: z.array(grantAmendmentSchema),
});

export type GrantAmendmentRequestsFormInputs = z.infer<
  typeof GrantAmendmentRequestsFormSchema
>;

export type GrantAmendmentRequests = z.output<
  typeof GrantAmendmentRequestsFormSchema
>;

export function getOriginalLastDayToExercise({
  granteeTerminationDate,
  originalPostTerminationExercisePeriod,
}: {
  granteeTerminationDate: Date;
  originalPostTerminationExercisePeriod: null | PostTerminationExercisePeriod;
}) {
  return originalPostTerminationExercisePeriod
    ? addPeriodToDate(
        granteeTerminationDate,
        originalPostTerminationExercisePeriod.duration,
        originalPostTerminationExercisePeriod.unit,
      )
    : null;
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment grantAmendmentRequest_useAmendedLastDayToExercise_Organization on Organization {
    id
    postTerminationExercisePeriods {
      id
      duration
      durationUnit
    }
  }
`;

export function useAmendedLastDayToExercise({
  grantAmendment,
  granteeTerminationDate,
  organizationFragment,
}: {
  grantAmendment: GrantAmendmentRequest | null;
  granteeTerminationDate: Date;
  organizationFragment: grantAmendmentRequest_useAmendedLastDayToExercise_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const amendedLastDayToExercise = useMemo(() => {
    if (!grantAmendment?.postTerminationExercisePeriodId) {
      return null;
    }

    const selectedPtep = organization.postTerminationExercisePeriods.find(
      (ptep) => ptep.id === grantAmendment.postTerminationExercisePeriodId,
    );

    if (!selectedPtep) {
      throw new Error("Can't find selected PTEP");
    }

    return addPeriodToDate(
      granteeTerminationDate,
      selectedPtep.duration,
      selectedPtep?.durationUnit,
    );
  }, [
    grantAmendment?.postTerminationExercisePeriodId,
    granteeTerminationDate,
    organization.postTerminationExercisePeriods,
  ]);

  return { amendedLastDayToExercise };
}

export function getAmendedVestingEndingOn({
  grantAmendment,
  terminationDate,
  terminationLastDayAtTheCompany,
}: {
  grantAmendment: GrantAmendmentRequest | null;
  terminationDate: string;
  terminationLastDayAtTheCompany: string;
}) {
  if (!grantAmendment?.vestingEndOption) {
    return new Date(terminationDate);
  }

  if (grantAmendment.vestingEndOption === "CUSTOM_DATE") {
    if (!grantAmendment.vestingEndCustomDate) {
      throw new Error(
        "Missing custom date. Can't choose this vesting end option",
      );
    }

    return new Date(grantAmendment.vestingEndCustomDate);
  }

  if (grantAmendment.vestingEndOption === "LAST_DAY_AT_THE_COMPANY") {
    return new Date(terminationLastDayAtTheCompany);
  }

  if (grantAmendment.vestingEndOption === "TERMINATION_DATE") {
    return new Date(terminationDate);
  }

  throw new Error("Unknown vestingEndOption");
}

export const getAmendmentVestingEndOption = ({
  grantAmendment,
}: {
  grantAmendment: GrantAmendmentRequest | null;
}) => {
  if (!grantAmendment?.vestingEndOption) return "TERMINATION_DATE";

  return grantAmendment.vestingEndOption;
};
