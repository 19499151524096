import classNames from "classnames";
import { formatISO, isAfter } from "date-fns";
import { isNil } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { FormattedNumber, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useTrackEvent } from "../../hooks/useAnalytics";
import { useBoolean } from "../../hooks/useBoolean";
import { useOrganizationSharesUtil } from "../../hooks/useOrganizationSharesUtil";
import { CreateSlideOverRemote as CreatePTEPSlideOverRemote } from "../../pages/Admin/Grants/Configure/PostTermination/CreateSlideOver";
import { CreateSlideOverRemote as CreateVestingScheduleSlideOverRemote } from "../../pages/Admin/Grants/Configure/VestingSchedules/CreateSlideOver";
import { AccelerationClauseOptions } from "../../services/AccelerationClause";
import { EarlyExerciseBeneficialNoticeMessage } from "../EarlyExerciseBeneficialNoticeMessage";
import { EquityGridLevelTopUpTip } from "../EquityGridLevelTopUpTip";
import { FormattedFMV } from "../FormattedFMV";
import {
  GrantedSharesInput,
  useGrantedSharesController,
} from "../GrantedSharesInput";
import { GranteeContractNotStartedYetNoticeMessage } from "../GranteeContractNotStartedYetNoticeMessage";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../GranteeFormSlide";
import { GranteeNotGrantableAlert } from "../GranteeNotGrantableAlert";
import { GranteeSelect } from "../GranteeSelect";
import { GrantWatchouts } from "../GrantWatchouts";
import { NeedsAdditionalFormalitiesWarning } from "../NeedsAdditionalFormalitiesWarning";
import { Percentage } from "../Percentage";
import { Checkbox } from "../ui/Form/Checkbox";
import { FormActions } from "../ui/Form/FormActions";
import { FormRow } from "../ui/Form/FormRow";
import { DatePicker } from "../ui/Form/Inputs/DatePicker";
import { Input } from "../ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../ui/Form/Inputs/Select/SelectAutocomplete";
import { SelectVestingScheduleInput } from "../ui/Form/SelectVestingScheduleInput";
import { Toggle } from "../ui/Form/Toggle";
import { NoticeMessage } from "../ui/NoticeMessage";
import { FadeAndScaleTransition } from "../ui/Transition";
import { Typography } from "../ui/Typography";
import { GrantForm_Grantees$key } from "./__generated__/GrantForm_Grantees.graphql";
import { GrantForm_Organization$key } from "./__generated__/GrantForm_Organization.graphql";
import { GrantForm_Viewer$key } from "./__generated__/GrantForm_Viewer.graphql";
import { PostTerminationExercisePeriodSelectionBlock } from "./PostTerminationExercisePeriodSelectionBlock";
import { useGrantForm } from "./useGrantForm";

const GRANTEES_FRAGMENT = graphql`
  fragment GrantForm_Grantees on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" })
  @relay(plural: true) {
    id
    contractStartDate
    ctmsGrants {
      __typename
    }
    easopGrants {
      __typename
    }
    instruments(sortBy: TaxFavored, sortDirection: DESC) {
      id
      name
      needsAdditionalFormalities
      isEarlyExerciseBeneficial
      valuation(organizationId: $organizationId) {
        type
        valueInDollars
        requiresNewValuationToGrant
      }
      equityType {
        awardSuperType
        ...GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType
        ...GrantWatchouts_Rule100KWatchout_EquityType
        ...GrantWatchouts_Rule83BWatchout_EquityType
      }
      ...EarlyExerciseBeneficialNoticeMessage_Instrument
      ...NeedsAdditionalFormalitiesWarning_Instrument
      ...PostTerminationExercisePeriodSelectionBlock_Instrument
    }
    # eslint-disable-next-line relay/unused-fields
    equityGridLevel {
      __typename
    }
    name
    grantableStatus
    workRelationship
    defaultVestingSchedule {
      id
    }
    defaultAccelerationClause
    earlyExerciseIsAllowedByDefault
    defaultPostTerminationExercisePeriod {
      id
    }
    ...GranteeSelect_Grantees
    ...GranteeFormSlide_Grantee
    ...GranteeNotGrantableAlert_Grantee
    ...EquityGridLevelTopUpTip_Grantee
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantForm_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    equityGrid {
      activated
    }
    hasCooleyAsOutsideCounsel
    activeGrantees: grantees(filters: { status: Active }) {
      edges {
        node {
          ...GrantForm_Grantees @arguments(organizationId: $organizationId)
        }
      }
    }
    allowAcceleration
    allowEarlyExercise
    fullyDilutedShares
    latestPricePerShare {
      value
    }
    ...useOrganizationSharesUtil_Organization
    ...GranteeSelect_Organization
    ...GranteeFormSlide_Organization
    ...GranteeNotGrantableAlert_Organization
    ...PostTerminationExercisePeriodSelectionBlock_Organization
    ...SelectVestingScheduleInput_Organization
    ...EarlyExerciseBeneficialNoticeMessage_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GrantForm_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isSuperAdmin
    ...GranteeNotGrantableAlert_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

export type UseGrantForm = ReturnType<typeof useGrantForm>;

export const GrantForm: React.FC<
  {
    control: UseGrantForm["control"];
    creatingGrant: boolean;
    formActions?: React.ReactNode;
    getFieldState: UseGrantForm["getFieldState"];
    getValues: UseGrantForm["getValues"];
    maximumGrantableShares: number;
    onCancel?: () => void;
    onGranteesUpdated: () => void;
    organizationFragment: GrantForm_Organization$key;
    resetField: UseGrantForm["resetField"];
    setValue: UseGrantForm["setValue"];
    viewerFragment: GrantForm_Viewer$key;
  } & React.ComponentProps<"form">
> = ({
  className,
  control,
  creatingGrant,
  formActions,
  getFieldState,
  getValues,
  maximumGrantableShares,
  onCancel,
  onGranteesUpdated,
  organizationFragment,
  resetField,
  setValue,
  viewerFragment,
  ...props
}) => {
  const intl = useIntl();
  const formState = useFormState({ control });

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const activeGrantees = useFragment<GrantForm_Grantees$key>(
    GRANTEES_FRAGMENT,
    organization.activeGrantees.edges.map((edge) => edge.node),
  );

  const showEquityGridFeature = organization.equityGrid.activated;

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const [
    grantLabel,
    exercisePrice,
    granteeId,
    quantityGranted,
    accelerationClause,
    instrumentId,
    earlyExercise,
  ] = useWatch({
    control,
    name: [
      "label",
      "exercisePrice",
      "granteeId",
      "quantityGranted",
      "accelerationClause",
      "instrumentId",
      "earlyExercise",
    ],
  });

  const grantee = useMemo(
    () => activeGrantees.find((grantee) => grantee.id === granteeId),
    [granteeId, activeGrantees],
  );

  const compatibleInstruments = useMemo(
    () => grantee?.instruments ?? [],
    [grantee],
  );

  const instrument = useMemo(
    () =>
      compatibleInstruments.find(
        (instrument) => instrument.id === instrumentId,
      ),
    [instrumentId, compatibleInstruments],
  );

  const defaultPostTerminationExercisePeriodId = useMemo(
    () => grantee?.defaultPostTerminationExercisePeriod?.id ?? null,
    [grantee],
  );

  useEffect(() => {
    if (defaultPostTerminationExercisePeriodId && creatingGrant) {
      setValue(
        "postTerminationExercisePeriodId",
        defaultPostTerminationExercisePeriodId,
      );
    }
  }, [defaultPostTerminationExercisePeriodId, creatingGrant, setValue]);

  const { isDirty } = getFieldState("granteeId", formState);

  const { setTrue: onGranteeModified, value: granteeWasModified } =
    useBoolean(false);

  useEffect(() => {
    if (isDirty) {
      onGranteeModified();
    }
  }, [isDirty, onGranteeModified]);

  useEffect(() => {
    if (granteeWasModified) {
      const granteeHasAlreadyBeenGranted =
        (grantee?.ctmsGrants.length ?? 0) + (grantee?.easopGrants.length ?? 0) >
        0;
      const now = formatISO(new Date(), { representation: "date" });

      setValue(
        "vestingStartDate",
        granteeHasAlreadyBeenGranted
          ? now
          : (grantee?.contractStartDate ?? now),
      );

      if (grantee?.defaultVestingSchedule) {
        setValue("vestingScheduleId", grantee?.defaultVestingSchedule.id);
      }
    }
  }, [
    grantee,
    setValue,
    granteeWasModified,
    organization,
    showEquityGridFeature,
  ]);

  useEffect(() => {
    if (granteeWasModified) {
      const firstFilteredInstrument = compatibleInstruments[0];
      if (firstFilteredInstrument) {
        setValue("instrumentId", firstFilteredInstrument.id);
      }
    }
  }, [compatibleInstruments, setValue, granteeWasModified]);

  useEffect(() => {
    if (granteeWasModified) {
      if (grantee?.earlyExerciseIsAllowedByDefault) {
        setValue("earlyExercise", true);
      }
    }
  }, [setValue, granteeWasModified, grantee?.earlyExerciseIsAllowedByDefault]);

  const [showAccelerationSection, _setShowAccelerationSection] =
    useState(!!accelerationClause);

  useEffect(() => {
    if (granteeWasModified) {
      if (grantee?.defaultAccelerationClause) {
        _setShowAccelerationSection(true);
        setValue("accelerationClause", grantee?.defaultAccelerationClause);
      }
    }
  }, [
    setValue,
    granteeWasModified,
    grantee?.defaultAccelerationClause,
    _setShowAccelerationSection,
  ]);

  const granteeContractNotStartedYet = useMemo(() => {
    if (!grantee?.contractStartDate) {
      return false;
    }

    return isAfter(new Date(grantee?.contractStartDate), new Date());
  }, [grantee]);

  const grantedSharesController = useGrantedSharesController({
    fullyDilutedShares: organization.fullyDilutedShares,
    initialState: {
      mode: "FULLY_DILUTED",
      shares: getValues().quantityGranted,
    },
    latestPricePerShare: organization.latestPricePerShare?.value ?? null,
    maximumShares: maximumGrantableShares,
  });

  useEffect(() => {
    if (grantedSharesController.shares === null) {
      return resetField("quantityGranted");
    }

    setValue("quantityGranted", grantedSharesController.shares);
  }, [grantedSharesController.shares, resetField, setValue]);

  const trackEvent = useTrackEvent();

  const isVirtual = instrument?.equityType?.awardSuperType === "SAR";

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  const setShowAccelerationSection = useCallback(
    (value: boolean) => {
      if (value) {
        setValue("accelerationClause", AccelerationClauseOptions[0].value);
      } else {
        setValue("accelerationClause", null);
      }
      _setShowAccelerationSection(value);
    },
    [setValue],
  );

  const exercisePriceLowerThanValuationMessage = useMemo(() => {
    switch (instrument?.valuation.type ?? "FMV_409A") {
      case "FMV_409A":
        return (
          <>
            I acknowledge that I am aware of the risks and adverse tax
            consequences that can arise as a result of opting for an exercise
            price which is lower than the fair market value of the company’s
            shares as at the time of grant of the options
          </>
        );
      case "UK_VALUATION":
        return (
          <>
            I acknowledge that I am aware of the risks and adverse tax
            consequences that can arise as a result of opting for an exercise
            price which is lower than the EMI valuation of the company’s shares
            as at the time of grant of the options
          </>
        );
    }
  }, [instrument]);

  const noValidValuationWarningMessage = useMemo(() => {
    switch (instrument?.valuation.type ?? "FMV_409A") {
      case "FMV_409A":
        return (
          <>
            There is no valid Fair Market Value. Please head over to your Cap
            Table Management System and request a 409A.
          </>
        );
      case "UK_VALUATION":
        return <>There is no valid EMI valuation.</>;
    }
  }, [instrument]);

  const currentValuationMessage = useMemo(() => {
    if (!instrument || instrument.valuation.requiresNewValuationToGrant)
      return null;
    if (instrument.valuation.valueInDollars === null) return null;

    switch (instrument.valuation.type) {
      case "FMV_409A":
        return (
          <>
            Current FMV:{" "}
            <FormattedFMV value={instrument.valuation.valueInDollars} />
          </>
        );
      case "UK_VALUATION":
        return (
          <>
            Current EMI valuation:{" "}
            <FormattedFMV value={instrument.valuation.valueInDollars} />
          </>
        );
    }
  }, [instrument]);

  const quantityGrantedConverter = useMemo(() => {
    if (isNil(quantityGranted)) return null;

    return {
      ownership: sharesToFullyDilutedRatio(quantityGranted),
      shares: quantityGranted,
      usdValue: sharesToValue(quantityGranted),
    };
  }, [quantityGranted, sharesToFullyDilutedRatio, sharesToValue]);

  return (
    <>
      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={closeGranteeFormSlide}
        onGranteeDeleted={closeGranteeFormSlide}
        onGranteeUpdated={closeGranteeFormSlide}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />

      <CreatePTEPSlideOverRemote.Provider>
        <CreateVestingScheduleSlideOverRemote.Provider>
          <form
            className={classNames(className, "flex flex-col")}
            {...props}
            data-cy="GrantForm"
          >
            <div className="space-y-6 px-6 py-4">
              <Controller
                control={control}
                defaultValue={undefined}
                name="granteeId"
                render={({ field, fieldState }) => (
                  <FormRow error={fieldState.error?.message} label="Grantee">
                    <GranteeSelect
                      granteesFragment={activeGrantees}
                      onChange={field.onChange}
                      onGranteesUpdated={onGranteesUpdated}
                      organizationFragment={organization}
                      value={field.value}
                    />
                  </FormRow>
                )}
              />

              {grantee?.workRelationship === "EoREmployee" && (
                <NoticeMessage size="Small" variant="Idea">
                  It is recommended to inform the Employer of Record whenever
                  you grant equity to employees.
                </NoticeMessage>
              )}

              {grantee && grantee.grantableStatus !== "GRANTABLE" ? (
                <GranteeNotGrantableAlert
                  granteeFragment={grantee}
                  onCompleteGranteeInformationClick={() => {
                    openGranteeFormSlideInEditMode(grantee);
                  }}
                  organizationFragment={organization}
                  viewerFragment={viewer}
                />
              ) : granteeContractNotStartedYet ? (
                <GranteeContractNotStartedYetNoticeMessage />
              ) : null}

              <div className="space-y-1">
                <Controller
                  control={control}
                  name="instrumentId"
                  render={({ field, fieldState }) => (
                    <FormRow
                      error={fieldState.error?.message}
                      label="Equity type"
                    >
                      <SelectAutocomplete
                        getOptionLabel={(instrument) => instrument.name}
                        getOptionValue={(instrument) => instrument.id}
                        onChange={(option) => {
                          field.onChange(option?.id);
                        }}
                        options={compatibleInstruments}
                        value={compatibleInstruments.find(
                          (instrument) => instrument.id === field.value,
                        )}
                      />
                    </FormRow>
                  )}
                />
                {instrument?.needsAdditionalFormalities && (
                  <div className="pt-2">
                    <NeedsAdditionalFormalitiesWarning
                      instrumentFragment={instrument}
                    />
                  </div>
                )}
              </div>

              {viewer.isSuperAdmin && (
                <FormRow
                  error={formState.errors.label?.message}
                  label="Unique ID"
                >
                  <Input
                    invalid={!!formState.errors.label}
                    placeholder="ES-1, OPT-2..."
                    {...control.register("label")}
                  />
                </FormRow>
              )}

              <div className="space-y-1">
                <FormRow
                  error={formState.errors.quantityGranted?.message}
                  label="Quantity granted"
                  subLabel={`Maximum grant: ${intl.formatNumber(
                    maximumGrantableShares,
                  )}`}
                >
                  <GrantedSharesInput
                    {...grantedSharesController.inputController}
                    isVirtual={isVirtual}
                    name="quantityGranted"
                    spacing={2}
                  />
                </FormRow>
                {quantityGrantedConverter &&
                quantityGrantedConverter.shares > 0 ? (
                  <div className="mt-4">
                    {quantityGrantedConverter.usdValue !== null && (
                      <div className="text-sm text-gray-09">
                        <span className="text-black-07">$-value granted:</span>{" "}
                        <FormattedNumber
                          currency="USD"
                          style="currency"
                          value={quantityGrantedConverter.usdValue}
                        />
                      </div>
                    )}
                    <div className="mt-1 text-sm text-gray-09">
                      <span className="text-black-07">
                        # {isVirtual ? "SARs" : "shares"} granted:
                      </span>{" "}
                      <FormattedNumber
                        value={quantityGrantedConverter.shares}
                      />
                    </div>
                    {quantityGrantedConverter.ownership !== null && (
                      <div className="mt-1 text-sm text-gray-09">
                        <span className="text-black-07">
                          % granted ({isVirtual ? "virtual " : ""}
                          fully diluted):
                        </span>{" "}
                        <Percentage
                          value={quantityGrantedConverter.ownership}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                {showEquityGridFeature && grantee && (
                  <div className="pt-1">
                    <EquityGridLevelTopUpTip
                      granteeFragment={grantee}
                      onGrantTipClicked={(shares) => {
                        trackEvent("Grant Draft - Use Equity Grid Value", {
                          granteeId: grantee.id,
                          grantLabel,
                          grantMethod: "expert",
                          instrumentId,
                        });
                        grantedSharesController.setMode("SHARES");
                        grantedSharesController.setShares(shares);
                      }}
                    />
                  </div>
                )}
              </div>

              <FormRow
                error={formState.errors.vestingScheduleId?.message}
                label="Vesting schedule"
              >
                <SelectVestingScheduleInput.Form
                  control={control}
                  name="vestingScheduleId"
                  organizationFragment={organization}
                />
              </FormRow>

              {instrument?.equityType && (
                <GrantWatchouts.Rule100KWatchout
                  equityTypeFragment={instrument.equityType}
                />
              )}

              <Controller
                control={control}
                name="vestingStartDate"
                render={({ field, fieldState }) => (
                  <FormRow
                    error={fieldState.error?.message}
                    label="Vesting start date"
                  >
                    <DatePicker onChange={field.onChange} value={field.value} />
                  </FormRow>
                )}
              />

              <PostTerminationExercisePeriodSelectionBlock
                control={control}
                instrumentFragment={instrument ?? null}
                organizationFragment={organization}
              />

              <div className="space-y-1">
                <FormRow
                  error={formState.errors.exercisePrice?.message}
                  label={
                    isVirtual ? "Virtual exercise price" : "Exercise price"
                  }
                  subLabel={currentValuationMessage}
                >
                  <Input
                    before="$"
                    invalid={!!formState.errors.exercisePrice}
                    min={0}
                    step={Input.FMV_DECIMAL_STEP}
                    type="number"
                    {...control.register("exercisePrice", {
                      valueAsNumber: true,
                    })}
                    id="exercise-price"
                  />
                </FormRow>

                {instrument?.valuation.requiresNewValuationToGrant && (
                  <NoticeMessage size="Small" variant="Danger">
                    {noValidValuationWarningMessage}
                  </NoticeMessage>
                )}
              </div>

              {instrument &&
              !isNil(exercisePrice) &&
              instrument.valuation.valueInDollars !== null &&
              exercisePrice < instrument.valuation.valueInDollars ? (
                <FormRow
                  error={formState.errors.exercisePriceBelowFMVSetOn?.message}
                  label={null}
                >
                  <div className="rounded border-[0.5px] border-orange-05 bg-orange-01 p-4 shadow-100">
                    <Typography as="div" variant="Medium/Small">
                      Please confirm that you want to lower the exercise price.
                    </Typography>
                    <div className="mt-4 flex gap-4">
                      <Controller
                        control={control}
                        name="exercisePriceBelowFMVSetOn"
                        render={({ field }) => (
                          <Checkbox
                            checked={!!field.value}
                            onChange={(e) => {
                              field.onChange(
                                e.currentTarget.checked
                                  ? formatISO(new Date(), {
                                      representation: "date",
                                    })
                                  : null,
                              );
                            }}
                          />
                        )}
                      />

                      <Typography as="div" variant="Regular/Extra Small">
                        {exercisePriceLowerThanValuationMessage}
                      </Typography>
                    </div>
                  </div>
                </FormRow>
              ) : null}

              {!isVirtual ? (
                <>
                  {organization.allowEarlyExercise && (
                    <div className="space-y-2">
                      <FormRow
                        error={formState.errors.earlyExercise?.message}
                        label="Early exercise"
                      >
                        <Controller
                          control={control}
                          name="earlyExercise"
                          render={({ field }) => (
                            <Toggle
                              enabled={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </FormRow>
                      {instrument?.isEarlyExerciseBeneficial && (
                        <EarlyExerciseBeneficialNoticeMessage
                          instrumentFragment={instrument}
                          organizationFragment={organization}
                        />
                      )}
                      {organization.hasCooleyAsOutsideCounsel &&
                        earlyExercise && (
                          <NoticeMessage size="Small" variant="Warning">
                            Early exercise is an important decision that
                            indirectly impacts your investors. If you opt for
                            early exercise, discuss it with your investors and
                            lawyers first.
                          </NoticeMessage>
                        )}
                      {instrument?.equityType && (
                        <GrantWatchouts.Rule83BWatchout
                          earlyExerciseAllowed={earlyExercise ?? false}
                          equityTypeFragment={instrument.equityType}
                        />
                      )}
                    </div>
                  )}

                  {organization.allowAcceleration && (
                    <div className="space-y-2">
                      <FormRow label="Allow acceleration">
                        <Toggle
                          enabled={showAccelerationSection}
                          name="showAccelerationSection"
                          onChange={setShowAccelerationSection}
                        />
                      </FormRow>
                      <NoticeMessage size="Small" variant="Warning">
                        Acceleration is an important decision that indirectly
                        impacts your investors. If you opt for acceleration,
                        discuss it with your investors and lawyers first.
                      </NoticeMessage>
                    </div>
                  )}
                </>
              ) : null}
              <FadeAndScaleTransition show={showAccelerationSection}>
                <Controller
                  control={control}
                  name="accelerationClause"
                  render={({ field, fieldState }) => (
                    <FormRow
                      error={fieldState.error?.message}
                      label="Acceleration scenario"
                    >
                      <SelectAutocomplete
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        onChange={(option) => {
                          field.onChange(option?.value);
                        }}
                        options={AccelerationClauseOptions}
                        value={AccelerationClauseOptions.find(
                          (option) => field.value === option.value,
                        )}
                      />
                    </FormRow>
                  )}
                />
              </FadeAndScaleTransition>
            </div>

            <FormActions className="px-6 pb-6 pt-2" onCancel={onCancel}>
              {formActions}
            </FormActions>
          </form>
        </CreateVestingScheduleSlideOverRemote.Provider>
      </CreatePTEPSlideOverRemote.Provider>
    </>
  );
};
