import { zodResolver } from "@hookform/resolvers/zod";
import { isNil } from "lodash";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { graphql } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "../../../../components/ui/Button";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { Input } from "../../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "../../../../components/ui/Modal";
import { zodExtra } from "../../../../helpers/zod-extra";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  SimulateExerciseModal_Query,
  SimulateExerciseModal_Query$data,
} from "./__generated__/SimulateExerciseModal_Query.graphql";

const FORM_NAME = "simulate-exercise";

const makeFormSchema = ({
  ctmsGrants,
}: {
  ctmsGrants: SimulateExerciseModal_Query$data["grantee"]["ctmsGrants"];
}) =>
  z
    .object({
      ctmsGrantId: z.string().min(1),
      quantityToExercise: zodExtra.preprocessNaNToUndefined(
        z.number().positive(),
      ),
    })
    .refine(
      ({ ctmsGrantId, quantityToExercise }) => {
        const selectedCtmsGrant = ctmsGrants.find(
          (grant) => grant.id === ctmsGrantId,
        );

        if (!selectedCtmsGrant) {
          return true;
        }

        return quantityToExercise <= selectedCtmsGrant.quantityIssued;
      },
      {
        message: "You cannot exercise more shares that the quantity granted.",
        path: ["quantityToExercise"],
      },
    );

type FormSchema = z.input<ReturnType<typeof makeFormSchema>>;
type FormSubmitData = z.output<ReturnType<typeof makeFormSchema>>;

const QUERY = graphql`
  query SimulateExerciseModal_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) @required(action: THROW) {
      __typename
      organization {
        id
      }
      ctmsGrants {
        id
        label
        exercisePrice
        vestingStartDate
        quantityIssued
        cumulativeVested
      }
    }
  }
`;

export const SimulateExerciseModal: React.FC<{
  granteeId: string;
  onExited: () => void;
}> = ({ granteeId, onExited }) => {
  const {
    query: { grantee },
  } = useQuery<SimulateExerciseModal_Query>(QUERY, {
    granteeId,
  });
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
  };
  const form = useForm<FormSubmitData, FormSchema>({
    resolver: zodResolver(makeFormSchema({ ctmsGrants: grantee.ctmsGrants })),
  });
  const intl = useIntl();

  const onFormSubmit = form.handleSubmit(
    ({ ctmsGrantId, quantityToExercise }) => {
      const path = generatePath(
        APPLICATION_ROUTES[
          "organizationEquityCtmsGrantExerciseRequestSimulation"
        ],
        {
          ctmsGrantId,
          organizationId: grantee.organization.id,
        },
      );

      const search = new URLSearchParams();

      search.set("quantityExercised", quantityToExercise.toString());

      navigate(`${path}?${search.toString()}`);
    },
  );

  const ctmsGrantsWithExercisePrice = grantee.ctmsGrants.filter(
    (grant) => !isNil(grant.exercisePrice),
  );

  const [ctmsGrantId] = form.watch(["ctmsGrantId"]);

  const selectedCtmsGrant = grantee.ctmsGrants.find(
    (grant) => grant.id === ctmsGrantId,
  );

  const onVestedButtonClick = useCallback(() => {
    if (selectedCtmsGrant) {
      form.setValue("quantityToExercise", selectedCtmsGrant.cumulativeVested);
    }
  }, [form, selectedCtmsGrant]);

  const onGrantedButtonClick = useCallback(() => {
    if (selectedCtmsGrant) {
      form.setValue("quantityToExercise", selectedCtmsGrant.quantityIssued);
    }
  }, [form, selectedCtmsGrant]);

  return (
    <Modal onClose={handleClose} onExited={onExited} show={show}>
      <Modal.Content
        actionsInHeader={<Button form={FORM_NAME}>Simulate</Button>}
        title="Simulate an exercise"
      >
        <form
          className="space-y-4"
          id={FORM_NAME}
          name={FORM_NAME}
          onSubmit={onFormSubmit}
        >
          <FormRow.Form control={form.control} label="Grant" name="ctmsGrantId">
            <SelectAutocomplete.Form
              control={form.control}
              getOptionLabel={(grant) => {
                if (!grant.vestingStartDate) {
                  return grant.label;
                }
                return `${grant.label} (vesting from ${intl.formatDate(
                  grant.vestingStartDate,
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  },
                )})`;
              }}
              getOptionValue={(grant) => grant.id}
              name="ctmsGrantId"
              options={ctmsGrantsWithExercisePrice}
              usePortal
            />
          </FormRow.Form>
          <FormRow.Form
            context={
              selectedCtmsGrant && (
                <FormattedMessage
                  defaultMessage="<VestedButton>{vested, plural, one {# share} other {# shares}}</VestedButton> have vested out of the <GrantedButton>{granted, plural, one {# share} other {# shares}}</GrantedButton> granted"
                  values={{
                    granted: selectedCtmsGrant.quantityIssued,
                    GrantedButton: (chunks) => (
                      <button
                        className="text-primary"
                        onClick={onGrantedButtonClick}
                        type="button"
                      >
                        {chunks}
                      </button>
                    ),
                    vested: selectedCtmsGrant.cumulativeVested,
                    VestedButton: (chunks) => (
                      <button
                        className="text-primary"
                        onClick={onVestedButtonClick}
                        type="button"
                      >
                        {chunks}
                      </button>
                    ),
                  }}
                />
              )
            }
            control={form.control}
            label="Quantity to exercise (shares number)"
            name="quantityToExercise"
          >
            <Input.Form
              control={form.control}
              name="quantityToExercise"
              type="number"
              valueAsNumber
            />
          </FormRow.Form>
        </form>
      </Modal.Content>
    </Modal>
  );
};
