/**
 * @generated SignedSource<<d33a3d6593adbd1bea5420f2d64add41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityGrid_ConfigureGrants_Query$variables = {
  organizationId: string;
};
export type EquityGrid_ConfigureGrants_Query$data = {
  readonly organization: {
    readonly equityGrid: {
      readonly activated: boolean;
      readonly id: string;
      readonly levels: ReadonlyArray<{
        readonly equityInShares: number | null;
        readonly equityInUsd: number;
        readonly id: string;
        readonly name: string;
        readonly ownership: number | null;
        readonly role: string | null;
        readonly yearlySalary: number | null;
      }>;
      readonly name: string;
    };
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreateEquityGridLevelSlideOver_Organization" | "EditEquityGridLevelSlideOver_Organization">;
  };
};
export type EquityGrid_ConfigureGrants_Query = {
  response: EquityGrid_ConfigureGrants_Query$data;
  variables: EquityGrid_ConfigureGrants_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EquityGrid",
  "kind": "LinkedField",
  "name": "equityGrid",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "levels",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yearlySalary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equityInShares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equityInUsd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownership",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityGrid_ConfigureGrants_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreateEquityGridLevelSlideOver_Organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditEquityGridLevelSlideOver_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityGrid_ConfigureGrants_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullyDilutedShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricePerShare",
            "kind": "LinkedField",
            "name": "latestPricePerShare",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "64494f28a5b518cd7fb48134a0841fda",
    "id": null,
    "metadata": {},
    "name": "EquityGrid_ConfigureGrants_Query",
    "operationKind": "query",
    "text": "query EquityGrid_ConfigureGrants_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    equityGrid {\n      id\n      activated\n      name\n      levels {\n        id\n        name\n        role\n        yearlySalary\n        equityInShares\n        equityInUsd\n        ownership\n      }\n    }\n    ...CreateEquityGridLevelSlideOver_Organization\n    ...EditEquityGridLevelSlideOver_Organization\n    id\n  }\n}\n\nfragment CreateEquityGridLevelSlideOver_Organization on Organization {\n  ...EquityGridLevelForm_ConfigureGrants_Organization\n}\n\nfragment EditEquityGridLevelSlideOver_Organization on Organization {\n  ...EquityGridLevelForm_ConfigureGrants_Organization\n}\n\nfragment EquityGridLevelForm_ConfigureGrants_Organization on Organization {\n  fullyDilutedShares\n  latestPricePerShare {\n    value\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa6f28165e37d3cfef5b4a7ba8c1e4b4";

export default node;
