import React, { useCallback, useContext, useMemo } from "react";

import { Account, useAuthentication } from "./AuthenticationProvider";

type OnLoginSuccess = (account: Account) => void;

type LoginPageRender = (options: {
  onLoginSuccess: OnLoginSuccess;
}) => React.ReactElement;

const AuthenticatedAccountContext = React.createContext<Account | null>(null);

export const useAccount = () => {
  const account = useContext(AuthenticatedAccountContext);

  return account;
};

export const useAuthenticatedAccount = () => {
  const account = useAccount();

  if (!account) {
    throw new Error(
      "Can't get authenticated account, make sure you're inside an AuthenticatedGuard",
    );
  }

  return account;
};

const AuthenticatedGuard: React.FC<{
  children: React.ReactNode;
  loginPageRender: LoginPageRender;
}> = ({ children, loginPageRender }) => {
  const authenticationContext = useAuthentication();
  const onLoginSuccess: OnLoginSuccess = useCallback(
    (account) => {
      authenticationContext.setAccount(account);
    },
    [authenticationContext],
  );

  const value = useMemo(
    () => authenticationContext.account,
    [authenticationContext.account],
  );

  if (!authenticationContext.account) {
    return loginPageRender({
      onLoginSuccess,
    });
  }

  return (
    <AuthenticatedAccountContext.Provider value={value}>
      {children}
    </AuthenticatedAccountContext.Provider>
  );
};

export const MockedAuthenticatedGuardProvider: React.FC<
  React.PropsWithChildren<{
    account?: Account;
  }>
> = ({
  account = {
    email: "guy_number_one@gmail.com",
    firstName: "Guy",
    id: "1",
    lastName: "Fieri",
  },
  children,
}) => (
  <AuthenticatedAccountContext.Provider value={account}>
    {children}
  </AuthenticatedAccountContext.Provider>
);

export default AuthenticatedGuard;
