import { zodResolver } from "@hookform/resolvers/zod";
import { throttle } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery, graphql } from "relay-runtime";
import { z } from "zod";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { InviteEquityViewerSlideOver_Account_Query } from "./__generated__/InviteEquityViewerSlideOver_Account_Query.graphql";
import { InviteEquityViewerSlideOver_AddEquityViewer_Mutation } from "./__generated__/InviteEquityViewerSlideOver_AddEquityViewer_Mutation.graphql";
import { InviteEquityViewerSlideOver_Admin_Query } from "./__generated__/InviteEquityViewerSlideOver_Admin_Query.graphql";
import { InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation } from "./__generated__/InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { FormRow } from "./ui/Form/FormRow";
import { Input } from "./ui/Form/Inputs/Input";
import { SlideOver } from "./ui/SlideOver";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const CREATE_ACCOUNT_AND_ADD_IT_AS_EQUITY_VIEWER_MUTATION = graphql`
  mutation InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation(
    $organizationId: OrganizationId!
    $attributes: AccountAttributes!
  ) {
    createAccountAndAddItAsEquityViewer(
      organizationId: $organizationId
      attributes: $attributes
      source: "Remote Equity Web App"
    ) {
      organization {
        ...OrganizationSettingsEquityViewers_Organization
      }
    }
  }
`;

const ADD_EQUITY_VIEWER_MUTATION = graphql`
  mutation InviteEquityViewerSlideOver_AddEquityViewer_Mutation(
    $organizationId: OrganizationId!
    $accountId: String!
  ) {
    addEquityViewer(
      accountId: $accountId
      organizationId: $organizationId
      source: "Remote Equity Web App"
    ) {
      organization {
        ...OrganizationSettingsEquityViewers_Organization
      }
    }
  }
`;

const ACCOUNT_QUERY = graphql`
  query InviteEquityViewerSlideOver_Account_Query($email: String!) {
    accountFromEmail(email: $email) {
      id
      email
      firstName
      lastName
    }
  }
`;

const EQUITY_VIEWER_QUERY = graphql`
  query InviteEquityViewerSlideOver_Admin_Query(
    $organizationId: OrganizationId!
    $accountId: String!
  ) {
    isAccountAnOrganizationEquityViewer(
      accountId: $accountId
      organizationId: $organizationId
    )
  }
`;

const EquityViewerFormSchema = z.object({
  email: z.string().trim().email(),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
});
const EmailSchema = z.string().trim().email();

interface Account {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export type EquityViewerFormInputs = z.infer<typeof EquityViewerFormSchema>;

export const InviteEquityViewerSlideOver: React.FC<{
  onClose: () => void;
  organizationId: string;
  show: boolean;
}> = ({ onClose, organizationId, show }) => {
  const relayEnvironment = useRelayEnvironment();
  const form = useForm({
    resolver: zodResolver(EquityViewerFormSchema),
  });

  const toaster = useToaster();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [existingAccount, setExistingAccount] = useState<Account | null>(null);
  const [isAlreadyEquityViewer, setIsAlreadyEquityViewer] = useState(false);

  const refreshIsAlreadyEquityViewer = useMemo(
    () => (account: Account) => {
      fetchQuery<InviteEquityViewerSlideOver_Admin_Query>(
        // @ts-expect-error - relay-runtime types are not up-to-date
        relayEnvironment,
        EQUITY_VIEWER_QUERY,
        {
          accountId: account.id,
          organizationId,
        },
      ).subscribe({
        next: ({ isAccountAnOrganizationEquityViewer }) => {
          setIsAlreadyEquityViewer(isAccountAnOrganizationEquityViewer);
          if (isAccountAnOrganizationEquityViewer) {
            form.setError("email", {
              message: "This person is already an Equity Viewer.",
              type: "custom",
            });
          }
        },
      });
    },
    [form, organizationId, relayEnvironment],
  );

  const onExistingAccountChanged = useMemo(
    () => (account: Account | null) => {
      if (account) {
        form.setValue("firstName", account.firstName);
        form.setValue("lastName", account.lastName);
        refreshIsAlreadyEquityViewer(account);
      }
    },
    [form, refreshIsAlreadyEquityViewer],
  );

  const _refreshExistingAccount = useMemo(
    () => (email: string) => {
      const _isValidEmail = EmailSchema.safeParse(email).success;
      setIsAlreadyEquityViewer(false);
      form.clearErrors("email");
      setIsValidEmail(_isValidEmail);
      if (EmailSchema.safeParse(email).success) {
        fetchQuery<InviteEquityViewerSlideOver_Account_Query>(
          // @ts-expect-error - relay-runtime types are not up-to-date
          relayEnvironment,
          ACCOUNT_QUERY,
          {
            email,
          },
        ).subscribe({
          next: ({ accountFromEmail: account }) => {
            setExistingAccount(account);
            onExistingAccountChanged(account);
          },
        });
      }
    },
    [form, onExistingAccountChanged, relayEnvironment],
  );

  const refreshExistingAccount = useMemo(
    () => throttle(_refreshExistingAccount, 250),
    [_refreshExistingAccount],
  );

  const [createAccountAndAddItAsEquityViewer] =
    useSafeMutation<InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation>(
      CREATE_ACCOUNT_AND_ADD_IT_AS_EQUITY_VIEWER_MUTATION,
    );
  const [addEquityViewer] =
    useSafeMutation<InviteEquityViewerSlideOver_AddEquityViewer_Mutation>(
      ADD_EQUITY_VIEWER_MUTATION,
    );

  const onSubmit = form.handleSubmit(async (_data) => {
    const data = _data as EquityViewerFormInputs;
    if (existingAccount) {
      await addEquityViewer({
        variables: {
          accountId: existingAccount.id,
          organizationId,
        },
      });
    } else {
      await createAccountAndAddItAsEquityViewer({
        variables: {
          attributes: { ...data },
          organizationId: organizationId,
        },
      });
    }

    toaster.push(
      <Toast title="Wonderful!">Equity viewer successfully invited!</Toast>,
    );
    onClose();
    form.reset();
  });

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose}>
          Invite a new equity viewer
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <form className="space-y-4 px-6 py-4" onSubmit={onSubmit}>
        <FormRow label="Email address">
          <Input
            {...form.control.register("email")}
            onChange={(event) => {
              refreshExistingAccount(event.target.value);
            }}
            placeholder="johndoe@my.company"
            type="email"
          />
        </FormRow>
        <FormRow label="First name">
          <Input
            {...form.control.register("firstName")}
            disabled={!!existingAccount && isValidEmail}
            placeholder="John"
          />
        </FormRow>
        <FormRow label="Last name">
          <Input
            {...form.control.register("lastName")}
            disabled={!!existingAccount && isValidEmail}
            placeholder="Doe"
          />
        </FormRow>
        <div className="flex flex-row-reverse gap-4">
          <Button
            disabled={isAlreadyEquityViewer}
            loading={form.formState.isSubmitting}
            size="small"
            type="submit"
          >
            Invite
          </Button>
          <Button
            onClick={onClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
        </div>
        <Typography
          as="div"
          className="text-right text-gray-09"
          variant="Regular/Caption"
        >
          After you click Invite, we&apos;ll send an email to invite the equity
          viewer.
        </Typography>
      </form>
    </SlideOver>
  );
};
