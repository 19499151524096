/**
 * @generated SignedSource<<62b63b240eab16c636fa26257e14a919>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_PENDING_BOARD_APPROVAL";
export type FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation$variables = {
  fairMarketValueId: string;
  valuationFirm: string;
  valuationReportDocumentId: string;
};
export type FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation$data = {
  readonly sendFairMarketValueToBoardForConsent: {
    readonly valuationWarnings: ReadonlyArray<{
      readonly reason: ValuationWarningReason;
      readonly valuationType: ValuationType;
    }>;
  };
};
export type FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation = {
  response: FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation$data;
  variables: FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fairMarketValueId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valuationFirm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valuationReportDocumentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fairMarketValueId",
    "variableName": "fairMarketValueId"
  },
  {
    "kind": "Variable",
    "name": "valuationFirm",
    "variableName": "valuationFirm"
  },
  {
    "kind": "Variable",
    "name": "valuationReportDocumentId",
    "variableName": "valuationReportDocumentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationValuationWarning",
  "kind": "LinkedField",
  "name": "valuationWarnings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valuationType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "sendFairMarketValueToBoardForConsent",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "sendFairMarketValueToBoardForConsent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a001f8b53d3649cad34d3b0ec0080511",
    "id": null,
    "metadata": {},
    "name": "FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation",
    "operationKind": "mutation",
    "text": "mutation FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation(\n  $fairMarketValueId: UUID!\n  $valuationFirm: String!\n  $valuationReportDocumentId: String!\n) {\n  sendFairMarketValueToBoardForConsent(fairMarketValueId: $fairMarketValueId, valuationFirm: $valuationFirm, valuationReportDocumentId: $valuationReportDocumentId) {\n    valuationWarnings {\n      reason\n      valuationType\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "63988f296124b4290d4d67c9cb1867bd";

export default node;
