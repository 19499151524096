import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

import { LeftConfetti, RightConfetti } from "../Confettis";
import { ToastElementProps } from "../Toaster";
import { Typography } from "./Typography";

const VARIANT = {
  congrats: {
    className: classNames("bg-green-02 text-green-08"),
    leftAddon: <LeftConfetti className="w-5" />,
    rightAddon: <RightConfetti className="w-5" />,
    textClassName: classNames("text-green-07"),
  },
  error: {
    className: classNames("bg-red-02 text-red-08"),
    leftAddon: <ExclamationTriangleIcon className="h-5 w-5 text-red" />,
    rightAddon: null,
    textClassName: classNames("text-red-07"),
  },
  success: {
    className: classNames("bg-green-02 text-green-08"),
    leftAddon: <CheckIcon className="h-5 w-5 text-green" />,
    rightAddon: null,
    textClassName: classNames("text-green-07"),
  },
};

const _Toast: React.ForwardRefRenderFunction<
  HTMLDivElement,
  React.PropsWithChildren<
    {
      title?: React.ReactNode;
      variant?: keyof typeof VARIANT;
    } & ToastElementProps
  >
> = (
  { children, className, onCloseButtonClick, title, variant = "success" },
  ref,
) => (
  <div
    className={classNames(
      "flex items-center gap-4 rounded-full px-4 py-2",
      VARIANT[variant].className,
      className,
    )}
    ref={ref}
  >
    {VARIANT[variant].leftAddon}
    <div className="flex items-center gap-2">
      {title && (
        <Typography as="div" variant="Medium/Small">
          {title}
        </Typography>
      )}
      <Typography
        as="div"
        className={VARIANT[variant].textClassName}
        variant="Regular/Small"
      >
        {children}
      </Typography>
    </div>
    {VARIANT[variant].rightAddon}
    {onCloseButtonClick && (
      <button onClick={onCloseButtonClick}>
        <XMarkIcon className="h-6 w-6" />
      </button>
    )}
  </div>
);

export const Toast = React.forwardRef(_Toast);
