import classNames from "classnames";

import { filterByPropertiesNotNull } from "../helpers/ts-utlity";
import { useSafeSimulatorContext } from "../pages/SafeSimulator/IContext";
import { Percentage } from "./Percentage";
import { PieChart } from "./PieChart";
import { Typography } from "./ui/Typography";

const _TooltipContent: React.FC<{
  children: React.ReactNode;
  ownership: null | number;
}> = ({ children, ownership }) => {
  return (
    <div className="space-y-0.5 rounded bg-black-07 p-4 text-left text-white">
      <Typography as="div" variant="Regular/Extra Small">
        {children}
      </Typography>
      <div className="space-y-0.5 text-gray-04">
        <Typography as="div" variant="Regular/Caption">
          Total percentage
        </Typography>
        <Typography as="div" variant="Medium/Caption">
          {ownership !== null ? <Percentage value={ownership} /> : "-"}
        </Typography>
      </div>
    </div>
  );
};

const TooltipContent: React.FC<{
  sharesKey: "current-shareholders" | "option-pool" | "safe";
}> = ({ sharesKey }) => {
  const {
    computedValues: {
      postConversionCurrentShareholdersOwnership,
      postConversionOptionPoolOwnership,
      postConversionSafeOwnership,
    },
  } = useSafeSimulatorContext();
  switch (sharesKey) {
    case "current-shareholders":
      return (
        <_TooltipContent ownership={postConversionCurrentShareholdersOwnership}>
          Current Sharesholders
        </_TooltipContent>
      );
    case "option-pool":
      return (
        <_TooltipContent ownership={postConversionOptionPoolOwnership}>
          Pool
        </_TooltipContent>
      );
    case "safe":
      return (
        <_TooltipContent ownership={postConversionSafeOwnership}>
          SAFE
        </_TooltipContent>
      );
  }
};

export const SafeSimulatorGraph: React.FC<{
  currentShareholdersShares: null | number;
  optionPoolShares: null | number;
  postConversionSafeShares: null | number;
}> = ({
  currentShareholdersShares,
  optionPoolShares,
  postConversionSafeShares,
}) => {
  const hasCurrentShareholdersShares = Boolean(currentShareholdersShares);
  const hasOptionPoolShares = Boolean(optionPoolShares);
  const hasPostConversionSafeShares = Boolean(postConversionSafeShares);

  return (
    <>
      <PieChart
        elements={filterByPropertiesNotNull(
          [
            {
              className: classNames("bg-purple-03 fill-purple-03"),
              key: "option-pool",
              value: optionPoolShares,
            },
            {
              className: classNames("bg-purple-05 fill-purple-05"),
              key: "current-shareholders",
              value: currentShareholdersShares,
            },
            {
              className: classNames("bg-green-04 fill-green-04"),
              key: "safe",
              value: postConversionSafeShares,
            },
          ] as const,
          "value",
        )}
        height={160}
        renderTooltip={({ key }) => <TooltipContent sharesKey={key} />}
      />
      <div className="grid grid-cols-3 items-center gap-2 text-gray-06">
        <Typography
          className={classNames({
            "text-purple": hasCurrentShareholdersShares,
          })}
          variant={
            hasCurrentShareholdersShares ? "Medium/Small" : "Regular/Small"
          }
        >
          Current Shareholders
        </Typography>
        <Typography
          className={classNames({ "text-purple-03": hasOptionPoolShares })}
          variant={hasOptionPoolShares ? "Medium/Small" : "Regular/Small"}
        >
          Pool
        </Typography>
        <Typography
          className={classNames({
            "text-green-04": hasPostConversionSafeShares,
          })}
          variant={
            hasPostConversionSafeShares ? "Medium/Small" : "Regular/Small"
          }
        >
          SAFE
        </Typography>
      </div>
    </>
  );
};
