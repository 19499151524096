import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useAlerter } from "../../../../components/Alerter";
import { Alert } from "../../../../components/ui/Alert";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Typography } from "../../../../components/ui/Typography";
import { useInstrumentValuationGuard_Grants$key } from "./__generated__/useInstrumentValuationGuard_Grants.graphql";
import { WarningNoticeMessageWithSlideOverExplanation } from "./WarningNoticeMessageWithSlideOverExplanation";

export type InstrumentValuationType = "FMV_409A" | "UK_VALUATION";

export const GuardWarning: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
}> = ({ children, title }) => (
  <NoticeMessage size="Small" variant="Danger">
    <div className="flex flex-col gap-2">
      <Typography className="text-red-05" variant="Medium/Extra Small">
        {title}
      </Typography>
      <Typography className="text-gray-09" variant="Regular/Extra Small">
        {children}
      </Typography>
    </div>
  </NoticeMessage>
);

export const WARNING_VALUATION_EXPIRED_MESSAGE_MAP: Record<
  InstrumentValuationType,
  string
> = {
  FMV_409A:
    "Drafts cannot be sent to your board members for approval until you’ve adopted a new 409A valuation.",
  UK_VALUATION:
    "Drafts cannot be sent to your board members for approval until you’ve received a new EMI valuation.",
};

const warning409AExpiredTitle = "Your 409A valuation has expired.";

const Warning409AExpired: React.FC = () => (
  <GuardWarning title={warning409AExpiredTitle}>
    {WARNING_VALUATION_EXPIRED_MESSAGE_MAP.FMV_409A}
  </GuardWarning>
);

const warningUKValuationExpiredTitle = "Your EMI valuation has expired.";

const WarningUKValuationExpired: React.FC = () => (
  <GuardWarning title={warningUKValuationExpiredTitle}>
    {WARNING_VALUATION_EXPIRED_MESSAGE_MAP.UK_VALUATION}
  </GuardWarning>
);

export const WarningValuationExpired: React.FC<{
  instrumentValuationType: InstrumentValuationType;
}> = ({ instrumentValuationType }) => {
  switch (instrumentValuationType) {
    case "FMV_409A":
      return <Warning409AExpired />;
    case "UK_VALUATION":
      return <WarningUKValuationExpired />;
  }
};

export const EASOP_GRANT_OUTDATED_REASON_MAP: Record<
  InstrumentValuationType,
  string
> = {
  FMV_409A: "Drafts are outdated since there has been a new 409A valuation.",
  UK_VALUATION: "Drafts are outdated since there has been a new EMI valuation.",
};

const EASOP_GRANT_OUTDATED_EXPLANATION_MAP: Record<
  InstrumentValuationType,
  string
> = {
  FMV_409A:
    "As a consequence, the exercise price chosen in your draft is no longer relevant and should be updated to reflect the fair market value in your latest 409A valuation. Please review and update your drafts.",
  UK_VALUATION:
    "As a consequence, the exercise price chosen in your draft is no longer relevant and should be updated to reflect the fair market value in your latest 409A valuation. Please review and update your drafts.",
};

export const WarningEasopGrantsOutdated: React.FC<{
  easopGrantsFragment: useInstrumentValuationGuard_Grants$key;
}> = ({ easopGrantsFragment }) => {
  const easopGrants = useFragment(EASOP_GRANTS_FRAGMENT, easopGrantsFragment);

  const outdatedEasopGrantsValuationTypes = useMemo(
    () =>
      _(easopGrants)
        .filter((easopGrant) =>
          easopGrant.warnings.includes("VALUATION_OUTDATED"),
        )
        .map((easopGrant) => easopGrant.instrument.valuation.type)
        .uniq()
        .value(),
    [easopGrants],
  );

  return outdatedEasopGrantsValuationTypes.map((type) => (
    <WarningNoticeMessageWithSlideOverExplanation
      explanation={EASOP_GRANT_OUTDATED_EXPLANATION_MAP[type]}
      helpEmailSubject="Some of my drafts are outdated"
      key={type}
    >
      {EASOP_GRANT_OUTDATED_REASON_MAP[type]}
    </WarningNoticeMessageWithSlideOverExplanation>
  ));
};

const WarningEasopGrant409AOutdated: React.FC = () => (
  <GuardWarning title="This draft is outdated since there has been a new 409A valuation.">
    As a consequence, the exercise price chosen in this draft is no longer
    relevant and should be updated to reflect the fair market value in your
    latest 409A valuation. Please either{" "}
    <span className="text-primary">edit this draft</span> to adjust the exercise
    price, or <span className="text-primary">delete it</span>.
  </GuardWarning>
);

const WarningEasopGrantUKValuationOutdated: React.FC = () => (
  <GuardWarning title="This draft is outdated since there has been a new EMI valuation.">
    As a consequence, the exercise price chosen in this draft is no longer
    relevant and should be updated to reflect your latest EMI valuation. Please
    either <span className="text-primary">edit this draft</span> to adjust the
    exercise price, or <span className="text-primary">delete it</span>.
  </GuardWarning>
);

export const WarningEasopGrantOutdated: React.FC<{
  instrumentValuationType: InstrumentValuationType;
}> = ({ instrumentValuationType }) => {
  switch (instrumentValuationType) {
    case "FMV_409A":
      return <WarningEasopGrant409AOutdated />;
    case "UK_VALUATION":
      return <WarningEasopGrantUKValuationOutdated />;
  }
};

const EASOP_GRANTS_FRAGMENT = graphql`
  fragment useInstrumentValuationGuard_Grants on EasopGrant
  @argumentDefinitions(organizationId: { type: "OrganizationId!" })
  @relay(plural: true) {
    instrument {
      valuation(organizationId: $organizationId) {
        type
      }
    }
    warnings
  }
`;

export const Alert409AExpired: React.FC = () => (
  <Alert title={warning409AExpiredTitle}>
    {WARNING_VALUATION_EXPIRED_MESSAGE_MAP.FMV_409A}
  </Alert>
);

export const AlertUKValuationExpired: React.FC = () => (
  <Alert title={warningUKValuationExpiredTitle}>
    {WARNING_VALUATION_EXPIRED_MESSAGE_MAP.UK_VALUATION}
  </Alert>
);

export const useInstrumentValuationGuard = () => {
  const alerter = useAlerter();

  const showValuationExpiredGuard = useCallback(
    ({ valuationType }: { valuationType: InstrumentValuationType }) => {
      switch (valuationType) {
        case "FMV_409A":
          {
            alerter.push(
              <Alert title={warning409AExpiredTitle}>
                {WARNING_VALUATION_EXPIRED_MESSAGE_MAP.FMV_409A}
              </Alert>,
            );
          }
          break;
        case "UK_VALUATION":
          {
            alerter.push(
              <Alert title={warningUKValuationExpiredTitle}>
                {WARNING_VALUATION_EXPIRED_MESSAGE_MAP.UK_VALUATION}
              </Alert>,
            );
          }
          break;
      }
    },
    [alerter],
  );

  const showEasopGrantsOutdatedGuard = useCallback(
    ({ valuationType }: { valuationType: InstrumentValuationType }) => {
      alerter.push(
        <Alert title={EASOP_GRANT_OUTDATED_REASON_MAP[valuationType]}>
          {EASOP_GRANT_OUTDATED_EXPLANATION_MAP[valuationType]}
        </Alert>,
      );
      return true;
    },
    [alerter],
  );

  return {
    showEasopGrantsOutdatedGuard,
    showValuationExpiredGuard,
  };
};
