/**
 * @generated SignedSource<<f19425020aa6cbf968fd9516a125eca9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueBoardApprovalSlider_Query$variables = {
  organizationId: string;
};
export type FairMarketValueBoardApprovalSlider_Query$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueBoardApprovalSlider_Organization">;
  };
};
export type FairMarketValueBoardApprovalSlider_Query = {
  response: FairMarketValueBoardApprovalSlider_Query$data;
  variables: FairMarketValueBoardApprovalSlider_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FairMarketValueBoardApprovalSlider_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FairMarketValueBoardApprovalSlider_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FairMarketValueBoardApprovalSlider_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowBoardConsentHandledOutsideEasop",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valuationFirm",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingCeoNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingGrantAmendmentCeoNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingGranteeTerminationGrantAmendmentCeoNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingFairMarketValueCeoNote",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d41f402351c9f19690f1cc664c8fd0a8",
    "id": null,
    "metadata": {},
    "name": "FairMarketValueBoardApprovalSlider_Query",
    "operationKind": "query",
    "text": "query FairMarketValueBoardApprovalSlider_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    ...FairMarketValueBoardApprovalSlider_Organization\n    id\n  }\n}\n\nfragment BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization on Organization {\n  id\n  boardMembers {\n    id\n    account {\n      firstName\n      lastName\n      email\n      id\n    }\n  }\n}\n\nfragment BoardNoteSection_Organization on Organization {\n  id\n  pendingCeoNote\n  pendingGrantAmendmentCeoNote\n  pendingGranteeTerminationGrantAmendmentCeoNote\n  pendingFairMarketValueCeoNote\n}\n\nfragment FairMarketValueBoardApprovalSlider_Organization on Organization {\n  id\n  allowBoardConsentHandledOutsideEasop\n  boardMembers {\n    id\n  }\n  latestFairMarketValue {\n    id\n    date\n    valuationFirm\n  }\n  ...BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization\n  ...BoardNoteSection_Organization\n}\n"
  }
};
})();

(node as any).hash = "b3b808140cc135cc7d91d91c39d6fcc8";

export default node;
