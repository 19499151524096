/**
 * @generated SignedSource<<d6a3d4b6eff1e477c2d93f4d22bcb7d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EarlyExerciseBeneficialPage_Organization$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationModal_Organization">;
  readonly " $fragmentType": "EarlyExerciseBeneficialPage_Organization";
}>;
export type EarlyExerciseBeneficialPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: EarlyExerciseBeneficialPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EarlyExerciseBeneficialPage_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c6cd953092a59eb95f00e81446b3a751";

export default node;
