/**
 * @generated SignedSource<<71bf92c0443da380376e6819c813dc6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailsLayout_CTMSGrantAmendmentRequest_Query$variables = {
  ctmsGrantAmendmentRequestId: string;
};
export type DetailsLayout_CTMSGrantAmendmentRequest_Query$data = {
  readonly ctmsGrantAmendmentRequest: {
    readonly " $fragmentSpreads": FragmentRefs<"Context_CTMSGrantAmendmentRequest">;
  };
};
export type DetailsLayout_CTMSGrantAmendmentRequest_Query = {
  response: DetailsLayout_CTMSGrantAmendmentRequest_Query$data;
  variables: DetailsLayout_CTMSGrantAmendmentRequest_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ctmsGrantAmendmentRequestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ctmsGrantAmendmentRequestId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsLayout_CTMSGrantAmendmentRequest_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "CTMSGrantAmendmentRequest",
          "kind": "LinkedField",
          "name": "ctmsGrantAmendmentRequest",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Context_CTMSGrantAmendmentRequest"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "ctmsGrantAmendmentRequest"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailsLayout_CTMSGrantAmendmentRequest_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "ctmsGrantAmendmentRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postTerminationExercisePeriodIsModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "postTerminationExercisePeriod",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accelerationClauseIsModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accelerationClause",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "earlyExerciseIsModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "earlyExercise",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedule",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vestingStartDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalAccelerationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "waiveCliff",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ccf37514a4396075d42ab9874efec371",
    "id": null,
    "metadata": {},
    "name": "DetailsLayout_CTMSGrantAmendmentRequest_Query",
    "operationKind": "query",
    "text": "query DetailsLayout_CTMSGrantAmendmentRequest_Query(\n  $ctmsGrantAmendmentRequestId: UUID!\n) {\n  ctmsGrantAmendmentRequest(id: $ctmsGrantAmendmentRequestId) {\n    ...Context_CTMSGrantAmendmentRequest\n    id\n  }\n}\n\nfragment Context_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {\n  postTerminationExercisePeriodIsModified\n  postTerminationExercisePeriod {\n    __typename\n    id\n  }\n  accelerationClauseIsModified\n  accelerationClause\n  earlyExerciseIsModified\n  earlyExercise\n  status\n  vestingSchedule {\n    __typename\n    id\n  }\n  vestingStartDate\n  totalAccelerationDate\n  waiveCliff\n}\n"
  }
};
})();

(node as any).hash = "153365a0c90a87ab8e04a7f84e9f8b74";

export default node;
