import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { useToaster } from "../../../../components/Toaster";
import { Toast } from "../../../../components/ui/Toast";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useGranteeIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { GranteeTermination_Grantee$key } from "./__generated__/GranteeTermination_Grantee.graphql";
import { GranteeTermination_Organization$key } from "./__generated__/GranteeTermination_Organization.graphql";
import { GranteeTermination_Query } from "./__generated__/GranteeTermination_Query.graphql";
import { GranteeTermination_TerminateGrantee_Mutation } from "./__generated__/GranteeTermination_TerminateGrantee_Mutation.graphql";
import { GranteeTermination_Viewer$key } from "./__generated__/GranteeTermination_Viewer.graphql";
import {
  GranteeTerminationContextProvider,
  GranteeTerminationStep,
  useGranteeTerminationContext,
} from "./GranteeTerminationContext";
import { GranteeTerminationGrantAmendmentsList } from "./GranteeTerminationGrantAmendmentsList";
import { GranteeTerminationInformation } from "./GranteeTerminationInformation";
import { GranteeTerminationReview } from "./GranteeTerminationReview";

const VIEWER_FRAGMENT = graphql`
  fragment GranteeTermination_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...GranteeTerminationGrantAmendmentsList_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTermination_Organization on Organization {
    id
    ...GranteeTerminationInformation_Organization
    ...GranteeTerminationGrantAmendmentsList_Organization
    ...GranteeTerminationReview_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeTermination_Grantee on Grantee {
    id
    ...GranteeTerminationInformation_Grantee
    ...GranteeTerminationGrantAmendmentsList_Grantee
    ...GranteeTerminationReview_Grantee
  }
`;

const TERMINATE_GRANTEE_MUTATION = graphql`
  mutation GranteeTermination_TerminateGrantee_Mutation(
    $id: GranteeId!
    $attributes: SetGranteeTerminationAttributes!
  ) {
    setGranteeTermination(id: $id, attributes: $attributes) {
      terminationInformations {
        terminationDate
      }
      organization {
        ...AdminLayout_Organization
      }
      ...GranteeTermination_Grantee
    }
  }
`;

const GranteeTermination: React.FC<{
  granteeFragment: GranteeTermination_Grantee$key;
  organizationFragment: GranteeTermination_Organization$key;
  viewerFragment: GranteeTermination_Viewer$key;
}> = ({ granteeFragment, organizationFragment, viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const granteeTerminationContext = useGranteeTerminationContext();
  const toaster = useToaster();
  const [activeStep, setActiveStep] =
    useState<GranteeTerminationStep>("INFORMATION");

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const [terminateGrantee, terminateGranteeInFlight] =
    useSafeMutation<GranteeTermination_TerminateGrantee_Mutation>(
      TERMINATE_GRANTEE_MUTATION,
    );

  const navigate = useNavigate();
  const goToGranteePage = useCallback(
    () =>
      navigate(
        generatePath(APPLICATION_ROUTES["organizationGrantee"], {
          granteeId: grantee.id,
          organizationId: organization.id,
        }),
      ),
    [navigate, grantee, organization],
  );

  const onGranteeTerminationInformationCompleted = useCallback(async () => {
    const {
      grantAmendments,
      terminationDate,
      terminationLastDayAtTheCompany,
      terminationNewRelationship,
      terminationPersonalEmail,
      terminationType,
    } = granteeTerminationContext.getCompletedState();
    await terminateGrantee({
      variables: {
        attributes: {
          grantAmendments:
            grantAmendments?.map(({ ctmsGrantId, ...attributes }) => ({
              attributes: {
                postTerminationExercisePeriodId:
                  attributes.postTerminationExercisePeriodId,
                vestingEndCustomDate: attributes.vestingEndCustomDate,
                vestingEndOption: attributes.vestingEndOption,
                waiveCliff: attributes.waiveCliff,
              },
              ctmsGrantId,
            })) || [],
          terminationDate,
          terminationLastDayAtTheCompany,
          terminationNewRelationship,
          terminationPersonalEmail,
          terminationType,
        },
        id: grantee.id,
      },
    });

    toaster.push(
      <Toast title="Great!">Grantee successfully terminated!</Toast>,
    );

    goToGranteePage();
  }, [
    granteeTerminationContext,
    terminateGrantee,
    grantee.id,
    toaster,
    goToGranteePage,
  ]);

  switch (activeStep) {
    case "AMENDMENT":
      return (
        <GranteeTerminationGrantAmendmentsList
          granteeFragment={grantee}
          onBackClick={() => {
            setActiveStep("INFORMATION");
          }}
          onNextClick={(granteeAmendments) => {
            granteeTerminationContext.dispatchAction({
              type: "SET_AMENDMENT",
              ...granteeAmendments,
            });
            setActiveStep("REVIEW");
          }}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
    case "INFORMATION":
      return (
        <GranteeTerminationInformation
          granteeFragment={grantee}
          onNextClick={(granteeTerminationInformation) => {
            granteeTerminationContext.dispatchAction({
              type: "SET_TERMINATION_INFORMATION",
              ...granteeTerminationInformation,
            });

            if (
              granteeTerminationInformation.terminationType === "WITH_CAUSE"
            ) {
              setActiveStep("REVIEW");
            } else {
              setActiveStep("AMENDMENT");
            }
          }}
          organizationFragment={organization}
        />
      );
    case "REVIEW":
      return (
        <GranteeTerminationReview
          granteeFragment={grantee}
          onBackClick={() => {
            const { terminationType } =
              granteeTerminationContext.getCompletedState();

            if (terminationType === "WITH_CAUSE") {
              setActiveStep("INFORMATION");
            } else {
              setActiveStep("AMENDMENT");
            }
          }}
          onSubmit={onGranteeTerminationInformationCompleted}
          organizationFragment={organization}
          submitting={terminateGranteeInFlight}
        />
      );
  }
};

const QUERY = graphql`
  query GranteeTermination_Query(
    $organizationId: OrganizationId!
    $granteeId: GranteeId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...GranteeTermination_Organization
    }
    grantee(id: $granteeId) {
      ...GranteeTermination_Grantee
      ...GranteeTerminationContext_Grantee
    }
    me {
      ...GranteeTermination_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const GranteeTerminationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const granteeId = useGranteeIdParam();

  const {
    query: { grantee, me, organization },
  } = useQuery<GranteeTermination_Query>(QUERY, {
    granteeId,
    organizationId,
  });

  if (!organization || !grantee) {
    return <NotFoundPage />;
  }

  return (
    <GranteeTerminationContextProvider granteeFragment={grantee}>
      <Page
        analyticsName="Admin - Terminate Grantee"
        organizationId={organization.id}
        title={`Admin | ${organization.name} terminate grantee`}
      >
        <GranteeTermination
          granteeFragment={grantee}
          organizationFragment={organization}
          viewerFragment={me}
        />
      </Page>
    </GranteeTerminationContextProvider>
  );
};

export default GranteeTerminationPage;
