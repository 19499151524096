import {
  ArrowLeftOnRectangleIcon,
  ArrowPathIcon,
  ArrowsRightLeftIcon,
  FireIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useAccountRoles } from "../hooks/useAccountRoles";
import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { useAuthentication } from "../services/AuthenticationProvider";
import CONFIGURATION from "../services/configuration";
import { OrganizationRoleSwitcher_Account$key } from "./__generated__/OrganizationRoleSwitcher_Account.graphql";
import { OrganizationRoleSwitcher_Organization$key } from "./__generated__/OrganizationRoleSwitcher_Organization.graphql";
import { OrganizationRoleSwitcher_ResetDatabase_Mutation } from "./__generated__/OrganizationRoleSwitcher_ResetDatabase_Mutation.graphql";
import { OrganizationRoleSwitcher_resetSeedOrganization_Mutation } from "./__generated__/OrganizationRoleSwitcher_resetSeedOrganization_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Avatar } from "./ui/Avatar";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { MenuButton, MenuButtonPlacement } from "./ui/MenuButton";
import { Toast } from "./ui/Toast";

export type Role = "Admin" | "Board Member";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationRoleSwitcher_Organization on Organization {
    name
    ...useAccountRoles_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment OrganizationRoleSwitcher_Account on Account {
    isSuperAdmin
    ...useAccountRoles_Account
  }
`;

const RESET_DATABASE_MUTATION = graphql`
  mutation OrganizationRoleSwitcher_ResetDatabase_Mutation {
    resetDatabase
  }
`;
const RESET_SEED_ORGANIZATION_MUTATION = graphql`
  mutation OrganizationRoleSwitcher_resetSeedOrganization_Mutation {
    resetSeedOrganization
  }
`;

const environment = CONFIGURATION.ENVIRONMENT;

export const OrganizationRoleSwitcher: React.FC<{
  accountFragment: OrganizationRoleSwitcher_Account$key;
  activeRole: Role;
  organizationFragment: OrganizationRoleSwitcher_Organization$key;
  placement?: MenuButtonPlacement;
}> = ({
  accountFragment,
  activeRole,
  organizationFragment,
  placement = "bottom end",
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const account = useFragment(ACCOUNT_FRAGMENT, accountFragment);

  const authentication = useAuthentication();
  const toaster = useToaster();

  const navigate = useNavigate();

  const handleLogOutButtonClick = useCallback(() => {
    authentication.logout();
    navigate("/", { replace: true });
  }, [authentication, navigate]);

  const {
    setFalse: hideResetDatabaseModal,
    setTrue: showResetDatabaseModal,
    value: resetDatabaseModalIsShown,
  } = useBoolean(false);
  const {
    setFalse: hideResetAcmeModal,
    setTrue: showResetAcmeModal,
    value: resetAcmeModalIsShown,
  } = useBoolean(false);

  const {
    accountHasRoleInOtherOrganizations,
    activeOrganizationAdminRole,
    activeOrganizationBoardMemberRolePath,
    activeOrganizationHomePath,
  } = useAccountRoles({
    account,
    activeOrganization: organization,
  });

  const [resetDatabase, resetDatabaseMutationIsInFlight] =
    useSafeMutation<OrganizationRoleSwitcher_ResetDatabase_Mutation>(
      RESET_DATABASE_MUTATION,
    );
  const [_resetSeedOrganization, resetSeedOrganizationMutationIsInFlight] =
    useSafeMutation<OrganizationRoleSwitcher_resetSeedOrganization_Mutation>(
      RESET_SEED_ORGANIZATION_MUTATION,
    );
  const resetSeedOrganization = useCallback(async () => {
    await _resetSeedOrganization({
      variables: {},
    });

    toaster.push(
      <Toast title="Great!">Acme has been successfully reset!</Toast>,
    );

    hideResetAcmeModal();
  }, [_resetSeedOrganization, hideResetAcmeModal, toaster]);

  const showResetDatabaseButton =
    account.isSuperAdmin && environment === "local";
  const showResetAcmeButton =
    account.isSuperAdmin && environment !== "production";

  return (
    <>
      <ConfirmationModal
        confirmationLabel="Reset database"
        loading={resetDatabaseMutationIsInFlight}
        onClose={hideResetDatabaseModal}
        onConfirmed={async () => {
          await resetDatabase({
            variables: {},
          });

          toaster.push(
            <Toast title="Great!">Database has been successfully reset!</Toast>,
          );

          hideResetDatabaseModal();

          navigate(generatePath(APPLICATION_ROUTES["roleSelection"]));
        }}
        show={resetDatabaseModalIsShown}
        title="Reset the database"
      >
        Are you sure you want to reset the database?
      </ConfirmationModal>
      <ConfirmationModal
        confirmationLabel="Reset ACME"
        loading={resetSeedOrganizationMutationIsInFlight}
        onClose={hideResetAcmeModal}
        onConfirmed={resetSeedOrganization}
        show={resetAcmeModalIsShown}
        title="Reset ACME"
      >
        Are you sure you want to reset ACME?
      </ConfirmationModal>
      <MenuButton
        button={
          <button type="button">
            <Avatar>{organization.name}</Avatar>
          </button>
        }
        menuClassName="z-10"
        placement={placement}
      >
        {activeRole === "Board Member" && activeOrganizationAdminRole && (
          <MenuButton.LinkItem
            leftIcon={<UsersIcon />}
            to={activeOrganizationHomePath}
          >
            Admin view
          </MenuButton.LinkItem>
        )}
        {activeRole === "Admin" && activeOrganizationBoardMemberRolePath && (
          <MenuButton.LinkItem
            leftIcon={<UsersIcon />}
            to={activeOrganizationBoardMemberRolePath}
          >
            Board member view
          </MenuButton.LinkItem>
        )}
        {accountHasRoleInOtherOrganizations && (
          <MenuButton.LinkItem
            leftIcon={<ArrowsRightLeftIcon />}
            to={generatePath(APPLICATION_ROUTES["roleSelection"], {})}
          >
            Switch organization
          </MenuButton.LinkItem>
        )}

        {showResetDatabaseButton && (
          <MenuButton.Item
            leftIcon={<FireIcon />}
            onClick={showResetDatabaseModal}
            variant="Warning"
          >
            Reset database
          </MenuButton.Item>
        )}

        {showResetAcmeButton && (
          <MenuButton.Item
            leftIcon={<ArrowPathIcon />}
            onClick={showResetAcmeModal}
            variant="Warning"
          >
            Reset ACME
          </MenuButton.Item>
        )}

        <MenuButton.Item
          leftIcon={<ArrowLeftOnRectangleIcon />}
          onClick={handleLogOutButtonClick}
          variant="Danger"
        >
          Log out
        </MenuButton.Item>
      </MenuButton>
    </>
  );
};
