import React, { ReactNode, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedFMV } from "../../components/FormattedFMV";
import { Percentage } from "../../components/Percentage";
import { LabelValue } from "../../components/ui/LabelValue";
import { Tag } from "../../components/ui/Tag";
import { Typography } from "../../components/ui/Typography";
import { useGranteePortalGranteeShares } from "../../hooks/useGranteePortal";
import { useOrganizationSharesUtil } from "../../hooks/useOrganizationSharesUtil";
import { GranteeOwnership_Grantee$key } from "./__generated__/GranteeOwnership_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeOwnership_Grantee on Grantee {
    organization {
      latestPricePerShare {
        value
      }
      ...useOrganizationSharesUtil_Organization
    }
    ...useGranteePortalGranteeShares_Grantee
  }
`;

const OwnershipVesting: React.FC<{
  alreadyVested: ReactNode;
  fullyVested: ReactNode;
  title: string;
}> = ({ alreadyVested, fullyVested, title }) => {
  return (
    <div className="round-lg flex-1 space-y-4 bg-gray-01 p-4 sm:p-6">
      <Typography as="div" className="text-black-05" variant="Medium/Default">
        {title}
      </Typography>
      <div className="flex justify-between sm:block">
        <LabelValue isPrimary label="Already vested">
          {alreadyVested}
        </LabelValue>
        <LabelValue label="When fully vested">{fullyVested}</LabelValue>
      </div>
    </div>
  );
};

export const GranteeOwnership: React.FC<{
  granteeFragment: GranteeOwnership_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: grantee.organization,
    });

  const { totalGrantedShares, totalVestedShares } =
    useGranteePortalGranteeShares({
      granteeFragment: grantee,
    });

  const {
    totalGrantedOwnership,
    totalGrantedUSDValue,
    totalVestedOwnership,
    totalVestedUSDValue,
  } = useMemo(
    () => ({
      totalGrantedOwnership: sharesToFullyDilutedRatio(totalGrantedShares),
      totalGrantedUSDValue: sharesToValue(totalGrantedShares),
      totalVestedOwnership: sharesToFullyDilutedRatio(totalVestedShares),
      totalVestedUSDValue: sharesToValue(totalVestedShares),
    }),
    [
      sharesToFullyDilutedRatio,
      sharesToValue,
      totalVestedShares,
      totalGrantedShares,
    ],
  );

  return (
    <div className="space-y-6">
      <div className="flex flex-col items-start justify-between gap-4 sm:flex-row sm:gap-0">
        <div className="flex flex-col">
          <Typography variant="Medium/Default">
            What do you have exactly?
          </Typography>
          <Typography className="text-black-05" variant="Regular/Small">
            This shows what your ownership looks like
          </Typography>
        </div>

        {grantee.organization.latestPricePerShare ? (
          <Tag color="glass-green">
            Current Price Per Share:{" "}
            <FormattedFMV
              value={grantee.organization.latestPricePerShare.value}
            />
          </Tag>
        ) : (
          <Tag color="orange">Missing current Price Per Share</Tag>
        )}
      </div>

      <div className="flex flex-col gap-4">
        {totalVestedOwnership !== null && totalGrantedOwnership !== null && (
          <OwnershipVesting
            alreadyVested={<Percentage value={totalVestedOwnership} />}
            fullyVested={<Percentage value={totalGrantedOwnership} />}
            title="Total % ownership"
          />
        )}

        <OwnershipVesting
          alreadyVested={
            <FormattedNumber
              maximumFractionDigits={2}
              value={totalVestedShares}
            />
          }
          fullyVested={
            <FormattedNumber
              maximumFractionDigits={2}
              value={totalGrantedShares}
            />
          }
          title="Total # options"
        />

        {totalVestedUSDValue !== null && totalGrantedUSDValue !== null && (
          <OwnershipVesting
            alreadyVested={
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={2}
                style="currency"
                value={totalVestedUSDValue}
              />
            }
            fullyVested={
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={2}
                style="currency"
                value={totalGrantedUSDValue}
              />
            }
            title="Total $ value"
          />
        )}
      </div>
    </div>
  );
};
