import { posthog } from "posthog-js";
import { PostHogProvider as PostHogProvider_ } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Account } from "./AuthenticationProvider";
import CONFIGURATION from "./configuration";

if (typeof window !== "undefined") {
  posthog.init(CONFIGURATION.POSTHOG_API_KEY, {
    api_host: CONFIGURATION.POSTHOG_HOST,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  });
}

export function useTrackPageViewInPostHog() {
  const location = useLocation();

  useEffect(() => {
    let url = window.origin + location.pathname;
    if (location.search) {
      url = url + `?${location.search}`;
    }

    posthog.capture("$pageview", {
      $current_url: url,
    });
  }, [location.pathname, location.search]);
}

export function useIdentifyAccountInPostHog(account: Account | null) {
  useEffect(() => {
    if (!account) {
      posthog.reset();
      return;
    }

    posthog.identify(account.email, {
      email: account.email,
      name: `${account.firstName} ${account.lastName}`,
    });
  }, [account]);
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider_ client={posthog}>{children}</PostHogProvider_>;
}
