import { GiftIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DetailsBox } from "../../../../components/DetailsBox";
import { FormattedCurrencyParts } from "../../../../components/FormattedCurrencyParts";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { MissingInformationTag } from "../../../../components/MissingInformationTag";
import { Percentage } from "../../../../components/Percentage";
import { SingleValueProgress } from "../../../../components/ui/SingleValueProgress";
import { Switch } from "../../../../components/ui/Switch";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import {
  CtmsGrantDetailsGrant_CTMSGrant$data,
  CtmsGrantDetailsGrant_CTMSGrant$key,
} from "./__generated__/CtmsGrantDetailsGrant_CTMSGrant.graphql";
import {
  CtmsGrantDetailsGrant_Organization$data,
  CtmsGrantDetailsGrant_Organization$key,
} from "./__generated__/CtmsGrantDetailsGrant_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsGrant_CTMSGrant on CTMSGrant {
    quantityIssued
    cumulativeVested
    isActive
    label
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsGrant_Organization on Organization {
    latestPricePerShare {
      value
    }
    ...useOrganizationSharesUtil_Organization
  }
`;

const VALUES_TO_DISPLAY_VARIANTS = {
  large: {
    className: "text-black-07",
    fractionTypographyVariant: "Medium/Default",
    typographyVariant: "Medium/Extra Large",
  },
  "light small": {
    className: "text-black-05",
    fractionTypographyVariant: "Regular/Caption",
    typographyVariant: "Regular/Small",
  },
  small: {
    className: "text-black-07",
    fractionTypographyVariant: "Medium/Caption",
    typographyVariant: "Medium/Small",
  },
} as const;

const ValueToDisplay: React.FC<{
  ctmsGrant: CtmsGrantDetailsGrant_CTMSGrant$data;
  equityMode: "# Shares" | "$ Value" | "% Fully diluted";
  organization: CtmsGrantDetailsGrant_Organization$data;
  value: "issued" | "vested";
  variant: keyof typeof VALUES_TO_DISPLAY_VARIANTS;
}> = ({ ctmsGrant, equityMode, organization, value, variant }) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });
  const { className, fractionTypographyVariant, typographyVariant } =
    VALUES_TO_DISPLAY_VARIANTS[variant];

  const cumulativeVestedValue = sharesToValue(ctmsGrant.cumulativeVested);
  const quantityIssuedValue = sharesToValue(ctmsGrant.quantityIssued);
  const cumulativeVestedOwnership = sharesToFullyDilutedRatio(
    ctmsGrant.cumulativeVested,
  );
  const quantityIssuedOwnership = sharesToFullyDilutedRatio(
    ctmsGrant.quantityIssued,
  );

  switch (`${equityMode} ${value}` as const) {
    case "# Shares issued":
      return (
        <Typography as="span" className={className} variant={typographyVariant}>
          <FormattedNumber value={ctmsGrant.quantityIssued} />
        </Typography>
      );
    case "# Shares vested":
      return (
        <Typography as="span" className={className} variant={typographyVariant}>
          <FormattedNumber value={ctmsGrant.cumulativeVested} />
        </Typography>
      );
    case "$ Value issued":
      if (quantityIssuedValue === null) return <MissingInformationTag />;
      return (
        <FormattedCurrencyParts
          className={className}
          defaultVariant={typographyVariant}
          fractionVariant={fractionTypographyVariant}
          value={quantityIssuedValue}
        />
      );
    case "$ Value vested":
      if (cumulativeVestedValue === null) return <MissingInformationTag />;
      return (
        <FormattedCurrencyParts
          className={className}
          defaultVariant={typographyVariant}
          fractionVariant={fractionTypographyVariant}
          value={cumulativeVestedValue}
        />
      );
    case "% Fully diluted issued":
      if (quantityIssuedOwnership === null) return <MissingInformationTag />;
      return (
        <Typography as="span" className={className} variant={typographyVariant}>
          <Percentage value={quantityIssuedOwnership} />
        </Typography>
      );
    case "% Fully diluted vested":
      if (cumulativeVestedOwnership === null) return <MissingInformationTag />;
      return (
        <Typography as="span" className={className} variant={typographyVariant}>
          <Percentage value={cumulativeVestedOwnership} />
        </Typography>
      );
  }
};

export const CtmsGrantDetailsGrant: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsGrant_CTMSGrant$key;
  organizationFragment: CtmsGrantDetailsGrant_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const equityModes = ["$ Value", "# Shares", "% Fully diluted"] as const;
  const [selectedEquityMode, setSelectedEquityMode] = useState<
    (typeof equityModes)[number]
  >(equityModes[0]);

  return (
    <DetailsBox Icon={GiftIcon} title="Grant overview">
      <div className="space-y-6">
        <div className="flex justify-center">
          <Switch
            getOptionValue={(option) => option}
            name="equity-mode"
            onChange={setSelectedEquityMode}
            options={equityModes}
            selectedOption={selectedEquityMode}
          />
        </div>

        <div className="space-y-1 text-center">
          <Typography className="text-black-05" variant="Regular/Caption">
            Equity vested
          </Typography>
          {organization.latestPricePerShare && (
            <div className="text-center">
              <Tag color="purple">
                Current PPS:{" "}
                <FormattedFMV value={organization.latestPricePerShare.value} />
              </Tag>
            </div>
          )}
          <ValueToDisplay
            ctmsGrant={ctmsGrant}
            equityMode={selectedEquityMode}
            organization={organization}
            value="vested"
            variant="large"
          />
        </div>
        <div className="flex items-center gap-2 py-4">
          <Tag>{ctmsGrant.label}</Tag>
          <SingleValueProgress
            className="relative h-1.5 flex-1"
            current={ctmsGrant.cumulativeVested}
            max={ctmsGrant.quantityIssued}
            variant={!ctmsGrant.isActive ? "light-red" : "primary"}
          />
          <div className="text-right">
            <div>
              <ValueToDisplay
                ctmsGrant={ctmsGrant}
                equityMode={selectedEquityMode}
                organization={organization}
                value="vested"
                variant="small"
              />
            </div>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Small"
            >
              /
              <ValueToDisplay
                ctmsGrant={ctmsGrant}
                equityMode={selectedEquityMode}
                organization={organization}
                value="issued"
                variant="light small"
              />
            </Typography>
          </div>
        </div>
      </div>
    </DetailsBox>
  );
};
