import React from "react";
import { graphql, useFragment } from "react-relay";

import { FairMarketValueHistoryCard_Organization$key } from "./__generated__/FairMarketValueHistoryCard_Organization.graphql";
import { FormattedFMV } from "./FormattedFMV";
import { Percentage } from "./Percentage";
import { Card } from "./ui/Card";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";
import { ValuationHistoryChart } from "./ValuationHistoryChart";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValueHistoryCard_Organization on Organization {
    latestFairMarketValue {
      value
      boardDetermined
      tag
    }
    latestPricePerShare {
      value
    }
  }
`;

export const FairMarketValueHistoryCard: React.FC<{
  children: React.ReactElement<
    React.ComponentProps<typeof ValuationHistoryChart>
  >;
  className?: string;
  organizationFragment: FairMarketValueHistoryCard_Organization$key;
}> = ({ children, className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!organization.latestFairMarketValue) return null;

  return (
    <Card className={className}>
      <div className="flex flex-col justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="Medium/Default">Fair Market Value</Typography>

          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <Typography variant="Medium/Default">
                <FormattedFMV
                  value={organization.latestFairMarketValue.value ?? 0}
                />
              </Typography>
              <div className="text-black-05">
                <Typography as="div" variant="Regular/Extra Small">
                  Fair Market Value
                </Typography>
                <Typography as="div" variant="Regular/Caption">
                  (Shares of Common Stock)
                </Typography>
              </div>
            </div>
            {organization.latestFairMarketValue &&
              organization.latestPricePerShare && (
                <div className="flex flex-col gap-1 text-right">
                  <Typography variant="Medium/Default">
                    <Percentage
                      maximumFractionDigits={0}
                      value={
                        1 -
                        organization.latestFairMarketValue.value /
                          organization.latestPricePerShare.value
                      }
                    />
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Extra Small"
                  >
                    Discount vs Price per share
                  </Typography>
                </div>
              )}
          </div>
          <div className="flex gap-2">
            {organization.latestFairMarketValue.tag && (
              <Tag color="primary">
                {organization.latestFairMarketValue.tag.toUpperCase()}
              </Tag>
            )}
            {organization.latestFairMarketValue.boardDetermined && (
              <Tag color="gray">BOARD DETERMINED</Tag>
            )}
          </div>
        </div>

        {React.cloneElement(children, {
          height: 230,
        })}
      </div>
    </Card>
  );
};
