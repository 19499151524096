import classNames from "classnames";
import React from "react";

import { Typography } from "./Typography";

const Row: React.FC<{ label: string } & React.ComponentProps<"div">> = ({
  children,
  className,
  label,
}) => (
  <div className="flex items-center py-4" data-cy={label}>
    <div className="max-w-[60%] text-gray-09">{label}</div>
    <div className={classNames("ml-auto", className)}>{children}</div>
  </div>
);

const AltRow: React.FC<{ label: string } & React.ComponentProps<"div">> = ({
  children,
  label,
}) => (
  <div className="space-y-2 py-4" data-cy={label}>
    <div className="text-gray-09">{label}</div>
    <div>{children}</div>
  </div>
);

const _InformationRows: React.FC<React.ComponentProps<"div">> = ({
  children,
  className,
}) => (
  <Typography
    as="div"
    className={classNames(
      className,
      "flex flex-col justify-center divide-y-[0.5px] divide-gray-04",
    )}
    variant="Regular/Extra Small"
  >
    {children}
  </Typography>
);

export const InformationRows = Object.assign(_InformationRows, { AltRow, Row });
