/**
 * @generated SignedSource<<d1d4d4879fcf7e02439b73d859ebaa5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
export type OrganizationCTMSGrantsFiltersOrderByField = "cumulativeVested" | "equityType" | "exercisePrice" | "grantee" | "label" | "quantityExercised" | "quantityIssued" | "remainingDaysToExercise" | "settled";
export type OrganizationCTMSGrantsFiltersVestingStatus = "CLIFF_NOT_PASSED" | "FULLY_VESTED" | "VESTING";
export type SortDirection = "ASC" | "DESC";
export type OrganizationCTMSGrantsFilters = {
  equityTypeIn?: ReadonlyArray<string> | null;
  granteeId?: string | null;
  idIn?: ReadonlyArray<string> | null;
  orderBy?: OrganizationCTMSGrantsFiltersOrderBy | null;
  search?: string | null;
  statusIn?: ReadonlyArray<CTMSGrantStatus> | null;
  vestingStatus?: OrganizationCTMSGrantsFiltersVestingStatus | null;
};
export type OrganizationCTMSGrantsFiltersOrderBy = {
  direction: SortDirection;
  field: OrganizationCTMSGrantsFiltersOrderByField;
};
export type UnderwaterGrantsSlideOver_Query$variables = {
  filters?: OrganizationCTMSGrantsFilters | null;
  organizationId: string;
};
export type UnderwaterGrantsSlideOver_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly latestFairMarketValue: {
      readonly value: number;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrantsSlideOver_Refetchable_Organization">;
  };
};
export type UnderwaterGrantsSlideOver_Query = {
  response: UnderwaterGrantsSlideOver_Query$data;
  variables: UnderwaterGrantsSlideOver_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v6 = [
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnderwaterGrantsSlideOver_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FairMarketValue",
              "kind": "LinkedField",
              "name": "latestFairMarketValue",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": [
                (v5/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "UnderwaterGrantsSlideOver_Refetchable_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnderwaterGrantsSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exercisePrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "CTMSGrants_Organization_ctmsGrants",
            "kind": "LinkedHandle",
            "name": "ctmsGrants"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00aad050a87ab7b9f7c4559bf4bb5b05",
    "id": null,
    "metadata": {},
    "name": "UnderwaterGrantsSlideOver_Query",
    "operationKind": "query",
    "text": "query UnderwaterGrantsSlideOver_Query(\n  $organizationId: OrganizationId!\n  $filters: OrganizationCTMSGrantsFilters\n) {\n  organization(id: $organizationId) {\n    id\n    latestFairMarketValue {\n      value\n      id\n    }\n    ...UnderwaterGrantsSlideOver_Refetchable_Organization_VTAHT\n  }\n}\n\nfragment UnderwaterGrantsSlideOver_Refetchable_Organization_VTAHT on Organization {\n  ctmsGrants(first: 20, filters: $filters) {\n    totalCount\n    edges {\n      node {\n        id\n        label\n        exercisePrice\n        grantee {\n          name\n          email\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "c918bd33dd073b106c7bb1096bdb694e";

export default node;
