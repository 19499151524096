import React from "react";
import { generatePath, Navigate, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { useToaster } from "../../../../components/Toaster";
import { Toast } from "../../../../components/ui/Toast";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useCtmsGrantIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation } from "./__generated__/ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation.graphql";
import { ReviewCTMSGrantModification_Query } from "./__generated__/ReviewCTMSGrantModification_Query.graphql";
import { useModifyCTMSGrantContext } from "./Context";
import { NewEarlyExerciseAllowedOption } from "./NewEarlyExerciseAllowedSlider";
import { ReviewCTMSGrantModificationPageContent } from "./ReviewCTMSGrantModificationPageContent";

const QUERY = graphql`
  query ReviewCTMSGrantModification_Query(
    $organizationId: OrganizationId!
    $ctmsGrantId: CtmsGrantId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...ReviewCTMSGrantModificationPageContent_Organization
    }
    ctmsGrant(id: $ctmsGrantId) {
      id
      ...ReviewCTMSGrantModificationPageContent_CTMSGrant
    }
  }
`;

const CREATE_AMENDMENT_MUTATION = graphql`
  mutation ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation(
    $ctmsGrantId: CtmsGrantId!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    createCTMSGrantAmendmentRequest(
      ctmsGrantId: $ctmsGrantId
      attributes: $attributes
    ) {
      __typename
    }
  }
`;

const ReviewCTMSGrantModification_: React.FC<{
  ctmsGrant: NonNullable<
    ReviewCTMSGrantModification_Query["response"]["ctmsGrant"]
  >;
  organization: NonNullable<
    ReviewCTMSGrantModification_Query["response"]["organization"]
  >;
}> = ({ ctmsGrant, organization }) => {
  const [createCTMSGrantAmendmentRequest] =
    useSafeMutation<ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation>(
      CREATE_AMENDMENT_MUTATION,
    );

  const toaster = useToaster();
  const navigate = useNavigate();

  const context = useModifyCTMSGrantContext();
  const {
    newAccelerationAllowedValue,
    newEarlyExerciseAllowedValue,
    newOrganizationPostTerminationExercisePeriodId,
    newVestingScheduleId,
    newVestingStartDate,
    totalAccelerationDate,
    waiveCliff,
  } = context.state;

  const onConfirmModificationClicked = async () => {
    await createCTMSGrantAmendmentRequest({
      variables: {
        attributes: {
          accelerationClause: newAccelerationAllowedValue?.accelerationClause,
          accelerationClauseIsModified: Boolean(newAccelerationAllowedValue),
          earlyExercise:
            newEarlyExerciseAllowedValue?.earlyExercise ===
            NewEarlyExerciseAllowedOption.ALLOWED,
          earlyExerciseIsModified: Boolean(newEarlyExerciseAllowedValue),
          postTerminationExercisePeriodId:
            newOrganizationPostTerminationExercisePeriodId,
          postTerminationExercisePeriodIsModified: Boolean(
            newOrganizationPostTerminationExercisePeriodId,
          ),
          totalAccelerationDate,
          vestingScheduleId: newVestingScheduleId,
          vestingStartDate: newVestingStartDate,
          waiveCliff,
        },
        ctmsGrantId: ctmsGrant.id,
      },
    });

    toaster.push(
      <Toast title="Great!">
        Grant amendment request successfully created!
      </Toast>,
    );

    navigate(
      generatePath(
        APPLICATION_ROUTES["organizationCTMSGrantAmendmentRequestsUnderReview"],
        {
          organizationId: organization.id,
        },
      ),
    );
  };
  return (
    <ReviewCTMSGrantModificationPageContent
      backLink={generatePath(
        APPLICATION_ROUTES["organizationEquityCtmsGrantModify"],
        {
          ctmsGrantId: ctmsGrant.id,
          organizationId: organization.id,
        },
      )}
      ctmsGrantFragment={ctmsGrant}
      onConfirmModificationClicked={onConfirmModificationClicked}
      organizationFragment={organization}
    />
  );
};

const ReviewCTMSGrantModification: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantId = useCtmsGrantIdParam();

  const context = useModifyCTMSGrantContext();

  const {
    query: { ctmsGrant, organization },
  } = useQuery<ReviewCTMSGrantModification_Query>(QUERY, {
    ctmsGrantId,
    organizationId,
  });

  if (!organization || !ctmsGrant) {
    return <NotFoundPage />;
  }

  if (context.isEmpty) {
    return (
      <Navigate
        to={generatePath(
          APPLICATION_ROUTES["organizationEquityCtmsGrantModify"],
          { ctmsGrantId, organizationId },
        )}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName="Admin - Grants - Review grant modification"
      organizationId={organization.id}
      title={`Admin | ${organization.name} review grant modification`}
    >
      <ReviewCTMSGrantModification_
        ctmsGrant={ctmsGrant}
        organization={organization}
      />
    </Page>
  );
};

export default ReviewCTMSGrantModification;
