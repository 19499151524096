import { useEffect, useRef } from "react";

interface PandaDocDocumentCompletedEvent {
  payload: {
    recipient: {
      company: string;
      email: string;
      firstName: string;
      lastName: string;
    };
    uuid: string;
  };
  type: "session_view.document.completed";
}

interface PandaDocDocumentLoadedEvent {
  payload: unknown;
  type: "session_view.document.loaded";
}

interface PandaDocDocumentExceptionEvent {
  payload: unknown;
  type: "session_view.document.exception";
}

type PandaDocEvent =
  | PandaDocDocumentCompletedEvent
  | PandaDocDocumentExceptionEvent
  | PandaDocDocumentLoadedEvent;

type WidowMessageEvent = PandaDocEvent;

interface Props extends Omit<React.ComponentProps<"iframe">, "src"> {
  onDocumentCompleted?: (
    payload: PandaDocDocumentCompletedEvent["payload"],
  ) => void;
  onDocumentLoaded?: () => void;
  sessionUrl: string;
}

export const PandaDocFrame = ({
  onDocumentCompleted,
  onDocumentLoaded,
  sessionUrl,
  ...props
}: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    const onWindowMessageEvent = (event: MessageEvent<WidowMessageEvent>) => {
      if (event.source !== iframeRef.current?.contentWindow) {
        return;
      }

      switch (event.data.type) {
        case "session_view.document.loaded":
          onDocumentLoaded?.();
          break;
        case "session_view.document.completed":
          onDocumentCompleted?.(event.data.payload);
          break;
        case "session_view.document.exception":
          throw event.data.payload;
        default:
          break;
      }
    };

    window.addEventListener("message", onWindowMessageEvent);

    return () => window.removeEventListener("message", onWindowMessageEvent);
  }, [onDocumentCompleted, onDocumentLoaded]);

  return <iframe ref={iframeRef} src={sessionUrl} {...props} />;
};
