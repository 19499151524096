import { zodResolver } from "@hookform/resolvers/zod";
import { throttle } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery, graphql } from "relay-runtime";
import { z } from "zod";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { InviteAdminSlideOver_Account_Query } from "./__generated__/InviteAdminSlideOver_Account_Query.graphql";
import { InviteAdminSlideOver_AddAdmin_Mutation } from "./__generated__/InviteAdminSlideOver_AddAdmin_Mutation.graphql";
import { InviteAdminSlideOver_Admin_Query } from "./__generated__/InviteAdminSlideOver_Admin_Query.graphql";
import { InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation } from "./__generated__/InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { FormRow } from "./ui/Form/FormRow";
import { Input } from "./ui/Form/Inputs/Input";
import { SlideOver } from "./ui/SlideOver";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const CREATE_ACCOUNT_AND_ADD_IT_AS_ADMIN_MUTATION = graphql`
  mutation InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation(
    $organizationId: OrganizationId!
    $attributes: AccountAttributes!
  ) {
    createAccountAndAddItAsAdmin(
      organizationId: $organizationId
      attributes: $attributes
      source: "Remote Equity Web App"
    ) {
      organization {
        ...OrganizationSettingsAdmins_Organization
      }
    }
  }
`;

const ADD_ADMIN_MUTATION = graphql`
  mutation InviteAdminSlideOver_AddAdmin_Mutation(
    $attributes: AdminAttributes!
  ) {
    addAdmin(attributes: $attributes, source: "Remote Equity Web App") {
      organization {
        ...OrganizationSettingsAdmins_Organization
      }
    }
  }
`;

const ACCOUNT_QUERY = graphql`
  query InviteAdminSlideOver_Account_Query($email: String!) {
    accountFromEmail(email: $email) {
      id
      email
      firstName
      lastName
    }
  }
`;

const ADMIN_QUERY = graphql`
  query InviteAdminSlideOver_Admin_Query($attributes: AdminAttributes!) {
    isAccountAnOrganizationAdmin(attributes: $attributes)
  }
`;

const AdminFormSchema = z.object({
  email: z.string().trim().email(),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
});
const EmailSchema = z.string().trim().email();

interface Account {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export type AdminFormInputs = z.infer<typeof AdminFormSchema>;

export const InviteAdminSlideOver: React.FC<{
  onClose: () => void;
  organizationId: string;
  show: boolean;
}> = ({ onClose, organizationId, show }) => {
  const relayEnvironment = useRelayEnvironment();
  const form = useForm({
    resolver: zodResolver(AdminFormSchema),
  });

  const toaster = useToaster();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [existingAccount, setExistingAccount] = useState<Account | null>(null);
  const [isAlreadyAdmin, setIsAlreadyAdmin] = useState(false);

  const refreshIsAlreadyAdmin = useMemo(
    () => (account: Account) => {
      fetchQuery<InviteAdminSlideOver_Admin_Query>(
        // @ts-expect-error - relay-runtime types are not up-to-date
        relayEnvironment,
        ADMIN_QUERY,
        {
          attributes: {
            accountId: account.id,
            organizationId,
          },
        },
      ).subscribe({
        next: ({ isAccountAnOrganizationAdmin }) => {
          setIsAlreadyAdmin(isAccountAnOrganizationAdmin);
          if (isAccountAnOrganizationAdmin) {
            form.setError("email", {
              message: "This person is already an Admin",
              type: "custom",
            });
          }
        },
      });
    },
    [form, organizationId, relayEnvironment],
  );

  const onExistingAccountChanged = useMemo(
    () => (account: Account | null) => {
      if (account) {
        form.setValue("firstName", account.firstName);
        form.setValue("lastName", account.lastName);
        refreshIsAlreadyAdmin(account);
      }
    },
    [form, refreshIsAlreadyAdmin],
  );

  const _refreshExistingAccount = useMemo(
    () => (email: string) => {
      const _isValidEmail = EmailSchema.safeParse(email).success;
      setIsAlreadyAdmin(false);
      form.clearErrors("email");
      setIsValidEmail(_isValidEmail);
      if (EmailSchema.safeParse(email).success) {
        fetchQuery<InviteAdminSlideOver_Account_Query>(
          // @ts-expect-error - relay-runtime types are not up-to-date
          relayEnvironment,
          ACCOUNT_QUERY,
          {
            email,
          },
        ).subscribe({
          next: ({ accountFromEmail: account }) => {
            setExistingAccount(account);
            onExistingAccountChanged(account);
          },
        });
      }
    },
    [form, onExistingAccountChanged, relayEnvironment],
  );

  const refreshExistingAccount = useMemo(
    () => throttle(_refreshExistingAccount, 250),
    [_refreshExistingAccount],
  );

  const [createAccountAndAddItAsAdmin] =
    useSafeMutation<InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation>(
      CREATE_ACCOUNT_AND_ADD_IT_AS_ADMIN_MUTATION,
    );
  const [addAdmin] =
    useSafeMutation<InviteAdminSlideOver_AddAdmin_Mutation>(ADD_ADMIN_MUTATION);

  const onSubmit = form.handleSubmit(async (_data) => {
    const data = _data as AdminFormInputs;
    if (existingAccount) {
      await addAdmin({
        variables: {
          attributes: {
            accountId: existingAccount.id,
            organizationId: organizationId,
          },
        },
      });
    } else {
      await createAccountAndAddItAsAdmin({
        variables: {
          attributes: { ...data },
          organizationId: organizationId,
        },
      });
    }

    toaster.push(<Toast title="Wonderful!">Admin successfully invited!</Toast>);
    onClose();
    form.reset();
  });

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose}>
          Invite a new admin
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <form className="space-y-4 px-6 py-4" onSubmit={onSubmit}>
        <FormRow label="Email address">
          <Input
            {...form.control.register("email")}
            onChange={(event) => {
              refreshExistingAccount(event.target.value);
            }}
            placeholder="johndoe@my.company"
            type="email"
          />
        </FormRow>
        <FormRow label="First name">
          <Input
            {...form.control.register("firstName")}
            disabled={!!existingAccount && isValidEmail}
            placeholder="John"
          />
        </FormRow>
        <FormRow label="Last name">
          <Input
            {...form.control.register("lastName")}
            disabled={!!existingAccount && isValidEmail}
            placeholder="Doe"
          />
        </FormRow>
        <div className="flex flex-row-reverse gap-4">
          <Button
            disabled={isAlreadyAdmin}
            loading={form.formState.isSubmitting}
            size="small"
            type="submit"
          >
            Invite
          </Button>
          <Button
            onClick={onClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
        </div>
        <Typography
          as="div"
          className="text-right text-gray-09"
          variant="Regular/Caption"
        >
          After you click Invite, we&apos;ll send an email to invite the admin.
        </Typography>
      </form>
    </SlideOver>
  );
};
