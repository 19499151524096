import {
  ArrowLeftOnRectangleIcon,
  ArrowPathIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { compact, isNil } from "lodash";
import { Suspense, useCallback, useEffect, useMemo } from "react";
import {
  generatePath,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { graphql } from "relay-runtime";

import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { Button } from "../../components/ui/Button";
import { Toggle } from "../../components/ui/Form/Toggle";
import { Logo } from "../../components/ui/Logo/Logo";
import { PoweredByCarta } from "../../components/ui/PoweredByCarta/PoweredByCarta";
import { RoundedBox } from "../../components/ui/RoundedBox";
import { Typography } from "../../components/ui/Typography";
import { WideDropdown } from "../../components/ui/WideDropdown";
import { useBoolean } from "../../hooks/useBoolean";
import { useQuery } from "../../hooks/useQuery";
import { useTailwindActiveBreakpoints } from "../../hooks/useTailwindActiveBreakpoints";
import {
  APPLICATION_ROUTES,
  useEquityTypesGroupParam,
  useGranteeIdParam,
  useLocationMatchesRoutes,
} from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import {
  GranteeEquityTypesGroupLayout_Grantee_Query,
  GranteeEquityTypesGroupLayout_Grantee_Query$data,
} from "./__generated__/GranteeEquityTypesGroupLayout_Grantee_Query.graphql";

export type GranteePortalSection =
  | "exercise"
  | "faq"
  | "overview"
  | "projection"
  | "reporting"
  | "taxation";

export const GRANTEE_LAYOUT_LINKS = {
  exercise: {
    color: "bg-primary-02",
    description:
      "Get all your questions answered around exercising your options, when to do it and how much it would cost you.",
    label: "Exercise",
    route: APPLICATION_ROUTES["granteeExercise"],
  },
  faq: {
    color: null,
    description: null,
    label: "FAQ",
    route: APPLICATION_ROUTES["granteeFAQ"],
  },
  overview: {
    color: "bg-glass-green-02",
    description:
      "Get a 360 view on your equity ownership to help you figure out how much equity you have vested over time.",
    label: "Overview",
    route: APPLICATION_ROUTES["granteeOverview"],
  },
  projection: {
    color: "bg-purple-02",
    description:
      "A few exit and sales scenario to help you envision the future with how much your equity could be worth.",
    label: "Projection",
    route: APPLICATION_ROUTES["granteeProjection"],
  },
  reporting: {
    color: "bg-orange-02",
    description:
      "Everything you need to know on what to report to your local (tax) authorities, and how and when your equity incentive is likely to be taxed.",
    label: "Reporting and general taxation",
    route: APPLICATION_ROUTES["granteeReporting"],
  },
  taxation: {
    color: "bg-red-02",
    description:
      "Everything you and your accountant need to know about taxation based on your own grant data.",
    label: "Taxation: Example",
    route: APPLICATION_ROUTES["granteeTaxation"],
  },
} as const;

const getLinks = ({
  grantee,
}: {
  grantee: GranteeEquityTypesGroupLayout_Grantee_Query$data["grantee"];
}) => {
  const everyGrantHasTaxationGranteePortalContent = grantee.ctmsGrants.every(
    (ctmsGrant) => !isNil(ctmsGrant.taxationGranteePortalContent),
  );
  const someGrantsAreNotVirtual = grantee.ctmsGrants.some(
    (ctmsGrant) => !ctmsGrant.isVirtual,
  );

  return compact([
    GRANTEE_LAYOUT_LINKS["overview"],
    someGrantsAreNotVirtual && GRANTEE_LAYOUT_LINKS["exercise"],
    GRANTEE_LAYOUT_LINKS["projection"],
    someGrantsAreNotVirtual && GRANTEE_LAYOUT_LINKS["reporting"],
    someGrantsAreNotVirtual &&
      everyGrantHasTaxationGranteePortalContent &&
      GRANTEE_LAYOUT_LINKS["taxation"],
    GRANTEE_LAYOUT_LINKS["faq"],
  ]);
};

export interface GranteeLayoutContext {
  isExpertMode: boolean;
}

export const useGranteeLayoutContext = () => {
  return useOutletContext<GranteeLayoutContext>();
};

const QUERY = graphql`
  query GranteeEquityTypesGroupLayout_Grantee_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) @required(action: THROW) {
      organization {
        id
        name
      }
      id
      organization {
        name
        isConnectedToCarta
      }
      ctmsGrants {
        isVirtual
        taxationGranteePortalContent {
          __typename
        }
      }
    }
  }
`;

const GranteeEquityTypesGroupLayout: React.FC = () => {
  const granteeId = useGranteeIdParam();

  const {
    query: { grantee },
  } = useQuery<GranteeEquityTypesGroupLayout_Grantee_Query>(QUERY, {
    granteeId,
  });

  const authentication = useAuthentication();

  const navigate = useNavigate();

  const { isXs } = useTailwindActiveBreakpoints();

  const { toggle: switchMode, value: isExpertMode } = useBoolean(false);
  const {
    setFalse: closeMenu,
    setTrue: openMenu,
    value: isMenuOpen,
  } = useBoolean(!isXs);

  const granteeLayoutContext: GranteeLayoutContext = {
    isExpertMode,
  };

  const handleLogOutButtonClick = useCallback(() => {
    authentication.logout();
    navigate("/", { replace: true });
  }, [authentication, navigate]);

  const links = useMemo(() => getLinks({ grantee }), [grantee]);

  const location = useLocation();

  const showPoweredByCarta = useLocationMatchesRoutes([
    "granteeOverview",
    "granteeProjection",
    "granteeExercise",
  ]);

  // Needed to close modal when clicking on a link
  useEffect(() => {
    closeMenu();
  }, [closeMenu, location]);

  const equityTypesGroup = useEquityTypesGroupParam();

  const virtualGrants = grantee.ctmsGrants.filter((grant) => grant.isVirtual);
  const realGrants = grantee.ctmsGrants.filter((grant) => !grant.isVirtual);

  const hasBothVirtualAndRealGrants =
    virtualGrants.length > 0 && realGrants.length > 0;

  return (
    <div className="h-full overflow-auto bg-gray-01">
      {isXs ? (
        <>
          <div className="sticky left-0 right-0 top-0 z-10 mb-9 flex items-center justify-between bg-white px-4 py-5 shadow-100">
            <Logo className="h-6" variant="black" />

            <Bars3Icon className="w-8" onClick={openMenu} />
          </div>

          <div
            className={classNames("fixed inset-0 z-20 hidden bg-black-09/50", {
              "block sm:hidden": isMenuOpen,
            })}
          />
        </>
      ) : null}

      {!isXs || isMenuOpen ? (
        <div className="fixed inset-4 left-0 right-0 top-0 z-30 grid content-start items-center gap-4 rounded bg-white px-6 py-6 shadow-100 sm:sticky sm:inset-auto sm:z-10 sm:mb-9 sm:grid-cols-[1fr_max-content_1fr] sm:content-normal sm:gap-2 sm:rounded-none sm:px-4 sm:py-2">
          <div className="flex justify-between sm:hidden">
            <Logo className="h-6" variant="black" />

            <button className="h-6 w-6" onClick={closeMenu}>
              <XMarkIcon className="text-black-07" />
            </button>
          </div>
          <div>
            <WideDropdown
              className="max-w-full sm:max-w-fit"
              label={grantee.organization.name}
            >
              {hasBothVirtualAndRealGrants && (
                <WideDropdown.LinkItem
                  icon={<ArrowPathIcon />}
                  to={generatePath(
                    APPLICATION_ROUTES["granteeEquityTypesGroupSelector"],
                    {
                      granteeId: grantee.id,
                    },
                  )}
                >
                  Switch portal
                </WideDropdown.LinkItem>
              )}
              <WideDropdown.ButtonItem
                icon={<ArrowLeftOnRectangleIcon />}
                onClick={handleLogOutButtonClick}
                variant="red"
              >
                Log out
              </WideDropdown.ButtonItem>
            </WideDropdown>
          </div>

          <div className="flex flex-1 flex-col items-start justify-center gap-1 sm:flex-row sm:items-center sm:gap-2">
            {links.map((link) => (
              <NavLink
                className="block w-full sm:inline sm:w-auto"
                key={link.label}
                to={generatePath(link.route, {
                  equityTypesGroup,
                  granteeId: grantee.id,
                })}
              >
                {({ isActive }) => (
                  <Button
                    className="w-full sm:w-auto"
                    size="small"
                    variant={isActive ? "Primary Outline" : "Tertiary Link"}
                  >
                    {link.label}
                  </Button>
                )}
              </NavLink>
            ))}
          </div>

          <div className="mt-3 flex items-center justify-center gap-2 sm:mt-0 sm:justify-end">
            <Typography as="div" variant="Regular/Extra Small">
              Beginner
            </Typography>
            <Toggle enabled={isExpertMode} onChange={switchMode} />
            <Typography as="div" variant="Regular/Extra Small">
              Expert
            </Typography>
          </div>
        </div>
      ) : null}

      <div className="max-w-[960px] space-y-10 px-4 sm:m-auto sm:pb-20">
        <RoundedBox className="p-6 sm:p-10" withShadow>
          <Suspense fallback={<LoadingPlaceholder />}>
            <Outlet context={granteeLayoutContext} />
          </Suspense>
        </RoundedBox>

        {showPoweredByCarta && grantee.organization.isConnectedToCarta && (
          <PoweredByCarta className="m-auto h-[22px]" />
        )}

        <div className="flex flex-col gap-4 sm:flex-row">
          {links.map((link) => {
            const { color: linkColor, description: linkDescription } = link;
            if (!linkDescription || !linkColor) return null;
            return (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "hidden"
                    : `flex flex-1 flex-row items-center justify-between gap-6 rounded-lg p-6 sm:flex-col sm:items-start sm:justify-normal ${linkColor}`
                }
                key={link.label}
                to={generatePath(link.route, {
                  equityTypesGroup,
                  granteeId: grantee.id,
                })}
              >
                <div>
                  <Typography
                    as="div"
                    className="mb-0 sm:mb-2"
                    variant="Medium/Default"
                  >
                    {link.label}
                  </Typography>
                  <Typography
                    as="div"
                    className="hidden sm:block"
                    variant="Regular/Small"
                  >
                    {linkDescription}
                  </Typography>
                </div>
                <Typography
                  as="div"
                  className="flex items-center gap-2 text-primary sm:mt-auto"
                  variant="Medium/Small"
                >
                  Explore
                  <ArrowRightIcon className="w-6" />
                </Typography>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GranteeEquityTypesGroupLayout;
