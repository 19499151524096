import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { isAfter } from "date-fns";
import { startTransition, useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import * as z from "zod";

import { GranteeContractNotStartedYetNoticeMessage } from "../../../components/GranteeContractNotStartedYetNoticeMessage";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../components/GranteeFormSlide";
import { GranteeNotGrantableAlert } from "../../../components/GranteeNotGrantableAlert";
import { GranteeSelect } from "../../../components/GranteeSelect";
import { Page } from "../../../components/Page";
import { ShortDate } from "../../../components/ShortDate";
import { Button } from "../../../components/ui/Button";
import { Divider } from "../../../components/ui/Divider";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import NotFoundPage from "../../NotFound/NotFound";
import { Grantee_EquityOffer$key } from "./__generated__/Grantee_EquityOffer.graphql";
import { Grantee_Grantee$key } from "./__generated__/Grantee_Grantee.graphql";
import { Grantee_Organization$key } from "./__generated__/Grantee_Organization.graphql";
import { Grantee_Query } from "./__generated__/Grantee_Query.graphql";
import { Grantee_Viewer$key } from "./__generated__/Grantee_Viewer.graphql";
import { useAssistedGrantContext } from "./Context";
import { AssistedGrantPageGridLayout, AssistedGrantPageTitle } from "./Shared";

const NoOrganizationInstrumentsAlert: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <NoticeMessage className="mt-4" size="Small" variant="Danger">
      You haven’t unlocked this geography yet.
      <br />
      Please contact {applicationName} support team.
    </NoticeMessage>
  );
};

const AssistedGrantSelectGrantee = z.object({
  granteeId: z.string(),
});

export type AssistedGrantSelectGranteeFormInputs = z.infer<
  typeof AssistedGrantSelectGrantee
>;

const ORGANIZATION_FRAGMENT = graphql`
  fragment Grantee_Organization on Organization
  @refetchable(queryName: "Grantee_Organization_RefetchQuery") {
    id
    instruments {
      id
    }
    activeGrantees: grantees(filters: { status: Active }) {
      edges {
        node {
          ...Grantee_Grantee
        }
      }
    }
    equityGrid {
      activated
    }
    ...GranteeFormSlide_Organization
    ...GranteeNotGrantableAlert_Organization
    ...GranteeSelect_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment Grantee_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...GranteeNotGrantableAlert_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment Grantee_Grantee on Grantee @relay(plural: true) {
    id
    name
    workRelationship
    managementCompanyName
    grantableStatus
    contractStartDate
    jobTitle
    taxResidenceCountry {
      emoji
      name
    }
    equityGridLevel {
      name
    }
    offGrid
    company {
      completeName
    }
    ...GranteeSelect_Grantees
    ...GranteeFormSlide_Grantee
    ...GranteeNotGrantableAlert_Grantee
  }
`;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Grantee_EquityOffer on EquityOffer {
    candidateName
    position
    workRelationship
    contractStartDate
    taxResidenceCountry {
      code
    }
  }
`;

const AdminAssistedGrantGranteeView: React.FC<{
  organizationFragment: Grantee_Organization$key;
  viewerFragment: Grantee_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const activeGrantees = useFragment<Grantee_Grantee$key>(
    GRANTEE_FRAGMENT,
    organization.activeGrantees.edges.map((edge) => edge.node),
  );

  const context = useAssistedGrantContext();
  const navigate = useNavigate();

  const equityOfferSource =
    useFragment<Grantee_EquityOffer$key>(
      EQUITY_OFFER_FRAGMENT,
      context.state.equityOfferSource,
    ) ?? null;

  const showEquityGridFeature = organization.equityGrid.activated;

  const [urlSearchParams] = useSearchParams();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      granteeId:
        context.state.granteeId ??
        urlSearchParams.get("defaultGranteeId") ??
        undefined,
    },
    resolver: zodResolver(AssistedGrantSelectGrantee),
  });

  const granteeId = watch("granteeId");
  const grantee = activeGrantees.find((grantee) => grantee.id === granteeId);

  const onSubmit = handleSubmit((_data) => {
    const data = _data as AssistedGrantSelectGranteeFormInputs;
    context.dispatchAction({
      granteeCountry: grantee?.taxResidenceCountry?.name || "",
      granteeId: data.granteeId,
      granteeManagementCompanyName:
        grantee?.workRelationship === "ContractorManagementCompany"
          ? grantee.managementCompanyName
          : null,
      type: "SELECT_GRANTEE",
    });
    navigate(
      generatePath(
        APPLICATION_ROUTES["organizationAssistedGrantGranteeInstrument"],
        {
          organizationId: organization.id,
        },
      ),
    );
  });

  const granteeContractNotStartedYet = useMemo(() => {
    if (!grantee?.contractStartDate) {
      return false;
    }

    return isAfter(new Date(grantee?.contractStartDate), new Date());
  }, [grantee]);

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  const onGranteesUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({});
    });
  }, [refetchOrganization]);

  const defaultGranteeCreationValues = useMemo((): React.ComponentProps<
    typeof GranteeSelect
  >["defaultGranteeCreationValues"] => {
    if (!equityOfferSource?.candidateName) {
      return null;
    }

    return {
      companyId: undefined,
      contractStartDate: equityOfferSource.contractStartDate ?? undefined,
      countryCode: equityOfferSource.taxResidenceCountry?.code ?? undefined,
      email: undefined,
      employerOfRecordId: undefined,
      equityGridLevelId: undefined,
      jobTitle: equityOfferSource.position ?? undefined,
      managementCompanyName: undefined,
      name: equityOfferSource.candidateName,
      USStateOfResidenceCode: undefined,
      workRelationship: equityOfferSource.workRelationship ?? undefined,
    };
  }, [equityOfferSource]);

  if (!organization) {
    return <NotFoundPage />;
  }

  const hasNoInstruments = !organization.instruments[0];

  if (hasNoInstruments) {
    return <NoOrganizationInstrumentsAlert />;
  }

  return (
    <>
      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={closeGranteeFormSlide}
        onGranteeDeleted={closeGranteeFormSlide}
        onGranteeUpdated={closeGranteeFormSlide}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />
      <form id="assisted-grant-select-grantee" onSubmit={onSubmit}>
        <AssistedGrantPageGridLayout
          footer={
            grantee?.grantableStatus === "GRANTABLE" && (
              <Button
                data-cy="granteeNextStepButton"
                rightIcon={<ArrowRightIcon />}
                type="submit"
              >
                Next
              </Button>
            )
          }
          header={
            <AssistedGrantPageTitle subtitle="Start creating your grant by selecting the team member you want to incentivize.">
              Alright, who’s the grantee?
            </AssistedGrantPageTitle>
          }
        >
          <div className="space-y-4" data-cy="select-grantee">
            <Controller
              control={control}
              defaultValue={undefined}
              name="granteeId"
              render={({ field, fieldState }) => (
                <GranteeSelect
                  defaultGranteeCreationValues={defaultGranteeCreationValues}
                  disabled={context.isParameterImmutable("granteeId")}
                  granteesFragment={activeGrantees}
                  invalid={!!fieldState.error}
                  onChange={field.onChange}
                  onGranteesUpdated={onGranteesUpdated}
                  organizationFragment={organization}
                  value={field.value}
                />
              )}
            />

            <div className="w-full space-y-10">
              <div className="space-y-4">
                {grantee ? (
                  grantee.grantableStatus === "GRANTABLE" ? (
                    <>
                      {granteeContractNotStartedYet && (
                        <GranteeContractNotStartedYetNoticeMessage />
                      )}
                      <div className="w-full rounded bg-gray-01 p-4">
                        <Typography
                          className="text-black-05"
                          variant="Regular/Small"
                        >
                          👍 Great!{" "}
                          <Typography
                            className="text-primary-05"
                            variant="Regular/Small"
                          >
                            {grantee.name}
                          </Typography>{" "}
                          will be so thrilled! Now let’s prepare the details of
                          the grant...
                        </Typography>
                      </div>
                      <RoundedBox
                        className="space-y-4 p-6"
                        withBorder
                        withShadow
                      >
                        <Typography variant="Medium/Extra Small">
                          👀 Verify grantee’s details
                        </Typography>
                        <Typography
                          as="div"
                          className="rounded bg-gray-01 px-4 py-3 !leading-7"
                          variant="Regular/Extra Small"
                        >
                          {grantee.name} has their tax residence in&nbsp;
                          <Tag color="gray">
                            {grantee.taxResidenceCountry?.emoji}{" "}
                            {grantee.taxResidenceCountry?.name}
                          </Tag>
                          &nbsp;and is working
                          {grantee.company && (
                            <>
                              &nbsp;for&nbsp;
                              <Tag color="primary">
                                {grantee.company.completeName}
                              </Tag>
                            </>
                          )}
                          &nbsp;as a&nbsp;
                          <Tag color="primary">
                            {grantee.workRelationship
                              ? WORK_RELATIONSHIP_TO_LABEL_HELPER[
                                  grantee.workRelationship
                                ].singularLabel
                              : null}
                          </Tag>
                          &nbsp;since&nbsp;
                          <Tag color="primary">
                            {grantee.contractStartDate ? (
                              <ShortDate value={grantee.contractStartDate} />
                            ) : null}
                          </Tag>
                          .
                          <br />
                          This grantee is&nbsp;
                          {grantee.jobTitle ? (
                            <Tag color="primary">{grantee.jobTitle}</Tag>
                          ) : (
                            <Tag color="gray">missing job title</Tag>
                          )}
                          {showEquityGridFeature &&
                            (grantee.offGrid ? (
                              <>
                                &nbsp;and is marked as&nbsp;
                                <Tag color="primary">Off Grid</Tag>
                              </>
                            ) : grantee.equityGridLevel ? (
                              <>
                                &nbsp;and is marked as equity grid level&nbsp;
                                <Tag color="primary">
                                  {grantee.equityGridLevel.name}
                                </Tag>
                              </>
                            ) : (
                              <>
                                &nbsp;and is&nbsp;
                                <Tag color="gray">
                                  missing equity grid level
                                </Tag>
                              </>
                            ))}
                          .
                        </Typography>
                        {grantee.workRelationship === "EoREmployee" && (
                          <NoticeMessage size="Small" variant="Idea">
                            It is recommended to inform the Employer of Record
                            whenever you grant equity to employees.
                          </NoticeMessage>
                        )}
                        <Divider />
                        <Typography
                          as="div"
                          className="text-gray-09"
                          variant="Regular/Caption"
                        >
                          Feel free to{" "}
                          <button
                            onClick={() => {
                              openGranteeFormSlideInEditMode(grantee);
                            }}
                            type="button"
                          >
                            <Typography
                              className="text-primary"
                              variant="Medium/Caption"
                            >
                              update the grantee’s details
                            </Typography>
                          </button>{" "}
                          or proceed to the next page.
                        </Typography>
                      </RoundedBox>
                    </>
                  ) : (
                    <GranteeNotGrantableAlert
                      granteeFragment={grantee}
                      onCompleteGranteeInformationClick={() => {
                        openGranteeFormSlideInEditMode(grantee);
                      }}
                      organizationFragment={organization}
                      viewerFragment={viewer}
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </AssistedGrantPageGridLayout>
      </form>
    </>
  );
};

const QUERY = graphql`
  query Grantee_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...Grantee_Organization
    }
    me {
      ...Grantee_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const AdminAssistedGrantGranteePage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { me: viewer, organization },
  } = useQuery<Grantee_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Assisted Grant Flow"
      analyticsName="Admin - Assisted Grant Flow - Grantee"
      organizationId={organization.id}
      title={`Admin | ${organization.name} assisted grant flow - grantee`}
    >
      <AdminAssistedGrantGranteeView
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    </Page>
  );
};

export default AdminAssistedGrantGranteePage;
