import { UserIcon } from "@heroicons/react/24/outline";
import { isNil } from "lodash";
import { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DetailsBox } from "../../../../components/DetailsBox";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../../components/GranteeFormSlide";
import { MissingInformationTag } from "../../../../components/MissingInformationTag";
import { ShortDate } from "../../../../components/ShortDate";
import { Button } from "../../../../components/ui/Button";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { APPLICATION_ROUTES } from "../../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import { GranteeDetailsBox_Grantee$key } from "./__generated__/GranteeDetailsBox_Grantee.graphql";
import { GranteeDetailsBox_Organization$key } from "./__generated__/GranteeDetailsBox_Organization.graphql";
import { GranteeDetailsBox_Viewer$key } from "./__generated__/GranteeDetailsBox_Viewer.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeDetailsBox_Grantee on Grantee {
    name
    email
    jobTitle
    workRelationship
    managementCompanyName
    company {
      completeName
    }
    taxResidenceCountry {
      emoji
      name
    }
    USStateOfResidence {
      name
    }
    contractStartDate
    ...GranteeFormSlide_Grantee
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeDetailsBox_Organization on Organization {
    id
    ...GranteeFormSlide_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GranteeDetailsBox_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

export const GranteeDetailsBox: React.FC<{
  granteeFragment: GranteeDetailsBox_Grantee$key;
  organizationFragment: GranteeDetailsBox_Organization$key;
  viewerFragment: GranteeDetailsBox_Viewer$key;
}> = ({ granteeFragment, organizationFragment, viewerFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const navigate = useNavigate();
  const goToGranteesPage = useCallback(
    () =>
      navigate(
        generatePath(APPLICATION_ROUTES["organizationGrantees"], {
          organizationId: organization.id,
        }),
      ),
    [navigate, organization.id],
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  return (
    <>
      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={closeGranteeFormSlide}
        onGranteeDeleted={() => {
          closeGranteeFormSlide();
          goToGranteesPage();
        }}
        onGranteeUpdated={closeGranteeFormSlide}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />

      <DetailsBox
        headerRightAddon={
          viewer.isAllowedToManageOrganization && (
            <Button
              className="ml-auto"
              onClick={() => {
                openGranteeFormSlideInEditMode(grantee);
              }}
              size="small"
              variant="Primary Outline"
            >
              Edit grantee details
            </Button>
          )
        }
        Icon={UserIcon}
        title="Grantee details"
      >
        <div className="flex flex-col gap-4">
          <InformationRows>
            <InformationRows.Row label="Full name">
              {grantee.name}
            </InformationRows.Row>

            <InformationRows.Row label="Email">
              {grantee.email}
            </InformationRows.Row>

            <InformationRows.Row label="Work relationship">
              {grantee.workRelationship ? (
                WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
                  .singularLabel
              ) : (
                <MissingInformationTag />
              )}
            </InformationRows.Row>

            <InformationRows.Row label="Job title">
              {grantee.jobTitle ?? <MissingInformationTag />}
            </InformationRows.Row>

            {!isNil(grantee.managementCompanyName) && (
              <InformationRows.Row label="Management company">
                {grantee.managementCompanyName}
              </InformationRows.Row>
            )}

            <InformationRows.Row label="With">
              {grantee.company?.completeName ?? <MissingInformationTag />}
            </InformationRows.Row>

            <InformationRows.Row label="Country of residence">
              {grantee.taxResidenceCountry ? (
                `${grantee.taxResidenceCountry.emoji} ${grantee.taxResidenceCountry.name}`
              ) : (
                <MissingInformationTag />
              )}
            </InformationRows.Row>

            {grantee.USStateOfResidence ? (
              <InformationRows.Row label="State of residence">
                {grantee.USStateOfResidence.name}
              </InformationRows.Row>
            ) : null}

            <InformationRows.Row label="Contract start date">
              {grantee.contractStartDate ? (
                <ShortDate value={grantee.contractStartDate} />
              ) : (
                <MissingInformationTag />
              )}
            </InformationRows.Row>
          </InformationRows>
        </div>
      </DetailsBox>
    </>
  );
};
