/**
 * @generated SignedSource<<f1345a00672c0ac8e57b00ba4208c09b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestSimulation_CTMSGrant$data = {
  readonly exercisePrice: number;
  readonly grantee: {
    readonly id: string;
    readonly workRelationship: WorkRelationship | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovalSection_CTMSGrant" | "ExerciseRequestGranteeSection_CTMSGrant" | "ExerciseRequestNotifySection_CTMSGrant" | "ExerciseRequestTaxTreatmentSection_CTMSGrant">;
  readonly " $fragmentType": "ExerciseRequestSimulation_CTMSGrant";
};
export type ExerciseRequestSimulation_CTMSGrant$key = {
  readonly " $data"?: ExerciseRequestSimulation_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestSimulation_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestSimulation_CTMSGrant",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "exercisePrice",
        "storageKey": null
      },
      "action": "THROW",
      "path": "exercisePrice"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestGranteeSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestTaxTreatmentSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestApprovalSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestNotifySection_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "4004f0b86567034334b69f4c0c3c0327";

export default node;
