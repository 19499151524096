/**
 * @generated SignedSource<<62d379ff1f54b935d915e24e73498377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type VestingSchedule_Grantee$data = {
  readonly contractStartDate: string;
  readonly ctmsGrants: ReadonlyArray<{
    readonly __typename: "CTMSGrant";
  }>;
  readonly defaultVestingSchedule: {
    readonly id: string;
  } | null;
  readonly easopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly equityGridLevel: {
    readonly __typename: "EquityGridLevel";
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingPreview_Grantee">;
  readonly " $fragmentType": "VestingSchedule_Grantee";
};
export type VestingSchedule_Grantee$key = {
  readonly " $data"?: VestingSchedule_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingSchedule_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingSchedule_Grantee",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contractStartDate",
        "storageKey": null
      },
      "action": "THROW",
      "path": "contractStartDate"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "defaultVestingSchedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantVestingPreview_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "2d6318f4271985388ac9f67c8ce36ee9";

export default node;
