/**
 * @generated SignedSource<<ec657d3dba2e0acc6785b302a29b67ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
export type OrganizationCTMSGrantsFiltersOrderByField = "cumulativeVested" | "equityType" | "exercisePrice" | "grantee" | "label" | "quantityExercised" | "quantityIssued" | "remainingDaysToExercise" | "settled";
export type OrganizationCTMSGrantsFiltersVestingStatus = "CLIFF_NOT_PASSED" | "FULLY_VESTED" | "VESTING";
export type SortDirection = "ASC" | "DESC";
export type OrganizationCTMSGrantsFilters = {
  equityTypeIn?: ReadonlyArray<string> | null;
  granteeId?: string | null;
  idIn?: ReadonlyArray<string> | null;
  orderBy?: OrganizationCTMSGrantsFiltersOrderBy | null;
  search?: string | null;
  statusIn?: ReadonlyArray<CTMSGrantStatus> | null;
  vestingStatus?: OrganizationCTMSGrantsFiltersVestingStatus | null;
};
export type OrganizationCTMSGrantsFiltersOrderBy = {
  direction: SortDirection;
  field: OrganizationCTMSGrantsFiltersOrderByField;
};
export type CliffPeriodsSlideOver_Query$variables = {
  filters?: OrganizationCTMSGrantsFilters | null;
  organizationId: string;
};
export type CliffPeriodsSlideOver_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CliffPeriodsSlideOver_Refetchable_Organization">;
  };
};
export type CliffPeriodsSlideOver_Query = {
  response: CliffPeriodsSlideOver_Query$data;
  variables: CliffPeriodsSlideOver_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CliffPeriodsSlideOver_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": [
                (v3/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "CliffPeriodsSlideOver_Refetchable_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "organization"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CliffPeriodsSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstVestingEventDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "CTMSGrants_Organization_ctmsGrants",
            "kind": "LinkedHandle",
            "name": "ctmsGrants"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d3542d55f9c5479dc77293e70d96d7c",
    "id": null,
    "metadata": {},
    "name": "CliffPeriodsSlideOver_Query",
    "operationKind": "query",
    "text": "query CliffPeriodsSlideOver_Query(\n  $filters: OrganizationCTMSGrantsFilters\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    ...CliffPeriodsSlideOver_Refetchable_Organization_VTAHT\n  }\n}\n\nfragment CliffPeriodsSlideOver_Refetchable_Organization_VTAHT on Organization {\n  ctmsGrants(first: 20, filters: $filters) {\n    totalCount\n    edges {\n      node {\n        id\n        label\n        firstVestingEventDate\n        grantee {\n          id\n          name\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "5e036675941056c32f93a4bed40bc0a3";

export default node;
