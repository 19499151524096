import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo } from "react";

import { ToastElementProps, useToaster } from "../components/Toaster";
import { Button } from "../components/ui/Button";
import { RoundedBox } from "../components/ui/RoundedBox";
import { Typography } from "../components/ui/Typography";
import { useBoolean } from "./useBoolean";
import useClientHealth from "./useClientHealth";

const _UpgradeToast: React.ForwardRefRenderFunction<
  HTMLDivElement,
  {
    onReloadButtonClick?: React.ComponentProps<"button">["onClick"];
  } & ToastElementProps
> = ({ onCloseButtonClick, onReloadButtonClick }, ref) => (
  <RoundedBox className="w-full max-w-xs space-y-6 p-6" ref={ref} withShadow>
    <div className="flex items-center justify-between">
      <Typography variant="Medium/Default">New version available</Typography>
      <button onClick={onCloseButtonClick}>
        <XMarkIcon className="h-6 w-6" />
      </button>
    </div>
    <Typography as="div" variant="Regular/Extra Small">
      There is a new version available and the app will automatically refresh.
    </Typography>
    <div className="flex items-center gap-2">
      <Button
        fullWidth
        onClick={onCloseButtonClick}
        size="small"
        type="button"
        variant="Secondary Outline"
      >
        Refresh later
      </Button>
      <Button
        fullWidth
        onClick={onReloadButtonClick}
        size="small"
        type="button"
      >
        OK
      </Button>
    </div>
  </RoundedBox>
);

export const UpgradeToast = React.forwardRef(_UpgradeToast);

export const useUpgradeNotificationDispatcher = () => {
  const clientHealth = useClientHealth();
  const toaster = useToaster();
  const { setTrue: onUserNotified, value: userWasNotified } = useBoolean(false);
  const applicationIsOutdated = useMemo(() => {
    if (clientHealth.state === "loading" || clientHealth.state === "failed") {
      return false;
    }

    return (
      clientHealth.value.activeVersion !== clientHealth.value.latestVersion
    );
  }, [clientHealth]);

  const reloadWindow = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    if (applicationIsOutdated && !userWasNotified) {
      toaster.push(<UpgradeToast onReloadButtonClick={reloadWindow} />, {
        position: "bottom-right",
        timeout: null,
      });
      onUserNotified();
    }
  }, [
    applicationIsOutdated,
    reloadWindow,
    toaster,
    userWasNotified,
    onUserNotified,
  ]);
};
