import { ButtonIcon, Pill } from "@remote-com/norma";
import { IconDownload } from "@remote-com/norma/icons/IconDownload";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { SelectOrganizationModal } from "../../../../components/SelectOrganizationModal";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { downloadFile } from "../../../../helpers/downloadFile";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { LegalWatchOuts_GenerateCTMSGrantsWithLegalWatchOutsXLSXExportUrl_Mutation } from "./__generated__/LegalWatchOuts_GenerateCTMSGrantsWithLegalWatchOutsXLSXExportUrl_Mutation.graphql";
import { LegalWatchOutsPage_EquityType$key } from "./__generated__/LegalWatchOutsPage_EquityType.graphql";
import { LegalWatchOutsPage_Organization$key } from "./__generated__/LegalWatchOutsPage_Organization.graphql";
import { LegalWatchOutsPage_Query } from "./__generated__/LegalWatchOutsPage_Query.graphql";
import { EquityTypesLegalWatchOutsTable } from "./EquityTypesLegalWatchOutsTable";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment LegalWatchOutsPage_EquityType on EquityType @relay(plural: true) {
    ...EquityTypesLegalWatchOutsTable_EquityType
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment LegalWatchOutsPage_Organization on Organization
  @relay(plural: true) {
    ...SelectOrganizationModal_Organization
  }
`;

const GENERATE_CTMS_GRANTS_WITH_LEGAL_WATCH_OUTS_XLSX_EXPORT_URL_MUTATION = graphql`
  mutation LegalWatchOuts_GenerateCTMSGrantsWithLegalWatchOutsXLSXExportUrl_Mutation(
    $organizationId: OrganizationId!
  ) {
    generateCTMSGrantsWithLegalWatchOutsXLSXExportUrl(
      organizationId: $organizationId
    )
  }
`;

const LegalWatchOutsPage_: React.FC<{
  equityTypesFragment: LegalWatchOutsPage_EquityType$key;
  organizationsFragment: LegalWatchOutsPage_Organization$key;
}> = ({ equityTypesFragment, organizationsFragment }) => {
  const equityTypes = useFragment(EQUITY_TYPE_FRAGMENT, equityTypesFragment);
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const [
    generateCTMSGrantsWithLegalWatchOutsXLSXExportUrl,
    mutationIsInFlight,
  ] =
    useSafeMutation<LegalWatchOuts_GenerateCTMSGrantsWithLegalWatchOutsXLSXExportUrl_Mutation>(
      GENERATE_CTMS_GRANTS_WITH_LEGAL_WATCH_OUTS_XLSX_EXPORT_URL_MUTATION,
    );

  const generateCTMSGrantsWithLegalWatchOutsXLSXExport = useCallback(
    async ({ organizationId }: { organizationId: string }) => {
      const { generateCTMSGrantsWithLegalWatchOutsXLSXExportUrl: url } =
        await generateCTMSGrantsWithLegalWatchOutsXLSXExportUrl({
          variables: { organizationId },
        });

      downloadFile({
        filename: "grants_with_legal_watchout.xlsx",
        url,
      });
    },
    [generateCTMSGrantsWithLegalWatchOutsXLSXExportUrl],
  );

  const {
    setFalse: closeSelectOrganizationModal,
    setTrue: openSelectOrganizationModal,
    value: isSelectOrganizationModalOpen,
  } = useBoolean();

  return (
    <>
      <SelectOrganizationModal
        onClose={closeSelectOrganizationModal}
        onSelect={(organizationId) => {
          void generateCTMSGrantsWithLegalWatchOutsXLSXExport({
            organizationId,
          });
        }}
        organizationsFragment={organizations}
        show={isSelectOrganizationModalOpen}
      />
      <OneColumnLayout
        Breadcrumb={
          <BreadCrumb>
            <BreadCrumb.Link to="..">Legal content</BreadCrumb.Link>
            <BreadCrumb.Link to=".">Legal Watch Outs</BreadCrumb.Link>
          </BreadCrumb>
        }
        showFooter
      >
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="flex items-center gap-4">
            <BackButton />
            <div className="flex-grow space-y-2">
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Default">
                  Legal Watch Outs
                </Typography>
                <Pill>
                  <FormattedMessage
                    defaultMessage="{count, plural, one {# equity type} other {# equity types}}"
                    values={{
                      count: equityTypes.length,
                    }}
                  />
                </Pill>
              </div>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Create a new watch out to be displayed when granting equity
              </Typography>
            </div>
            <ButtonIcon
              disabled={mutationIsInFlight}
              Icon={IconDownload}
              label="Export grants"
              onClick={() => {
                openSelectOrganizationModal();
              }}
              tone="secondary"
            />
          </div>
          <EquityTypesLegalWatchOutsTable equityTypesFragment={equityTypes} />
        </RoundedBox>
      </OneColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query LegalWatchOutsPage_Query {
    equityTypes {
      ...LegalWatchOutsPage_EquityType
    }
    organizations(
      filters: { status: Active, orderBy: { field: name, direction: ASC } }
    ) {
      ...LegalWatchOutsPage_Organization
    }
  }
`;

const LegalWatchOutsPage: React.FC = () => {
  const { equityTypes, organizations } =
    useLazyLoadQuery<LegalWatchOutsPage_Query>(QUERY, {});
  return (
    <Page
      analyticsName="Super Admin - Legal Watch Outs"
      title={`Super admin | legal watch outs`}
    >
      <LegalWatchOutsPage_
        equityTypesFragment={equityTypes}
        organizationsFragment={organizations}
      />
    </Page>
  );
};

export default LegalWatchOutsPage;
