import { Avatar } from "@remote-com/norma";

import { useAuthenticatedAccount } from "../../../../services/AuthenticatedGuard";
import remoteEquityLogo from "./remote-equity-logo.png";

const NavBar: React.FC = () => {
  const authenticatedAccount = useAuthenticatedAccount();
  const fullName = `${authenticatedAccount.firstName} ${authenticatedAccount.lastName}`;

  return (
    <div className="border-b border-remote-grey-200 bg-white">
      <div className="container mx-auto flex items-center justify-between gap-4 px-10 py-5.5">
        <img
          alt="Remote Equity Logo"
          className="w-full max-w-[182px] object-contain"
          src={remoteEquityLogo}
        />
        <div className="flex items-center gap-2">
          <Avatar name={fullName} size="sm" />
          <div>
            <div className="text-remote-XS/Medium text-remote-grey-900">
              {fullName}
            </div>
            <div className="text-remote-XS text-remote-grey-600">
              {authenticatedAccount.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RemoteLikeApplicationLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="grid min-h-full grid-rows-[auto_1fr] bg-remote-background-base font-remote-body">
      <div className="sticky top-0 z-10">
        <NavBar />
      </div>
      <div className={"container mx-auto h-full px-10 py-6"}>{children}</div>
    </div>
  );
};
