import {
  EllipsisHorizontalIcon,
  StarIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Graph, ReceiptItem, Star1, Tag2, UserTag } from "iconsax-react";
import { isNil } from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import {
  DeleteSafeConfirmationModal,
  useDeleteSafeConfirmationModalState,
} from "../../../components/DeleteSafeConfirmationModal";
import {
  DeleteSimulatedGrantConfirmationModal,
  useDeleteSimulatedGrantConfirmationModalState,
} from "../../../components/DeleteSimulatedGrantConfirmationModal";
import { EditSafeSimulatorInitialSharesModal } from "../../../components/EditSafeSimulatorInitialSharesModal";
import { CompactFormattedCurrency } from "../../../components/Formatted/CompactFormattedCurrency";
import { FormattedFMV } from "../../../components/FormattedFMV";
import { Percentage } from "../../../components/Percentage";
import { SafeCalculatorDisclaimer } from "../../../components/SafeCalculatorDisclaimer";
import { SafeModal, useNewSafeModalState } from "../../../components/SafeModal";
import { ShortDate } from "../../../components/ShortDate";
import { FormValues } from "../../../components/SimulateAGrantFormModal/FORM_SCHEMA";
import { SimulateAGrantFormModal } from "../../../components/SimulateAGrantFormModal/SimulateAGrantFormModal";
import { Button } from "../../../components/ui/Button";
import { MenuButton } from "../../../components/ui/MenuButton";
import { RainbowCard } from "../../../components/ui/RainbowCard";
import { ReplyIcon } from "../../../components/ui/ReplyIcon";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { useBoolean } from "../../../hooks/useBoolean";
import {
  Safe,
  SimulatedGrantWithComputedValues,
} from "../../../services/SafeCalculatorService";
import { useSafeSimulatorContext } from "../IContext";
import {
  SafeSimulatorCalculatorContent_Organization$data,
  SafeSimulatorCalculatorContent_Organization$key,
} from "./__generated__/SafeSimulatorCalculatorContent_Organization.graphql";

const SAFE_TYPE_LABEL: Record<Safe["type"], string> = {
  POST_MONEY: "Post-money",
  POST_MONEY_MFN: "Post-money MFN",
  PRE_MONEY: "Pre-money",
};

export const SafeName: React.FC<{ safe: Safe }> = ({ safe }) => {
  const valuationCap = useMemo(() => {
    switch (safe.type) {
      case "POST_MONEY":
      case "PRE_MONEY":
        return (
          <>
            <CompactFormattedCurrency value={safe.valuationCapInUSD} /> cap
          </>
        );
      case "POST_MONEY_MFN":
        return <>No cap</>;
    }
  }, [safe.type, safe.valuationCapInUSD]);
  return (
    <FormattedMessage
      defaultMessage="SAFE {safeType} {amountInvested} / {valuationCap}"
      values={{
        amountInvested: (
          <CompactFormattedCurrency value={safe.totalAmountInvestedInUSD} />
        ),
        safeType: SAFE_TYPE_LABEL[safe.type],
        valuationCap,
      }}
    />
  );
};

function CapitalizationTable() {
  const {
    computedValues: {
      currentShareholdersShares,
      postConversionCurrentShareholdersOwnership,
      postConversionOptionPoolOwnership,
      preConversionCurrentShareholdersOwnership,
      preConversionOptionPoolOwnership,
    },
    state: { optionPoolShares },
  } = useSafeSimulatorContext();

  return (
    <Table
      borderless
      className="col-span-full grid-cols-subgrid"
      display="grid"
    >
      <Table.Header className="rounded-lg">
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Pre conversion</Table.HeaderCell>
          <Table.HeaderCell>Post conversion</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className="auto-rows-max">
        <Table.Row>
          <Table.Cell variant="Medium/Small">
            <div className="flex items-center gap-2">
              <Graph className="h-6 w-6 text-purple-05" variant="Bulk" />
              <span>Current shares count</span>
            </div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <div className="flex items-center gap-2 pl-2">
              <ReplyIcon className="h-3 w-3 text-gray-07" />
              <div className="h-1.5 w-1.5 rounded-full bg-purple-05"></div>
              <span className="text-black-05">Current Shareholders</span>
            </div>
          </Table.Cell>
          <Table.Cell>
            {currentShareholdersShares &&
            preConversionCurrentShareholdersOwnership &&
            preConversionCurrentShareholdersOwnership ? (
              <div className="space-y-0.5 text-black-05">
                <Typography as="div" variant="Medium/Small">
                  <FormattedNumber value={currentShareholdersShares} />
                </Typography>
                <Typography as="div" variant="Regular/Caption">
                  <Percentage
                    value={preConversionCurrentShareholdersOwnership}
                  />
                </Typography>
              </div>
            ) : (
              "-"
            )}
          </Table.Cell>
          <Table.Cell>
            {currentShareholdersShares &&
            postConversionCurrentShareholdersOwnership &&
            preConversionCurrentShareholdersOwnership ? (
              <div className="space-y-0.5 text-black-05">
                <Typography as="div" variant="Medium/Small">
                  <FormattedNumber value={currentShareholdersShares} />
                </Typography>
                <div className="flex items-center gap-0.5">
                  <Typography as="div" variant="Regular/Caption">
                    <Percentage
                      value={postConversionCurrentShareholdersOwnership}
                    />
                  </Typography>
                  {postConversionCurrentShareholdersOwnership &&
                    preConversionCurrentShareholdersOwnership !==
                      postConversionCurrentShareholdersOwnership && (
                      <Tag color="red" size="Extra Small">
                        <Percentage
                          signDisplay="always"
                          value={
                            postConversionCurrentShareholdersOwnership -
                            preConversionCurrentShareholdersOwnership
                          }
                        />
                      </Tag>
                    )}
                </div>
              </div>
            ) : (
              "-"
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <div className="flex items-center gap-2 pl-2">
              <ReplyIcon className="h-3 w-3 text-gray-07" />
              <div className="h-1.5 w-1.5 rounded-full bg-purple-03"></div>
              <span className="text-black-05">Option pool</span>
            </div>
          </Table.Cell>
          <Table.Cell>
            {optionPoolShares && preConversionOptionPoolOwnership ? (
              <div className="space-y-0.5 text-black-05">
                <Typography as="div" variant="Medium/Small">
                  <FormattedNumber value={optionPoolShares} />
                </Typography>
                <Typography as="div" variant="Regular/Caption">
                  <Percentage value={preConversionOptionPoolOwnership} />
                </Typography>
              </div>
            ) : (
              "-"
            )}
          </Table.Cell>
          <Table.Cell>
            {optionPoolShares &&
            postConversionOptionPoolOwnership &&
            preConversionOptionPoolOwnership ? (
              <div className="space-y-0.5 text-black-05">
                <Typography as="div" variant="Medium/Small">
                  <FormattedNumber value={optionPoolShares} />
                </Typography>
                <div className="flex items-center gap-0.5">
                  <Typography as="div" variant="Regular/Caption">
                    <Percentage value={postConversionOptionPoolOwnership} />
                  </Typography>
                  {postConversionOptionPoolOwnership &&
                    preConversionOptionPoolOwnership !==
                      postConversionOptionPoolOwnership && (
                      <Tag color="red" size="Extra Small">
                        <Percentage
                          signDisplay="always"
                          value={
                            postConversionOptionPoolOwnership -
                            preConversionOptionPoolOwnership
                          }
                        />
                      </Tag>
                    )}
                </div>
              </div>
            ) : (
              "-"
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function SAFENotesTable({ readOnly }: { readOnly?: boolean }) {
  const {
    computedValues: { safesWithPostConversionShares },
    onSafeAdded,
    onSafeDeleted,
    onSafeEdited,
    onSafeSetAsCurrentValuation,
  } = useSafeSimulatorContext();

  const {
    closeDeleteSafeConfirmationModal,
    deleteSafeConfirmationModalState,
    showDeleteSafeConfirmationModal,
  } = useDeleteSafeConfirmationModalState();

  const { closeNewSafeModal, newSafeModalState, openNewSafeModal } =
    useNewSafeModalState();

  return (
    <>
      <SafeModal
        onAddSafe={onSafeAdded}
        onClose={closeNewSafeModal}
        onEditSafe={onSafeEdited}
        state={newSafeModalState}
      />
      <DeleteSafeConfirmationModal
        onClose={closeDeleteSafeConfirmationModal}
        onDeleteSafe={onSafeDeleted}
        state={deleteSafeConfirmationModalState}
      />
      <Table
        borderless
        className="col-span-full grid-cols-subgrid"
        display="grid"
      >
        <Table.Header className="rounded-lg">
          <Table.Row>
            <Table.HeaderCell>SAFE Notes</Table.HeaderCell>
            <Table.HeaderCell>Pre conversion</Table.HeaderCell>
            <Table.HeaderCell>Post conversion</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className="auto-rows-max">
          {safesWithPostConversionShares.map((safe, index) => (
            <Table.Row key={`safe${index}`}>
              <Table.Cell variant="Medium/Small">
                <div className="flex items-center gap-2">
                  {safe.useAsValuation ? (
                    <Star1 className="h-6 w-6 text-purple" variant="Bulk" />
                  ) : (
                    <Tag2 className="h-6 w-6 text-green-04" variant="Bulk" />
                  )}
                  <div className="space-y-0.5">
                    <div>
                      <SafeName safe={safe} />
                    </div>
                    <Typography
                      as="div"
                      className="text-black-05"
                      variant="Regular/Caption"
                    >
                      <ShortDate value={safe.lastIssuanceDate} />
                    </Typography>
                    {"mfnDate" in safe ? (
                      <Typography
                        as="div"
                        className="text-black-05"
                        variant="Regular/Caption"
                      >
                        MFN date <ShortDate value={safe.mfnDate} />
                      </Typography>
                    ) : null}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>
                {safe.postConversionShares !== null &&
                safe.postConversionOwnership !== null &&
                safe.postConversionPPSInUSD !== null ? (
                  <div className="space-y-0.5">
                    <Typography as="div" variant="Medium/Small">
                      <FormattedNumber value={safe.postConversionShares} />
                    </Typography>
                    <Typography
                      as="div"
                      className="text-black-05"
                      variant="Regular/Caption"
                    >
                      <Percentage value={safe.postConversionOwnership} />
                    </Typography>
                    <Typography as="div" variant="Medium/Caption">
                      <FormattedFMV value={safe.postConversionPPSInUSD} />
                      /Share
                    </Typography>
                  </div>
                ) : (
                  "-"
                )}
              </Table.Cell>
              {!readOnly && (
                <Table.Cell>
                  <div className="flex items-center justify-end gap-2">
                    <MenuButton
                      button={
                        <Button
                          leftIcon={<EllipsisHorizontalIcon />}
                          size="extra small"
                          variant="Secondary Full"
                        />
                      }
                    >
                      {safesWithPostConversionShares.length > 1 && (
                        <MenuButton.Item
                          disabled={safe.useAsValuation}
                          leftIcon={<StarIcon />}
                          onClick={() => onSafeSetAsCurrentValuation(safe)}
                        >
                          Set as current valuation
                        </MenuButton.Item>
                      )}
                      <MenuButton.Item
                        leftIcon={<TrashIcon />}
                        onClick={() => showDeleteSafeConfirmationModal(safe)}
                      >
                        Delete
                      </MenuButton.Item>
                    </MenuButton>
                    <Button
                      onClick={() => openNewSafeModal(safe)}
                      size="extra small"
                      type="button"
                      variant="Secondary Outline"
                    >
                      Edit
                    </Button>
                  </div>
                </Table.Cell>
              )}
            </Table.Row>
          ))}

          <Table.Row>
            <Table.Cell className="py-4">
              {!readOnly && (
                <Button
                  onClick={() => openNewSafeModal()}
                  size="extra small"
                  type="button"
                  variant="Primary Full"
                >
                  + Add SAFE note
                </Button>
              )}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function FullyDilutedSharesTable() {
  const {
    computedValues: { postConversionFullyDilutedShares },
    state: { preConversionFullyDilutedShares },
  } = useSafeSimulatorContext();

  return (
    <div className="col-span-full grid grid-cols-subgrid overflow-hidden rounded-lg border-[0.5px] border-gray-05">
      <Table
        borderless
        className="col-span-full grid-cols-subgrid"
        display="grid"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Pre conversion</Table.HeaderCell>
            <Table.HeaderCell>Post conversion</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell variant="Medium/Small">
              <div className="flex items-center gap-2">
                <ReceiptItem
                  className="h-6 w-6 text-primary-05"
                  variant="Bulk"
                />
                <span>Fully Diluted Shares Count</span>
              </div>
            </Table.Cell>
            <Table.Cell>
              {preConversionFullyDilutedShares ? (
                <div className="space-y-0.5 text-black-05">
                  <Typography as="div" variant="Medium/Small">
                    <FormattedNumber value={preConversionFullyDilutedShares} />
                  </Typography>
                  <Typography as="div" variant="Regular/Caption">
                    100%
                  </Typography>
                </div>
              ) : (
                "-"
              )}
            </Table.Cell>
            <Table.Cell>
              {postConversionFullyDilutedShares ? (
                <div className="space-y-0.5 text-black-05">
                  <Typography as="div" variant="Medium/Small">
                    <FormattedNumber value={postConversionFullyDilutedShares} />
                  </Typography>
                  <Typography as="div" variant="Regular/Caption">
                    100%
                  </Typography>
                </div>
              ) : (
                "-"
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

function GrantSimulationTableRow({
  readOnly,
  simulatedGrant,
}: {
  readOnly?: boolean;
  simulatedGrant: SimulatedGrantWithComputedValues;
}) {
  const {
    computedValues: {
      postConversionCurrentPricePerShare,
      postConversionFullyDilutedShares,
    },
    onSimulatedGrantDeleted,
    onSimulatedGrantEdited,
    state: { optionPoolShares, preConversionFullyDilutedShares },
  } = useSafeSimulatorContext();

  if (preConversionFullyDilutedShares === null)
    throw new Error(
      "preConversionFullyDilutedShares should be defined at that point",
    );
  if (postConversionFullyDilutedShares === null)
    throw new Error(
      "preConversionFullyDilutedShares should be defined at that point",
    );

  const {
    setFalse: closeSimulateAGrantFormModal,
    setTrue: openSimulateAGrantFormModal,
    value: simulateAGrantFormModalIsOpen,
  } = useBoolean(false);

  const handleSimulateAGrantFormSubmit = useCallback(
    async (formData: FormValues) => {
      await onSimulatedGrantEdited({
        ...simulatedGrant,
        ...formData,
      });

      closeSimulateAGrantFormModal();
    },
    [closeSimulateAGrantFormModal, onSimulatedGrantEdited, simulatedGrant],
  );

  const {
    closeShowDeleteSimulatedGrantConfirmationModal,
    deleteSimulatedGrantConfirmationModalState,
    showDeleteSimulatedGrantConfirmationModal,
  } = useDeleteSimulatedGrantConfirmationModalState();

  const handleDeleteSimulatedGrantConfirmation = useCallback(async () => {
    await onSimulatedGrantDeleted(simulatedGrant);
    closeShowDeleteSimulatedGrantConfirmationModal();
  }, [
    closeShowDeleteSimulatedGrantConfirmationModal,
    onSimulatedGrantDeleted,
    simulatedGrant,
  ]);

  return (
    <>
      <SimulateAGrantFormModal
        existingSimilatedGrant={simulatedGrant}
        maximumShares={optionPoolShares}
        onClose={closeSimulateAGrantFormModal}
        onSubmit={handleSimulateAGrantFormSubmit}
        postConversionCurrentPricePerShare={postConversionCurrentPricePerShare}
        postConversionFullyDilutedShares={postConversionFullyDilutedShares}
        preConversionFullyDilutedShares={preConversionFullyDilutedShares}
        show={simulateAGrantFormModalIsOpen}
        submitButtonLabel="Save"
      />
      <DeleteSimulatedGrantConfirmationModal
        onClose={closeShowDeleteSimulatedGrantConfirmationModal}
        onConfirmed={handleDeleteSimulatedGrantConfirmation}
        state={deleteSimulatedGrantConfirmationModalState}
      />
      <Table.Row key={simulatedGrant.id}>
        <Table.Cell variant="Medium/Small">
          <div className="flex items-center gap-2">
            <UserTag className="h-6 w-6 text-black-05" variant="Bulk" />
            <div className="space-y-0.5">
              <div>
                Grant of <FormattedNumber value={simulatedGrant.shares} />{" "}
                shares
              </div>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Caption"
              >
                {simulatedGrant.type === "PRE_MONEY"
                  ? "Pre conversion"
                  : "Post conversion"}
              </Typography>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="space-y-0.5">
            <Typography as="div" variant="Medium/Small">
              <FormattedNumber value={simulatedGrant.shares} />
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              <Percentage value={simulatedGrant.preConversionOwnership} />
            </Typography>
            <Typography as="div" variant="Medium/Caption">
              -
            </Typography>
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="space-y-0.5">
            <Typography as="div" variant="Medium/Small">
              <FormattedNumber value={simulatedGrant.shares} />
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              <Percentage value={simulatedGrant.postConversionOwnership} />
            </Typography>
            <Typography as="div" variant="Medium/Caption">
              {isNil(simulatedGrant.postConversionValueInUSD) ? (
                "-"
              ) : (
                <FormattedNumber
                  currency="USD"
                  style="currency"
                  value={simulatedGrant.postConversionValueInUSD}
                />
              )}
            </Typography>
          </div>
        </Table.Cell>
        {!readOnly && (
          <Table.Cell>
            <div className="flex items-center justify-end gap-2">
              <Button
                onClick={showDeleteSimulatedGrantConfirmationModal}
                size="extra small"
                type="button"
                variant="Tertiary Link"
              >
                Delete
              </Button>
              <Button
                onClick={openSimulateAGrantFormModal}
                size="extra small"
                type="button"
                variant="Secondary Outline"
              >
                Edit
              </Button>
            </div>
          </Table.Cell>
        )}
      </Table.Row>
    </>
  );
}

function GrantSimulationTable() {
  const {
    computedValues: {
      postConversionCurrentPricePerShare,
      postConversionFullyDilutedShares,
      simulatedGrantsWithComputedValues,
    },
    onSimulatedGrantAdded,
    state: { optionPoolShares, preConversionFullyDilutedShares },
  } = useSafeSimulatorContext();

  const {
    setFalse: closeSimulateAGrantFormModal,
    setTrue: openSimulateAGrantFormModal,
    value: simulateAGrantFormModalIsOpen,
  } = useBoolean(false);

  const handleSimulateAGrantFormSubmit = useCallback(
    async (formData: FormValues) => {
      await onSimulatedGrantAdded(formData);

      closeSimulateAGrantFormModal();
    },
    [closeSimulateAGrantFormModal, onSimulatedGrantAdded],
  );

  if (
    preConversionFullyDilutedShares === null ||
    postConversionFullyDilutedShares === null
  ) {
    return null;
  }

  return (
    <>
      <SimulateAGrantFormModal
        maximumShares={optionPoolShares}
        onClose={closeSimulateAGrantFormModal}
        onSubmit={handleSimulateAGrantFormSubmit}
        postConversionCurrentPricePerShare={postConversionCurrentPricePerShare}
        postConversionFullyDilutedShares={postConversionFullyDilutedShares}
        preConversionFullyDilutedShares={preConversionFullyDilutedShares}
        show={simulateAGrantFormModalIsOpen}
      />
      <RainbowCard
        innerClassName="col-span-full grid grid-cols-subgrid"
        outerClassName="col-span-full grid grid-cols-subgrid"
        padded={false}
      >
        <Table
          borderless
          className="col-span-full grid-cols-subgrid"
          display="grid"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Grant simulation</Table.HeaderCell>
              <Table.HeaderCell>Pre conversion</Table.HeaderCell>
              <Table.HeaderCell>Post conversion</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {simulatedGrantsWithComputedValues.map((simulatedGrant) => (
              <GrantSimulationTableRow
                key={simulatedGrant.id}
                simulatedGrant={simulatedGrant}
              />
            ))}

            <Table.Row>
              <Table.Cell className="py-4">
                <Button
                  onClick={openSimulateAGrantFormModal}
                  size="extra small"
                  type="button"
                  variant="Primary Full"
                >
                  Simulate grant
                </Button>
              </Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </RainbowCard>
    </>
  );
}

const EditInitialSharesRow: React.FC<{
  organization: null | SafeSimulatorCalculatorContent_Organization$data;
}> = ({ organization }) => {
  const {
    setFalse: closeModal,
    setTrue: openModal,
    value: modalIsShown,
  } = useBoolean();
  return (
    <div className="col-span-full px-4">
      <EditSafeSimulatorInitialSharesModal
        onClose={closeModal}
        organizationFragment={organization}
        show={modalIsShown}
      />
      <Button
        onClick={openModal}
        size="extra small"
        type="button"
        variant="Secondary Outline"
      >
        Edit
      </Button>
    </div>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment SafeSimulatorCalculatorContent_Organization on Organization {
    ...EditSafeSimulatorInitialSharesModal_Organization
  }
`;

export const SafeSimulatorCalculatorContent: React.FC<{
  organizationFragment: null | SafeSimulatorCalculatorContent_Organization$key;
  readOnly?: boolean;
}> = ({ organizationFragment, readOnly }) => {
  const organization =
    useFragment(ORGANIZATION_FRAGMENT, organizationFragment) ?? null;
  return (
    <div className="w-full">
      <div className="flex-grow space-y-4">
        <div className="grid grid-cols-[356px_1fr_1fr_auto] gap-x-2 gap-y-4">
          <CapitalizationTable />
          {!readOnly && <EditInitialSharesRow organization={organization} />}
          <SAFENotesTable readOnly={readOnly} />
          <FullyDilutedSharesTable />
          <GrantSimulationTable />
        </div>
        <SafeCalculatorDisclaimer />
      </div>
    </div>
  );
};
