import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useAsync } from "react-use";
import { z } from "zod";

import { Page } from "../../components/Page";
import { Button } from "../../components/ui/Button";
import { FormRow } from "../../components/ui/Form/FormRow";
import { Input } from "../../components/ui/Form/Inputs/Input";
import { InfoCard } from "../../components/ui/InfoCard";
import { LoadingSpinner } from "../../components/ui/LoadingSpinner";
import { LogoWithoutText } from "../../components/ui/Logo/Logo";
import { PasswordInput } from "../../components/ui/PasswordInput";
import { Typography } from "../../components/ui/Typography";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { APPLICATION_ROUTES } from "../../paths";
import { AuthenticationAPIClient } from "../../services/AuthenticationAPIClient";
import NotFoundPage from "../NotFound/NotFound";
import PasswordResetTokenExpiredCard from "./PasswordResetTokenExpiredCard";
import {
  PasswordStrengthInfoCard,
  passwordValidationSchema,
  validatePasswordStrength,
} from "./PasswordStrength";

const formSchema = z.object({
  password: passwordValidationSchema(),
});

export type FormInputs = z.infer<typeof formSchema>;

const ROLES = ["board-member", "admin", "grantee", "equity-viewer"] as const;

type Role = (typeof ROLES)[number];

const ROLE_LABEL_MAP: Record<Role, string> = {
  admin: "an Admin",
  "board-member": "a Board Member",
  "equity-viewer": "an Equity Viewer",
  grantee: "a Grantee",
};

const isRole = (string: string): string is Role => {
  return ROLES.includes(string as Role);
};

interface Props {
  email: string;
  organization: string;
  passwordResetToken: string;
  role: Role;
}

const JoinTheTeamPage_ = ({
  email,
  organization,
  passwordResetToken,
  role,
}: Props) => {
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      password: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = form.handleSubmit(async (formData) => {
    const loginResult = await AuthenticationAPIClient.updatePassword({
      email,
      newPassword: formData.password,
      passwordResetToken,
    });

    if (loginResult.outcome === "FAILURE") {
      throw new Error(loginResult.reason);
    } else {
      navigate(generatePath(APPLICATION_ROUTES["roleSelection"], {}), {
        replace: true,
      });
    }
  });

  const validatePasswordResetTokenState = useAsync(() => {
    return AuthenticationAPIClient.validatePasswordResetToken({
      email,
      passwordResetToken,
    });
  }, [email, passwordResetToken]);

  const password = form.watch("password");

  const validatePasswordStrengthResult = useMemo(
    () => validatePasswordStrength(password),
    [password],
  );

  if (validatePasswordResetTokenState.loading)
    return (
      <div className="flex h-full w-full justify-center align-middle">
        <LoadingSpinner className="h-10 w-10" />
      </div>
    );

  if (validatePasswordResetTokenState.error) {
    throw validatePasswordResetTokenState.error;
  }

  if (!validatePasswordResetTokenState.value) {
    throw new Error("empty state value");
  }

  if (validatePasswordResetTokenState.value.outcome === "FAILURE") {
    if (
      validatePasswordResetTokenState.value.reason ===
      "EXPIRED_PASSWORD_RESET_TOKEN"
    ) {
      return <PasswordResetTokenExpiredCard email={email} />;
    }

    return (
      <InfoCard
        title="Oops! Something seems wrong with this URL"
        variant="error"
      >
        Reason: {validatePasswordResetTokenState.value.reason}
      </InfoCard>
    );
  }

  const { account } = validatePasswordResetTokenState.value;

  return (
    <AuthLayout.Card
      logo={<LogoWithoutText />}
      subtitle={
        <>
          Hello {account.firstName} {account.lastName}, you have been invited as{" "}
          <span className="text-primary">{email}</span>
        </>
      }
      title={
        <>
          Join the team <span className="text-primary-05">{organization}</span>
          <br />
          as {ROLE_LABEL_MAP[role]}!
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <FormRow className="hidden" id="email" label="Email">
          <Input
            autoComplete="email"
            disabled
            placeholder="Email address"
            type="email"
            value={email}
          />
        </FormRow>

        <FormRow
          className="mt-2"
          error={form.formState.errors.password?.message}
          label="Create a password *"
        >
          <PasswordInput
            autoComplete="new-password"
            id="new-password"
            placeholder="Enter a strong password"
            {...form.register("password")}
          />
        </FormRow>

        {form.formState.errors.password &&
          !validatePasswordStrengthResult.passwordIsStrongEnough && (
            <PasswordStrengthInfoCard className="mt-6" password={password} />
          )}

        <Button
          className="mt-6"
          fullWidth
          loading={form.formState.isSubmitting}
          type="submit"
        >
          Sign Up
        </Button>

        <Typography
          as="div"
          className="mt-6 text-gray-08"
          variant="Regular/Extra Small"
        >
          By clicking “Sign Up” you agree to our{" "}
          <a
            className="font-medium text-primary"
            href="https://www.easop.com/privacy/terms-of-use"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            className="font-medium text-primary"
            href="https://www.easop.com/privacy/privacy-policy"
          >
            Privacy Policy
          </a>
        </Typography>
      </form>
    </AuthLayout.Card>
  );
};

const JoinTheTeamPage = () => {
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const passwordResetToken = searchParams.get("passwordResetToken");
  const role = searchParams.get("role");
  const organizationParam = searchParams.get("organization");

  // We want to make sure that if someone pastes the URL twice the page still works properly
  const organization = organizationParam?.split("https")[0] || "";

  if (
    !email ||
    !passwordResetToken ||
    !role ||
    !isRole(role) ||
    !organization
  ) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Authentication"
      analyticsName="Authentication - Join the team"
      title="Authentication - Join the team"
    >
      <JoinTheTeamPage_
        email={email}
        organization={organization}
        passwordResetToken={passwordResetToken}
        role={role}
      />
    </Page>
  );
};

export default JoinTheTeamPage;
