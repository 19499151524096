import React, { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationIdParam } from "../paths";
import { GranteeForm_DefaultHRISProviderEmployee$key } from "./__generated__/GranteeForm_DefaultHRISProviderEmployee.graphql";
import { GranteeFormSlide_Grantee$key } from "./__generated__/GranteeFormSlide_Grantee.graphql";
import { GranteeFormSlide_Organization$key } from "./__generated__/GranteeFormSlide_Organization.graphql";
import { ConnectHRISProvidersMessage } from "./ConnectHRISProvidersMessage";
import { GranteeForm, useGranteeForm } from "./GranteeForm";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { SlideOver } from "./ui/SlideOver";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeFormSlide_Organization on Organization {
    isOriginatingFromRemoteEquity
    ...GranteeForm_Organization
    ...ConnectHRISProvidersMessage_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeFormSlide_Grantee on Grantee {
    ...GranteeForm_Grantee
  }
`;

type State = {
  defaultHRISProviderEmployeeFragment?: GranteeForm_DefaultHRISProviderEmployee$key | null;
  defaultValues?: null | Parameters<typeof useGranteeForm>[0]["defaultValues"];
  granteeFragment?: GranteeFormSlide_Grantee$key | null;
  open: boolean;
};

export type GranteeFormSlideState = State;

export const useGranteeFormSlideState = (initialState?: State) => {
  const [granteeFormSlideState, setState] = React.useState<State>(
    initialState ?? {
      open: false,
    },
  );

  const openGranteeFormSlideInEditMode = (
    granteeFragment: GranteeFormSlide_Grantee$key,
  ) => {
    setState({
      granteeFragment,
      open: true,
    });
  };

  const openGranteeFormSlideInCreateMode = ({
    defaultHRISProviderEmployeeFragment,
    defaultValues,
  }: {
    defaultHRISProviderEmployeeFragment?: GranteeForm_DefaultHRISProviderEmployee$key;
    defaultValues?: Parameters<typeof useGranteeForm>[0]["defaultValues"];
  } = {}) => {
    setState({
      defaultHRISProviderEmployeeFragment,
      defaultValues,
      open: true,
    });
  };

  const closeGranteeFormSlide = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }));
  };

  return {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInCreateMode,
    openGranteeFormSlideInEditMode,
  };
};

const GranteeFormSlideContent: React.FC<
  {
    defaultValues?: Parameters<typeof useGranteeForm>[0]["defaultValues"];
    granteesRelayConnectionIds?: string[];
    onCancel: () => void;
    organizationFragment: GranteeFormSlide_Organization$key;
    state: State;
  } & Pick<
    React.ComponentProps<typeof GranteeForm>,
    | "ignoredHRISProviderEmployeesFragment"
    | "onGranteeCreated"
    | "onGranteeDeleted"
    | "onGranteeUpdated"
  >
> = ({
  granteesRelayConnectionIds,
  ignoredHRISProviderEmployeesFragment,
  onCancel,
  onGranteeCreated,
  onGranteeDeleted,
  onGranteeUpdated,
  organizationFragment,
  state,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, state.granteeFragment ?? null);
  const organizationId = useOrganizationIdParam();

  const { control, handleSubmit, isSubmitting, reset, setValue, watch } =
    useGranteeForm({
      defaultValues: state.defaultValues,
      granteeFragment: grantee,
      organizationId,
    });

  return (
    <div className="space-y-6 overflow-y-auto p-10">
      {!organization.isOriginatingFromRemoteEquity && (
        <ConnectHRISProvidersMessage organizationFragment={organization} />
      )}
      <GranteeForm
        control={control}
        defaultGranteeFragment={grantee}
        defaultHRISProviderEmployeeFragment={
          state.defaultHRISProviderEmployeeFragment
        }
        formReset={reset}
        granteesRelayConnectionIds={granteesRelayConnectionIds}
        handleSubmit={handleSubmit}
        id="grantee-form"
        ignoredHRISProviderEmployeesFragment={
          ignoredHRISProviderEmployeesFragment
        }
        onCancel={onCancel}
        onGranteeCreated={onGranteeCreated}
        onGranteeDeleted={onGranteeDeleted}
        onGranteeUpdated={onGranteeUpdated}
        organizationFragment={organization}
        setValue={setValue}
        submissionInProgress={isSubmitting}
        watch={watch}
      />
    </div>
  );
};

export const GranteeFormSlide: React.FC<
  React.ComponentProps<typeof GranteeFormSlideContent>
> = (props) => {
  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={props.onCancel} padding={10}>
          {props.state.granteeFragment ? "Edit grantee" : "New grantee"}
        </SlideOver.Header>
      }
      onClose={props.onCancel}
      show={props.state.open}
    >
      <Suspense fallback={<LoadingPlaceholder />}>
        <GranteeFormSlideContent {...props} />
      </Suspense>
    </SlideOver>
  );
};
