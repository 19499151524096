/**
 * @generated SignedSource<<e0b643f67b0fe8e15387fc6a04925fe1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteesTableRow_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Deferred_Grantee">;
  readonly " $fragmentType": "GranteesTableRow_Grantee";
};
export type GranteesTableRow_Grantee$key = {
  readonly " $data"?: GranteesTableRow_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteesTableRow_Grantee",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "kind": "FragmentSpread",
          "name": "GranteesTableRow_Deferred_Grantee"
        }
      ]
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "bd3d5b2464d07ce493ed7bd390aa9fc8";

export default node;
