/**
 * @generated SignedSource<<e64e45b5b0a40d4402445f5bd4816efd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
export type GranteeStatus = "Active" | "Terminated";
export type HRISProvider = "DEEL" | "REMOTE";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GranteesTableRow_Deferred_Grantee$data = {
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly ctmsGrantsCount: number;
  readonly draftGrantCount: number;
  readonly email: string;
  readonly equityGridLevel: {
    readonly name: string;
  } | null;
  readonly hRISProviderEmployee: {
    readonly hRISProvider: HRISProvider;
  } | null;
  readonly hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal: boolean;
  readonly hasPortalAccess: boolean;
  readonly id: string;
  readonly isDeletable: boolean;
  readonly isTerminable: boolean;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly offGrid: boolean;
  readonly plannedGrantCount: number;
  readonly status: GranteeStatus;
  readonly tenure: {
    readonly months: number;
    readonly years: number;
  } | null;
  readonly terminationInformations: {
    readonly terminationDate: string;
  } | null;
  readonly totalExercisedSharesBreakdown: {
    readonly total: number;
  };
  readonly totalGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly totalVestedSharesBreakdown: {
    readonly total: number;
  };
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee" | "GranteeNameWithCountryFlag_Grantee" | "GranteeStatusTag_Grantee" | "InviteGranteesModal_Grantees" | "NewEquitySelectionModal_DefaultGrantee" | "PortalAccessTag_Grantee" | "RevokeGranteeAccessModal_Grantee">;
  readonly " $fragmentType": "GranteesTableRow_Deferred_Grantee";
};
export type GranteesTableRow_Deferred_Grantee$key = {
  readonly " $data"?: GranteesTableRow_Deferred_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Deferred_Grantee">;
};

import GranteesTableRow_Deferred_GranteeRefetchQuery_graphql from './GranteesTableRow_Deferred_GranteeRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": GranteesTableRow_Deferred_GranteeRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "GranteesTableRow_Deferred_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DurationInYearsAndMonths",
      "kind": "LinkedField",
      "name": "tenure",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "months",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "years",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offGrid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsGrantsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftGrantCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedGrantCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalGrantedSharesBreakdown",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalVestedSharesBreakdown",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalExercisedSharesBreakdown",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPortalAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTerminable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationInformations",
      "kind": "LinkedField",
      "name": "terminationInformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "hRISProviderEmployee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hRISProvider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeStatusTag_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortalAccessTag_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteesModal_Grantees"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "NewEquitySelectionModal_DefaultGrantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RevokeGranteeAccessModal_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNameWithCountryFlag_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "494320a46686ebb3d4daa2eeeaa4a731";

export default node;
