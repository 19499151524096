import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import ReactMaskedInput, { Mask } from "react-text-mask";
import { createNumberMask } from "text-mask-addons";

import { Input } from "./Input";

const NumberInput: React.FC<
  {
    allowDecimal?: boolean;
    decimalLimit?: number;
  } & Pick<
    React.ComponentProps<typeof Input>,
    | "after"
    | "before"
    | "className"
    | "disabled"
    | "invalid"
    | "onChange"
    | "placeholder"
    | "prefix"
    | "value"
  >
> = ({
  after,
  allowDecimal = false,
  before,
  className,
  decimalLimit = 2,
  disabled,
  invalid,
  onChange,
  placeholder,
  prefix = "",
  value,
}) => {
  const currencyMask = createNumberMask({
    allowDecimal,
    allowLeadingZeroes: false,
    allowNegative: false,
    decimalLimit, // how many digits allowed after the decimal
    decimalSymbol: ".",
    includeThousandsSeparator: true,
    prefix,
    suffix: "",
    thousandsSeparatorSymbol: ",",
  }) as Mask;

  return (
    <Input.Wrapper
      after={after}
      before={before}
      className={className}
      disabled={disabled}
      invalid={invalid}
    >
      <ReactMaskedInput
        defaultValue={value}
        mask={currencyMask}
        onChange={(event?: { target: { value: string } }) => {
          const value = event?.target.value
            ? event.target.value.replace(/[^0-9.-]+/g, "")
            : "";

          onChange?.({
            target: { value },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        placeholder={placeholder}
        value={value}
      />
    </Input.Wrapper>
  );
};

const FormNumberInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & React.ComponentProps<typeof NumberInput>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumberInput {...field} {...props} value={field.value ?? ""} />
      )}
    />
  );
};

const CurrencyInput: React.FC<React.ComponentProps<typeof NumberInput>> = ({
  allowDecimal = true,
  prefix = "$",
  ...props
}) => <NumberInput {...props} allowDecimal={allowDecimal} prefix={prefix} />;

const FormCurrencyInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & React.ComponentProps<typeof CurrencyInput>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CurrencyInput {...field} {...props} value={field.value ?? ""} />
      )}
    />
  );
};

export const MaskedInput = {
  Currency: CurrencyInput,
  FormCurrency: FormCurrencyInput,
  FormNumber: FormNumberInput,
  Number: NumberInput,
};
