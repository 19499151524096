import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { ConnectToDeelButton_GenerateOrganizationDeelAuthorizationFlowURLMutation } from "./__generated__/ConnectToDeelButton_GenerateOrganizationDeelAuthorizationFlowURLMutation.graphql";
import { ConnectToDeelButton_Organization$key } from "./__generated__/ConnectToDeelButton_Organization.graphql";
import { Button } from "./ui/Button";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConnectToDeelButton_Organization on Organization {
    id
  }
`;

const GENERATE_ORGANIZATION_DEEL_AUTHORIZATION_FLOW_URL_MUTATION = graphql`
  mutation ConnectToDeelButton_GenerateOrganizationDeelAuthorizationFlowURLMutation(
    $organizationId: OrganizationId!
    $returnURL: String!
  ) {
    generateOrganizationDeelAuthorizationFlowURL(
      organizationId: $organizationId
      returnURL: $returnURL
    )
  }
`;

export const ConnectToDeelButton: React.FC<
  {
    organizationFragment: ConnectToDeelButton_Organization$key;
  } & Omit<React.ComponentProps<typeof Button>, "loading" | "onClick">
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const {
    setTrue: setDeelAuthorizationIsLoading,
    value: deelAuthorizationFlowIsLoading,
  } = useBoolean(false);

  const [generateOrganizationDeelAuthorizationFlowUrl] =
    useSafeMutation<ConnectToDeelButton_GenerateOrganizationDeelAuthorizationFlowURLMutation>(
      GENERATE_ORGANIZATION_DEEL_AUTHORIZATION_FLOW_URL_MUTATION,
    );

  const handleConnectToDeelButtonClick = useCallback(async () => {
    setDeelAuthorizationIsLoading();

    const result = await generateOrganizationDeelAuthorizationFlowUrl({
      variables: {
        organizationId: organization.id,
        returnURL: window.location.href,
      },
    });

    window.location.href = result.generateOrganizationDeelAuthorizationFlowURL;
  }, [
    generateOrganizationDeelAuthorizationFlowUrl,
    organization.id,
    setDeelAuthorizationIsLoading,
  ]);

  return (
    <Button
      loading={deelAuthorizationFlowIsLoading}
      onClick={handleConnectToDeelButtonClick}
      type="button"
      {...props}
    />
  );
};
