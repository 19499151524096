import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation } from "./__generated__/ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation.graphql";
import { ConnectToRemoteButton_Organization$key } from "./__generated__/ConnectToRemoteButton_Organization.graphql";
import { Button } from "./ui/Button";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConnectToRemoteButton_Organization on Organization {
    id
  }
`;

const GENERATE_ORGANIZATION_REMOTE_AUTHORIZATION_FLOW_URL_MUTATION = graphql`
  mutation ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation(
    $organizationId: OrganizationId!
    $returnURL: String!
  ) {
    generateOrganizationRemoteAuthorizationFlowURL(
      organizationId: $organizationId
      returnURL: $returnURL
    )
  }
`;

export const ConnectToRemoteButton: React.FC<
  {
    organizationFragment: ConnectToRemoteButton_Organization$key;
  } & Omit<React.ComponentProps<typeof Button>, "loading" | "onClick">
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const {
    setTrue: setRemoteAuthorizationIsLoading,
    value: remoteAuthorizationFlowIsLoading,
  } = useBoolean(false);

  const [generateOrganizationRemoteAuthorizationFlowUrl] =
    useSafeMutation<ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation>(
      GENERATE_ORGANIZATION_REMOTE_AUTHORIZATION_FLOW_URL_MUTATION,
    );

  const handleConnectToRemoteButtonClick = useCallback(async () => {
    setRemoteAuthorizationIsLoading();

    const result = await generateOrganizationRemoteAuthorizationFlowUrl({
      variables: {
        organizationId: organization.id,
        returnURL: window.location.href,
      },
    });

    window.location.href =
      result.generateOrganizationRemoteAuthorizationFlowURL;
  }, [
    generateOrganizationRemoteAuthorizationFlowUrl,
    organization.id,
    setRemoteAuthorizationIsLoading,
  ]);

  return (
    <Button
      loading={remoteAuthorizationFlowIsLoading}
      onClick={handleConnectToRemoteButtonClick}
      type="button"
      {...props}
    />
  );
};
