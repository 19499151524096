/**
 * @generated SignedSource<<25571ee6d0cc6d620f800689f80d4108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ValuationWarningMessage_Organization$data = {
  readonly id: string;
  readonly lastestUKValuation: {
    readonly remainingDaysBeforeExpiration: number;
  } | null;
  readonly latestFairMarketValue: {
    readonly manualInvalidationDate: string | null;
    readonly remainingDaysBeforeExpiration: number;
  } | null;
  readonly " $fragmentType": "ValuationWarningMessage_Organization";
};
export type ValuationWarningMessage_Organization$key = {
  readonly " $data"?: ValuationWarningMessage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ValuationWarningMessage_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingDaysBeforeExpiration",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ValuationWarningMessage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualInvalidationDate",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UKValuation",
      "kind": "LinkedField",
      "name": "lastestUKValuation",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "6370d43f90871dbfa0771a4fa6c1ff25";

export default node;
