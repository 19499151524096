/**
 * @generated SignedSource<<2417b33892255a23aad1d4af6a599127>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionCards_ValueToday_Organization$data = {
  readonly fullyDilutedShares: number | null;
  readonly latestPricePerShare: {
    readonly value: number;
  };
  readonly " $fragmentType": "QuestionCards_ValueToday_Organization";
};
export type QuestionCards_ValueToday_Organization$key = {
  readonly " $data"?: QuestionCards_ValueToday_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionCards_ValueToday_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionCards_ValueToday_Organization",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "PricePerShare",
        "kind": "LinkedField",
        "name": "latestPricePerShare",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "latestPricePerShare"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "13342bca71e61775fd1a120b5ba2f3d3";

export default node;
