import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { LargeCenteredDetailsLayout } from "../../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { AddNew_Mutation } from "./__generated__/AddNew_Mutation.graphql";
import { AddNew_Query } from "./__generated__/AddNew_Query.graphql";
import { FormValues, useFormSchema } from "./FORM_SCHEMA";
import { PostTerminationForm } from "./PostTerminationForm";

const QUERY = graphql`
  query AddNew_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      hasCooleyAsOutsideCounsel
      termOfOptionsInMonths
      ...LargeCenteredDetailsLayout_Organization
      ...PostTerminationForm_Organization
    }
  }
`;

const CREATE_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_MUTATION = graphql`
  mutation AddNew_Mutation(
    $input: CreateOrganizationPostTerminationExercisePeriodInput!
  ) {
    createOrganizationPostTerminationExercisePeriod(input: $input) {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        id
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        id
      }
    }
  }
`;

export default function AddNew() {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<AddNew_Query>(QUERY, {
    organizationId,
  });
  const navigate = useNavigate();

  const [createOrganizationPostTerminationExercisePeriod] =
    useSafeMutation<AddNew_Mutation>(
      CREATE_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_MUTATION,
    );

  const form = useForm<FormValues>({
    defaultValues: {
      type: "FIXED",
    },
    resolver: zodResolver(
      useFormSchema({
        organizationHasCooleyAsOutsideCounsel:
          query.organization.hasCooleyAsOutsideCounsel,
        organizationTermOfOptionsInMonths:
          query.organization.termOfOptionsInMonths,
      }),
    ),
  });

  const parentLink = generatePath(
    APPLICATION_ROUTES["organizationEquityConfigurePostTermination"],
    {
      organizationId,
    },
  );

  const handleSubmit = form.handleSubmit(async (values) => {
    await createOrganizationPostTerminationExercisePeriod({
      variables: {
        input: {
          organizationId,
          ...values,
        },
      },
    });

    navigate(parentLink, {
      replace: true,
    });
  });

  return (
    <Page
      analyticsCategory="Admin Configure Post Termination"
      analyticsName="Admin - Equity - Configure - Post Termination - Add New"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} equity settings | add new post-termination exercise period`}
    >
      <LargeCenteredDetailsLayout
        organizationFragment={query.organization}
        parents={[
          {
            link: parentLink,
            title: "Post-termination",
          },
        ]}
        subtitle="Define the post-termination exercise period parameters below"
        title="Add new post-termination exercise period"
      >
        <div className="space-y-10">
          <PostTerminationForm
            control={form.control}
            id="create-post-termination-exercise-period-form"
            onSubmit={handleSubmit}
            organizationFragment={query.organization}
          />
          <div className="flex items-center gap-2">
            <Button
              form="create-post-termination-exercise-period-form"
              loading={form.formState.isSubmitting}
              size="small"
              type="submit"
            >
              Create post-termination exercise period
            </Button>
            <LinkButton size="small" to={parentLink} variant="Secondary Full">
              Cancel
            </LinkButton>
          </div>
        </div>
      </LargeCenteredDetailsLayout>
    </Page>
  );
}
