import _, { chain, isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import {
  useDownloadeGranteeTerminationGrantAmendmentBoardConsentPreview,
  useDownloadGrantBoardConsentPreview,
  useDownloadGrantsAmendmentsBoardConsentPreview,
} from "../../hooks/useDownloadBoardConsentPreview";
import { Button } from "../ui/Button";
import { Divider } from "../ui/Divider";
import { useFileInputUploadController } from "../ui/Form/FileInput";
import { SlideOver } from "../ui/SlideOver";
import { Typography } from "../ui/Typography";
import { ConfirmSubmitBoardConsentSlide_EasopGrants$key } from "./__generated__/ConfirmSubmitBoardConsentSlide_EasopGrants.graphql";
import { ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest$key } from "./__generated__/ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest.graphql";
import { ConfirmSubmitBoardConsentSlide_Organization$key } from "./__generated__/ConfirmSubmitBoardConsentSlide_Organization.graphql";
import {
  AcknowledgementSection,
  use409ARenewalObligationAcknowledgement,
  useBoardMembersListConfirmation,
  useEligibilityAcknowledgments,
  useOutsideEasopBoardConsentAcknowledgement,
} from "./AcknowledgementSection";
import { BoardMembersSection } from "./BoardMembersSection";
import { BoardNoteSection } from "./BoardNoteSection";
import { ExportSelectedItemsSection } from "./ExportSelectedItemsSection";
import { RadioGroupSection } from "./RadioGroupSection";
import { SelfManagedBoardConsentDetailsSection } from "./SelfManagedBoardConsentDetailsSection";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConfirmSubmitBoardConsentSlide_Organization on Organization {
    id
    boardMembers {
      id
    }
    allowBoardConsentHandledOutsideEasop
    ...BoardNoteSection_Organization
    ...BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization
    ...AcknowledgementSection_ConfirmSubmitBoardConsentSlide_Organization
    ...useDownloadBoardConsentPreview_Organization
  }
`;

const EASOP_GRANTS_FRAGMENT = graphql`
  fragment ConfirmSubmitBoardConsentSlide_EasopGrants on EasopGrant
  @relay(plural: true) {
    id
    instrument {
      id
      requiresEligibilityAcknowledgmentBeforeGranting
      ...AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment
    }
    ...ExportSelectedItemsSection_EasopGrants
  }
`;

const GRANTEE_TERMINATION_CTMS_GRANT_AMENDMENT_REQUESTS_FRAGMENT = graphql`
  fragment ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest
  @relay(plural: true) {
    id
    ...ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest
  }
`;

export const ConfirmSubmitBoardConsentSlide: React.FC<
  {
    onClose: () => void;
    onSubmit: (props: {
      acknowledged409ARenewalObligation: boolean;
      boardConsentHandledOutsideEasop: boolean;
      boardConsentSignatureDate?: null | string;
      confirmedBoardMembersList: boolean;
      dontShow409ARenewalObligationBeforeSendingGrants: boolean;
      dontShowBoardMembersListConfirmationBeforeSendingGrants: boolean;
      grantsRequireEligibilityAcknowledgmentChecked: boolean;
      signedBoardConsentDocumentIds?: null | string[];
    }) => void;
    open: boolean;
    organizationFragment: ConfirmSubmitBoardConsentSlide_Organization$key;
    selectedItemsCount: number;
    submissionInProgress: boolean;
  } & (
    | {
        ctmsGrantAmendmentRequestsIds: string[];
        easopGrantsFragment?: undefined;
        granteeTerminationCTMSGrantAmendmentRequestsFragment?: undefined;
        type: "GRANT_AMENDMENT_REQUEST";
      }
    | {
        ctmsGrantAmendmentRequestsIds?: undefined;
        easopGrantsFragment: ConfirmSubmitBoardConsentSlide_EasopGrants$key;
        granteeTerminationCTMSGrantAmendmentRequestsFragment?: undefined;
        type: "GRANT";
      }
    | {
        ctmsGrantAmendmentRequestsIds?: undefined;
        easopGrantsFragment?: undefined;
        granteeTerminationCTMSGrantAmendmentRequestsFragment: ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest$key;
        type: "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST";
      }
  )
> = ({
  ctmsGrantAmendmentRequestsIds,
  easopGrantsFragment,
  granteeTerminationCTMSGrantAmendmentRequestsFragment,
  onClose,
  onSubmit,
  open,
  organizationFragment,
  selectedItemsCount,
  submissionInProgress,
  type,
}) => {
  const easopGrants = useFragment(EASOP_GRANTS_FRAGMENT, easopGrantsFragment);
  const granteeTerminationCTMSGrantAmendmentRequests = useFragment(
    GRANTEE_TERMINATION_CTMS_GRANT_AMENDMENT_REQUESTS_FRAGMENT,
    granteeTerminationCTMSGrantAmendmentRequestsFragment,
  );

  const instrumentsRequiringEligibilityAcknowledgment = useMemo(() => {
    return _(easopGrants ?? [])
      .map((grant) => grant.instrument)
      .filter((instrument) => {
        return instrument.requiresEligibilityAcknowledgmentBeforeGranting;
      })
      .uniqBy((instrument) => instrument.id)
      .value();
  }, [easopGrants]);

  const [boardConsentSignatureDate, setBoardConsentSignatureDate] = useState<
    null | string
  >(null);

  const someGrantsRequireEligibilityAcknowledgment = useMemo(
    () => !isEmpty(instrumentsRequiringEligibilityAcknowledgment),
    [instrumentsRequiringEligibilityAcknowledgment],
  );

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const acknowledgement409ARenewalObligation =
    use409ARenewalObligationAcknowledgement({
      organizationFragment: organization,
    });
  const confirmationBoardMembersList = useBoardMembersListConfirmation({
    organizationFragment: organization,
  });

  const eligibilityAcknowledgments = useEligibilityAcknowledgments({
    instrumentsFragment: instrumentsRequiringEligibilityAcknowledgment ?? [],
    organizationId: organization.id,
  });

  const boardConsentHandledOutsideEasopDefaultValue = useMemo(() => {
    if (!organization.allowBoardConsentHandledOutsideEasop) {
      return false;
    }

    return undefined;
  }, [organization.allowBoardConsentHandledOutsideEasop]);

  const [boardConsentHandledOutsideEasop, setBoardConsentHandledOutsideEasop] =
    useState<boolean | undefined>(boardConsentHandledOutsideEasopDefaultValue);

  const outsideEasopBoardConsentAcknowledgement =
    useOutsideEasopBoardConsentAcknowledgement({
      shouldBeDisplayed: boardConsentHandledOutsideEasop ?? false,
      type,
    });

  const acknowledgments = useMemo(() => {
    switch (type) {
      case "GRANT":
        return chain([
          outsideEasopBoardConsentAcknowledgement,
          ...eligibilityAcknowledgments.items,
          confirmationBoardMembersList,
          acknowledgement409ARenewalObligation,
        ])
          .compact()
          .filter(({ shouldBeDisplayed }) => shouldBeDisplayed)
          .value();
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return chain([outsideEasopBoardConsentAcknowledgement])
          .compact()
          .filter(({ shouldBeDisplayed }) => shouldBeDisplayed)
          .value();
    }
  }, [
    type,
    eligibilityAcknowledgments.items,
    confirmationBoardMembersList,
    acknowledgement409ARenewalObligation,
    outsideEasopBoardConsentAcknowledgement,
  ]);

  const {
    documentIds,
    inputProps,
    reset: resetFileInputUploadController,
    someItemsAreNotReady,
  } = useFileInputUploadController({
    accept: "*",
    multiple: true,
  });

  const submitButtonDisabled = useMemo(() => {
    if (someItemsAreNotReady) {
      return true;
    }

    if (boardConsentHandledOutsideEasop === undefined) {
      return true;
    }

    if (acknowledgments.some(({ confirmed }) => !confirmed)) {
      return true;
    }

    if (
      boardConsentHandledOutsideEasop === false &&
      isEmpty(organization.boardMembers)
    ) {
      return true;
    }

    if (
      boardConsentHandledOutsideEasop === true &&
      (!boardConsentSignatureDate || documentIds.length === 0)
    ) {
      return true;
    }

    return false;
  }, [
    someItemsAreNotReady,
    boardConsentHandledOutsideEasop,
    acknowledgments,
    organization.boardMembers,
    boardConsentSignatureDate,
    documentIds.length,
  ]);

  const showBoardConsentHandledOutsideEasopRadioGroup =
    organization.allowBoardConsentHandledOutsideEasop;

  const resetForm = useCallback(() => {
    setBoardConsentHandledOutsideEasop(
      boardConsentHandledOutsideEasopDefaultValue,
    );
    setBoardConsentSignatureDate(null);
    resetFileInputUploadController();
  }, [
    boardConsentHandledOutsideEasopDefaultValue,
    resetFileInputUploadController,
  ]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      acknowledged409ARenewalObligation:
        acknowledgement409ARenewalObligation?.confirmed ?? false,
      boardConsentHandledOutsideEasop: boardConsentHandledOutsideEasop ?? false,
      boardConsentSignatureDate: boardConsentHandledOutsideEasop
        ? boardConsentSignatureDate
        : null,
      confirmedBoardMembersList:
        confirmationBoardMembersList?.confirmed ?? false,
      dontShow409ARenewalObligationBeforeSendingGrants:
        acknowledgement409ARenewalObligation?.dontAskMeAgain ?? false,
      dontShowBoardMembersListConfirmationBeforeSendingGrants:
        confirmationBoardMembersList?.dontAskMeAgain ?? false,
      grantsRequireEligibilityAcknowledgmentChecked:
        someGrantsRequireEligibilityAcknowledgment ? true : false,
      signedBoardConsentDocumentIds: boardConsentHandledOutsideEasop
        ? documentIds
        : null,
    });
  }, [
    acknowledgement409ARenewalObligation?.confirmed,
    acknowledgement409ARenewalObligation?.dontAskMeAgain,
    boardConsentHandledOutsideEasop,
    boardConsentSignatureDate,
    confirmationBoardMembersList?.confirmed,
    confirmationBoardMembersList?.dontAskMeAgain,
    documentIds,
    onSubmit,
    someGrantsRequireEligibilityAcknowledgment,
  ]);
  const applicationName = useApplicationName();

  const submitButtonLabel = useMemo(() => {
    if (boardConsentHandledOutsideEasop) {
      return (
        <FormattedMessage
          defaultMessage={`Import {documentCount, plural, one {document} other {documents}}`}
          values={{
            documentCount: documentIds.length,
          }}
        />
      );
    }

    switch (type) {
      case "GRANT":
        return (
          <FormattedMessage
            defaultMessage={`Send {grantsCount, plural, one {draft} other {drafts}} to board`}
            values={{
              grantsCount: selectedItemsCount,
            }}
          />
        );
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return (
          <FormattedMessage
            defaultMessage={`Send {amendmentRequestsCount, plural, one {amendment request} other {amendment requests}} to board`}
            values={{
              amendmentRequestsCount: selectedItemsCount,
            }}
          />
        );
    }
  }, [
    boardConsentHandledOutsideEasop,
    documentIds.length,
    selectedItemsCount,
    type,
  ]);

  const {
    downloadBoardConsentPreview,
    downloadIsInFlight: _downloadIsInFlight,
  } = useDownloadGrantBoardConsentPreview({
    easopGrantsIds: easopGrants?.map((grant) => grant.id) ?? [],
    organizationFragment: organization,
  });

  const {
    downloadGranteeTerminationGrantAmendmentBoardConsentPreview,
    downloadIsInFlight: __downloadIsInFlight,
  } = useDownloadeGranteeTerminationGrantAmendmentBoardConsentPreview({
    granteeTerminationCTMSGrantAmendmentRequestIds:
      granteeTerminationCTMSGrantAmendmentRequests?.map(
        (grantAmendmentRequest) => grantAmendmentRequest.id,
      ) ?? [],
    organizationId: organization.id,
  });

  const {
    downloadGrantAmendmentBoardConsentPreview,
    downloadIsInFlight: ___downloadIsInFlight,
  } = useDownloadGrantsAmendmentsBoardConsentPreview({
    ctmsGrantAmendmentRequestIds: ctmsGrantAmendmentRequestsIds ?? [],
    organizationId: organization.id,
  });

  const downloadIsInFlight =
    _downloadIsInFlight || __downloadIsInFlight || ___downloadIsInFlight;

  const onPreviewBoardConsentClick = useCallback(async () => {
    switch (type) {
      case "GRANT":
        await downloadBoardConsentPreview();
        break;
      case "GRANT_AMENDMENT_REQUEST":
        await downloadGrantAmendmentBoardConsentPreview();
        break;
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        await downloadGranteeTerminationGrantAmendmentBoardConsentPreview();
        break;
    }
  }, [
    type,
    downloadBoardConsentPreview,
    downloadGranteeTerminationGrantAmendmentBoardConsentPreview,
    downloadGrantAmendmentBoardConsentPreview,
  ]);

  return (
    <SlideOver
      floating
      header={
        <SlideOver.Header onClose={onClose}>
          Proceed with board approval
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={resetForm}
      show={open}
    >
      <div className="space-y-4 p-6">
        {showBoardConsentHandledOutsideEasopRadioGroup && (
          <>
            <RadioGroupSection
              boardConsentHandledOutsideEasop={boardConsentHandledOutsideEasop}
              setBoardConsentHandledOutsideEasop={
                setBoardConsentHandledOutsideEasop
              }
              type={type}
            />
            <Divider />
          </>
        )}

        {(boardConsentHandledOutsideEasop === false ||
          !organization.allowBoardConsentHandledOutsideEasop) && (
          <>
            <BoardMembersSection organizationFragment={organization} />
            <Divider />

            <BoardNoteSection organizationFragment={organization} type={type} />
            <Divider />
          </>
        )}

        {boardConsentHandledOutsideEasop && (
          <>
            <ExportSelectedItemsSection
              ctmsGrantAmendmentRequestsIds={
                ctmsGrantAmendmentRequestsIds ?? []
              }
              easopGrantsFragment={easopGrants ?? []}
              granteeTerminationCTMSGrantAmendmentRequestsFragment={
                granteeTerminationCTMSGrantAmendmentRequests ?? []
              }
              onPreviewBoardConsentClick={onPreviewBoardConsentClick}
              previewBoardConsentIsInFlight={downloadIsInFlight}
              type={type}
            />
            <Divider />
            <SelfManagedBoardConsentDetailsSection
              boardConsentSignatureDate={boardConsentSignatureDate}
              fileInputItems={inputProps.items}
              onFileInputItemRemoved={inputProps.onItemRemoved}
              onFilesAdded={inputProps.onFilesAdded}
              setBoardConsentSignatureDate={setBoardConsentSignatureDate}
              type={type}
            />
            <Divider />
          </>
        )}

        {typeof boardConsentHandledOutsideEasop === "boolean" &&
          !isEmpty(acknowledgments) && (
            <>
              <AcknowledgementSection acknowledgments={acknowledgments} />
              <Divider />
            </>
          )}

        <div className="space-y-4">
          <div className="flex justify-between gap-4">
            <Button
              loading={downloadIsInFlight}
              onClick={onPreviewBoardConsentClick}
              size="small"
              type="button"
              variant="Secondary Outline"
            >
              Preview board consent
            </Button>
            <div className="flex gap-4">
              <Button
                onClick={onClose}
                size="small"
                type="button"
                variant="Secondary Full"
              >
                Cancel
              </Button>
              <Button
                disabled={submitButtonDisabled}
                loading={submissionInProgress}
                onClick={handleSubmit}
                size="small"
              >
                {submitButtonLabel}
              </Button>
            </div>
          </div>
          {boardConsentHandledOutsideEasop === true && (
            <Typography
              as="div"
              className="text-right text-gray-09"
              variant="Regular/Caption"
            >
              The {applicationName} team will implement the grants in your Cap
              Table Management Solution afterwards
            </Typography>
          )}
          {boardConsentHandledOutsideEasop === false && (
            <Typography
              as="div"
              className="text-right text-gray-09"
              variant="Regular/Caption"
            >
              Board members will be notified of the new approval via email
            </Typography>
          )}
        </div>
      </div>
    </SlideOver>
  );
};
