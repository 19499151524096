import classNames from "classnames";
import { useCallback, useState } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from "recharts";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { EsopChartGrantedStacked_Organization$key } from "./__generated__/EsopChartGrantedStacked_Organization.graphql";
import { Percentage } from "./Percentage";
import { RoundedBarShape, RoundedBarShapeProps } from "./ui/RoundedBarShape";
import { Typography } from "./ui/Typography";

type ShareCategory =
  | "availablePending"
  | "availableWithoutPending"
  | "currentApproval"
  | "granted"
  | "grantedSettling"
  | "grantedToVest"
  | "grantedVestedExercised"
  | "grantedVestedToExercise";

interface BarData {
  className: string;
  key: ShareCategory;
  label: string;
  percent: null | number;
  quantity: number;
  stackId?: string;
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment EsopChartGrantedStacked_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
  }
`;

export const EsopChartGrantedStacked: React.FC<{
  availablePending: number;
  availableWithoutPending: number;
  currentApproval: number;
  grantedSettling: number;
  grantedToVest: number;
  grantedVestedExercised: number;
  grantedVestedToExercise: number;
  height?: number;
  legendPosition?: "bottom" | "top";
  organizationFragment: EsopChartGrantedStacked_Organization$key;
}> = ({
  availablePending,
  availableWithoutPending,
  currentApproval,
  grantedSettling,
  grantedToVest,
  grantedVestedExercised,
  grantedVestedToExercise,
  height = 190,
  legendPosition = "bottom",
  organizationFragment,
}) => {
  const [hoveredBar, setHoveredBar] = useState<null | ShareCategory>(null);

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { sharesToFullyDilutedRatio } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const toBar = useCallback(
    (barsData: BarData[]) =>
      barsData.map((barData, i) => (
        <Bar
          activeBar={false}
          className={barData.className}
          dataKey={barData.key}
          key={barData.key}
          name={barData.label}
          onMouseEnter={() => setHoveredBar(barData.key)}
          onMouseLeave={() => setHoveredBar(null)}
          shape={
            !barData.stackId || i === barsData.length - 1
              ? (props: unknown) => (
                  <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                )
              : undefined
          }
          stackId={barData.stackId}
          unit="%"
        />
      )),
    [],
  );

  const currentApprovalFullyDilutedRatio =
    sharesToFullyDilutedRatio(currentApproval);
  const availableWithoutPendingFullyDilutedRatio = sharesToFullyDilutedRatio(
    availableWithoutPending,
  );
  const availablePendingFullyDilutedRatio =
    sharesToFullyDilutedRatio(availablePending);
  const grantedToVestFullyDilutedRatio =
    sharesToFullyDilutedRatio(grantedToVest);
  const grantedVestedToExerciseFullyDilutedRatio = sharesToFullyDilutedRatio(
    grantedVestedToExercise - grantedSettling,
  );
  const grantedSettlingFullyDilutedRatio =
    sharesToFullyDilutedRatio(grantedSettling);
  const grantedVestedExercisedFullyDilutedRatio = sharesToFullyDilutedRatio(
    grantedVestedExercised,
  );

  const currentApprovalBarData: BarData = {
    className: classNames("bg-primary-05 fill-primary-05"),
    key: "currentApproval",
    label: "Current Approval",
    percent:
      currentApprovalFullyDilutedRatio !== null
        ? currentApprovalFullyDilutedRatio * 100
        : null,
    quantity: currentApproval,
  };
  const barsData: BarData[] = [
    {
      className: classNames("bg-yellow-05 fill-yellow-05"),
      key: "availableWithoutPending",
      label: "Available",
      percent:
        availableWithoutPendingFullyDilutedRatio !== null
          ? availableWithoutPendingFullyDilutedRatio * 100
          : null,
      quantity: availableWithoutPending,
      stackId: "available",
    },
    {
      className: classNames("bg-yellow-03 fill-yellow-03"),
      key: "availablePending",
      label: "Other Pending Grants",
      percent:
        availablePendingFullyDilutedRatio !== null
          ? availablePendingFullyDilutedRatio * 100
          : null,
      quantity: availablePending,
      stackId: "available",
    },
    currentApprovalBarData,
    {
      className: classNames("bg-gray-09 fill-gray-09"),
      key: "grantedToVest",
      label: "Granted (To vest)",
      percent:
        grantedToVestFullyDilutedRatio !== null
          ? grantedToVestFullyDilutedRatio * 100
          : null,
      quantity: grantedToVest,
      stackId: "granted",
    },
    {
      className: classNames("bg-gray-07 fill-gray-07"),
      key: "grantedVestedToExercise",
      label: "Granted (Vested to exercised)",
      percent:
        grantedVestedToExerciseFullyDilutedRatio !== null
          ? grantedVestedToExerciseFullyDilutedRatio * 100
          : null,
      quantity: grantedVestedToExercise - grantedSettling,
      stackId: "granted",
    },
    {
      className: classNames("bg-gray-06 fill-gray-06"),
      key: "grantedSettling",
      label: "Granted (Settling)",
      percent:
        grantedSettlingFullyDilutedRatio !== null
          ? grantedSettlingFullyDilutedRatio * 100
          : null,
      quantity: grantedSettling,
      stackId: "granted",
    },
    {
      className: classNames("bg-gray-05 fill-gray-05"),
      key: "grantedVestedExercised",
      label: "Granted (Vested and exercised)",
      percent:
        grantedVestedExercisedFullyDilutedRatio !== null
          ? grantedVestedExercisedFullyDilutedRatio * 100
          : null,
      quantity: grantedVestedExercised,
      stackId: "granted",
    },
  ];

  const availableBarsDataNoZero = barsData.filter(
    (barData) => barData.stackId === "available" && barData.quantity > 0,
  );

  const grantedBarsDataNoZero = barsData.filter(
    (barData) => barData.stackId === "granted" && barData.quantity > 0,
  );

  const barsDataNoZero = [
    ...availableBarsDataNoZero,
    currentApprovalBarData,
    ...grantedBarsDataNoZero,
  ];

  const legend = [
    {
      className: classNames("bg-yellow-05 fill-yellow-05"),
      key: "available",
      label: "Available",
      value: availableWithoutPending + availablePending,
    },
    currentApprovalBarData,
    {
      className: classNames("bg-gray-09 fill-gray-09"),
      key: "granted",
      label: "Granted",
      value: grantedToVest + grantedVestedToExercise + grantedVestedExercised,
    },
  ];

  return (
    <div
      className={classNames(
        "mt-6 flex gap-6",
        legendPosition === "bottom" ? "flex-col" : "flex-col-reverse",
      )}
    >
      <ResponsiveContainer height={height} width="100%">
        <BarChart
          barGap={16}
          barSize={64}
          data={[
            {
              availablePending:
                availablePendingFullyDilutedRatio !== null
                  ? availablePendingFullyDilutedRatio * 100
                  : null,
              availableWithoutPending:
                availableWithoutPendingFullyDilutedRatio !== null
                  ? availableWithoutPendingFullyDilutedRatio * 100
                  : null,
              currentApproval:
                currentApprovalFullyDilutedRatio !== null
                  ? currentApprovalFullyDilutedRatio * 100
                  : null,
              grantedSettling:
                grantedSettlingFullyDilutedRatio !== null
                  ? grantedSettlingFullyDilutedRatio * 100
                  : null,
              grantedToVest:
                grantedToVestFullyDilutedRatio !== null
                  ? grantedToVestFullyDilutedRatio * 100
                  : null,
              grantedVestedExercised:
                grantedVestedExercisedFullyDilutedRatio !== null
                  ? grantedVestedExercisedFullyDilutedRatio * 100
                  : null,
              grantedVestedToExercise:
                grantedVestedToExerciseFullyDilutedRatio !== null
                  ? grantedVestedToExerciseFullyDilutedRatio * 100
                  : null,
            },
          ]}
        >
          <CartesianGrid
            className="stroke-gray-04"
            strokeDasharray="3 3"
            vertical={false}
          />
          <YAxis
            axisLine={false}
            className="stroke-gray-08"
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: 12,
            }}
            tickLine={false}
            tickMargin={28}
            unit="%"
          />
          <Tooltip
            content={({ active }) => {
              if (!active || !hoveredBar) return null;

              const item = barsDataNoZero.find(
                (barData) => barData.key === hoveredBar,
              );

              if (!item) return;

              return (
                <div className="rounded bg-black-07 px-4 py-2 text-left">
                  <Typography
                    as="div"
                    className="text-white"
                    variant="Regular/Extra Small"
                  >
                    {item.label}
                  </Typography>

                  {item.percent !== null && (
                    <Typography
                      as="div"
                      className="mt-1 text-gray-04"
                      variant="Regular/Caption"
                    >
                      <Percentage value={item.percent / 100} /> fully diluted
                    </Typography>
                  )}

                  <Typography
                    as="div"
                    className="mt-1 text-gray-04"
                    variant="Regular/Caption"
                  >
                    <FormattedNumber value={item.quantity} /> shares
                  </Typography>
                </div>
              );
            }}
            cursor={false}
          />
          {[
            ...toBar(availableBarsDataNoZero),
            ...toBar([currentApprovalBarData]),
            ...toBar(grantedBarsDataNoZero),
          ]}
        </BarChart>
      </ResponsiveContainer>

      <ul className="flex justify-center gap-8">
        {legend.map((legendItem) => (
          <li key={`item-${legendItem.key}`}>
            <div className="flex gap-2">
              <div
                className={classNames(
                  "mt-1 h-2.5 w-2.5 rounded-sm",
                  legendItem.className,
                )}
              />
              <div>
                <Typography
                  as="div"
                  className="text-gray-09"
                  variant="Regular/Caption"
                >
                  {legendItem.label}
                </Typography>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
